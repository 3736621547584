import React, { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import "css/StockList.css";
import { alpha } from "@mui/material/styles";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import axios from "axios";
import defaultString from "constants/defaultString.json";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import AlertDialog from "components/AlertDialog/AlertDialog";
import { read, utils, writeFile } from "xlsx";
import WarningIcon from "@mui/icons-material/Warning";
import { AuthContext } from "context/AuthContext";
import { resetToken } from "apiURL";
import {
  Add,
  ArrowDropDown,
  KeyboardArrowDown,
  Upload,
} from "@mui/icons-material";
import { Breadcrumbs, Menu, MenuItem } from "@mui/material";

export default function StockList() {
  let navigate = useNavigate();

  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  // const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchContent, setSearchContent] = useState("");
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [limit, setLimit] = useState(10);
  const { dispatch } = useContext(AuthContext);
  const hiddenFileInput = React.useRef(null);
  const [manufacturer, setManufacturer] = useState([]);
  const [modelNo, setModelNo] = useState([]);
  const [serialNo, setSerialNo] = useState([]);
  const [productType, setProductType] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [displayedItemsManufacturer, setDisplayedItemsManufacturer] =
    useState(5); // Number of items initially displayed
  const [showAllManufacturer, setShowAllManufacturer] = useState(false);
  const [displayedItemsModelNo, setDisplayedItemsModelNo] = useState(5);
  const [showAllModelNo, setShowAllModelNo] = useState(false);
  const [displayedItemsSerialNo, setDisplayedItemsSerialNo] = useState(5);
  const [showAllSerialNo, setShowAllSerialNo] = useState(false);
  const [displayedItemsProductType, setDisplayedItemsProductType] = useState(5);
  const [clearFiltersClicked, setClearFiltersClicked] = useState(false);
  const [showAllProductType, setShowAllProductType] = useState(false);
  function EnhancedTableHead(props) {
    const { t } = useTranslation();
    const { onSelectAllClick, numSelected, rowCount } = props;

    const headCells = [
      {
        id: "brand",
        numeric: false,
        disablePadding: true,
        label: t("brand"),
      },
      {
        id: "modelNumber",
        numeric: true,
        disablePadding: false,
        label: t("modelNo"),
      },
      {
        id: "containerNumber",
        numeric: true,
        disablePadding: false,
        label: t("containerNo"),
      },
      {
        id: "palletNumber",
        numeric: true,
        disablePadding: false,
        label: t("palletNo"),
      },
      {
        id: "serialNumber",
        numeric: true,
        disablePadding: false,
        label: t("serialNumber"),
      },
      {
        id: "pmax",
        numeric: true,
        disablePadding: false,
        label: t("pmax"),
      },
      {
        id: "type",
        numeric: true,
        disablePadding: false,
        label: t("type"),
      },
      {
        id: "status",
        numeric: true,
        disablePadding: false,
        label: t("status"),
      },
    ];

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    // const {t} = useTranslation();
    return <Box></Box>;
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setSearchContent("");
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    // console.log(selectedIndex)
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await fnStockList(newPage + 1);
    setSelected([]);
  };

  const handleChangeRowsPerPage = async (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    // console.log(event.target.value)
    // console.log(rowsPerPage)
    // console.log(parseInt(event.target.value, 10))
    setLimit(parseInt(event.target.value, 10));
    await fnStockList(1);
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const navigateToUpdate = (data) => {
    // navigate('/admin/edit-stock',{state: {data}})
  };

  const handleStockButtonClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleImportStock = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          console.log(rows);
          fnAddStock(rows);
        } else {
          setOpen(true);
          setErrorMsg("No file available to upload");
          return;
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const fnAddStock = async (rows) => {
    setIsLoading(true);
    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));

    const arrStock = [];
    if (rows.length) {
      let user = JSON.parse(localStorage.getItem("user"));

      rows.map((row, index) => {
        let objStock = {};
        let productTypeId = "";

        if (row.Type == "Solar Panel") {
          productTypeId = localStorage.getItem("solarpanel");
        } else if (row.Type == "Inverter") {
          productTypeId = localStorage.getItem("inverter");
        } else if (row.Type == "Battery") {
          productTypeId = localStorage.getItem("storage");
        } else if (row.Type == "Hybrid Inverter") {
          productTypeId = localStorage.getItem("hybrid_inverter");
        } else if (row.Type == "Accessory") {
          productTypeId = localStorage.getItem("accessory");
        } else if (row.Type == "Carport") {
          productTypeId = localStorage.getItem("carport");
        } else if (row.Type == "Mounting") {
          productTypeId = localStorage.getItem("mounting");
        }

        if (row.ModelNo && row.ContainerNo && row.PalletNo && row.SerialNo) {
          objStock.userId = user._id;
          objStock.modelNo = row.ModelNo;
          objStock.containerNo = row.ContainerNo;
          objStock.palletNo = row.PalletNo;
          objStock.serialNo = row.SerialNo;
          objStock.p_max = row.Pmax.toFixed(2);
          objStock.productType = productTypeId;
          objStock.createdBy = user._id;

          arrStock.push(objStock);
        }
      });

      // console.log(arrStock);

      await axios
        .post(
          apiURL.service_add_stock,
          { data: arrStock },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
        .then(async (res) => {
          console.log(res.data);
          if (res.status === apiStatusCode.CREATED) {
            await fnStockList(currentPage);
            await fnStockFilter();
          } else {
            console.log("In else portion ", res.data);
            setOpen(true);
            setErrorMsg(res.data.data.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error.response);
          setOpen(true);
          if (error.code === defaultString.network_error)
            setErrorMsg(error.message);
          else setErrorMsg(error.response.data.message);
          setIsLoading(false);
          if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
            doLogout(error);
          }
        });
    } else {
      setOpen(true);
      setErrorMsg(defaultString.validData);
      setIsLoading(false);
    }
  };

  const fnStockList = async (page) => {
    setIsLoading(true);

    let params = {};

    const selectedManufacturer = manufacturer
      .filter((option) => checkedItems.includes(option?._id?._id))
      .map((option) => option?._id?._id);

    const selectedModelNo = modelNo
      .filter((option) => checkedItems.includes(option._id))
      .map((option) => option._id);
    const selectedSerialNo = serialNo
      .filter((option) => checkedItems.includes(option._id))
      .map((option) => option._id);
    const selectedProductType = productType
      .filter((option) => checkedItems.includes(option._id._id))
      .map((option) => option._id._id);

    if (selectedManufacturer.length > 0) {
      params.brands = selectedManufacturer;
    }
    if (selectedModelNo.length > 0) {
      params.modelNos = selectedModelNo;
    }
    if (selectedSerialNo.length > 0) {
      params.serialNos = selectedSerialNo;
    }
    if (selectedProductType.length > 0) {
      params.productTypes = selectedProductType;
    }
    params.page = page;
    params.limit = limit;
    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));

    // console.log('current page in list funcion ', page)
    // console.log('searchString ',searchString)
    await axios
      .post(apiURL.service_list_stock, params, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        if (res.status === apiStatusCode.SUCCESS) {
          console.log(res.data.data);
          setRows(res.data.data.stock);
          // setTotalPages(res.data.data.totalPages)
          setTotalRecords(res.data.data.totalRecords);
        } else {
          console.log("In else portion ", res.data);
          setOpen(true);
          setErrorMsg(res.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setOpen(true);
        if (error.code === defaultString.network_error)
          setErrorMsg(error.message);
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === 404) {
            setOpen(false);
          } else if (error.response.data.status === 401) {
            setErrorMsg(error.response.data.message);
          }
        }

        if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
          doLogout(error);
        }
        setRows([]);
        setTotalRecords(0);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    if (clearFiltersClicked) {
      setClearFiltersClicked(false);
      fnStockList(currentPage);
    }
    fnStockList(currentPage);
    fnStockFilter();
  }, [currentPage, clearFiltersClicked, limit]);

  const confirmDeleteStock = async () => {
    console.log("selected:: ", selected);
    await fnDeleteStock();
    setOpenDeleteAlert(false);
  };

  const handleDeleteAlertClose = () => {
    setOpenDeleteAlert(false);
    setSelected([]);
  };

  const deleteStock = () => {
    setOpenDeleteAlert(true);
  };

  const fnDeleteStock = async () => {
    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));

    console.log("token ", token);
    console.log("ids ", selected);

    const objProduct = JSON.stringify({
      stockIds: selected,
    });

    console.log(objProduct);

    await axios
      .post(apiURL.service_delete_stock, objProduct, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.status === apiStatusCode.DELETED) {
          setOpen(true);
          setErrorMsg(res.data.message);

          const filteredArr = rows.filter(
            (item) => !selected.includes(item._id)
          );
          setRows(filteredArr);

          setSelected([]);
          await fnStockList(page + 1);
        } else {
          setOpen(true);
          setErrorMsg(res.data.data.message);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setOpen(true);
        if (error.code === defaultString.network_error)
          setErrorMsg(error.message);
        else setErrorMsg(error.response.data.message);
        if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
          doLogout(error);
        }
      });
  };

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const doLogout = async (resErr) => {
    const data = await resetToken();
    if (data === true) {
      dispatch({ type: "LOGOUT", payload: null });
      navigate("/", {
        state: { isModalPopup: true, errorMsg: resErr.response.data.message },
      });
    }
  };

  const fnStockFilter = async () => {
    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));
    await axios
      .get(apiURL.service_stock_filter_data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.status === apiStatusCode.SUCCESS) {
          setManufacturer(res.data.data.brands);
          setModelNo(res.data.data.modelNos);
          setSerialNo(res.data.data.serialNos);
          setProductType(res.data.data.productTypes);
          // setOpen(true);
          // setErrorMsg(res.data.message);
        } else {
          setOpen(true);
          setErrorMsg(res.data.data.message);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setOpen(true);
        if (error.code === defaultString.network_error)
          setErrorMsg(error.message);
        else setErrorMsg(error.response.data.message);
        // if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
        //   doLogout(error)
        // }
      });
  };

  const handleCheckboxChange = (optionId) => {
    if (checkedItems.includes(optionId)) {
      setCheckedItems(checkedItems.filter((id) => id !== optionId));
    } else {
      setCheckedItems([...checkedItems, optionId]);
    }
  };

  const handleClearFilters = () => {
    setCheckedItems([]);

    setClearFiltersClicked(true);
  };

  const [anchorElManufacturer, setAnchorElManufacturer] = React.useState(null);
  const Manufactureropen = Boolean(anchorElManufacturer);
  const handleManufacturerClick = (event) => {
    setAnchorElManufacturer(event.currentTarget);
  };
  const handleManufacturerClose = () => {
    setAnchorElManufacturer(null);
  };

  const [anchorElModelNo, setAnchorElModelNo] = React.useState(null);
  const ModelNoopen = Boolean(anchorElModelNo);
  const handleModelNoClick = (event) => {
    setAnchorElModelNo(event.currentTarget);
  };
  const handleModelNoClose = () => {
    setAnchorElModelNo(null);
  };

  const [anchorElSerialNo, setAnchorElSerialNo] = React.useState(null);
  const SerialNoopen = Boolean(anchorElSerialNo);
  const handleSerialNoClick = (event) => {
    setAnchorElSerialNo(event.currentTarget);
  };
  const handleSerialNoClose = () => {
    setAnchorElSerialNo(null);
  };

  const [anchorElProductType, setAnchorElProductType] = React.useState(null);
  const ProductTypeopen = Boolean(anchorElProductType);
  const handleProductTypeClick = (event) => {
    setAnchorElProductType(event.currentTarget);
  };
  const handleProductTypeClose = () => {
    setAnchorElProductType(null);
  };

  return (
    <>
      <Container fluid className="">
        <Box className="d-flex justify-content-between align-items-center">
          <Box sx={{ color: "var(--primary-color)" }}>
            <h2>
              <strong>{t("stock")}</strong>
            </h2>
          </Box>

          <Box>
            <Link to="">
              <Button
                variant="contained"
                size="small"
                color="success"
                sx={{
                  color: "white",
                  backgroundColor: "var(--primary-color)",
                  border: "none",
                  padding: "6px 16px",
                  fontWeight: "700",
                  borderRadius: "4px",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "var(--primary-color-hover)",
                    cursor: "pointer",
                  },
                }}
                startIcon={<Upload />}
              >
                {t("Upload")}
              </Button>
            </Link>
          </Box>
        </Box>

        <Box mt={1} className="display-flex">
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleManufacturerClick}
            endIcon={<KeyboardArrowDown />}
            sx={{
              color: "var(--title-color)",
            }}
          >
            {t("manufacturer")}
          </Button>

          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleModelNoClick}
            endIcon={<KeyboardArrowDown />}
            sx={{
              color: "var(--title-color)",
            }}
          >
            {t("modelNo")}
          </Button>

          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleSerialNoClick}
            endIcon={<KeyboardArrowDown />}
            sx={{
              color: "var(--title-color)",
            }}
          >
            {t("serialNumber")}
          </Button>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleProductTypeClick}
            endIcon={<KeyboardArrowDown />}
            sx={{
              color: "var(--title-color)",
            }}
          >
            {t("productType")}
          </Button>
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorElManufacturer}
          open={Manufactureropen}
          onClose={handleManufacturerClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem className="d-flex align-items-center px-4">
            <input
              className="me-3 mt-0 form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            Hausan
          </MenuItem>
          <MenuItem className="d-flex align-items-center px-4">
            <input
              className="me-3 mt-0 form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            Profile
          </MenuItem>
          <MenuItem className="d-flex align-items-center px-4">
            <input
              className="me-3 mt-0 form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            Profile
          </MenuItem>
        </Menu>

        <Menu
          id="basic-menu"
          anchorEl={anchorElModelNo}
          open={ModelNoopen}
          onClose={handleModelNoClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem className="d-flex align-items-center px-4">
            <input
              className="me-3 mt-0 form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            HS-1234512
          </MenuItem>
          <MenuItem className="d-flex align-items-center px-4">
            <input
              className="me-3 mt-0 form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            HS-786512
          </MenuItem>
          <MenuItem className="d-flex align-items-center px-4">
            <input
              className="me-3 mt-0 form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            HS-B144DS450W1
          </MenuItem>
          <MenuItem className="d-flex align-items-center px-4">
            <input
              className="me-3 mt-0 form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            HS-123245654
          </MenuItem>
          <MenuItem className="d-flex align-items-center px-4">
            <input
              className="me-3 mt-0 form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            HS-456129
          </MenuItem>
        </Menu>

        <Menu
          id="basic-menu"
          anchorEl={anchorElSerialNo}
          open={SerialNoopen}
          onClose={handleSerialNoClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem className="d-flex align-items-center px-4">
            <input
              className="me-3 mt-0 form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            HAB22081116172
          </MenuItem>
          <MenuItem className="d-flex align-items-center px-4">
            <input
              className="me-3 mt-0 form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            HAB22081113876
          </MenuItem>
          <MenuItem className="d-flex align-items-center px-4">
            <input
              className="me-3 mt-0 form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            HAB22081113738
          </MenuItem>
          <MenuItem className="d-flex align-items-center px-4">
            <input
              className="me-3 mt-0 form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            HAB22081116982
          </MenuItem>
          <MenuItem className="d-flex align-items-center px-4">
            <input
              className="me-3 mt-0 form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            HAB22081113733
          </MenuItem>
        </Menu>

        <Menu
          id="basic-menu"
          anchorEl={anchorElProductType}
          open={ProductTypeopen}
          onClose={handleProductTypeClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem className="d-flex align-items-center px-4">
            <input
              className="me-3 mt-0 form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            {t("solarPanel")}
          </MenuItem>
          <MenuItem className="d-flex align-items-center px-4">
            <input
              className="me-3 mt-0 form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            {t("inverter")}
          </MenuItem>
          <MenuItem className="d-flex align-items-center px-4">
            <input
              className="me-3 mt-0 form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            {t("storage")}
          </MenuItem>
          <MenuItem className="d-flex align-items-center px-4">
            <input
              className="me-3 mt-0 form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            {t("hybrid") + " " + t("invetre")}
          </MenuItem>
          <MenuItem className="d-flex align-items-center px-4">
            <input
              className="me-3 mt-0 form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            {t("accessories")}
          </MenuItem>
        </Menu>

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Box sx={{ width: "100%" }} className="main-area">
            <Paper
              sx={{
                width: "100%",
                mt: 2,
                mb: 2,
                overflow: "hidden",
                borderRadius: "16px",
              }}
            >
              <EnhancedTableToolbar numSelected={selected.length} />
              <TableContainer sx={{ maxHeight: 700 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{ minWidth: 750 }}
                  // aria-labelledby="tableTitle"
                  size="medium"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {rows
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const isItemSelected = isSelected(row._id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                            sx={{ textDecoration: "none" }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                onClick={(event) => handleClick(event, row._id)}
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>

                            <TableCell align="left" sx={{ padding: "0px" }}>
                              {row?.brand?.name}
                            </TableCell>
                            <TableCell align="right" sx={{ padding: "16px" }}>
                              {row.modelNo}
                            </TableCell>
                            <TableCell align="right" sx={{ padding: "16px" }}>
                              {row.containerNo}
                            </TableCell>
                            <TableCell align="right" sx={{ padding: "16px" }}>
                              {row.palletNo}
                            </TableCell>
                            <TableCell align="right" sx={{ padding: "16px" }}>
                              {row.serialNo}
                            </TableCell>
                            <TableCell align="right" sx={{ padding: "16px" }}>
                              {row.p_max}
                            </TableCell>
                            <TableCell align="right" sx={{ padding: "16px" }}>
                              {row.productType.name}
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{ paddingRight: "16px" }}
                            >
                              {row.status == "NotFound" ? (
                                <p>
                                  <WarningIcon style={{ color: "red" }} />
                                  Model not present in db
                                </p>
                              ) : (
                                row.status
                              )}
                            </TableCell>
                            {/* <TableCell align="right">{row.voc}</TableCell>
                                <TableCell align="right">{row.isc}</TableCell>
                                <TableCell align="right">{row.ff}</TableCell>
                                <TableCell align="right">{row.current}</TableCell> */}
                            {/* <TableCell align="right" className='stock'>
                                <div className='d-flex no-wrap justify-content-end'>

                                  <Button variant='contained' size='medium' color='success' className='ms-3' onClick={() => { navigateToUpdate(row) }}
                                    sx={{
                                      color: "#FD841F",
                                      backgroundColor: "#FEF1E6",
                                      border: 'none',
                                      padding: "12px 18px",
                                      fontWeight: 700,
                                      fontSize: '16px',
                                      boxShadow: "none",
                                      "&:hover": {
                                        color: 'white',
                                        backgroundColor: '#FD841F',
                                        cursor: 'pointer',
                                      },
                                    }}
                                  >
                                    {t('edit')}
                                  </Button>
                                </div>
                              </TableCell> */}
                          </TableRow>
                        );
                      })}
                    {/* {emptyRows > 0 && (
                          <TableRow
                            style={{
                              height: (53) * emptyRows,
                            }}
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )} */}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                style={{ marginTop: "8px" }}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        )}
      </Container>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "80%" }}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>

      <AlertDialog
        open={openDeleteAlert}
        title={selected.length === 1 ? "Delete Stock" : "Delete Stocks"}
        message={
          selected.length === 1
            ? "Are you sure you want to delete the stock?"
            : "Are you sure you want to delete the stocks?"
        }
        handleAction={confirmDeleteStock}
        handleClose={handleDeleteAlertClose}
      ></AlertDialog>
    </>
  );
}
