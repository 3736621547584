import React, { useState, useEffect, useCallback, useContext } from "react";
// import FileUploadModal from 'FileUploadModal';

import { useNavigate, useLocation } from "react-router";
import { Link, useParams } from "react-router-dom";
import "css/SolarPanel.css";
import { alpha } from "@mui/material/styles";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import axios from "axios";
import defaultString from "constants/defaultString.json";
import StorageNav from "components/StorageNav/StorageNav";
import "css/product.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import AlertDialog from "components/AlertDialog/AlertDialog";
import { saveAs } from "file-saver";
import { resetToken } from "apiURL";
import { AuthContext } from "context/AuthContext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { FaPlus } from "react-icons/fa";
import { Add, Download, Edit } from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import Switch from "@mui/material/Switch";
import { Autocomplete, Divider, Grid, Input, TextField } from "@mui/material";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { MdCancel } from "react-icons/md";
import FileUploadModal from "./FileUploadModal";
import { useSolarPanelValidator } from "hooks/useSolarPanelValidator";
import EditSolarForm from "../SolarPanel/EditSolarForm";
import AddInstaller from "../Storage/Accessories/Add";
import AddCompanyAgent from "../Storage/HybridInverter/Add";
import AddBattery from "../Storage/Batteries/Add";
import AddCarPort from "../CarPort/Add";
import AddInverter from "../Inverter/Edit";
import SkeletonImg from "../../../../src/SkeletonNew.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  maxHeight: "85%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px",
  overflowY: "scroll",
};
const label = { inputProps: { "aria-label": "Switch demo" } };

export default function ProductList(props) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  // const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchContent, setSearchContent] = useState("");
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [productId, setProductId] = useState();
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const { dispatch } = useContext(AuthContext);
  const [isEdit, setIsEdit] = useState(false);
  const [isDisabledSaveBtn, setIsDisabledSaveBtn] = useState(false);
  const [moduleEfficiency, setModuleEfficiency] = useState("");
  const [structure, setStructure] = useState("");
  const [cellType, setCellType] = useState("");
  const [tempPmax, setTempPmax] = useState("");
  const [containerLength, setContainerLength] = useState("");
  const [palletPerContainer, setPalletPerContainer] = useState("");
  const [modulePerPallet, setModulePerPallet] = useState("");
  const [modulePerContainer, setModulePerContainer] = useState("");
  const [description, setDescription] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  let navigate = useNavigate();
  const params = useParams();
  const handleOpen = (data) => {
    setOpenModal(!openModal);
    setData(data);
  };
  const handleClose = () => setOpenModal(false);
  const [status, setStatus] = useState(false);
  const [image, setImage] = useState([]);
  const [mainImage, setMainImage] = useState({ id: 0, logo: null });
  const [logoImageError, setLogoImageError] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [list, setList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [name, setName] = useState(list[0]);
  const [manufacturerError, setManufacturerError] = useState(false);
  const [weight, setWeight] = useState("");
  const [parkingNo, setParkingNo] = useState("");
  const [power, setPower] = useState("");
  const [maxNoBatteryConnection, setMaxNoBatteryConnection] = useState("");
  const [ip, setIp] = useState("");
  const [maxInverter, setMaxInverter] = useState("");
  const [batteryVoltageRange, setBatteryVoltageRange] = useState("");
  const [maxChargingCurrent, setMaxChargingCurrent] = useState("");
  const [form, setForm] = useState({
    modelNo: "",
    p_max: "",
    price: "",
    dimention: "",
  });
  const [photoPower, setPhotoPower] = useState("");
  const [maxVoltage, setMaxVoltage] = useState("");
  const [gridVoltage, setGridVoltage] = useState("");
  const [efficiency, setEfficiency] = useState("");
  const [mppt, setMppt] = useState("");
  const [phases, setPhases] = useState("");
  const [nominalPower, setNominalPower] = useState("");
  const [solarPanel, setSolarPanel] = useState("");
  const [inverter, setInverter] = useState("");
  const [openAddUser, setOpenAddUser] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [inverterObj, setInverterObj] = useState(null);
  const [solarPanelObj, setSolarPanelObj] = useState(null);
  const [mountingObj, setMountingObj] = useState(null);
  const [carportObj, setCarportObj] = useState(null);
  const [accessoryObj, setAccessoryObj] = useState(null);
  const [hybridInverterObj, setHybridInverterObj] = useState(null);
  const [storageObj, setStorageObj] = useState(null);

  const { state } = useLocation();

  function EnhancedTableHead(props) {
    const { t } = useTranslation();
    const { onSelectAllClick, numSelected, rowCount } = props;

    let headCells = [];

    if (params.id === localStorage.getItem("solarpanel")) {
      headCells = [
        {
          id: "product",
          numeric: false,
          disablePadding: true,
          label: t("product"),
        },
        {
          id: "manufacturer",
          numeric: true,
          disablePadding: false,
          label: t("manufacturer"),
        },
        {
          id: "modelNumber",
          numeric: true,
          disablePadding: false,
          label: t("modelNo"),
        },
        {
          id: "pmax",
          numeric: true,
          disablePadding: false,
          label: t("pmax"),
        },
        {
          id: "dimentions",
          numeric: true,
          disablePadding: false,
          label: t("dimentions") + " (mm)",
        },
        {
          id: "price",
          numeric: true,
          disablePadding: false,
          label: t("listPrice"),
        },
        {
          id: "status",
          numeric: true,
          disablePadding: false,
          label: t("status"),
        },
        {
          id: "download",
          numeric: true,
          disablePadding: false,
          label: "",
        },
      ];
    } else if (params.id === localStorage.getItem("inverter")) {
      headCells = [
        {
          id: "product",
          numeric: false,
          disablePadding: true,
          label: t("product"),
        },
        {
          id: "manufacturer",
          numeric: true,
          disablePadding: false,
          label: t("manufacturer"),
        },
        {
          id: "modelNumber",
          numeric: true,
          disablePadding: false,
          label: t("modelNo"),
        },
        {
          id: "power",
          numeric: true,
          disablePadding: false,
          label: t("power"),
        },
        {
          id: "mppt",
          numeric: true,
          disablePadding: false,
          label: t("mpptLabel"),
        },
        {
          id: "price",
          numeric: true,
          disablePadding: false,
          label: t("listPrice"),
        },
        {
          id: "status",
          numeric: true,
          disablePadding: false,
          label: t("status"),
        },
        {
          id: "download",
          numeric: true,
          disablePadding: false,
          label: "",
        },
      ];
    } else if (params.id === localStorage.getItem("storage")) {
      headCells = [
        {
          id: "product",
          numeric: false,
          disablePadding: true,
          label: t("Logo"),
        },
        {
          id: "manufacturer",
          numeric: true,
          disablePadding: false,
          label: t("manufacturer"),
        },
        {
          id: "modelNo",
          numeric: true,
          disablePadding: false,
          label: t("modelNo"),
        },
        {
          id: "power",
          numeric: true,
          disablePadding: false,
          label: t("power"),
        },
        {
          id: "weight",
          numeric: true,
          disablePadding: false,
          label: t("weight"),
        },
        {
          id: "dimentions",
          numeric: true,
          disablePadding: false,
          label: t("dimentions"),
        },
        {
          id: "max",
          numeric: true,
          disablePadding: false,
          label: t("maxBattery"),
        },
        {
          id: "ip",
          numeric: true,
          disablePadding: false,
          label: t("ip"),
        },
        {
          id: "price",
          numeric: true,
          disablePadding: false,
          label: t("price"),
        },
        {
          id: "status",
          numeric: true,
          disablePadding: false,
          label: t("status"),
        },
        {
          id: "download",
          numeric: true,
          disablePadding: false,
          label: "",
        },
      ];
    } else if (params.id === localStorage.getItem("hybrid_inverter")) {
      headCells = [
        {
          id: "product",
          numeric: false,
          disablePadding: true,
          label: t("Logo"),
        },
        {
          id: "manufacturer",
          numeric: true,
          disablePadding: false,
          label: t("manufacturer"),
        },
        {
          id: "modelNo",
          numeric: true,
          disablePadding: false,
          label: t("modelNo"),
        },
        {
          id: "power",
          numeric: true,
          disablePadding: false,
          label: t("power"),
        },
        {
          id: "dimentions",
          numeric: true,
          disablePadding: false,
          label: t("dimentions"),
        },
        {
          id: "voltage",
          numeric: true,
          disablePadding: false,
          label: t("voltageBattery"),
        },
        // {
        //   id: 'mppt',
        //   numeric: true,
        //   disablePadding: false,
        //   label:  t('mpptLabel'),
        // },
        {
          id: "price",
          numeric: true,
          disablePadding: false,
          label: t("price"),
        },
        {
          id: "status",
          numeric: true,
          disablePadding: false,
          label: t("status"),
        },
        {
          id: "download",
          numeric: true,
          disablePadding: false,
          label: "",
        },
      ];
    } else if (params.id === localStorage.getItem("accessory")) {
      headCells = [
        {
          id: "product",
          numeric: false,
          disablePadding: true,
          label: t("product"),
        },
        {
          id: "manufacturer",
          numeric: true,
          disablePadding: false,
          label: t("manufacturer"),
        },
        {
          id: "modelNo",
          numeric: true,
          disablePadding: false,
          label: t("modelNo"),
        },
        {
          id: "description",
          numeric: true,
          disablePadding: false,
          label: t("description"),
        },
        {
          id: "price",
          numeric: true,
          disablePadding: false,
          label: t("price"),
        },

        {
          id: "status",
          numeric: true,
          disablePadding: false,
          label: t("status"),
        },

        {
          id: "statistic",
          numeric: true,
          disablePadding: false,
          label: "",
        },
      ];
    } else if (params.id === localStorage.getItem("carport")) {
      headCells = [
        {
          id: "product",
          numeric: false,
          disablePadding: true,
          label: t("product"),
        },
        {
          id: "manufacturer",
          numeric: true,
          disablePadding: false,
          label: t("manufacturer"),
        },
        {
          id: "modelNumber",
          numeric: true,
          disablePadding: false,
          label: t("modelNo"),
        },
        {
          id: "parkingNo",
          numeric: true,
          disablePadding: false,
          label: t("parkingNo"),
        },
        {
          id: "dimentions",
          numeric: true,
          disablePadding: false,
          label: t("dimentions") + "",
        },
        {
          id: "power",
          numeric: true,
          disablePadding: false,
          label: t("power") + "",
        },
        {
          id: "price",
          numeric: true,
          disablePadding: false,
          label: t("listPrice"),
        },
        {
          id: "status",
          numeric: true,
          disablePadding: false,
          label: t("status"),
        },
        {
          id: "download",
          numeric: true,
          disablePadding: false,
          label: "",
        },
      ];
    } else if (params.id === localStorage.getItem("mounting")) {
      headCells = [
        {
          id: "product",
          numeric: false,
          disablePadding: true,
          label: t("product"),
        },
        {
          id: "manufacturer",
          numeric: true,
          disablePadding: false,
          label: t("manufacturer"),
        },
        {
          id: "modelNumber",
          numeric: true,
          disablePadding: false,
          label: t("modelNo"),
        },
        {
          id: "parkingNo",
          numeric: true,
          disablePadding: false,
          label: t("parkingNo"),
        },
        {
          id: "dimentions",
          numeric: true,
          disablePadding: false,
          label: t("dimentions") + "",
        },
        {
          id: "power",
          numeric: true,
          disablePadding: false,
          label: t("power") + "",
        },
        {
          id: "price",
          numeric: true,
          disablePadding: false,
          label: t("listPrice"),
        },
        {
          id: "status",
          numeric: true,
          disablePadding: false,
          label: t("status"),
        },
        {
          id: "download",
          numeric: true,
          disablePadding: false,
          label: "",
        },
      ];
    }

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  function EnhancedTableToolbar(props) {
    const { numSelected, setRows, originalData } = props;
    const { t } = useTranslation();
    return (
      //
      <div>
        <Button
          variant="contained"
          size="small"
          color="success"
          onClick={() => {
            props.onAddOpen();
            // handleRemoveSearch();
            setRows(originalData);
          }}
          sx={{
            color: "white",
            backgroundColor: "var(--primary-color)",
            border: "none",
            padding: "6px 16px",
            fontWeight: "700",
            borderRadius: "4px",
            "&:hover": {
              color: "white",
              backgroundColor: "var(--primary-color-hover)",
              cursor: "pointer",
            },
          }}
          startIcon={<Add />}
        >
          {t("ADD")}
        </Button>
      </div>
    );
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setSearchContent("");
  };

  const fnProductList = useCallback(
    async (paramsId, page, searchText = "") => {
      let searchString = "";
      if (searchText !== "") {
        searchString = "&search_text=" + searchText;
      }
      // console.log('current page in list funcion ', page)
      // console.log('searchString ',searchString)
      await axios
        .get(
          apiURL.service_list_product +
            `/${paramsId}?page=${page}+&limit=${limit}${searchString}`,
          {
            headers: {
              "Content-Type": "application/json",
              // 'Authorization': token,
            },
          }
        )
        .then((res) => {
          // console.log(res)
          setIsLoading(false);
          if (res.status === apiStatusCode.SUCCESS) {
            // console.log(res.data.data);
            setRows(res.data.data.product);
            // setTotalPages(res.data.data.totalPages)
            setTotalRecords(res.data.data.totalRecords);
          } else {
            // console.log("In else portion ", res.data);
            setOpen(true);
            setErrorMsg(res.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          setOpen(true);
          if (error.code === defaultString.network_error)
            setErrorMsg(error.message);
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === 404) {
              setOpen(false);
            } else if (error.response.data.status === 401) {
              setErrorMsg(error.response.data.message);
            }
          }
          setRows([]);
          setTotalRecords(0);
        });
    },
    [limit]
  );

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    // console.log(selectedIndex)
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await fnProductList(productId, newPage + 1);
    setSelected([]);
  };

  const handleChangeRowsPerPage = async (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    // console.log(event.target.value)
    // console.log(rowsPerPage)
    // console.log(parseInt(event.target.value, 10))
    setLimit(parseInt(event.target.value, 10));
    await fnProductList(productId, 1);
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const navigateToUpdate = (data) => {
    if (productId === localStorage.getItem("solarpanel")) {
      navigate("/admin/add-solar-panel", { state: { data } });
    } else if (productId === localStorage.getItem("inverter")) {
      handleOpen(data);
    } else if (productId === localStorage.getItem("storage")) {
      handleOpen(data);
    } else if (productId === localStorage.getItem("hybrid_inverter")) {
      handleOpen(data);
    } else if (productId === localStorage.getItem("accessory")) {
      handleOpen(data);
    } else if (productId === localStorage.getItem("carport")) {
      handleOpen(data);
    } else if (productId === localStorage.getItem("mounting")) {
      // navigate('/admin/add-inverter',{state: {data}})
    }
    setOpenModal(!openModal);
    setData(data);
  };

  // const confirmDeleteProduct = async () => {
  //   console.log("selected:: ", selected);
  //   // await fnDeleteProduct();
  //   setOpenDeleteAlert(false);
  // };

  // const handleDeleteAlertClose = () => {
  //   setOpenDeleteAlert(false);
  //   setSelected([]);
  // };

  // const deleteProduct = () => {
  //   setOpenDeleteAlert(true);
  // };

  // const fnDeleteProduct = async () => {
  //   const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));

  //   console.log("token ", token);
  //   console.log("ids ", selected);

  //   const objProduct = JSON.stringify({
  //     productDetailIds: selected,
  //   });

  //   console.log(objProduct);

  //   await axios
  //     .post(apiURL.service_delete_products, objProduct, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: token,
  //       },
  //     })
  //     .then(async (res) => {
  //       console.log(res.data);
  //       if (res.data.status === apiStatusCode.DELETED) {
  //         setOpen(true);
  //         setErrorMsg(res.data.message);

  //         const filteredArr = rows.filter(
  //           (item) => !selected.includes(item._id)
  //         );
  //         console.log("", filteredArr);
  //         setRows(filteredArr);
  //         setSelected([]);
  //       } else {
  //         setOpen(true);
  //         setErrorMsg(res.data.data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //       setOpen(true);
  //       if (error.code === defaultString.network_error)
  //         setErrorMsg(error.message);
  //       else setErrorMsg(error.response.data.message);
  //       if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
  //         doLogout(error);
  //       }
  //     });
  // };

  const onDownloadClick = (documents) => {
    if (documents.length > 0) {
      var JSzip = require("jszip");
      var zip = new JSzip();
      const otherFiles = zip.folder("files");
      const list = documents.map(async (doc, index) => {
        const fileUrl = apiURL.siteBaseUrl + doc.document;

        const response = await fetch(fileUrl);
        const data = await response.blob();
        // otherFiles.file(`${doc.document.split("\\productDocument\\")[1]}`, data);
        otherFiles.file(`document_${index + 1}.pdf`, data);
        return data;
      });

      // If all prmises are fullfilled it will call the zip
      // function and download it using the FileSaver library
      Promise.all(list).then(function () {
        zip.generateAsync({ type: "blob" }).then(function (content) {
          saveAs(content, "files");
        });
      });
    } else {
      setOpen(true);
      setErrorMsg("No file to be download");
    }
    setSelected([]);
  };

  useEffect(() => {
    console.log(params.id, "paramId");
    setProductId(params.id);
    setIsLoading(true);
    fnProductList(params.id, page + 1);
  }, [fnProductList, params.id, page]);

  const doLogout = async (resErr) => {
    const data = await resetToken();
    if (data === true) {
      dispatch({ type: "LOGOUT", payload: null });
      navigate("/", {
        state: { isModalPopup: true, errorMsg: resErr.response.data.message },
      });
    }
  };

  const onStatusChange = () => {
    setStatus(!status);
  };

  const handleMainPicture = (event) => {
    // if (Array.from(event.target.files).length > 1) {
    //     event.preventDefault();
    //     // alert(Cannot upload files more than ${defaultString.MAX_LENGTH});
    //     setOpen(true)
    //     setErrorMsg(Cannot upload files more than ${defaultString.MAX_LENGTH})
    //     return;
    // }
    // let file = event.target.files[0]
    const isLt2M = event.target.files[0].size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setOpen(true);
      setErrorMsg("Image must smaller than 2MB!");
      return;
    }
    setMainImage({ id: 0, logo: event.target.files[0] });
    setLogoImageError(false);
    // setIsChangeMainImage(true)
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const handleUpload = () => {
    // Handle the file upload logic here (e.g., send the file to the server).
    // You can use the 'selectedFile' state to access the selected file.
    console.log(selectedFile);

    // Close the modal
    handleClose();
  };

  const [openAdd, setOpenAdd] = React.useState(false);

  const handleOpenAdd = (data) => {
    setOpenAdd(true);
    localStorage.setItem("someKey", JSON.stringify(data));
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    // Handle the selected file (e.g., send it to the server, store it, etc.)
    console.log("Selected file:", selectedFile);
  };

  const [modalOpenFile, setModalOpenFile] = useState(false);

  const openModalFile = () => {
    setModalOpenFile(true);
  };

  const closeModalFile = () => {
    setModalOpenFile(false);
  };

  const { errors, validateForm, onBlurField } = useSolarPanelValidator(form);

  const onUpdateField = (e) => {
    const field = e.target.name;
    const nextFormState = {
      ...form,
      [field]: e.target.value,
    };
    setForm(nextFormState);

    if (errors[field].dirty)
      validateForm({
        form: nextFormState,
        errors,
        field,
      });
  };

  const editDataSolar = (data) => {
    setOpenAddUser(!openAddUser);
    setSolarPanelObj(data);
    setIsEdit(true);
  };

  const editDataInverter = (data) => {
    setOpenAddUser(!openAddUser);
    setInverterObj(data);
    setIsEdit(true);
  };

  const editDataAccessory = (data) => {
    setOpenAddUser(!openAddUser);
    setAccessoryObj(data);
    setIsEdit(true);
  };

  const editDataStorage = (data) => {
    setOpenAddUser(!openAddUser);
    setStorageObj(data);
    setIsEdit(true);
  };

  const editDataHybridInverter = (data) => {
    setOpenAddUser(!openAddUser);
    setHybridInverterObj(data);
    setIsEdit(true);
  };

  const editDataCarport = (data) => {
    setOpenAddUser(!openAddUser);
    setCarportObj(data);
    setIsEdit(true);
  };

  const editDataMounting = (data) => {
    setOpenAddUser(!openAddUser);
    setMountingObj(data);
    setIsEdit(true);
  };

  return (
    <>
      <Container fluid className="stock">
        <Box className="d-flex justify-content-between align-items-center">
          <Box>
            <h2>
              <strong>
                {params.id === localStorage.getItem("solarpanel") &&
                  t("solarPanel")}
                {params.id === localStorage.getItem("inverter") &&
                  t("inverter")}
                {(params.id === localStorage.getItem("storage") ||
                  params.id === localStorage.getItem("hybrid_inverter") ||
                  params.id === localStorage.getItem("accessory")) &&
                  t("storage")}
                {params.id === localStorage.getItem("carport") && t("carPort")}
                {params.id === localStorage.getItem("mounting") &&
                  t("mounting")}
              </strong>
            </h2>
          </Box>

          <Box>
            <EnhancedTableToolbar
              numSelected={selected.length}
              onAddOpen={() => {
                setOpenAddUser(!openAddUser);
                // setDefaultRows(true);
              }}
            />
          </Box>
        </Box>

        <Container fluid className="mt-3">
          {(params.id === localStorage.getItem("storage") ||
            params.id === localStorage.getItem("hybrid_inverter") ||
            params.id === localStorage.getItem("accessory")) && <StorageNav />}
        </Container>

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Box sx={{ width: "100%" }} className="main-area">
            <Paper
              sx={{
                mb: 2,
                p: 1.5,
                width: "100% !important",
                overflow: "hidden",
                borderRadius: "16px",
              }}
            >
              <TableContainer sx={{ maxHeight: 700 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{ minWidth: 750 }}
                  // aria-labelledby="tableTitle"
                  size="medium"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={rows.length}
                  />

                  {params.id === localStorage.getItem("solarpanel") && (
                    <TableBody>
                      {rows.map((row, index) => {
                        const isItemSelected = isSelected(row._id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                            sx={{ textDecoration: "none" }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                onClick={(event) => handleClick(event, row._id)}
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>

                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              className="img-box"
                            >
                              <img
                                className="product-img"
                                src={
                                  row.mainImage.logo
                                    ? apiURL.siteBaseUrl + row.mainImage.logo
                                    : SkeletonImg
                                }
                                alt="Product"
                              />
                              {/* {row.mainImage.logo != null ? (
                                <img
                                  className="product-img"
                                  src={apiURL.siteBaseUrl + row.mainImage.logo}
                                  alt="Product"
                                />
                              ) : (
                                <img
                                  className="product-img"
                                  src={SkeletonImg}
                                  alt="Product"
                                />
                              )} */}
                            </TableCell>

                            <TableCell align="right">
                              <p>{row.manufacturerId.name}</p>
                            </TableCell>

                            <TableCell align="right">
                              <p>{row.modelNo}</p>
                            </TableCell>

                            <TableCell align="right">
                              <p>{row.p_max}W</p>
                            </TableCell>

                            <TableCell align="right">
                              <p>{row.dimensions} mm</p>
                            </TableCell>

                            <TableCell align="right">
                              <p>{row.price} €</p>
                            </TableCell>

                            <TableCell align="right" className="status">
                              <p>{row.status}</p>
                            </TableCell>

                            <TableCell align="right" className="stock">
                              <div className="d-flex no-wrap justify-content-end">
                                <Button
                                  variant="contained"
                                  size="small"
                                  onClick={() => onDownloadClick(row.documents)}
                                  sx={{
                                    backgroundColor: "transparent",
                                    border: "1px solid var(--secondary-color)",
                                    fontWeight: 700,
                                    color: "var(--secondary-color)",
                                    // fontSize: "14px",
                                    boxShadow: "none",
                                    "&:hover": {
                                      border: "1px solid var(--primary-color)",
                                      color: "white",
                                      backgroundColor: "var(--primary-color)",
                                      border: "1px solid var(--primary-color)",
                                      cursor: "pointer",
                                      boxShadow: "none",
                                    },
                                  }}
                                >
                                  <Download />({row.documents.length})
                                </Button>

                                <Button
                                  variant="contained"
                                  size="small"
                                  onClick={() => {
                                    editDataSolar(row);
                                    // setRows(originalData);
                                  }}
                                  sx={{
                                    ml: 2,
                                    backgroundColor: "transparent",
                                    color: "var(--secondary-color)",
                                    border: "1px solid var(--secondary-color)",
                                    fontWeight: 700,
                                    // fontSize: "14px",
                                    boxShadow: "none",
                                    "&:hover": {
                                      border: "1px solid var(--primary-color)",
                                      color: "white",
                                      backgroundColor: "var(--primary-color)",
                                      border: "1px solid var(--primary-color)",
                                      cursor: "pointer",
                                      boxShadow: "none",
                                    },
                                  }}
                                >
                                  <Edit />
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {/* {emptyRows > 0 && (
                              <TableRow
                                style={{
                                  height: (53) * emptyRows,
                                }}
                              >
                                <TableCell colSpan={6} />
                              </TableRow>
                            )} */}
                    </TableBody>
                  )}
                  {params.id === localStorage.getItem("inverter") && (
                    <TableBody>
                      {rows
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row._id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={index}
                              selected={isItemSelected}
                              sx={{ textDecoration: "none" }}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  onClick={(event) =>
                                    handleClick(event, row._id)
                                  }
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell>

                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                className="img-box"
                              >
                                <img
                                  className="product-img"
                                  src={
                                    row.mainImage.logo
                                      ? apiURL.siteBaseUrl + row.mainImage.logo
                                      : SkeletonImg
                                  }
                                  alt="Product"
                                />
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.manufacturerId.name}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.modelNo}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.nominalOutputPower} kW</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.mppt}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.price} €</p>
                              </TableCell>

                              <TableCell align="right" className="status">
                                <p>{row.status}</p>
                              </TableCell>

                              <TableCell align="right" className="stock">
                                <div className="d-flex no-wrap justify-content-end">
                                  <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() =>
                                      onDownloadClick(row.documents)
                                    }
                                    sx={{
                                      backgroundColor: "transparent",
                                      border:
                                        "1px solid var(--secondary-color)",
                                      fontWeight: 700,
                                      color: "var(--secondary-color)",
                                      fontSize: "14px",
                                      boxShadow: "none",
                                      "&:hover": {
                                        color: "white",
                                        backgroundColor: "var(--primary-color)",
                                        border:
                                          "1px solid var(--primary-color)",
                                        cursor: "pointer",
                                        boxShadow: "none",
                                      },
                                    }}
                                  >
                                    <Download />({row.documents.length})
                                  </Button>

                                  <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => {
                                      editDataInverter(row);
                                      // setRows(originalData);
                                    }}
                                    sx={{
                                      ml: 2,
                                      backgroundColor: "transparent",
                                      border:
                                        "1px solid var(--secondary-color)",
                                      fontWeight: 700,
                                      color: "var(--secondary-color)",
                                      fontSize: "14px",
                                      boxShadow: "none",
                                      "&:hover": {
                                        color: "white",
                                        backgroundColor: "var(--primary-color)",
                                        border:
                                          "1px solid var(--primary-color)",
                                        cursor: "pointer",
                                        boxShadow: "none",
                                      },
                                    }}
                                  >
                                    <Edit />
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {/* {emptyRows > 0 && (
                              <TableRow
                                style={{
                                  height: (53) * emptyRows,
                                }}
                              >
                                <TableCell colSpan={6} />
                              </TableRow>
                            )} */}
                    </TableBody>
                  )}
                  {params.id === localStorage.getItem("storage") && (
                    <TableBody>
                      {rows
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row._id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={index}
                              selected={isItemSelected}
                              sx={{ textDecoration: "none" }}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  onClick={(event) =>
                                    handleClick(event, row._id)
                                  }
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell>

                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                className="img-box"
                              >
                                <img
                                  className="product-img"
                                  src={
                                    row.mainImage.logo
                                      ? apiURL.siteBaseUrl + row.mainImage.logo
                                      : SkeletonImg
                                  }
                                  alt="Product"
                                />
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.manufacturerId.name}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.modelNo}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.power}</p>
                              </TableCell>

                              <TableCell align="right">{row.weight}</TableCell>
                              <TableCell align="right">
                                {row.dimensions} mm
                              </TableCell>
                              <TableCell align="right">
                                {row.maxNoBatteryConnection}
                              </TableCell>
                              <TableCell align="right">{row.ip}</TableCell>

                              <TableCell align="right">
                                <p>{row.price} €</p>
                              </TableCell>

                              <TableCell align="right" className="status">
                                <p>{row.status}</p>
                              </TableCell>

                              <TableCell align="right" className="stock">
                                <div className="d-flex no-wrap justify-content-end">
                                  <Button
                                    variant="contained"
                                    size="medium"
                                    onClick={() =>
                                      onDownloadClick(row.documents)
                                    }
                                    sx={{
                                      backgroundColor: "transparent",
                                      border:
                                        "1px solid var(--secondary-color)",
                                      fontWeight: 700,
                                      color: "var(--secondary-color)",
                                      fontSize: "14px",
                                      boxShadow: "none",
                                      "&:hover": {
                                        color: "white",
                                        backgroundColor: "var(--primary-color)",
                                        border:
                                          "1px solid var(--primary-color)",
                                        cursor: "pointer",
                                        boxShadow: "none",
                                      },
                                    }}
                                  >
                                    <Download />({row.documents.length})
                                  </Button>

                                  <Button
                                    variant="contained"
                                    size="medium"
                                    onClick={() => {
                                      editDataStorage(row);
                                      // setRows(originalData);
                                    }}
                                    sx={{
                                      ml: 2,
                                      backgroundColor: "transparent",
                                      border:
                                        "1px solid var(--secondary-color)",
                                      fontWeight: 700,
                                      color: "var(--secondary-color)",
                                      fontSize: "14px",
                                      boxShadow: "none",
                                      "&:hover": {
                                        color: "white",
                                        backgroundColor: "var(--primary-color)",
                                        border:
                                          "1px solid var(--primary-color)",
                                        cursor: "pointer",
                                        boxShadow: "none",
                                      },
                                    }}
                                  >
                                    <Edit />
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {/* {emptyRows > 0 && (
                              <TableRow
                                style={{
                                  height: (53) * emptyRows,
                                }}
                              >
                                <TableCell colSpan={6} />
                              </TableRow>
                            )} */}
                    </TableBody>
                  )}
                  {params.id === localStorage.getItem("hybrid_inverter") && (
                    <TableBody>
                      {rows
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row._id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={index}
                              selected={isItemSelected}
                              sx={{ textDecoration: "none" }}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  onClick={(event) =>
                                    handleClick(event, row._id)
                                  }
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell>

                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                className="img-box"
                              >
                                <img
                                  className="product-img"
                                  src={
                                    row.mainImage.logo
                                      ? apiURL.siteBaseUrl + row.mainImage.logo
                                      : SkeletonImg
                                  }
                                  alt="Product"
                                />
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.manufacturerId.name}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.modelNo}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.power}</p>
                              </TableCell>
                              <TableCell align="right">
                                {row.dimensions} mm
                              </TableCell>
                              <TableCell align="right">
                                {row.batteryVoltageRange}
                              </TableCell>
                              {/* <TableCell align="right">{row.mppt}
                                    </TableCell> */}

                              <TableCell align="right">
                                <p>{row.price} €</p>
                              </TableCell>

                              <TableCell align="right" className="status">
                                <p>{row.status}</p>
                              </TableCell>

                              <TableCell align="right" className="stock">
                                <div className="d-flex no-wrap justify-content-end">
                                  <Button
                                    variant="contained"
                                    size="medium"
                                    onClick={() =>
                                      onDownloadClick(row.documents)
                                    }
                                    sx={{
                                      backgroundColor: "transparent",
                                      border:
                                        "1px solid var(--secondary-color)",
                                      fontWeight: 700,
                                      color: "var(--secondary-color)",
                                      fontSize: "14px",
                                      boxShadow: "none",
                                      "&:hover": {
                                        color: "white",
                                        backgroundColor: "var(--primary-color)",
                                        border:
                                          "1px solid var(--primary-color)",
                                        cursor: "pointer",
                                        boxShadow: "none",
                                      },
                                    }}
                                  >
                                    <Download />({row.documents.length})
                                  </Button>

                                  <Button
                                    variant="contained"
                                    size="medium"
                                    onClick={() => {
                                      editDataHybridInverter(row);
                                      // setRows(originalData);
                                    }}
                                    sx={{
                                      ml: 2,
                                      backgroundColor: "transparent",
                                      border:
                                        "1px solid var(--secondary-color)",
                                      fontWeight: 700,
                                      color: "var(--secondary-color)",
                                      fontSize: "14px",
                                      boxShadow: "none",
                                      "&:hover": {
                                        color: "white",
                                        backgroundColor: "var(--primary-color)",
                                        border:
                                          "1px solid var(--primary-color)",
                                        cursor: "pointer",
                                        boxShadow: "none",
                                      },
                                    }}
                                  >
                                    <Edit />
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {/* {emptyRows > 0 && (
                              <TableRow
                                style={{
                                  height: (53) * emptyRows,
                                }}
                              >
                                <TableCell colSpan={6} />
                              </TableRow>
                            )} */}
                    </TableBody>
                  )}
                  {params.id === localStorage.getItem("accessory") && (
                    <TableBody>
                      {rows
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row._id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={index}
                              selected={isItemSelected}
                              sx={{ textDecoration: "none" }}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  onClick={(event) =>
                                    handleClick(event, row._id)
                                  }
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell>

                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                className="img-box"
                              >
                                <img
                                  className="product-img"
                                  src={
                                    row.mainImage.logo
                                      ? apiURL.siteBaseUrl + row.mainImage.logo
                                      : SkeletonImg
                                  }
                                  alt="Product"
                                />
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.manufacturerId.name}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.modelNo}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.description}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.price} €</p>
                              </TableCell>

                              <TableCell align="right" className="status">
                                <p>{row.status}</p>
                              </TableCell>

                              <TableCell align="right" className="stock">
                                <div className="d-flex no-wrap justify-content-end">
                                  <Button
                                    variant="contained"
                                    size="medium"
                                    onClick={() =>
                                      onDownloadClick(row.documents)
                                    }
                                    sx={{
                                      backgroundColor: "transparent",
                                      border:
                                        "1px solid var(--secondary-color)",
                                      fontWeight: 700,
                                      color: "var(--secondary-color)",
                                      fontSize: "14px",
                                      boxShadow: "none",
                                      "&:hover": {
                                        color: "white",
                                        backgroundColor: "var(--primary-color)",
                                        border:
                                          "1px solid var(--primary-color)",
                                        cursor: "pointer",
                                        boxShadow: "none",
                                      },
                                    }}
                                  >
                                    <Download />({row.documents.length})
                                  </Button>

                                  <Button
                                    variant="contained"
                                    size="medium"
                                    onClick={() => {
                                      editDataAccessory(row);
                                      // setRows(originalData);
                                    }}
                                    sx={{
                                      ml: 2,
                                      backgroundColor: "transparent",
                                      border:
                                        "1px solid var(--secondary-color)",
                                      fontWeight: 700,
                                      color: "var(--secondary-color)",
                                      fontSize: "14px",
                                      boxShadow: "none",
                                      "&:hover": {
                                        color: "white",
                                        backgroundColor: "var(--primary-color)",
                                        border:
                                          "1px solid var(--primary-color)",
                                        cursor: "pointer",
                                        boxShadow: "none",
                                      },
                                    }}
                                  >
                                    <Edit />
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {/* {emptyRows > 0 && (
                              <TableRow
                                style={{
                                  height: (53) * emptyRows,
                                }}
                              >
                                <TableCell colSpan={6} />
                              </TableRow>
                            )} */}
                    </TableBody>
                  )}
                  {params.id === localStorage.getItem("carport") && (
                    <TableBody>
                      {rows
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row._id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={index}
                              selected={isItemSelected}
                              sx={{ textDecoration: "none" }}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  onClick={(event) =>
                                    handleClick(event, row._id)
                                  }
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell>

                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                className="img-box"
                              >
                                <img
                                  className="product-img"
                                  src={
                                    row.mainImage.logo
                                      ? apiURL.siteBaseUrl + row.mainImage.logo
                                      : SkeletonImg
                                  }
                                  alt="Product"
                                />
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.manufacturerId.name}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.modelNo}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.parkingNo}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.dimensions} mm</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.power}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.price} €</p>
                              </TableCell>

                              <TableCell align="right" className="status">
                                <p>{row.status}</p>
                              </TableCell>

                              <TableCell align="right" className="stock">
                                <div className="d-flex no-wrap justify-content-end">
                                  <Button
                                    variant="contained"
                                    size="medium"
                                    onClick={() =>
                                      onDownloadClick(row.documents)
                                    }
                                    sx={{
                                      backgroundColor: "transparent",
                                      border:
                                        "1px solid var(--secondary-color)",
                                      fontWeight: 700,
                                      color: "var(--secondary-color)",
                                      fontSize: "14px",
                                      boxShadow: "none",
                                      "&:hover": {
                                        color: "white",
                                        backgroundColor: "var(--primary-color)",
                                        border:
                                          "1px solid var(--primary-color)",
                                        cursor: "pointer",
                                        boxShadow: "none",
                                      },
                                    }}
                                  >
                                    <Download />({row.documents.length})
                                  </Button>

                                  <Button
                                    variant="contained"
                                    size="medium"
                                    onClick={() => {
                                      editDataCarport(row);
                                      // setRows(originalData);
                                    }}
                                    sx={{
                                      ml: 2,
                                      backgroundColor: "transparent",
                                      border:
                                        "1px solid var(--secondary-color)",
                                      fontWeight: 700,
                                      color: "var(--secondary-color)",
                                      fontSize: "14px",
                                      boxShadow: "none",
                                      "&:hover": {
                                        color: "white",
                                        backgroundColor: "var(--primary-color)",
                                        border:
                                          "1px solid var(--primary-color)",
                                        cursor: "pointer",
                                        boxShadow: "none",
                                      },
                                    }}
                                  >
                                    <Edit />
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {/* {emptyRows > 0 && (
                              <TableRow
                                style={{
                                  height: (53) * emptyRows,
                                }}
                              >
                                <TableCell colSpan={6} />
                              </TableRow>
                            )} */}
                    </TableBody>
                  )}
                  {params.id === localStorage.getItem("mounting") && (
                    <TableBody>
                      {rows
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row._id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={index}
                              selected={isItemSelected}
                              sx={{ textDecoration: "none" }}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  onClick={(event) =>
                                    handleClick(event, row._id)
                                  }
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell>

                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                className="img-box"
                              >
                                <img
                                  className="product-img"
                                  src={
                                    row.mainImage.logo
                                      ? apiURL.siteBaseUrl + row.mainImage.logo
                                      : SkeletonImg
                                  }
                                  alt="Product"
                                />
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.manufacturerId.name}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.modelNo}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.parkingNo}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.dimensions} mm</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.power}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.price} €</p>
                              </TableCell>

                              <TableCell align="right" className="status">
                                <p>{row.status}</p>
                              </TableCell>

                              <TableCell align="right" className="stock">
                                <div className="d-flex no-wrap justify-content-end">
                                  <Button
                                    variant="contained"
                                    size="medium"
                                    onClick={() =>
                                      onDownloadClick(row.documents)
                                    }
                                    sx={{
                                      backgroundColor: "transparent",
                                      border:
                                        "1px solid var(--secondary-color)",
                                      fontWeight: 700,
                                      color: "var(--secondary-color)",
                                      fontSize: "14px",
                                      boxShadow: "none",
                                      "&:hover": {
                                        color: "white",
                                        backgroundColor: "var(--primary-color)",
                                        border:
                                          "1px solid var(--primary-color)",
                                        cursor: "pointer",
                                        boxShadow: "none",
                                      },
                                    }}
                                  >
                                    <Download />({row.documents.length})
                                  </Button>

                                  <Button
                                    variant="contained"
                                    size="medium"
                                    onClick={() => {
                                      editDataMounting(row);
                                      // setRows(originalData);
                                    }}
                                    sx={{
                                      ml: 2,
                                      backgroundColor: "transparent",
                                      border:
                                        "1px solid var(--secondary-color)",
                                      fontWeight: 700,
                                      color: "var(--secondary-color)",
                                      fontSize: "14px",
                                      boxShadow: "none",
                                      "&:hover": {
                                        color: "white",
                                        backgroundColor: "var(--primary-color)",
                                        border:
                                          "1px solid var(--primary-color)",
                                        cursor: "pointer",
                                        boxShadow: "none",
                                      },
                                    }}
                                  >
                                    <Edit />
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {/* {emptyRows > 0 && (
                              <TableRow
                                style={{
                                  height: (53) * emptyRows,
                                }}
                              >
                                <TableCell colSpan={6} />
                              </TableRow>
                            )} */}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                // rowsPerPageOptions={[]}
                component="div"
                count={totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>

            {params.id === localStorage.getItem("solarpanel") && (
              <EditSolarForm
                open={openAddUser}
                // handleClose={props.handleClose}
                isEdit={isEdit}
                stateObj={solarPanelObj}
                handleClose={(data) => {
                  console.log("data", data);
                  if (data?.reload) {
                    fnProductList(productId, page + 1);
                  }
                  setIsEdit(false);
                  setSolarPanelObj(null);
                  setOpenAddUser(!openAddUser);
                }}
              />
            )}
            {params.id === localStorage.getItem("accessory") && (
              <AddInstaller
                open={openAddUser}
                // handleClose={props.handleClose}
                isEdit={isEdit}
                stateObj={accessoryObj}
                handleClose={(data) => {
                  console.log("data123123", data);
                  if (data?.reload) {
                    fnProductList(productId, page + 1);
                  }
                  setIsEdit(false);
                  setAccessoryObj(null);
                  setOpenAddUser(!openAddUser);
                }}
              />
            )}
            {params.id === localStorage.getItem("hybrid_inverter") && (
              <AddCompanyAgent
                open={openAddUser}
                // handleClose={props.handleClose}
                isEdit={isEdit}
                stateObj={hybridInverterObj}
                handleClose={(data) => {
                  if (data?.reload) {
                    fnProductList(productId, page + 1);
                  }
                  setIsEdit(false);
                  setHybridInverterObj(null);
                  setOpenAddUser(!openAddUser);
                }}
              />
            )}
            {params.id === localStorage.getItem("storage") && (
              <AddBattery
                open={openAddUser}
                // handleClose={props.handleClose}
                isEdit={isEdit}
                stateObj={storageObj}
                handleClose={(data) => {
                  if (data?.reload) {
                    fnProductList(productId, page + 1);
                  }
                  setIsEdit(false);
                  setStorageObj(null);
                  setOpenAddUser(!openAddUser);
                }}
              />
            )}
            {params.id === localStorage.getItem("carport") && (
              <AddCarPort
                open={openAddUser}
                // handleClose={props.handleClose}
                isEdit={isEdit}
                stateObj={carportObj}
                handleClose={(data) => {
                  if (data?.reload) {
                    fnProductList(productId, page + 1);
                  }
                  setIsEdit(false);
                  setCarportObj(null);
                  setOpenAddUser(!openAddUser);
                }}
              />
            )}
            {params.id === localStorage.getItem("inverter") && (
              <AddInverter
                open={openAddUser}
                // handleClose={props.handleClose}
                isEdit={isEdit}
                stateObj={inverterObj}
                handleClose={(data) => {
                  if (data?.reload) {
                    fnProductList(productId, page + 1);
                  }
                  setIsEdit(false);
                  setInverterObj(null);
                  setOpenAddUser(!openAddUser);
                }}
              />
            )}
          </Box>
        )}
      </Container>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>

      {/* <AlertDialog
        open={openDeleteAlert}
        title={selected.length === 1 ? "Delete Product" : "Delete Products"}
        message={
          selected.length === 1
            ? "Are you sure you want to delete the product?"
            : "Are you sure you want to delete the products?"
        }
        handleAction={confirmDeleteProduct}
        handleClose={handleDeleteAlertClose}
      ></AlertDialog> */}
    </>
  );
}
