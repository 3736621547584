import { useState } from "react";

import {
    modelNoValidator,
    powerValidator,
    priceValidator,
    dimentionValidator,
    batteryVoltageRangeValidator
} from "components/Validators/Validators.jsx";

const touchErrors = errors => {
    return Object.entries(errors).reduce((acc, [field, fieldError]) => {
        acc[field] = {
            ...fieldError,
            dirty: true,
        };
        return acc;
    }, {});
};

export const useBatteryVoltagerRngeValidator = form => {
    const [errors, setErrors] = useState({
        modelNo: {
            dirty: false,
            error: false,
            message: "",
        },
        power: {
            dirty: false,
            error: false,
            message: "",
        },
        price: {
            dirty: false,
            error: false,
            message: "",
        },
        dimention: {
            dirty: false,
            error: false,
            message: "",
        },
        batteryVoltageRange: {
            dirty: false,
            error: false,
            message: "",
        },
    });

    const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
        let isValid = true;

        // Create a deep copy of the errors
        let nextErrors = JSON.parse(JSON.stringify(errors));

        // Force validate all the fields
        if (forceTouchErrors) {
            nextErrors = touchErrors(errors);
        }

        const { modelNo = '', power = '', price = '', dimention = '', batteryVoltageRange = '' } = form;

        if (nextErrors.modelNo.dirty && (field ? field === "modelNo" : true)) {
            const modelNoMessage = modelNoValidator(modelNo, form);
            nextErrors.modelNo.error = !!modelNoMessage;
            nextErrors.modelNo.message = modelNoMessage;
            if (!!modelNoMessage) isValid = false;
        }
        // if (modelNo === '') isValid = true;


        if (nextErrors.power.dirty && (field ? field === "power" : true)) {
            const powerMessage = powerValidator(power, form);
            nextErrors.power.error = !!powerMessage;
            nextErrors.power.message = powerMessage;
            if (!!powerMessage) isValid = false;
        }
        // if (p_max === '') isValid = true;

        if (nextErrors.price.dirty && (field ? field === "price" : true)) {
            const priceMessage = priceValidator(price, form);
            nextErrors.price.error = !!priceMessage;
            nextErrors.price.message = priceMessage;
            if (!!priceMessage) isValid = false;
        }
        // if (price === '') isValid = true;

        if (nextErrors.dimention.dirty && (field ? field === "dimention" : true)) {
            const dimentionMessage = dimentionValidator(dimention, form);
            nextErrors.dimention.error = !!dimentionMessage;
            nextErrors.dimention.message = dimentionMessage;
            if (!!dimentionMessage) isValid = false;
        }

        if (nextErrors.batteryVoltageRange.dirty && (field ? field === "batteryVoltageRange" : true)) {
            const batteryVoltageRangeMessage = batteryVoltageRangeValidator(batteryVoltageRange, form);
            nextErrors.batteryVoltageRange.error = !!batteryVoltageRangeMessage;
            nextErrors.batteryVoltageRange.message = batteryVoltageRangeMessage;
            if (!!batteryVoltageRangeMessage) isValid = false;
        }
        // if (dimention === '') isValid = true;

        console.log("isValid", isValid

        )
        setErrors(nextErrors);
        return {
            isValid,
            errors: nextErrors,
        };
    };

    const onBlurField = e => {
        const field = e.target.name;
        const fieldError = errors[field];
        if (fieldError.dirty) return;

        const updatedErrors = {
            ...errors,
            [field]: {
                ...errors[field],
                dirty: true,
            },
        };

        validateForm({ form, field, errors: updatedErrors });
    };

    return {
        validateForm,
        onBlurField,
        errors,
    };
};