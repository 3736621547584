import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import 'css/SolarPanel.css';
import { alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import apiURL from 'apiURL/apiURL';
import apiStatusCode from 'constants/apiStatusCode';
import axios from "axios";
import defaultString from 'constants/defaultString.json';
import StorageNav from 'components/StorageNav/StorageNav';
import 'css/product.css';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import AlertDialog from 'components/AlertDialog/AlertDialog';
import { saveAs } from 'file-saver';
import AgentSubNav from 'components/AgentSubNav/AgentSubNav';
import AgentProductStorageNav from 'components/AgentProductStorageNav/AgentProductStorageNav';
import { FaArrowLeft } from 'react-icons/fa';
import { AuthContext } from 'context/AuthContext';


export default function ProductList() {

  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  // const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchContent, setSearchContent] = useState('');
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [productId, setProductId] = useState();
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [limit, setLimit] = useState(10);
  const [qty, setQty] = useState(1);
  const [quoteListData, setQuoteListData] = useState([]);
  const [clickedItems, setClickedItems] = useState([]);
  const { aadQuote, isSetAdQuote } = useContext(AuthContext);
  let navigate = useNavigate();
  const params = useParams();
  function EnhancedTableHead(props) {
    const { t } = useTranslation();
    const { onSelectAllClick, numSelected, rowCount } = props;



    let headCells = [];

    if (params.id === localStorage.getItem('solarpanel')) {
      headCells = [
        {
          id: 'product',
          numeric: false,
          disablePadding: true,
          label: t('product'),
        },
        {
          id: 'manufacturer',
          numeric: true,
          disablePadding: false,
          label: t('manufacturer'),
        },

        {
          id: 'pmax',
          numeric: true,
          disablePadding: false,
          label: t('pmax'),
        },
        {
          id: 'dimentions',
          numeric: true,
          disablePadding: false,
          label: t('dimentions') + ' (mm)',
        },
        {
          id: 'quantity',
          numeric: true,
          disablePadding: false,
          label: t('quantity'),
        },
        {
          id: 'price',
          numeric: true,
          disablePadding: false,
          label: t('listPrice'),
        },

        {
          id: 'download',
          numeric: true,
          disablePadding: false,
          label: '',
        },
      ];
    }
    else if (params.id === localStorage.getItem('inverter')) {
      headCells = [
        {
          id: 'product',
          numeric: false,
          disablePadding: true,
          label: t('product'),
        },
        {
          id: 'manufacturer',
          numeric: true,
          disablePadding: false,
          label: t('manufacturer'),
        },

        {
          id: 'pmax',
          numeric: true,
          disablePadding: false,
          label: t('pmax'),
        },
        {
          id: 'dimentions',
          numeric: true,
          disablePadding: false,
          label: t('dimentions') + ' (mm)',
        },
        {
          id: 'quantity',
          numeric: true,
          disablePadding: false,
          label: t('quantity'),
        },
        {
          id: 'price',
          numeric: true,
          disablePadding: false,
          label: t('listPrice'),
        },

        {
          id: 'download',
          numeric: true,
          disablePadding: false,
          label: '',
        },
      ];
    }
    else if (params.id === localStorage.getItem('hybrid_inverter')) {
      headCells = [
        {
          id: 'product',
          numeric: false,
          disablePadding: true,
          label: t('product'),
        },
        {
          id: 'manufacturer',
          numeric: true,
          disablePadding: false,
          label: t('manufacturer'),
        },

        {
          id: 'pmax',
          numeric: true,
          disablePadding: false,
          label: t('pmax'),
        },
        {
          id: 'dimentions',
          numeric: true,
          disablePadding: false,
          label: t('dimentions') + ' (mm)',
        },
        {
          id: 'quantity',
          numeric: true,
          disablePadding: false,
          label: t('quantity'),
        },
        {
          id: 'price',
          numeric: true,
          disablePadding: false,
          label: t('listPrice'),
        },

        {
          id: 'download',
          numeric: true,
          disablePadding: false,
          label: '',
        },
      ];
    }
    else if (params.id === localStorage.getItem('accessory')) {
      headCells = [
        {
          id: 'product',
          numeric: false,
          disablePadding: true,
          label: t('product'),
        },
        {
          id: 'manufacturer',
          numeric: true,
          disablePadding: false,
          label: t('manufacturer'),
        },

        {
          id: 'pmax',
          numeric: true,
          disablePadding: false,
          label: t('pmax'),
        },
        {
          id: 'dimentions',
          numeric: true,
          disablePadding: false,
          label: t('dimentions') + ' (mm)',
        },
        {
          id: 'quantity',
          numeric: true,
          disablePadding: false,
          label: t('quantity'),
        },
        {
          id: 'price',
          numeric: true,
          disablePadding: false,
          label: t('listPrice'),
        },

        {
          id: 'download',
          numeric: true,
          disablePadding: false,
          label: '',
        },
      ];
    }
    else if (params.id === localStorage.getItem('carport')) {
      headCells = [
        {
          id: 'product',
          numeric: false,
          disablePadding: true,
          label: t('product'),
        },
        {
          id: 'manufacturer',
          numeric: true,
          disablePadding: false,
          label: t('manufacturer'),
        },

        {
          id: 'pmax',
          numeric: true,
          disablePadding: false,
          label: t('pmax'),
        },
        {
          id: 'dimentions',
          numeric: true,
          disablePadding: false,
          label: t('dimentions') + ' (mm)',
        },
        {
          id: 'quantity',
          numeric: true,
          disablePadding: false,
          label: t('quantity'),
        },
        {
          id: 'price',
          numeric: true,
          disablePadding: false,
          label: t('listPrice'),
        },

        {
          id: 'download',
          numeric: true,
          disablePadding: false,
          label: '',
        },
      ];
    }
    else if (params.id === localStorage.getItem('mounting')) {
      headCells = [
        {
          id: 'product',
          numeric: false,
          disablePadding: true,
          label: t('product'),
        },
        {
          id: 'manufacturer',
          numeric: true,
          disablePadding: false,
          label: t('manufacturer'),
        },

        {
          id: 'pmax',
          numeric: true,
          disablePadding: false,
          label: t('pmax'),
        },
        {
          id: 'dimentions',
          numeric: true,
          disablePadding: false,
          label: t('dimentions') + ' (mm)',
        },
        {
          id: 'quantity',
          numeric: true,
          disablePadding: false,
          label: t('quantity'),
        },
        {
          id: 'price',
          numeric: true,
          disablePadding: false,
          label: t('listPrice'),
        },

        {
          id: 'download',
          numeric: true,
          disablePadding: false,
          label: '',
        },
      ];
    }

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    const { t } = useTranslation();
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >

          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton onClick={deleteProduct}>
              <DeleteIcon color="Red" />
            </IconButton>
          </Tooltip>
        ) : (
          // (params.id === localStorage.getItem('storage') || params.id === localStorage.getItem('hybrid_inverter') || params.id === localStorage.getItem('accessory')) ?
          //   <Container fluid className='d-flex justify-content-end add'>
          //   </Container>
          //   :
          //   <Container fluid className='d-flex justify-content-end'>

          //     <Tooltip title="Add">
          //       <Link to={
          //         productId === localStorage.getItem('solarpanel') && "/admin/add-solar-panel" ||
          //         productId === localStorage.getItem('inverter') && "/admin/add-inverter" ||
          //         productId === localStorage.getItem('storage') && "/admin/addbattery" ||
          //         productId === localStorage.getItem('hybrid_inverter') && "/admin/addhybridinverter" ||
          //         productId === localStorage.getItem('accessory') && "/admin/addaccessory" ||
          //         productId === localStorage.getItem('carport') && "/admin/addcarport"
          //       }
          //       ><Button variant='contained' size='large' color='success'
          //         sx={{
          //           color: "white",
          //           backgroundColor: "#FD841F",
          //           border: 'none',
          //           padding: "12px 20px",
          //           fontWeight: "700",
          //           borderRadius: "8px",
          //           "&:hover": {
          //             color: 'white',
          //             backgroundColor: '#E14D2A',
          //             cursor: 'pointer',
          //           },
          //         }}
          //       >{t('ADD')}
          //         </Button>
          //       </Link>
          //     </Tooltip>

          //   </Container>
          ""
        )}
      </Toolbar>
    );
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setSearchContent('');
  };

  const fnProductList = useCallback(async (paramsId, page, searchText = '') => {
    let searchString = '';
    if (searchText !== '') {
      searchString = '&search_text=' + searchText
    }

    console.log('in fn limitt', limit)
    // console.log('current page in list funcion ', page)
    // console.log('searchString ',searchString)
    await axios.get(apiURL.service_list_product + `/${paramsId}?page=${page}+&limit=${limit}${searchString}`
      ,
      {
        'headers': {
          'Content-Type': 'application/json',
          // 'Authorization': token,
        }
      }
    )
      .then((res) => {
        // console.log(res)
        setIsLoading(false)
        if (res.status === apiStatusCode.SUCCESS) {
          const newList = res.data.data.product;
          const newListProduct = [];
          newList.map((item,i) => {
            newListProduct.push({ ...item, quantity: 1, vat:10, totalVat:0, discount:0});
          })
          setRows(newListProduct)

          const quotationList = JSON.parse(localStorage.getItem('quotationList'));
          if (quotationList && quotationList.length > 0) {
            const newQuotationList = [...quotationList]
            newListProduct.map((item) => {
              newQuotationList.push(item)
            })

            const finalQuotationList = Array.from(new Set(newQuotationList.map(item => item._id)))
                .map(_id => newQuotationList.find(item => item._id === _id));
            localStorage.setItem("quotationList",JSON.stringify(finalQuotationList))
          } else {
            localStorage.setItem("quotationList",JSON.stringify(newListProduct))
          }
          
          // setTotalPages(res.data.data.totalPages)
          setTotalRecords(res.data.data.totalRecords)
        }
        else {
          console.log('In else portion ', res.data);
          setOpen(true);
          setErrorMsg(res.data.message)
        }
      }).catch((error) => {
        setIsLoading(false)
        console.log(error)
        setOpen(true);
        if (error.code === defaultString.network_error) setErrorMsg(error.message)
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === 404) {
            setOpen(false);
          }
        }
        setRows([])
        setTotalRecords(0)
      });
  }, [limit]
  )

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    // console.log(selectedIndex)
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await fnProductList(productId, newPage + 1);
    setSelected([]);
  };

  const handleChangeRowsPerPage = async (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    // console.log(event.target.value)
    // console.log(rowsPerPage)
    // console.log(parseInt(event.target.value, 10))
    setLimit(parseInt(event.target.value, 10))
    await fnProductList(productId, 1);
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


  // const navigateToUpdate = (data) => {
  //   if (productId === localStorage.getItem('solarpanel')) {
  //     navigate('/admin/add-solar-panel', { state: { data } })
  //   }
  //   else if (productId === localStorage.getItem('inverter')) {
  //     navigate('/admin/add-inverter', { state: { data } })
  //   }
  //   else if (productId === localStorage.getItem('storage')) {
  //     navigate('/admin/addbattery', { state: { data } })
  //   }
  //   else if (productId === localStorage.getItem('hybrid_inverter')) {
  //     navigate('/admin/addhybridinverter', { state: { data } })
  //   }
  //   else if (productId === localStorage.getItem('accessory')) {
  //     navigate('/admin/addaccessory', { state: { data } })
  //   }
  //   else if (productId === localStorage.getItem('carport')) {
  //     navigate('/admin/addcarport', { state: { data } })
  //   }
  //   else if (productId === localStorage.getItem('mounting')) {
  //     // navigate('/admin/add-inverter',{state: {data}})
  //   }
  // }

  const confirmDeleteProduct = async () => {
    console.log('selected:: ', selected)
    await fnDeleteProduct();
    setOpenDeleteAlert(false)
  }

  const handleDeleteAlertClose = () => {
    setOpenDeleteAlert(false);
    setSelected([])
  };

  const deleteProduct = () => {
    setOpenDeleteAlert(true)
  };

  const fnDeleteProduct = async () => {

    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken))

    console.log('token ', token)
    console.log('ids ', selected)

    const objProduct = JSON.stringify({
      'productDetailIds': selected
    });

    console.log(objProduct)

    await axios.post(apiURL.service_delete_products, objProduct, {
      'headers': {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.status === apiStatusCode.DELETED) {
          setOpen(true);
          setErrorMsg(res.data.message)

          const filteredArr = rows.filter(item => !selected.includes(item._id));
          setRows(filteredArr);
          setSelected([])
          await fnProductList(productId, page + 1);
        }
        else {
          setOpen(true);
          setErrorMsg(res.data.data.message)
        }

      }).catch((error) => {
        console.log(error.response)
        setOpen(true);
        if (error.code === defaultString.network_error) setErrorMsg(error.message)
        else setErrorMsg(error.response.data.message)
      });
  }

  const onDownloadClick = (documents) => {

    if (documents.length > 0) {
      var JSzip = require("jszip");
      var zip = new JSzip();
      const otherFiles = zip.folder("files");
      const list = documents.map(async (doc, index) => {
        const fileUrl = apiURL.siteBaseUrl + doc.document

        const response = await fetch(fileUrl);
        const data = await response.blob();
        // otherFiles.file(`${doc.document.split("\\productDocument\\")[1]}`, data);
        otherFiles.file(`document_${index + 1}.pdf`, data);
        return data;
      })

      // If all prmises are fullfilled it will call the zip 
      // function and download it using the FileSaver library
      Promise.all(list).then(function () {
        zip.generateAsync({ type: "blob" })
          .then(function (content) {
            saveAs(content, 'files');
          });
      });

    }
    else {
      setOpen(true)
      setErrorMsg('No file to be download')
    }
    setSelected([])
  }

  useEffect(() => {
    console.log(params.id)
    setProductId(params.id)
    setIsLoading(true)
    fnProductList(params.id, page + 1);
  }, [fnProductList, params.id, page])

  const navigateToUpdate = (data, i) => {
   const selectedData= JSON.parse(localStorage.getItem("addedToQuotation"))

   if(selectedData && selectedData.length > 0){
      const updatedVal=[...selectedData,data._id]
      // setClickedItems(updatedVal)
      localStorage.setItem("addedToQuotation",JSON.stringify(updatedVal))
   } else {
    setClickedItems([data._id])
    localStorage.setItem("addedToQuotation",JSON.stringify([data._id]))
   }
      navigate("/agent/addquotation");
  }

  useEffect(() => {
    const selectedData= localStorage.getItem("addedToQuotation")
  if(selectedData){

    setClickedItems(selectedData)
  }
   
  }, [])

  const doClickMinus = (index) => {
    const newList = [...rows];
    newList[index].quantity = newList[index].quantity - 1;
    setRows(newList);
    localStorage.setItem("quotationList",JSON.stringify(newList))
    
  }

  const doClickPlus = (index) => {
    const newList = [...rows];
    newList[index].quantity = newList[index].quantity + 1;
    setRows(newList);
    localStorage.setItem("quotationList",JSON.stringify(newList))
  }

  return (
    <>
      <Container fluid className='mt-5 fixed-top main-section'>
        <Row className='border-bottom pb-2 backBar'>
          <Col className='d-flex align-items-center'>
            <Link to="/agent/addquotation">
              <FaArrowLeft fontSize={24} />
              <span className='ms-2'>{t('back') + " " + t('to') + " " + t('addQuotation')}</span>
            </Link>
          </Col>


        </Row>
      </Container>
      {isLoading ? <LoadingSpinner /> :
        (
          <Container fluid className='py-5'>

            <Container fluid className='mb-3 table-navs border-bottom'>
              <AgentSubNav />
              {
                (params.id === localStorage.getItem('storage') || params.id === localStorage.getItem('hybrid_inverter') || params.id === localStorage.getItem('accessory')) &&
                <AgentProductStorageNav />
              }

            </Container>
            <Box sx={{ width: '100%' }} className="main-area">
              {/* <div className='filter mr-3'>
                <h2>{t('filter')}</h2>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>{t('manufacturer')}</Accordion.Header>
                    <Accordion.Body>
                      <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Option 1" />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Option 2" />
                      </Form.Group>

                      <Form.Group className="" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Option 3" />
                      </Form.Group>

                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="1">
                    <Accordion.Header>{t('modelNo')}</Accordion.Header>
                    <Accordion.Body>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Option 4" />
                      </Form.Group>

                      <Form.Group className="" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Option 5" />
                      </Form.Group>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header>{t('pmax')}</Accordion.Header>
                    <Accordion.Body>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Option 6" />
                      </Form.Group>

                      <Form.Group className="" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Option 7" />
                      </Form.Group>
                    </Accordion.Body>
                  </Accordion.Item>
                  
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>{t('dimentions')}</Accordion.Header>
                    <Accordion.Body>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Option 8" />
                      </Form.Group>

                      <Form.Group className="" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Option 9" />
                      </Form.Group>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>{t('price')}</Accordion.Header>
                    <Accordion.Body>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Option 10" />
                      </Form.Group>

                      <Form.Group className="" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Option 11" />
                      </Form.Group>
                    </Accordion.Body>
                  </Accordion.Item>
                  
                  <div className="d-grid gap-2 mt-3">
                  <Button variant="secondary" className='py-3'
                  sx={{
                    color: "white",
                    backgroundColor: "#B9BFC3",
                    border: 'none',
                    padding: "16px 24px",
                    fontWeight: "700",
                    fontSize: "20px",
                    cursor:'pointer',
                    "&:hover": {
                      backgroundColor: '#747F88',
                      cursor:'pointer',
                    },
                  }}
                  >{t('applyFilter')}</Button>
                  </div>
                </Accordion>
              </div> */}

              {/* width: 85% */}
              <Paper sx={{ mb: 2, width: '100% !important', overflow: 'hidden' }}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer sx={{ maxHeight: 700 }}>
                  <Table stickyHeader aria-label="sticky table"
                    sx={{ minWidth: 750 }}
                    // aria-labelledby="tableTitle"
                    size='medium'
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      onSelectAllClick={handleSelectAllClick}
                      rowCount={rows.length}
                    />
                    {
                      (params.id === localStorage.getItem('solarpanel')) &&
                      <TableBody>
                        {rows
                          // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {

                            const isItemSelected = isSelected(row._id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover

                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={index}
                                selected={isItemSelected}
                                sx={{ textDecoration: "none" }}>

                                <TableCell padding="checkbox">
                                  <Checkbox
                                    onClick={(event) => handleClick(event, row._id)}
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                  />
                                </TableCell>

                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                  className='img-box'
                                >
                                  {
                                    row.mainImage.logo != null ?

                                      <img className='product-img' src={apiURL.siteBaseUrl + row.mainImage.logo} alt="Product" />
                                      :
                                      <img className='product-img' src="/images/icons/no-image.png" alt="Product" />

                                  }
                                </TableCell>

                                <TableCell align="right">
                                  <p>{row.manufacturerId.name}</p>
                                </TableCell>


                                <TableCell align="right">
                                  <p>{row.p_max}W</p>
                                </TableCell>

                                <TableCell align="right">
                                  <p>{row.dimensions} mm</p>
                                </TableCell>

                                <TableCell align="right">
                                  <div className="counter">
                                    <span className="down" onClick={() => doClickMinus(index)}>-</span>
                                    <input type="text" value={row.quantity} key={index} onChange={(e) => setQty(e.target.value)} />
                                    <span className="up" onClick={() => doClickPlus(index)}>+</span>
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  <p>{row.price} €</p>
                                </TableCell>



                                <TableCell align="right" className='stock'>
                                  <div className='d-flex no-wrap justify-content-end'>
                                    <Button variant='contained' size='medium' color='success' onClick={() => { navigateToUpdate(row, index); }}
                                      sx={{
                                        color: "#FD841F",
                                        backgroundColor: "#FEF1E6",
                                        border: 'none',
                                        padding: "12px 18px",
                                        fontWeight: 700,
                                        fontSize: '16px',
                                        boxShadow: "none",
                                        "&:hover": {
                                          color: 'white',
                                          backgroundColor: '#FD841F',
                                          cursor: 'pointer',
                                        },
                                      }}
                                      disabled={clickedItems.includes(row._id) }
                                    >
                                     
                                      {clickedItems.includes(row._id) ?  t('addToQuote') : t('addToQuoted') }
                                    </Button>

                                  </div>
                                </TableCell>

                              </TableRow>
                            );
                          })}
                        {/* {emptyRows > 0 && (
                              <TableRow
                                style={{
                                  height: (53) * emptyRows,
                                }}
                              >
                                <TableCell colSpan={6} />
                              </TableRow>
                            )} */}
                      </TableBody>
                    }
                    {
                      (params.id === localStorage.getItem('inverter')) &&

                      <TableBody>
                        {rows
                          // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {

                            const isItemSelected = isSelected(row._id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover

                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={index}
                                selected={isItemSelected}
                                sx={{ textDecoration: "none" }}>

                                <TableCell padding="checkbox">
                                  <Checkbox
                                    onClick={(event) => handleClick(event, row._id)}
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                  />
                                </TableCell>

                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                  className='img-box'
                                >
                                  {
                                    row.mainImage.logo != null ?

                                      <img className='product-img' src={apiURL.siteBaseUrl + row.mainImage.logo} alt="Product" />
                                      :
                                      <img className='product-img' src="/images/icons/no-image.png" alt="Product" />

                                  }
                                </TableCell>


                                <TableCell align="right">
                                  <p>{row.manufacturerId.name}</p>
                                </TableCell>


                                <TableCell align="right">
                                  <p>{row.p_max}W</p>
                                </TableCell>

                                <TableCell align="right">
                                  <p>{row.dimensions} mm</p>
                                </TableCell>

                                <TableCell align="right">
                                <div className="counter">
                                    <span className="down" onClick={() => doClickMinus(index)}>-</span>
                                    <input type="text" value={row.quantity} key={index} onChange={(e) => setQty(e.target.value)} />
                                    <span className="up" onClick={() => doClickPlus(index)}>+</span>
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  <p>{row.price} €</p>
                                </TableCell>


                                <TableCell align="right" className='stock'>
                                  <div className='d-flex no-wrap justify-content-end'>
                                    <Button variant='contained' size='medium' color='success' onClick={() => { navigateToUpdate(row, index) }}
                                      sx={{
                                        color: "#FD841F",
                                        backgroundColor: "#FEF1E6",
                                        border: 'none',
                                        padding: "12px 18px",
                                        fontWeight: 700,
                                        fontSize: '16px',
                                        boxShadow: "none",
                                        "&:hover": {
                                          color: 'white',
                                          backgroundColor: '#FD841F',
                                          cursor: 'pointer',
                                        },
                                      }}
                                      disabled={clickedItems.includes(row._id) }
                                    >
                                      {clickedItems.includes(row._id) ? t('addToQuoted') : t('addToQuote')}
                                    </Button>


                                  </div>
                                </TableCell>

                              </TableRow>
                            );
                          })}
                        {/* {emptyRows > 0 && (
                              <TableRow
                                style={{
                                  height: (53) * emptyRows,
                                }}
                              >
                                <TableCell colSpan={6} />
                              </TableRow>
                            )} */}
                      </TableBody>
                    }
                    {
                      (params.id === localStorage.getItem('storage')) &&

                      <TableBody>
                        {rows
                          // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {

                            const isItemSelected = isSelected(row._id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover

                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={index}
                                selected={isItemSelected}
                                sx={{ textDecoration: "none" }}>

                                <TableCell padding="checkbox">
                                  <Checkbox
                                    onClick={(event) => handleClick(event, row._id)}
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                  />
                                </TableCell>

                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                  className='img-box'
                                >
                                  {
                                    row.mainImage.logo != null ?

                                      <img className='product-img' src={apiURL.siteBaseUrl + row.mainImage.logo} alt="Product" />
                                      :
                                      <img className='product-img' src="/images/icons/no-image.png" alt="Product" />
                                  }
                                </TableCell>


                                <TableCell align="right">
                                  <p>{row.manufacturerId.name}</p>
                                </TableCell>


                                <TableCell align="right">
                                  <p>{row.p_max}W</p>
                                </TableCell>

                                <TableCell align="right">
                                  <p>{row.dimensions} mm</p>
                                </TableCell>

                                <TableCell align="right">
                                  <div className="counter">
                                    <span className="down" onClick={() => doClickMinus(index)}>-</span>
                                    <input type="text" value={row.quantity} key={index} onChange={(e) => setQty(e.target.value)} />
                                    <span className="up" onClick={() => doClickPlus(index)}>+</span>
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  <p>{row.price} €</p>
                                </TableCell>


                                <TableCell align="right" className='stock'>
                                  <div className='d-flex no-wrap justify-content-end'>
                                    <Button variant='contained' size='medium' color='success' onClick={() => { navigateToUpdate(row, index) }}
                                      sx={{
                                        color: "#FD841F",
                                        backgroundColor: "#FEF1E6",
                                        border: 'none',
                                        padding: "12px 18px",
                                        fontWeight: 700,
                                        fontSize: '16px',
                                        boxShadow: "none",
                                        "&:hover": {
                                          color: 'white',
                                          backgroundColor: '#FD841F',
                                          cursor: 'pointer',
                                        },
                                      }}
                                      disabled={clickedItems.includes(row._id)}
                                    >
                                      {clickedItems.includes(row._id) ? t('addToQuoted') : t('addToQuote')}
                                    </Button>


                                  </div>
                                </TableCell>

                              </TableRow>
                            );
                          })}
                        {/* {emptyRows > 0 && (
                              <TableRow
                                style={{
                                  height: (53) * emptyRows,
                                }}
                              >
                                <TableCell colSpan={6} />
                              </TableRow>
                            )} */}
                      </TableBody>
                    }
                    {
                      (params.id === localStorage.getItem('hybrid_inverter')) &&

                      <TableBody>
                        {rows
                          // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {

                            const isItemSelected = isSelected(row._id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover

                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={index}
                                selected={isItemSelected}
                                sx={{ textDecoration: "none" }}>

                                <TableCell padding="checkbox">
                                  <Checkbox
                                    onClick={(event) => handleClick(event, row._id)}
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                  />
                                </TableCell>

                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                  className='img-box'
                                >
                                  {
                                    row.mainImage.logo != null ?

                                      <img className='product-img' src={apiURL.siteBaseUrl + row.mainImage.logo} alt="Product" />
                                      :
                                      <img className='product-img' src="/images/icons/no-image.png" alt="Product" />
                                  }
                                </TableCell>
                                <TableCell align="right">
                                  <p>{row.manufacturerId.name}</p>
                                </TableCell>


                                <TableCell align="right">
                                  <p>{row.p_max}W</p>
                                </TableCell>

                                <TableCell align="right">
                                  <p>{row.dimensions} mm</p>
                                </TableCell>

                                <TableCell align="right">
                                  <div className="counter">
                                    <span className="down" onClick={() => doClickMinus(index)}>-</span>
                                    <input type="text" value={row.quantity} key={index} onChange={(e) => setQty(e.target.value)} />
                                    <span className="up" onClick={() => doClickPlus(index)}>+</span>
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  <p>{row.price} €</p>
                                </TableCell>



                                <TableCell align="right" className='stock'>
                                  <div className='d-flex no-wrap justify-content-end'>
                                    <Button variant='contained' size='medium' color='success' onClick={() => { navigateToUpdate(row, index) }}
                                      sx={{
                                        color: "#FD841F",
                                        backgroundColor: "#FEF1E6",
                                        border: 'none',
                                        padding: "12px 18px",
                                        fontWeight: 700,
                                        fontSize: '16px',
                                        boxShadow: "none",
                                        "&:hover": {
                                          color: 'white',
                                          backgroundColor: '#FD841F',
                                          cursor: 'pointer',
                                        },
                                      }}
                                      disabled={clickedItems.includes(row._id)}
                                    >
                                      {clickedItems.includes(row._id) ? t('addToQuoted') : t('addToQuote')}
                                    </Button>

                                  </div>
                                </TableCell>

                              </TableRow>
                            );
                          })}
                        {/* {emptyRows > 0 && (
                              <TableRow
                                style={{
                                  height: (53) * emptyRows,
                                }}
                              >
                                <TableCell colSpan={6} />
                              </TableRow>
                            )} */}
                      </TableBody>
                    }
                    {
                      (params.id === localStorage.getItem('accessory')) &&

                      <TableBody>
                        {rows
                          // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {

                            const isItemSelected = isSelected(row._id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover

                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={index}
                                selected={isItemSelected}
                                sx={{ textDecoration: "none" }}>

                                <TableCell padding="checkbox">
                                  <Checkbox
                                    onClick={(event) => handleClick(event, row._id)}
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                  />
                                </TableCell>

                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                  className='img-box'
                                >
                                  {
                                    row.mainImage.logo != null ?

                                      <img className='product-img' src={apiURL.siteBaseUrl + row.mainImage.logo} alt="Product" />
                                      :
                                      <img className='product-img' src="/images/icons/no-image.png" alt="Product" />
                                  }
                                </TableCell>


                                <TableCell align="right">
                                  <p>{row.manufacturerId.name}</p>
                                </TableCell>


                                <TableCell align="right">
                                  <p>{row.p_max}W</p>
                                </TableCell>

                                <TableCell align="right">
                                  <p>{row.dimensions} mm</p>
                                </TableCell>

                                <TableCell align="right">
                                  <div className="counter">
                                    <span className="down" onClick={() => doClickMinus(index)}>-</span>
                                    <input type="text" value={row.quantity} key={index} onChange={(e) => setQty(e.target.value)} />
                                    <span className="up" onClick={() => doClickPlus(index)}>+</span>
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  <p>{row.price} €</p>
                                </TableCell>


                                <TableCell align="right" className='stock'>
                                  <div className='d-flex no-wrap justify-content-end'>
                                    <Button variant='contained' size='medium' color='success' onClick={() => { navigateToUpdate(row, index) }}
                                      sx={{
                                        color: "#FD841F",
                                        backgroundColor: "#FEF1E6",
                                        border: 'none',
                                        padding: "12px 18px",
                                        fontWeight: 700,
                                        fontSize: '16px',
                                        boxShadow: "none",
                                        "&:hover": {
                                          color: 'white',
                                          backgroundColor: '#FD841F',
                                          cursor: 'pointer',
                                        },
                                      }}
                                      disabled={clickedItems.includes(row._id)}
                                    >
                                      {clickedItems.includes(row._id) ? t('addToQuoted') : t('addToQuote')}
                                    </Button>


                                  </div>
                                </TableCell>

                              </TableRow>
                            );
                          })}
                        {/* {emptyRows > 0 && (
                              <TableRow
                                style={{
                                  height: (53) * emptyRows,
                                }}
                              >
                                <TableCell colSpan={6} />
                              </TableRow>
                            )} */}
                      </TableBody>
                    }
                    {
                      (params.id === localStorage.getItem('carport')) &&

                      <TableBody>
                        {rows
                          // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {

                            const isItemSelected = isSelected(row._id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover

                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={index}
                                selected={isItemSelected}
                                sx={{ textDecoration: "none" }}>

                                <TableCell padding="checkbox">
                                  <Checkbox
                                    onClick={(event) => handleClick(event, row._id)}
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                  />
                                </TableCell>

                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                  className='img-box'
                                >
                                  {
                                    row.mainImage.logo != null ?

                                      <img className='product-img' src={apiURL.siteBaseUrl + row.mainImage.logo} alt="Product" />
                                      :
                                      <img className='product-img' src="/images/icons/no-image.png" alt="Product" />

                                  }
                                </TableCell>


                                <TableCell align="right">
                                  <p>{row.manufacturerId.name}</p>
                                </TableCell>


                                <TableCell align="right">
                                  <p>{row.p_max}W</p>
                                </TableCell>

                                <TableCell align="right">
                                  <p>{row.dimensions} mm</p>
                                </TableCell>

                                <TableCell align="right">
                                  <div className="counter">
                                    <span className="down" onClick={() => doClickMinus(index)}>-</span>
                                    <input type="text" value={row.quantity} key={index} onChange={(e) => setQty(e.target.value)} />
                                    <span className="up" onClick={() => doClickPlus(index)}>+</span>
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  <p>{row.price} €</p>
                                </TableCell>


                                <TableCell align="right" className='stock'>
                                  <div className='d-flex no-wrap justify-content-end'>
                                    <Button variant='contained' size='medium' color='success' onClick={() => { navigateToUpdate(row, index) }}
                                      sx={{
                                        color: "#FD841F",
                                        backgroundColor: "#FEF1E6",
                                        border: 'none',
                                        padding: "12px 18px",
                                        fontWeight: 700,
                                        fontSize: '16px',
                                        boxShadow: "none",
                                        "&:hover": {
                                          color: 'white',
                                          backgroundColor: '#FD841F',
                                          cursor: 'pointer',
                                        },
                                      }}
                                      disabled={clickedItems.includes(row._id)}
                                    >
                                      {clickedItems.includes(row._id) ? t('addToQuoted') : t('addToQuote')}
                                    </Button>

                                  </div>
                                </TableCell>

                              </TableRow>
                            );
                          })}
                        {/* {emptyRows > 0 && (
                              <TableRow
                                style={{
                                  height: (53) * emptyRows,
                                }}
                              >
                                <TableCell colSpan={6} />
                              </TableRow>
                            )} */}
                      </TableBody>
                    }
                    {
                      (params.id === localStorage.getItem('mounting')) &&

                      <TableBody>
                        {rows
                          // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {

                            const isItemSelected = isSelected(row._id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover

                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={index}
                                selected={isItemSelected}
                                sx={{ textDecoration: "none" }}>

                                <TableCell padding="checkbox">
                                  <Checkbox
                                    onClick={(event) => handleClick(event, row._id)}
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                  />
                                </TableCell>

                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                  className='img-box'
                                >
                                  {
                                    row.mainImage.logo != null ?

                                      <img className='product-img' src={apiURL.siteBaseUrl + row.mainImage.logo} alt="Product" />
                                      :
                                      <img className='product-img' src="/images/icons/no-image.png" alt="Product" />

                                  }
                                </TableCell>


                                <TableCell align="right">
                                  <p>{row.manufacturerId.name}</p>
                                </TableCell>


                                <TableCell align="right">
                                  <p>{row.p_max}W</p>
                                </TableCell>

                                <TableCell align="right">
                                  <p>{row.dimensions} mm</p>
                                </TableCell>

                                <TableCell align="right">
                                  <span className="down" onClick={() => doClickMinus(index)}>-</span>
                                  <input type="text" value={row.quantity} key={index} onChange={(e) => setQty(e.target.value)} />
                                  <span className="up" onClick={() => doClickPlus(index)}>+</span>
                                </TableCell>
                                <TableCell align="right">
                                  <p>{row.price} €</p>
                                </TableCell>


                                <TableCell align="right" className='stock'>
                                  <div className='d-flex no-wrap justify-content-end'>
                                    <Button variant='contained' size='medium' color='success'
                                      onClick={() => { navigateToUpdate(row, index) }}
                                      sx={{
                                        color: "#FD841F",
                                        backgroundColor: "#FEF1E6",
                                        border: 'none',
                                        padding: "12px 18px",
                                        fontWeight: 700,
                                        fontSize: '16px',
                                        boxShadow: "none",
                                        "&:hover": {
                                          color: 'white',
                                          backgroundColor: '#FD841F',
                                          cursor: 'pointer',
                                        },
                                      }}
                                      disabled={clickedItems.includes(row._id)}
                                    >
                                      {clickedItems.includes(row._id) ? t('addToQuoted') : t('addToQuote')}
                                    </Button>


                                  </div>
                                </TableCell>

                              </TableRow>
                            );
                          })}
                        {/* {emptyRows > 0 && (
                              <TableRow
                                style={{
                                  height: (53) * emptyRows,
                                }}
                              >
                                <TableCell colSpan={6} />
                              </TableRow>
                            )} */}
                      </TableBody>
                    }

                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  // rowsPerPageOptions={[]}
                  component="div"
                  count={totalRecords}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>


            </Box>


          </Container>
        )
      }
      <Snackbar open={open} autoHideDuration={6000} onClose={handleAlertClose} sx={{ width: '100%' }}>
        <Alert onClose={handleAlertClose} severity="error" >
          {errorMsg}
        </Alert>
      </Snackbar>

      <AlertDialog open={openDeleteAlert} title={selected.length === 1 ? "Delete Product" : "Delete Products"} message={selected.length === 1 ? "Are you sure you want to delete the product?" : "Are you sure you want to delete the products?"} handleAction={confirmDeleteProduct} handleClose={handleDeleteAlertClose}></AlertDialog>
    </>
  );
}