import { useState } from "react";

import {
    modelNoValidator,
    nominalPowerValidator,
    priceValidator,
    mpptValidator
} from "components/Validators/Validators.jsx";

const touchErrors = errors => {
    return Object.entries(errors).reduce((acc, [field, fieldError]) => {
        acc[field] = {
            ...fieldError,
            dirty: true,
        };
        return acc;
    }, {});
};

export const useInverterValidator = form => {
    const [errors, setErrors] = useState({
        modelNo: {
            dirty: false,
            error: false,
            message: "",
        },
        price: {
            dirty: false,
            error: false,
            message: "",
        },
        nominalPower: {
            dirty: false,
            error: false,
            message: "",
        },
        mppt: {
            dirty: false,
            error: false,
            message: "",
        },
    });

    const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
        let isValid = true;

        // Create a deep copy of the errors
        let nextErrors = JSON.parse(JSON.stringify(errors));

        // Force validate all the fields
        if (forceTouchErrors) {
            nextErrors = touchErrors(errors);
        }

        const { modelNo = '', nominalPower = '', mppt = '', price = '' } = form;

        if (nextErrors.modelNo.dirty && (field ? field === "modelNo" : true)) {
            const modelNoMessage = modelNoValidator(modelNo, form);
            nextErrors.modelNo.error = !!modelNoMessage;
            nextErrors.modelNo.message = modelNoMessage;
            if (!!modelNoMessage) isValid = false;
        }



        if (nextErrors.nominalPower.dirty && (field ? field === "nominalPower" : true)) {
            const nominalPowerMessage = nominalPowerValidator(nominalPower, form);
            nextErrors.nominalPower.error = !!nominalPowerMessage;
            nextErrors.nominalPower.message = nominalPowerMessage;
            if (!!nominalPowerMessage) isValid = false;
        }


        if (nextErrors.price.dirty && (field ? field === "price" : true)) {
            const priceMessage = priceValidator(price, form);
            nextErrors.price.error = !!priceMessage;
            nextErrors.price.message = priceMessage;
            if (!!priceMessage) isValid = false;
        }


        if (nextErrors.mppt.dirty && (field ? field === "mppt" : true)) {
            const mpptMessage = mpptValidator(mppt, form);
            nextErrors.mppt.error = !!mpptMessage;
            nextErrors.mppt.message = mpptMessage;
            if (!!mpptMessage) isValid = false;
        }



        console.log("isValid", isValid

        )
        setErrors(nextErrors);
        return {
            isValid,
            errors: nextErrors,
        };
    };

    const onBlurField = e => {
        const field = e.target.name;
        const fieldError = errors[field];
        if (fieldError.dirty) return;

        const updatedErrors = {
            ...errors,
            [field]: {
                ...errors[field],
                dirty: true,
            },
        };

        validateForm({ form, field, errors: updatedErrors });
    };

    return {
        validateForm,
        onBlurField,
        errors,
    };
};