import React from 'react';
import AdminSidebar from 'components/AdminSidebar/AdminSidebar';
function HomeScreen(props) {
    return (
        <div>
            <AdminSidebar />
        </div>
    );
}

export default HomeScreen;