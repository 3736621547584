import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import HomeIcon from "@mui/icons-material/Home";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Dashboard from "pages/AdminPanel/Dashboard/Dashboard";
import { useTranslation } from "react-i18next";

import "css/adminaside.css";

import { GrUserWorker } from "react-icons/gr";
import {
  FaSolarPanel,
  FaCarSide,
  FaMountain,
  FaLanguage,
  FaUserTie,
} from "react-icons/fa";
import {
  MdStorefront,
  MdSupportAgent,
  MdHome,
  MdOutlineRouter,
} from "react-icons/md";
import { GiWallet } from "react-icons/gi";
import { HiUserCircle } from "react-icons/hi";

import { RiLightbulbFlashFill, RiBarChart2Fill } from "react-icons/ri";

import AgentDashboard from "pages/AgentPanel/Dashboard/Dashboard";
import AddPrivateClient from "pages/AgentPanel/Client/PrivateClient/Add/AddPrivateClient";
import PrivateClientList from "pages/AgentPanel/Client/PrivateClient/List/PrivateClientList";
import AddCompanyClient from "pages/AgentPanel/Client/CompanyClient/Add/AddCompanyClient";
import CompanyClientList from "pages/AgentPanel/Client/CompanyClient/List/CompanyClientList";

import QuotationList from "pages/AgentPanel/Quotation/List/QuotationList";
import AddItem from "pages/AgentPanel/Quotation/Item/AddItem";
import apiURL from "apiURL/apiURL";
import { AuthContext } from "context/AuthContext";
import ClientList from "pages/AgentPanel/List";
import AddQuotation from "pages/AgentPanel/Quotation/Add";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function AgentSidebar(props) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [path, setPath] = useState();
  const location = useLocation();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { i18n, t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const { currentUser } = useContext(AuthContext);

  const data = [
    {
      icon: <MdHome fontSize="24" />,
      label: t("dashBoard"),
      link: "/agent",
      paths: [""],
    },
    {
      icon: <GiWallet fontSize="24" />,
      label: t("quotationLabel"),
      link: "/agent/quotationlist",
      paths: [
        "/agent/addquotation",
        "/agent/additem",
        `/agent/quotation/additem/${localStorage.getItem("solarpanel")}`,
      ],
    },
    {
      icon: <RiBarChart2Fill fontSize="24" />,
      label: t("sales"),
      link: "/agent/saleslist",
      paths: [""],
    },
    {
      icon: <FaUserTie fontSize="24" />,
      label: t("client"),
      link: "/agent/client-list/private",
      paths: [
        "/agent/client-list/company",
        "/agent/addprivateclient",
        "/agent/addcompanyclient",
      ],
    },
    {
      icon: <HiUserCircle fontSize="24" />,
      label: t("profile"),
      link: "/agent/profilelist",
      paths: [""],
    },
  ];

  const changeLanguage = (e) => {
    const selectedLanguage = e.target.value;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("selectedLanguage", selectedLanguage);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    } else {
      i18n.changeLanguage("it");
    }
    //  console.log(You changed the page to: ${location.pathname})
    setPath(location.pathname);
  }, [location, path]);

  return (
    // <Router>
    <div>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          sx={{ backgroundColor: "#FD841F" }}
        >
          <Toolbar className="section-toolbar">
            <div className="d-flex align-items-center">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                {t("agentPanel")}
              </Typography>
            </div>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Link
                to="/"
                sx={
                  {
                    // "&:hover": {
                    //   backgroundColor: "#ffffff50",
                    // },
                  }
                }
              >
                <HomeIcon
                  sx={
                    {
                      // "&:hover": {
                      //   backgroundColor: "#ffffff50",
                      // },
                    }
                  }
                />
              </Link>

              <div className="d-flex align-items-center ps-3 ms-3 border-start language">
                <FaLanguage color="white" fontSize="1.5em" className="ms-1" />
                <FormControl>
                  <Select
                    id="demo-simple-select"
                    value={i18n.language}
                    onChange={changeLanguage}
                    sx={{
                      color: "white",
                      width: "60",

                      ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      ".MuiSelect-icon": { color: "white" },
                      "&:hover": {
                        backgroundColor: "#ffffff50",
                      },
                      "&.Mui-focused": {
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      },
                    }}
                  >
                    <MenuItem value="it">Italian</MenuItem>
                    <MenuItem value="en">English</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Box>
          </Toolbar>
        </AppBar>

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List style={{ textAlign: "center" }}>
            <ListItemAvatar
              style={{ justifyContent: "center", display: "flex" }}
            >
              <Avatar
                alt="User"
                src={
                  currentUser.mainImage != ""
                    ? apiURL.siteBaseUrl + currentUser.mainImage
                    : "/images/agent/agent.png"
                }
                sx={{
                  width: "120px !important",
                  height: "120px !important",
                  textAlign: "center",
                  objectFit: "cover",
                  color: "transparent",
                  textIndent: "10000px",
                }}
              />
            </ListItemAvatar>
            <ListItemText
              style={{
                justifyContent: "center",
                display: "flex",
                fontSize: "64px",
              }}
            >
              <h5 className="mt-2">
                {currentUser.firstName + " " + currentUser.lastName}
              </h5>
              <h6 className="mt-4">
                {currentUser.code ? `${t("yourCode")}:` : ""}
              </h6>
              <h3>
                <strong>
                  {currentUser.code ? "x".repeat(currentUser.code.length) : ""}
                </strong>
              </h3>
            </ListItemText>

            <div className="mt-5 DrawerItems">
              {data.map((item, index) => (
                <ListItem
                  key={item.label}
                  disablePadding
                  component={Link}
                  to={item.link}
                  style={
                    path === item.link || item?.paths?.includes(path)
                      ? {
                          backgroundColor: "#FD841F28",
                          color: "#FD841F",
                        }
                      : {}
                  }
                >
                  <ListItemButton>
                    <ListItemIcon
                      to={item.link}
                      style={
                        path === item.link || item?.paths?.includes(path)
                          ? {
                              color: "#FD841F",
                            }
                          : {}
                      }
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </div>
          </List>
        </Drawer>

        <Main open={open}>
          <DrawerHeader />
          <Routes>
            <Route path="/" name="Dashboard" element={<AgentDashboard />} />
            <Route path="/addprivateclient" element={<AddPrivateClient />} />
            <Route path="/addcompanyclient" element={<AddCompanyClient />} />
            <Route path="/client-list/:type" element={<ClientList />} />
            <Route path="/companyclientlist" element={<CompanyClientList />} />
            <Route path="/quotationlist" element={<QuotationList />} />
            <Route path="/addquotation" element={<AddQuotation />} />
            <Route path="/quotation/additem/:id" element={<AddItem />} />
          </Routes>
        </Main>
      </Box>
    </div>
  );
}

export default AgentSidebar;
