import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import { useLocation } from "react-router-dom";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AlertDialog from "components/AlertDialog/AlertDialog";

import Ratio from "react-bootstrap/Ratio";

import { FaArrowRight, FaHandPointRight, FaBox, FaMale } from "react-icons/fa";
// import Login from 'pages/Login/Login';

import "css/home.css";
import Login from "pages/Login";
import { AppBar } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Home = (props) => {
  const { state } = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const isModalPopup = state?.isModalPopup;
    const errorMsg = state?.errorMsg;
    setModalOpen(isModalPopup);

    if (errorMsg) {
      setOpen(true);
      setErrorMsg(errorMsg);
    }
  }, []);

  const handleModalOpen = (event) => {
    setModalOpen(false);
  };
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <>
      {/* <div className="costome-carousel"> */}
      <Carousel className="costome-carousel">
        <Carousel.Item className="carousel-slide">
          {/* <video className="w-100" src="video/carousel/solar.mp4" autoPlay loop>
              </video> */}
          <img
            className="full-height-image"
            src="images/carousel/slide1.jpeg"
            alt="Second slide"
          />
          <Carousel.Caption>
            <h3>{t("heroTitle1")}</h3>
            <p>{t("heroSubText1")}</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <Container className="section-why-choose">
        <div className="margin-bottom">
          <h6 className="text-edited">{t("whyChoose")}?</h6>
          <h1 className="text-center">{t("whyChooseTitle")}</h1>
        </div>
        <Row>
          <Col md={12} lg={4} className="px-4 flex-center mb-3">
            <div className="display-flex">
              <div className="icon-box">
                <FaHandPointRight
                  fontSize="56px"
                  color="var(--primary-color)"
                />
              </div>
              <div className="box-text">
                <span>{t("around")}</span>
                <span className="heading-text">30+ Yr</span>
                <span>{t("inMarket")}</span>
              </div>
            </div>
          </Col>
          <Col md={12} lg={4} className="px-4 flex-center mb-3">
            <div className="display-flex">
              <div className="icon-box">
                <FaBox fontSize="56px" color="var(--primary-color)" />
              </div>
              <div className="box-text">
                <span>{t("around")}</span>
                <span className="heading-text">100+</span>
                <span>{t("products")}</span>
              </div>
            </div>
          </Col>
          <Col md={12} lg={4} className="px-4 flex-center mb-3">
            <div className="display-flex">
              <div className="icon-box">
                <FaMale fontSize="56px" color="var(--primary-color)" />
              </div>
              <div className="box-text">
                <span>{t("teamOf")}</span>
                <span className="heading-text">150+</span>
                <span>{t("experts")}</span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="section-service">
        <Row className="text-center-home col">
          <Col sm={12} md={6}>
            <img
              className="rounded-100"
              src="images/services/solarpanel.jpg"
              alt="solar panel"
              width="100%"
              height="480px"
            />
          </Col>

          <Col sm={12} md={6} className="px-4 service-content">
            <Row>
              <span className="text-center-span-1">{t("service")}</span>
              <h1>{t("solarPanel")}</h1>

              <p>{t("solarPara")}</p>
            </Row>

            <Row>
              <Col
                xs={4}
                sm={4}
                md={4}
                style={{ padding: "8px" }}
                className="service-main"
              >
                <Ratio aspectRatio="1x1">
                  <div className="service-img">
                    <img src="images/solar-plate.jpeg" className="img-fluid" />
                  </div>
                </Ratio>

                {/* <img src="images/solar-plate.jpeg" width="100%" /> */}
                <h6 className="text-bold">10 wat</h6>
                <span>20 {t("yearWarranty")}</span>
              </Col>

              <Col
                xs={4}
                sm={4}
                md={4}
                style={{ padding: "8px" }}
                className="service-main"
              >
                <Ratio aspectRatio="1x1">
                  <div className="service-img">
                    <img src="images/solar-pv.jpeg" className="img-fluid" />
                  </div>
                </Ratio>

                {/* <img src="images/solar-plate.jpeg" width="100%" /> */}
                <h6 className="text-bold">10 wat</h6>
                <span>20 {t("yearWarranty")}</span>
              </Col>

              <Col
                xs={4}
                sm={4}
                md={4}
                style={{ padding: "8px" }}
                className="service-main"
              >
                <Ratio aspectRatio="1x1">
                  <div className="service-img">
                    <img src="images/solar.jpeg" className="img-fluid" />
                  </div>
                </Ratio>

                {/* <img src="images/solar-plate.jpeg" width="100%" /> */}
                <h6 className="text-bold">10 wat</h6>
                <span>20 {t("yearWarranty")}</span>
              </Col>
            </Row>
            {/* <div className='all-list'>
              <div className="list">
                <img src="images/icons/list.png" height={8} className="me-1" /><span>{t('homeSolar')}</span>
              </div>

              <div className="list">
                <img src="images/icons/list.png" height={8} className="me-1" /><span>{t('industrySolar')}</span>
              </div>
            </div> */}

            <Link to={`/product/${localStorage.getItem("solarpanel")}`}>
              <button className="learnMore">
                {t("learnMore")}
                <FaArrowRight className="button-icon ms-2" />
              </button>
            </Link>
          </Col>
        </Row>
      </Container>

      <Container className="section-service">
        <Row className="reverse text-center-home col">
          <Col sm={12} md={6} className="px-4 service-content">
            <Row>
              <span className="text-center-span-1">{t("product")}</span>
              <h1>{t("inverter")}</h1>

              <p>{t("inverterPara")}</p>
            </Row>

            <Row>
              <Col
                xs={4}
                sm={4}
                md={4}
                style={{ padding: "8px" }}
                className="service-main"
              >
                <Ratio aspectRatio="1x1">
                  <div className="service-img">
                    <img src="images/inv.png" className="img-fluid" />
                  </div>
                </Ratio>

                {/* <img src="images/solar-plate.jpeg" width="100%" /> */}
                <h6 className="text-bold">10 wat</h6>
                <span>20 {t("yearWarranty")}</span>
              </Col>

              <Col
                xs={4}
                sm={4}
                md={4}
                style={{ padding: "8px" }}
                className="service-main"
              >
                <Ratio aspectRatio="1x1">
                  <div className="service-img">
                    <img src="images/inv2.png" className="img-fluid" />
                  </div>
                </Ratio>

                {/* <img src="images/solar-plate.jpeg" width="100%" /> */}
                <h6 className="text-bold">10 wat</h6>
                <span>20 {t("yearWarranty")}</span>
              </Col>

              <Col
                xs={4}
                sm={4}
                md={4}
                style={{ padding: "8px" }}
                className="service-main"
              >
                <Ratio aspectRatio="1x1">
                  <div className="service-img">
                    <img src="images/5kv-inveter.jpeg" className="img-fluid" />
                  </div>
                </Ratio>

                {/* <img src="images/solar-plate.jpeg" width="100%" /> */}
                <h6 className="text-bold">10 wat</h6>
                <span>20 {t("yearWarranty")}</span>
              </Col>
            </Row>
            {/* <div className='all-list'>
              <div className="list">
                <img src="images/icons/list.png" height={8} className="me-1" /><span>{t('homeSolar')}</span>
              </div>

              <div className="list">
                <img src="images/icons/list.png" height={8} className="me-1" /><span>{t('industrySolar')}</span>
              </div>
            </div> */}

            <Link to={`/product/${localStorage.getItem("solarpanel")}`}>
              <button className="learnMore">
                {t("learnMore")}
                <FaArrowRight className="button-icon ms-2" />
              </button>
            </Link>
          </Col>

          <Col sm={12} md={6}>
            <img
              className="rounded-100"
              src="images/services/inverter.jpg"
              alt="solar panel"
              width="100%"
              height="480px"
            />
          </Col>
        </Row>
      </Container>

      <Container className="section-service">
        <Row className="text-center-home col">
          <Col sm={12} md={6}>
            <img
              className="rounded-100"
              src="images/services/storage.jpg"
              alt="solar panel"
              width="100%"
              height="480px"
            />
          </Col>

          <Col sm={12} md={6} className="px-4 service-content">
            <Row>
              <span className="text-center-span-1">{t("product")}</span>
              <h1>{t("storage")}</h1>
              <p>{t("storagePara")}</p>
            </Row>

            <Row>
              <Col
                xs={4}
                sm={4}
                md={4}
                style={{ padding: "8px" }}
                className="service-main"
              >
                <Ratio aspectRatio="1x1">
                  <div className="service-img">
                    <img src="images/inv3.png" className="img-fluid" />
                  </div>
                </Ratio>

                {/* <img src="images/solar-plate.jpeg" width="100%" /> */}
                <h6 className="text-bold">75 wattt</h6>
                <span>5 {t("yearWarranty")}</span>
              </Col>

              <Col
                xs={4}
                sm={4}
                md={4}
                style={{ padding: "8px" }}
                className="service-main"
              >
                <Ratio aspectRatio="1x1">
                  <div className="service-img">
                    <img
                      src="images/75watt-battery.jpeg"
                      className="img-fluid"
                    />
                  </div>
                </Ratio>

                {/* <img src="images/solar-plate.jpeg" width="100%" /> */}
                <h6 className="text-bold">75 watt</h6>
                <span>5 {t("yearWarranty")}</span>
              </Col>

              <Col
                xs={4}
                sm={4}
                md={4}
                style={{ padding: "8px" }}
                className="service-main"
              >
                <Ratio aspectRatio="1x1">
                  <div className="service-img">
                    <img
                      src="images/75watt-battery.jpeg"
                      className="img-fluid"
                    />
                  </div>
                </Ratio>

                {/* <img src="images/solar-plate.jpeg" width="100%" /> */}
                <h6 className="text-bold">75 watt</h6>
                <span>5 {t("yearWarranty")}</span>
              </Col>
            </Row>
            {/* <div className='all-list'>
              <div className="list">
                <img src="images/icons/list.png" height={8} className="me-1" /><span>{t('homeSolar')}</span>
              </div>

              <div className="list">
                <img src="images/icons/list.png" height={8} className="me-1" /><span>{t('industrySolar')}</span>
              </div>
            </div> */}

            <Link to={`/product/${localStorage.getItem("solarpanel")}`}>
              <button className="learnMore">
                {t("learnMore")}
                <FaArrowRight className="button-icon ms-2" />
              </button>
            </Link>
          </Col>
        </Row>
      </Container>

      <Container className="section-service">
        <Row className="reverse text-center-home col">
          <Col sm={12} md={6} className="px-4 service-content">
            <Row>
              <span className="text-center-span-1">{t("product")}</span>
              <h1>{t("mounting")}</h1>
              <p>{t("mountingPara")}</p>
            </Row>

            <Row>
              <Col
                xs={4}
                sm={4}
                md={4}
                style={{ padding: "8px" }}
                className="service-main"
              >
                <Ratio aspectRatio="1x1">
                  <div className="service-img">
                    <img src="images/structure-1.jpeg" className="img-fluid" />
                  </div>
                </Ratio>

                {/* <img src="images/solar-plate.jpeg" width="100%" /> */}
                <h6 className="text-bold">10 wattt</h6>
                <span>20 {t("yearWarranty")}</span>
              </Col>

              <Col
                xs={4}
                sm={4}
                md={4}
                style={{ padding: "8px" }}
                className="service-main"
              >
                <Ratio aspectRatio="1x1">
                  <div className="service-img">
                    <img src="images/structure-1.jpeg" className="img-fluid" />
                  </div>
                </Ratio>

                {/* <img src="images/solar-plate.jpeg" width="100%" /> */}
                <h6 className="text-bold">10 watt</h6>
                <span>20 {t("yearWarranty")}</span>
              </Col>

              <Col
                xs={4}
                sm={4}
                md={4}
                style={{ padding: "8px" }}
                className="service-main"
              >
                <Ratio aspectRatio="1x1">
                  <div className="service-img">
                    <img src="images/structure-1.jpeg" className="img-fluid" />
                  </div>
                </Ratio>

                {/* <img src="images/solar-plate.jpeg" width="100%" /> */}
                <h6 className="text-bold">10 watt</h6>
                <span>20 {t("yearWarranty")}</span>
              </Col>
            </Row>
            {/* <div className='all-list'>
              <div className="list">
                <img src="images/icons/list.png" height={8} className="me-1" /><span>{t('homeSolar')}</span>
              </div>

              <div className="list">
                <img src="images/icons/list.png" height={8} className="me-1" /><span>{t('industrySolar')}</span>
              </div>
            </div> */}

            <Link to={`/product/${localStorage.getItem("solarpanel")}`}>
              <button className="learnMore">
                {t("learnMore")}
                <FaArrowRight className="button-icon ms-2" />
              </button>
            </Link>
          </Col>

          <Col sm={12} md={6}>
            <img
              className="rounded-100"
              src="images/services/mounting.jpg"
              alt="solar panel"
              width="100%"
              height="480px"
            />
          </Col>
        </Row>
      </Container>

      <Container className="section-service">
        <Row className="text-center-home col">
          <Col sm={12} md={6}>
            <img
              className="rounded-100"
              src="images/services/carport.jpg"
              alt="solar panel"
              width="100%"
              height="480px"
            />
          </Col>

          <Col sm={12} md={6} className="px-4 service-content">
            <Row>
              <span className="text-center-span-1">{t("product")}</span>
              <h1>{t("carPort")}</h1>
              <p>{t("carPortPara")}</p>
            </Row>

            <Row>
              <Col
                xs={4}
                sm={4}
                md={4}
                style={{ padding: "8px" }}
                className="service-main"
              >
                <Ratio aspectRatio="1x1">
                  <div className="service-img">
                    <img src="images/structure-1.jpeg" className="img-fluid" />
                  </div>
                </Ratio>

                {/* <img src="images/solar-plate.jpeg" width="100%" /> */}
                <h6 className="text-bold">75 watt</h6>
                <span>5 {t("yearWarranty")}</span>
              </Col>

              <Col
                xs={4}
                sm={4}
                md={4}
                style={{ padding: "8px" }}
                className="service-main"
              >
                <Ratio aspectRatio="1x1">
                  <div className="service-img">
                    <img src="images/structure-1.jpeg" className="img-fluid" />
                  </div>
                </Ratio>

                {/* <img src="images/solar-plate.jpeg" width="100%" /> */}
                <h6 className="text-bold">75 watt</h6>
                <span>5 {t("yearWarranty")}</span>
              </Col>

              <Col
                xs={4}
                sm={4}
                md={4}
                style={{ padding: "8px" }}
                className="service-main"
              >
                <Ratio aspectRatio="1x1">
                  <div className="service-img">
                    <img src="images/structure-1.jpeg" className="img-fluid" />
                  </div>
                </Ratio>

                {/* <img src="images/solar-plate.jpeg" width="100%" /> */}
                <h6 className="text-bold">75 watt</h6>
                <span>5 {t("yearWarranty")}</span>
              </Col>
            </Row>
            {/* <div className='all-list'>
              <div className="list">
                <img src="images/icons/list.png" height={8} className="me-1" /><span>{t('homeSolar')}</span>
              </div>

              <div className="list">
                <img src="images/icons/list.png" height={8} className="me-1" /><span>{t('industrySolar')}</span>
              </div>
            </div> */}

            <Link to={`/product/${localStorage.getItem("solarpanel")}`}>
              <button className="learnMore">
                {t("learnMore")}
                <FaArrowRight className="button-icon ms-2" />
              </button>
            </Link>
          </Col>
        </Row>
      </Container>

      <div className="bg-color section-install">
        <Container>
          <div className="text-center">
            <h6 className="text-white mb-4">{t("WantToInstall")}?</h6>
            <h1 className="text-bold-h1">{t("WantToInstallTitle")}</h1>
          </div>
          <div className="center-div">
            <button className="btn-edit p-2">{t("contact")}</button>
          </div>
        </Container>
      </div>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>

      <Login modalOpen={modalOpen} handleModalOpen={handleModalOpen} />
      {/* </div> */}
      {/* //Todo */}
      {/* <Login show={false} /> */}
    </>
  );
};

export default Home;
