// src/components/FileUploadModal.js

import React from 'react';
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

function FileUploadModal({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <IconButton
        edge="end"
        color="inherit"
        onClick={onClose}
        aria-label="close"
        sx={{ position: 'absolute', top: '8px', right: '8px' }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <Typography variant="h5">Upload a File</Typography>
        {/* Add your file upload input and logic here */}
      </DialogContent>
    </Dialog>
  );
}

export default FileUploadModal;