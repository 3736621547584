import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
import Container from "react-bootstrap/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import "css/EditSolarForm.css";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import axios from "axios";
import defaultString from "constants/defaultString.json";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AlertDialog from "components/AlertDialog/AlertDialog";
import { AuthContext } from "context/AuthContext";
import { resetToken } from "apiURL";
import { styled } from "@mui/material/styles";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { Close } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  maxHeight: "85%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px",
  overflowY: "scroll",
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddManufacturer = (props) => {
  // console.log("props :: :: :: ", props);

  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [types, setTypes] = useState([]);

  const [name, setName] = useState("");
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [mainImage, setMainImage] = useState({ id: 0, logo: null });
  const [otherImages, setOtherImages] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [attachmentId, setAttachmentId] = useState("");
  const [isChangeMainImage, setIsChangeMainImage] = useState(false);
  const [indexToBeDelete, setIndexToBeDelete] = useState("");
  const [attachType, setAttachType] = useState("");
  const [logoImageError, setLogoImageError] = useState(false);
  const [typesError, setTypesError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [severity, setSeverity] = useState("error");

  const [isDisabledSaveBtn, setIsDisabledSaveBtn] = useState(false);
  const [isDisabledDeleteBtn, setIsDisabledDeleteBtn] = useState(false);

  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const { state } = useLocation();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const names1 = [
    t("solarPanel"),
    t("inverter"),
    t("storage"),
    t("mounting"),
    t("carPort"),
  ];

  const names = [
    {
      _id: localStorage.getItem("solarpanel"),
      name: t("solarPanel"),
    },
    {
      _id: localStorage.getItem("inverter"),
      name: t("inverter"),
    },
    {
      _id: localStorage.getItem("storage"),
      name: t("storage"),
    },
    {
      _id: localStorage.getItem("mounting"),
      name: t("mounting"),
    },
    {
      _id: localStorage.getItem("carport"),
      name: t("carPort"),
    },
  ];

  function getStyles(name, types, theme) {
    // console.log(name, types)
    return {
      fontWeight:
        types.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleChange = (event) => {
    setTypes([]);
    const {
      target: { value },
    } = event;
    console.log("in change", value);
    setTypes(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setTypesError(false);
  };

  const getValueFromKey = (id) => {
    return names.map((key) => {
      if (key._id === id) return key.name;
    });
  };
  useEffect(() => {
    if (props.stateObj) {
      setIsEdit(true);
      setName(props.stateObj.name);

      let typeIds = [];
      if (props.stateObj.type.length > 0) {
        props.stateObj.type.map((item) => {
          typeIds.push(item._id);
        });
      }
      setTypes(typeIds);

      if (props.stateObj.mainImage.logo !== null) {
        setMainImage(props.stateObj.mainImage);
      }

      let uploadedDocArray = [];
      if (props.stateObj.documents.length > 0) {
        for (let i = 0; i < props.stateObj.documents.length; i++) {
          uploadedDocArray.push(props.stateObj.documents[i]);
        }
        setDocuments(uploadedDocArray);
      }
    }
  }, [props]);

  useEffect(() => {
    if (!props.isEdit) {
      setName("");
      setMainImage("");
      setDocuments("");
      setTypes([]);
    }
    if (props.handleClose) {
      setLogoImageError(false);
      setTypesError(false);
      setNameError(false);
    }
  }, [props.handleClose, props.isEdit]);

  const handleMainPicture = (event) => {
    const isLt2M = event.target.files[0].size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setOpen(true);
      setSeverity("error");
      setErrorMsg("Image must smaller than 2MB!");
      return;
    }
    setMainImage({ id: 0, logo: event.target.files[0] });
    setLogoImageError(false);
    setIsChangeMainImage(true);
  };

  const handleDeleteAlertClose = () => {
    setOpenDeleteAlert(false);
    setAttachmentId("");
    setAttachType("");
    setIndexToBeDelete("");
  };

  const confirmDeleteItem = async () => {
    console.log("attachmentId:: ", attachmentId);
    setOpenDeleteAlert(false);

    if (attachType == "mainImage") {
      if (attachmentId !== 0) {
        await fnDeleteAttachment(attachmentId);
      } else {
        setMainImage({ id: 0, logo: null });
        setLogoImageError(true);
      }
      let inputId = document.getElementById("formFile1");
      inputId.value = null;
    } else if (attachType === "document") {
      console.log("indexToBeDelete: ", indexToBeDelete);
      if (attachmentId !== 0) {
        await fnDeleteAttachment(attachmentId);
      } else {
        const filteredArr = documents.filter(
          (item, index) => index !== indexToBeDelete
        );
        setDocuments(filteredArr);
      }
      // let inputId = document.getElementById('otherImages');
      // inputId.value = null;
    } else if (attachType === "product") {
      if (attachmentId !== 0) {
        await fnDeleteProduct(attachmentId);
      }
    }
  };

  const deleteItem = (itemId, index, attachmentType) => {
    // const s = attachments.filter((item, index) => index !== e);
    // setAttachments(s);
    // console.log(attachments)
    // console.log(s);
    setOpenDeleteAlert(true);
    setAttachmentId(itemId);
    setIndexToBeDelete(index);
    setAttachType(attachmentType);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const fnAdd = async (e) => {
    // e.preventDefault();
    if (!name) {
      setNameError(true);
    }
    if (types.length == 0) {
      setTypesError(true);
    }
    if (!mainImage.logo) {
      setLogoImageError(true);
      setSeverity("error");
      setOpen(true);
      setErrorMsg(defaultString.validData);
    }
    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));

    console.log(mainImage);
    // if (!!mainImage.logo) {

    if (documents.length > 5) {
      setSeverity("error");
      setOpen(true);
      setErrorMsg(defaultString.maxDocumentLimitMsg);
      return;
    }

    if (name && types.length > 0) {
      if (!mainImage.logo) {
        setLogoImageError(true);
        setSeverity("error");
        setOpen(true);
        setErrorMsg(defaultString.validData);
        return;
      }
      const formData = new FormData();

      if (mainImage.logo != null) {
        formData.append("profilePicture", mainImage.logo);
      }

      console.log(documents);
      if (documents.length > 0) {
        documents.map((image, i) => {
          if (!!image.document) formData.append("document", image.document);
        });
      }
      setIsDisabledSaveBtn(true);

      formData.append("name", name);
      formData.append("type", JSON.stringify(types));

      await axios
        .post(apiURL.service_add_manufacturer, formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.status === apiStatusCode.CREATED) {
            setOpen(true);
            setSeverity("success");
            setErrorMsg(res.data.message);
            props.handleClose({
              reload: true,
            });
          } else {
            console.log("In else portion ", res.data);
            setOpen(true);
            setSeverity("error");
            setIsDisabledSaveBtn(false);
            setErrorMsg(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error.response);
          setSeverity("error");
          setOpen(true);
          setIsDisabledSaveBtn(false);
          if (error.code === defaultString.network_error) {
            setSeverity("error");
            setErrorMsg(error.message);
          } else {
            setSeverity("error");
            setErrorMsg(error.response.data.message);
          }
          if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
            doLogout(error);
          }
        });
    } else {
      setSeverity("error");
      setOpen(true);
      setErrorMsg(defaultString.validData);
    }
    // }
    // else {
    //   setOpen(true);
    //   setErrorMsg("Please upload logo!")
    // }
  };

  const fnEdit = async (manufacturerId) => {
    // e.preventDefault();
    // if (!name) {
    //   setNameError(true);
    // }
    if (types.length == 0) {
      setTypesError(true);
    }
    if (!mainImage.logo) {
      setLogoImageError(true);
      setSeverity("error");
      setOpen(true);
      setErrorMsg(defaultString.validData);
    }
    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));

    if (name && types.length > 0) {
      if (!mainImage.logo) {
        setLogoImageError(true);
        setSeverity("error");
        setOpen(true);
        setErrorMsg(defaultString.validData);
        return;
      }
      if (documents.length > 5) {
        setSeverity("error");
        setOpen(true);
        setErrorMsg(defaultString.maxDocumentLimitMsg);
        return;
      }

      const formData = new FormData();

      if (!!mainImage.logo && mainImage.id === 0) {
        if (isChangeMainImage === true) {
          formData.append("profilePicture", mainImage.logo);
        }
      }

      console.log(documents);
      if (documents.length > 0) {
        documents.map((image, i) => {
          if (!!image.document && image.id === 0)
            formData.append("document", image.document);
        });
      }
      setIsDisabledSaveBtn(true);
      formData.append("manufacturerId", manufacturerId);
      formData.append("name", name);
      formData.append("type", JSON.stringify(types));

      await axios
        .post(apiURL.service_edit_manufacturer, formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        })
        .then((res) => {
          console.log("res.data :: :: :: ::", res.data.message);
          if (res.status === apiStatusCode.SUCCESS) {
            setOpen(true);
            setSeverity("success");
            setIsEdit(false);
            setErrorMsg(res.data.message);
            props.handleClose({ reload: true });
          } else {
            console.log("In else portion ", res.data);
            setSeverity("error");
            setOpen(true);
            setIsDisabledSaveBtn(false);
            setErrorMsg(res.data.data.message);
          }
        })
        .catch((error) => {
          console.log(error.response);
          setSeverity("error");

          setOpen(true);
          setIsDisabledSaveBtn(false);
          if (error.code === defaultString.network_error) {
            setErrorMsg(error.message);
            setSeverity("error");
          } else {
            setSeverity("error");
            setErrorMsg(error.response.data.message);
          }
          if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
            doLogout(error);
          }
        });
    } else {
      setSeverity("error");
      setOpen(true);
      setErrorMsg(defaultString.validData);
    }
  };

  const fnDeleteAttachment = async (attachmentId) => {
    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));

    await axios
      .delete(
        apiURL.service_delete_manufacturer_attachment + `/${attachmentId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === apiStatusCode.DELETED) {
          setOpen(true);
          setErrorMsg(res.data.message);
          setSeverity("success");
          if (attachType == "mainImage") {
            setMainImage({ id: 0, logo: null });
          } else if (attachType == "document") {
            const filteredArr = documents.filter(
              (item) => item.id !== attachmentId
            );
            setDocuments(filteredArr);
          }

          setAttachmentId("");
        } else {
          setOpenDeleteAlert(false);
          console.log("In else portion ", res.data);
          setSeverity("error");
          setOpen(true);
          setErrorMsg(res.data.data.message);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setSeverity("error");
        setOpen(true);
        if (error.code === defaultString.network_error) {
          setSeverity("error");
          setErrorMsg(error.message);
        } else {
          setSeverity("error");
          setErrorMsg(error.response.data.message);
        }
        if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
          doLogout(error);
        }
      });
  };

  const fnDeleteProduct = async (attachmentId) => {
    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));
    console.log("token ", token);
    const obj = JSON.stringify({
      ids: [attachmentId],
    });
    setIsDisabledDeleteBtn(true);
    await axios
      .post(apiURL.service_delete_manufacturer, obj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.status === apiStatusCode.DELETED) {
          setOpen(true);
          setSeverity("success");
          setErrorMsg(res.data.message);
          setAttachmentId("");
          props.handleClose({ reload: true });
        } else {
          console.log("In else portion ", res.data);
          setSeverity("error");
          setOpen(true);
          setIsDisabledDeleteBtn(false);
          setErrorMsg(res.data.data.message);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setSeverity("error");
        setOpen(true);
        setIsDisabledDeleteBtn(false);
        if (error.code === defaultString.network_error)
          setErrorMsg(error.message);
        else setErrorMsg(error.response.data.message);
        if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
          doLogout(error);
        }
      });
  };

  const handleDocuments = (event) => {
    let attachmentObj = [];
    attachmentObj.push(event.target.files);

    for (let i = 0; i < attachmentObj[0].length; i++) {
      let docuObj = {};
      docuObj.id = 0;
      docuObj.document = attachmentObj[0][i];

      setDocuments((x) => [...x, docuObj]);
    }
  };

  const doLogout = async (resErr) => {
    const data = await resetToken();
    if (data === true) {
      dispatch({ type: "LOGOUT", payload: null });
      navigate("/", {
        state: { isModalPopup: true, errorMsg: resErr.response.data.message },
      });
    }
  };
  const [data, setData] = useState([]);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = (data) => {
    setOpenModal(!openModal);
    setData(data);
  };

  return (
    <>
      {/* <div className="costome-carousel"> */}
      <Modal
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: "70%",
            backgroundColor: "white",
            borderRadius: 2,
            MaxHeight: "80%",
          }}
        >
          <Box
            sx={{
              padding: "8px 16px",
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: "white",
              borderBottom: "1px solid #d4d4d4",
              display: "flex",
              justifyContent: "space-between",
              borderRadius: "16px 16px 0 0",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontWeight: 700,
                fontSize: "24px",
                marginRight: "24px",
                color: "var(--primary-color)",
              }}
            >
              Add Manufacture
            </Typography>

            <IconButton
              onClick={() => {
                props.handleClose();
              }}
            >
              <Close />
            </IconButton>
          </Box>

          <Container
            fluid="md"
            style={{
              height: "90%",
              overflowY: "scroll",
            }}
          >
            <Box>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 700 }}
                className="my-2"
              >
                {t("images")}
              </Typography>
              <Row>
                <Col lg="6">
                  <Form.Group controlId="formFile1" className="mb-3">
                    <Form.Label>{t("chooseMainImage") + "*"}</Form.Label>
                    <Form.Control
                      type="file"
                      className="no-focus-shadow"
                      onChange={handleMainPicture}
                      name="mainImage"
                      accept="image/*"
                    />
                    <div className="sec-row">
                      {!!mainImage.logo && (
                        <>
                          {mainImage.id === 0 ? (
                            <img
                              key={mainImage.logo.name}
                              className="mt-3"
                              accept="image/*"
                              src={
                                mainImage.logo !== null ||
                                mainImage.logo !== undefined
                                  ? URL.createObjectURL(mainImage.logo)
                                  : null
                              }
                              alt={mainImage.logo.name}
                              height={80}
                              width={80}
                            />
                          ) : (
                            <img
                              key={mainImage.logo}
                              className="mt-3"
                              accept="image/*"
                              src={
                                mainImage.logo
                                  ? apiURL.siteBaseUrl + mainImage.logo
                                  : null
                              }
                              alt={mainImage.logo}
                              height={80}
                              width={80}
                            />
                          )}

                          <MdCancel
                            fontSize={20}
                            className="close-icon"
                            onClick={() =>
                              deleteItem(mainImage.id, 0, "mainImage")
                            }
                          />
                        </>
                      )}
                      {logoImageError ? (
                        <p className="productErrorMessage">{t("logoError")}</p>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Box
                sx={{
                  border: "1px solid #e2e2e2",
                  marginY: "16px",
                }}
              />
              <Row>
                <Typography sx={{ marginBottom: "8px", fontWeight: 700 }}>
                  {t("documents")}
                </Typography>
                <Col lg="12">
                  <Form.Group controlId="formFile2" className="mb-3">
                    <Form.Label>{t("uploadDocuments")}</Form.Label>
                    <Form.Control
                      type="file"
                      className="no-focus-shadow"
                      onChange={handleDocuments}
                      multiple
                      accept="application/pdf,.doc,.docx"
                    />
                    {documents.length > 0 &&
                      documents.map((item, index) => {
                        return (
                          !!item.document && (
                            <div key={index} className="img-list">
                              {item.id === 0 ? (
                                <h6 className="mt-1 ms-2">
                                  {item.document.name}
                                </h6>
                              ) : (
                                <h6 className="mt-1 ms-2">
                                  {
                                    item.document.split(
                                      "/manufacturerDocument/"
                                    )[1]
                                  }
                                </h6>
                              )}
                              <MdCancel
                                fontSize={20}
                                className="doc-icon"
                                onClick={() =>
                                  deleteItem(item.id, index, "document")
                                }
                              />
                            </div>
                          )
                        );
                      })}
                  </Form.Group>
                </Col>
              </Row>
              <Box
                sx={{
                  border: "1px solid #e2e2e2",
                  marginY: "16px",
                }}
              />
              <Row>
                <Typography sx={{ marginBottom: "8px", fontWeight: 700 }}>
                  {t("manufacturer")} {t("data")}
                </Typography>
                <Col lg="12">
                  <FormControl
                    sx={{ mt: 1, width: "100%" }}
                    className="dropdown-chip "
                  >
                    <InputLabel id="demo-multiple-chip-label">
                      {t("type") + "*"}
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={types}
                      onChange={handleChange}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label={t("type")}
                        />
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((id) => (
                            <Chip key={id} label={getValueFromKey(id)} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {names.map((name) => (
                        <MenuItem
                          key={name.name}
                          value={name._id}
                          style={getStyles(name, types, theme)}
                        >
                          {name.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {typesError ? (
                    <p className="productErrorMessage">{t("typeError")}</p>
                  ) : null}
                </Col>
                <Col lg="12" className="mt-3">
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      className="no-focus-shadow"
                      value={name}
                      placeholder={t("electricalCharacteristics")}
                      onChange={(e) => {
                        setName(e.target.value);
                        setNameError(false);
                      }}
                    />
                    <label htmlFor="floatingInputCustom">
                      {t("electricalCharacteristics") + "*"}
                    </label>
                    {nameError ? (
                      <p className="productErrorMessage">{t("nameError")}</p>
                    ) : null}
                  </Form.Floating>
                </Col>
              </Row>

              <Box
                sx={{
                  border: "1px solid #e2e2e2",
                  marginY: "16px",
                }}
              />
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                  marginBottom: "8px",
                }}
              >
                {props.isEdit && (
                  <Grid item xs={1}>
                    <Button
                      sx={{
                        backgroundColor: "#ff6166",
                        "&:hover": {
                          backgroundColor: "#fa1919",
                        },
                      }}
                      fullWidth
                      variant="contained"
                      size="medium"
                      onClick={() =>
                        deleteItem(props?.stateObj?._id, 0, "product")
                      }
                    >
                      {t("delete")}
                    </Button>
                  </Grid>
                )}

                <Grid item xs={1} ml={1}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={
                      props.isEdit ? () => fnEdit(props.stateObj._id) : fnAdd
                    }
                    sx={{
                      backgroundColor: "var(--primary-color)",
                      "&:hover": {
                        backgroundColor: "var(--primary-color-hover)",
                      },
                    }}
                    size="medium"
                  >
                    {t("save")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Modal>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
      >
        <Alert onClose={handleAlertClose} severity={severity}>
          {errorMsg}
        </Alert>
      </Snackbar>

      <AlertDialog
        open={openDeleteAlert}
        title={
          attachType === "product" ? "Delete Product" : "Delete Attachment"
        }
        message={
          attachType === "product"
            ? "Are you sure you want to delete the product?"
            : "Are you sure you want to delete the attachment?"
        }
        handleAction={confirmDeleteItem}
        handleClose={handleDeleteAlertClose}
      ></AlertDialog>
    </>
  );
};

export default AddManufacturer;
