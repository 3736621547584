import { createContext, useEffect, useReducer, useState } from "react";
import AuthReducer from "./AuthReducer";

const INITIAL_STATE = {
    currentUser: JSON.parse(localStorage.getItem("user")) || null
}

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);
    const [aadQuote, setAadQuote] = useState([]);
    const [cartList, setCartList] = useState([]);

    useEffect(() => {
        localStorage.setItem("user", JSON.stringify(state.currentUser))
    }, [state.currentUser])

    const isSetAdQuote = (text) => {
        setAadQuote(text);
    };

    const doSetCartData = (text) => {
        setCartList(text);
    };


    return (
        <AuthContext.Provider value={{ currentUser: state.currentUser, aadQuote, isSetAdQuote, dispatch, cartList, doSetCartData }}>
            {children}
        </AuthContext.Provider>
    )
}