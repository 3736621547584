import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Carousel from "react-bootstrap/Carousel";
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import "css/Register.css";
import "css/ContactUs.css";
import countryList from "react-select-country-list";

function ContactUs(props) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("Italy");
  const [address, setAddress] = useState("");
  const [notes, setNotes] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  return (
    <div>
      <div className="contact-us-header">
        <Row>
          
            <div className="carousel-slide-contact position-relative">
              {/* <video className="w-100" src="video/carousel/solar.mp4" autoPlay loop>
              </video> */}
              <img
                className="img-height-contact"
                src="images/carousel/slide1.jpeg"
                alt="Second slide"
              />
              <div className="carousel-caption position-absolute start-50 w-100 translate-middle">
                <h1 className="text-bold-contact mb-3">{t("contactus")}</h1>
                <p>{t("contactUsSubText")}</p>
              </div>
            </div>
          
        </Row>
      </div>
      <Container className="w-100 contact-container">
        <div className="flex-center-contact">
          {/* <h3 className='mt-4 mb-3'>Contacts</h3> */}
          <Col xs={12} sm={4} lg={4} className="mb-xs-5">
            <div className="text-center-contact">
              <div className="mb-4">
                <span className="icons">
                  <img src="images/phone.png" className="icons" />
                </span>
              </div>
              <div>
                <p className="text-edited-contact">
                  <span className="text-black">Call Us</span>
                </p>
                <p className="text-bold text-black text-edited-contact">
                  <span>{t("phone")}: 0521 303478</span>
                </p>
              </div>
              {/* <p className='pt-4'>
                                <strong>{t('headquarter')}</strong>
                            </p>
                            <p>
                                <span>{t('headquarterAdd')}</span>
                            </p> */}
            </div>
          </Col>
          <Col xs={12} sm={4} lg={4} className="mb-xs-5">
            <div className="text-center-contact">
              <div className="mb-4">
                <span className="icons">
                  <img src="images/location.png" className="icons" />
                </span>
              </div>
              <div>
                <p className="text-edited-contact">
                  <span className="text-black">{t("regOffice")}</span>
                </p>
                <p className="text-bold text-black text-edited-contact">
                  <span>
                    Strada Antolini 18/f, Parma Lemignano di Collecchio, | Italy
                  </span>
                </p>
              </div>

              {/* <p className='contact-info'>
                                <span>www.solarpanel.com</span>
                            </p> */}
            </div>
          </Col>
          <Col xs={12} sm={4} lg={4}>
            <div className="text-center-contact">
              <div className="mb-4">
                <span className="icons">
                  <img src="images/envelope.png" className="icons" />
                </span>
              </div>
              <div className="align-center-contact">
                <p className="text-edited-contact">
                  <span className="text-black">Mail Us</span>
                </p>
                <p className="text-bold text-black text-edited-contact">
                  <span>info@eurobox.it</span>
                </p>
              </div>
            </div>
          </Col>
        </div>
      </Container>

      <Container className="w-100 mt-4 mb-5">
        <div className="border p-xs-5 rounded-5">
          <Col md={12} className="d-flex flex-column">
            <div className="mb-5">
              <h6 className="text-bold text-center">{t("writetus")}</h6>
              <span className="text-center-span">{t("iNeedHelp?")}</span>
            </div>
            <div>
              <Form>
                <Row sm className="mb-4">
                  <Col sm={12} md={4} className="mb-xs-4">
                    <FloatingLabel controlId="floatingName" label={t("name")}>
                      <Form.Control
                        type="text"
                        className="no-focus-shadow"
                        placeholder={t("name")}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col sm={12} md={4} className="mb-xs-4">
                    <FloatingLabel
                      controlId="floatingPhoneNumber"
                      label={t("phoneNumber")}
                    >
                      <Form.Control
                        type="text"
                        className="no-focus-shadow"
                        placeholder={t("phoneNumber")}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col sm={12} md={4}>
                    <FloatingLabel controlId="floatingEmail" label={t("email")}>
                      <Form.Control
                        type="email"
                        className="no-focus-shadow"
                        placeholder={t("email")}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Col s={12} md={6} className="mb-xs-4">
                    <FloatingLabel
                      controlId="floatingCustomer"
                      label={t("type")}
                    >
                      <Form.Select
                        className="no-focus-shadow"
                        aria-label="Floating label select example"
                      >
                        <option>- Select -</option>
                        <option value="1">Installer</option>
                        <option value="2">Retailer</option>
                        <option value="3">Designer</option>
                        <option value="4">Subdistributor</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col s={12} md={6}>
                    <FloatingLabel
                      controlId="floatingCountry"
                      label={t("country")}
                    >
                      <Form.Select
                        className="no-focus-shadow"
                        aria-label="Floating label select example"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        {options.map((value) => (
                          <option key={value.value}>{value.label}</option>
                        ))}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Col s={12} md={12}>
                    <FloatingLabel
                      controlId="floatingTextarea2"
                      label={t("notes")}
                    >
                      <Form.Control
                        className="no-focus-shadow"
                        as="textarea"
                        placeholder={t("notes")}
                        style={{ height: "200px" }}
                        onChange={(e) => setNotes(e.target.value)}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>

                <Row className="pt-4">
                  <Row className="flex-center">
                    <Button className="btn-contact" type="submit">
                      {t("send")}
                    </Button>
                  </Row>
                </Row>
              </Form>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  );
}

export default ContactUs;
