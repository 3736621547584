import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function SubNav(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const location = useLocation();
  const [path, setPath] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    //  console.log(`You changed the page to: ${location.pathname}`)
    setPath(location.pathname);
  }, [location, path]);

  return (
    <div className="">
      <Nav
        style={{
          backgroundColor: "#283739",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
          flexWrap: "nowrap",
          overflow: "auto",
          width: "100%",
        }}
      >
        <Nav.Item className="me-2" style={{ whiteSpace: "nowrap" }}>
          <Nav.Link
            style={{
              width: "100%",
              whiteSpace: "nowrap",
            }}
            href={`/product/${localStorage.getItem("solarpanel")}`}
            active={
              path === `/product/${localStorage.getItem("solarpanel")}`
                ? true
                : false
            }
          >
            {t("solarPanel")}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="me-2" style={{ whiteSpace: "nowrap" }}>
          <Nav.Link
            href={`/product/${localStorage.getItem("inverter")}`}
            active={
              path === `/product/${localStorage.getItem("inverter")}`
                ? true
                : false
            }
          >
            {t("inverter")}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="me-2" style={{ whiteSpace: "nowrap" }}>
          <Nav.Link
            href={`/product/${localStorage.getItem("storage")}`}
            active={
              path === `/product/${localStorage.getItem("storage")}`
                ? true
                : false
            }
          >
            {t("storage")}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="me-2" style={{ whiteSpace: "nowrap" }}>
          <Nav.Link
            href={`/product/${localStorage.getItem("mounting")}`}
            active={
              path === `/product/${localStorage.getItem("mounting")}`
                ? true
                : false
            }
          >
            {t("mounting")}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="me-2" style={{ whiteSpace: "nowrap" }}>
          <Nav.Link
            href={`/product/${localStorage.getItem("carport")}`}
            active={
              path === `/product/${localStorage.getItem("carport")}`
                ? true
                : false
            }
          >
            {t("carPort")}
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </div>
  );
}

export default SubNav;
