import React from 'react';
import { FaRegTimesCircle, FaUserCircle } from "react-icons/fa";
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import "css/CartDrawer.css"

function CartDrawer(props) {

  const drawerWidth = 400;

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));

  const theme = useTheme();

  return (
    <div>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={props.showDrawer}
      >
        <DrawerHeader>
          <FaRegTimesCircle color="var(--primary-color)" size="2em" onClick={props.handleDrawerOpen}>
            {theme.direction === 'rtl' ? <FaRegTimesCircle /> : <FaUserCircle />}
          </FaRegTimesCircle>
        </DrawerHeader>
        {/* <Divider color="black" /> */}
        
        <Typography variant="h6" marginLeft={2} marginTop={2} style={{ color: 'var(--title-color)' }}>
          <span>Availability: </span>
          <span>Immediate</span>
        </Typography>

        <List>
          {['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6'].map((text, index) => (
            <ListItem key={text} className='border-bottom'>
              <ListItemButton className='drawer'>

                <ListItemText primary={text} />
                <ListItemText primary={2} />
                <ListItemButton className='success' sx={{ textAlign: 'center', backgroundColor: '#31C46C', color: 'white' }} >
                  <ListItemText primary="REMOVE" />
                </ListItemButton>

              </ListItemButton>

            </ListItem>
          ))}

        </List>

        <Grid container spacing={2} marginLeft={2} marginTop={3} marginBottom={3} lineHeight={'10px'} >
          <Grid item xs={6}>
            <span>SubTotal</span>
          </Grid>
          <Grid item xs={6}>
            <span>€1,490.67</span>
          </Grid>
          <Grid item xs={6}>
            <span>Vat</span>
          </Grid>
          <Grid item xs={6}>
            <span>€307.98</span>
          </Grid>
          <Grid item xs={6}>
            <span>Vat</span>
          </Grid>
          <Grid item xs={6}>
            <span>€9.08</span>
          </Grid>
        </Grid>

        <Divider color="black" />

        <Grid container spacing={2} marginLeft={2} marginTop={3} marginBottom={3} lineHeight={'10px'}>
          <Grid item xs={6}>
            <strong>Total</strong>
          </Grid>
          <Grid item xs={6}>
            <strong>€1,807.73</strong>
          </Grid>

        </Grid>
        <Divider color="black" />

        <Grid container spacing={2} marginLeft={2} marginTop={3} marginBottom={3} lineHeight={'10px'}>
          <Grid item xs={6}>
            <strong>Total</strong>
          </Grid>
          <Grid item xs={6}>
            <strong>€1,807.73</strong>
          </Grid>

        </Grid>
        <Divider color="black" />

        <Grid container spacing={2} marginLeft={2} marginTop={3} marginBottom={3} lineHeight={'10px'}>
          <Grid item xs={6}>
            <strong>Total</strong>
          </Grid>
          <Grid item xs={6}>
            <strong>€1,807.73</strong>
          </Grid>

        </Grid>
        
        <Divider color="black" />

        <Grid container spacing={2} marginLeft={2} marginTop={3} marginBottom={3} lineHeight={'10px'}>
          <Grid item xs={6}>
            <strong>Total</strong>
          </Grid>
          <Grid item xs={6}>
            <strong>€1,807.73</strong>
          </Grid>

        </Grid>
        
        <Divider color="black" />

        <Stack margin={3} spacing={2} direction="row">
          <Button style={{ backgroundColor: "var(--primary-color)", }} variant="contained">Cart</Button>
          <Button style={{ color: "var(--primary-color)", borderColor: "var(--primary-color)" }} variant="outlined">Complete Order</Button>
        </Stack>
      </Drawer>
    </div>
  );
}

export default CartDrawer;