import React, { useState, useMemo, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Container from "react-bootstrap/Container";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import countryList from 'react-select-country-list';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useNavigate, useLocation } from 'react-router-dom';

import "css/EditSolarForm.css";

import defaultString from 'constants/defaultString.json';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import apiURL from 'apiURL/apiURL';

import Paper from '@mui/material/Paper';
import { AuthContext } from 'context/AuthContext';
import QuotationList from '../List';

function createData(product, manufacturer, modelNo, qty, discount, price, vat, totalVat, total) {
  return { product, manufacturer, modelNo, qty, discount, price, vat, totalVat, total };
}

const rows = [
  createData('1', 'Deye Sun', 'SUN-6K-SG03LP1-EU', 159, 6.0, 100, 4.0, 100, 100),
  createData('1', 'Deye Sun', 'SUN-6K-SG03LP1-EU', 159, 6.0, 100, 4.0, 100, 100),
  createData('1', 'Deye Sun', 'SUN-6K-SG03LP1-EU', 159, 6.0, 100, 4.0, 100, 100),
  createData('1', 'Deye Sun', 'SUN-6K-SG03LP1-EU', 159, 6.0, 100, 4.0, 100, 100),
  createData('1', 'Deye Sun', 'SUN-6K-SG03LP1-EU', 159, 6.0, 100, 4.0, 100, 100),
];



// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const vats = [
  {
    id: 1, value: 10,

  },
  {
    id: 2, value: 22
  }
]
const top100Films = [
  { id: 1, label: 'The Shawshank Redemption', year: 1994 },
  { id: 2, label: 'The Godfather', year: 1972 },
  { id: 3, label: 'The Godfather: Part II', year: 1974 },
  { id: 4, label: 'The Dark Knight', year: 2008 },
  { id: 5, label: '12 Angry Men', year: 1957 },
  { id: 6, label: "Schindler's List", year: 1993 },
  { id: 7, label: 'Pulp Fiction', year: 1994 },
  {
    id: 8, label: 'The Lord of the Rings: The Return of the King',
    year: 2003,
  },
  { id: 9, label: 'The Good, the Bad and the Ugly', year: 1966 },
  { label: 'Fight Club', year: 1999 },
  {
    id: 10, label: 'The Lord of the Rings: The Fellowship of the Ring',
    year: 2001,
  },
  {
    id: 11, label: 'Star Wars: Episode V - The Empire Strikes Back',
    year: 1980,
  },
  { id: 12, label: 'Forrest Gump', year: 1994 },
  { label: 'Inception', year: 2010 },
  {
    id: 13, label: 'The Lord of the Rings: The Two Towers',
    year: 2002,
  },
  { id: 14, label: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { id: 15, label: 'Goodfellas', year: 1990 },
  { id: 16, label: 'The Matrix', year: 1999 },
  { id: 17, label: 'Seven Samurai', year: 1954 },
  {
    id: 18, label: 'Star Wars: Episode IV - A New Hope',
    year: 1977,
  },
  { id: 19, label: 'City of God', year: 2002 },
  { id: 20, label: 'Se7en', year: 1995 },
  { id: 21, label: 'The Silence of the Lambs', year: 1991 },
  { id: 22, label: "It's a Wonderful Life", year: 1946 },
  { id: 23, label: 'Life Is Beautiful', year: 1997 },
  { id: 24, label: 'The Usual Suspects', year: 1995 },
  { id: 25, label: 'Léon: The Professional', year: 1994 },
  { id: 26, label: 'Spirited Away', year: 2001 },
  { id: 27, label: 'Saving Private Ryan', year: 1998 },
  { id: 28, label: 'Once Upon a Time in the West', year: 1968 },
  { id: 29, label: 'American History X', year: 1998 },
  { id: 30, label: 'Interstellar', year: 2014 },
  { id: 31, label: 'Casablanca', year: 1942 },
  { id: 32, label: 'City Lights', year: 1931 },
  { id: 33, label: 'Psycho', year: 1960 },
  { id: 34, label: 'The Green Mile', year: 1999 },
  { id: 35, label: 'The Intouchables', year: 2011 },
  { id: 36, label: 'Modern Times', year: 1936 },
  { id: 37, label: 'Raiders of the Lost Ark', year: 1981 },
  { id: 38, label: 'Rear Window', year: 1954 },
  { id: 39, label: 'The Pianist', year: 2002 },
  { id: 40, label: 'The Departed', year: 2006 },
  { id: 41, label: 'Terminator 2: Judgment Day', year: 1991 },
  { id: 42, label: 'Back to the Future', year: 1985 },
  { id: 43, label: 'Whiplash', year: 2014 },
  { id: 44, label: 'Gladiator', year: 2000 },
  { id: 45, label: 'Memento', year: 2000 },
  { id: 46, label: 'The Prestige', year: 2006 },
  { id: 47, label: 'The Lion King', year: 1994 },
  { id: 48, label: 'Apocalypse Now', year: 1979 },
  { id: 49, label: 'Alien', year: 1979 },
  { id: 50, label: 'Sunset Boulevard', year: 1950 },
  {
    id: 51, label: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
    year: 1964,
  },
  { id: 52, label: 'The Great Dictator', year: 1940 },
  { id: 53, label: 'Cinema Paradiso', year: 1988 },
  { id: 54, label: 'The Lives of Others', year: 2006 },
  { id: 55, label: 'Grave of the Fireflies', year: 1988 },
  { id: 56, label: 'Paths of Glory', year: 1957 },
  { id: 57, label: 'Django Unchained', year: 2012 },
  { id: 58, label: 'The Shining', year: 1980 },
  { id: 59, label: 'WALL·E', year: 2008 },
  { id: 60, label: 'American Beauty', year: 1999 },
  { id: 61, label: 'The Dark Knight Rises', year: 2012 },
  { id: 62, label: 'Princess Mononoke', year: 1997 },
  { id: 63, label: 'Aliens', year: 1986 },
  { id: 64, label: 'Oldboy', year: 2003 },
  { id: 65, label: 'Once Upon a Time in America', year: 1984 },
  { id: 66, label: 'Witness for the Prosecution', year: 1957 },
  { id: 67, label: 'Das Boot', year: 1981 },
  { id: 68, label: 'Citizen Kane', year: 1941 },
  { id: 69, label: 'North by Northwest', year: 1959 },
  { id: 70, label: 'Vertigo', year: 1958 },
  {
    id: 71, label: 'Star Wars: Episode VI - Return of the Jedi',
    year: 1983,
  },
  { id: 72, label: 'Reservoir Dogs', year: 1992 },
  { id: 73, label: 'Braveheart', year: 1995 },
  { id: 74, label: 'M', year: 1931 },
  { id: 75, label: 'Requiem for a Dream', year: 2000 },
  { id: 76, label: 'Amélie', year: 2001 },
  { id: 77, label: 'A Clockwork Orange', year: 1971 },
  { id: 78, label: 'Like Stars on Earth', year: 2007 },
  { id: 79, label: 'Taxi Driver', year: 1976 },
  { id: 80, label: 'Lawrence of Arabia', year: 1962 },
  { id: 81, label: 'Double Indemnity', year: 1944 },
  {
    id: 82, label: 'Eternal Sunshine of the Spotless Mind',
    year: 2004,
  },
  { id: 83, label: 'Amadeus', year: 1984 },
  { id: 84, label: 'To Kill a Mockingbird', year: 1962 },
  { id: 85, label: 'Toy Story 3', year: 2010 },
  { id: 86, label: 'Logan', year: 2017 },
  { id: 87, label: 'Full Metal Jacket', year: 1987 },
  { id: 88, label: 'Dangal', year: 2016 },
  { id: 89, label: 'The Sting', year: 1973 },
  { id: 90, label: '2001: A Space Odyssey', year: 1968 },
  { id: 91, label: "Singin' in the Rain", year: 1952 },
  { id: 92, label: 'Toy Story', year: 1995 },
  { id: 93, label: 'Bicycle Thieves', year: 1948 },
  { id: 94, label: 'The Kid', year: 1921 },
  { id: 95, label: 'Inglourious Basterds', year: 2009 },
  { id: 96, label: 'Snatch', year: 2000 },
  { id: 97, label: '3 Idiots', year: 2009 },
  { id: 98, label: 'Monty Python and the Holy Grail', year: 1975 },
];

const AddQuotation = (props) => {

  const { t } = useTranslation();

  const [number, setNumber] = useState('');
  const [dob, setDob] = useState('');
  const [address, setAddress] = useState('');
  const [postCode, setPostCode] = useState('');
  const [quoteListData, setQuoteListData] = useState([]);
  const [clickedItems, setClickedItems] = useState([])
  const [note, setNote] = useState('');
  const [country, setCountry] = useState('Italy');

  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [clientNameList, setClientListName] = useState([])
  const [name, setName] = useState(clientNameList[0]);
  const [inputValue, setInputValue] = useState('');
  const [vat, setVat] = useState('10');
  const [clientNameListError, setClientNameListError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [quantity, setQuantity] = useState('')
  const [discount, setDiscount] = useState('')
  const options = useMemo(() => countryList().getData(), [])

  // console.log("FINAL ITEM --->", JSON.stringify(aadQuote));

  const data1 = [1, 2, 3, 4]

  useEffect(() => {
    quoteListSelectedData()
    setClientListName(top100Films)



  }, [])

  const quoteListSelectedData = () => {
    const quotationList = JSON.parse(localStorage.getItem('quotationList'));
    const clickedData = JSON.parse(localStorage.getItem('addedToQuotation'))
    if (quotationList && quotationList.length > 0 && clickedData && clickedData.length > 0) {

      setClickedItems(clickedData)
      const newData = quotationList.filter((item) => clickedData.includes(item._id))
      setQuoteListData(newData);
    }
    else{
      setClickedItems([])
      setQuoteListData([]);
    }
  }


  const handleQuantityChange = (index, newQuantity) => {
    const updatedData = [...quoteListData];
    updatedData[index].quantity = newQuantity;

    setQuoteListData(updatedData);
    calculateTotalVAT(updatedData);
  }
  const handleDiscountChange = (index, newQuantity) => {
    const updatedData = [...quoteListData];
    updatedData[index].discount = newQuantity;

    setQuoteListData(updatedData);
    calculateTotalVAT(updatedData);
  }

  const handleVatChange = (index, newQuantity) => {
    const updatedData = [...quoteListData];
    updatedData[index].vat = newQuantity;

    setQuoteListData(updatedData);
    calculateTotalVAT(updatedData);
  }

  const calculateTotalVAT = (price, quantity, vat, discount) => {
    const total = price * quantity;
    const vatAmount = (total * vat) / 100;
    const finalTotal = vatAmount - (vatAmount * discount) / 100
    // const discounted = vatAmount * discount 
    // const discountedPrice = vatAmount - discounted;
    return isNaN(finalTotal) ? 0 : finalTotal;

  };

  const calculateTotal = () => {
    const updatedData = [...quoteListData];
    return updatedData.reduce((sum, row) => {
      const newTotal = sum + (row.price * row.quantity) * row.vat / 100
      const finalTotal = newTotal - (newTotal * row.discount) / 100
      return finalTotal
    }, 0);

  }


  const fnAddQuotation = () => {
    // const { isValid } = validateForm({ form, errors, forceTouchErrors: true });
    // if (!isValid) {
    //   setOpen(true);
    //   setErrorMsg(defaultString.validData)
    //   return
    // };

    if (name == undefined) {

      setClientNameListError(true)
      return

    }
    if (!address) {
      setAddressError(true)
      return

    }
  }

  const deleteQuoteList = (rowIndex) => {
    const updatedItems = [...clickedItems];
    updatedItems.splice(rowIndex, 1);
    localStorage.setItem("addedToQuotation", JSON.stringify(updatedItems));
    setClickedItems(updatedItems);
    quoteListSelectedData();
  }


  return (
    <>
      <Container fluid className='mt-5'>
        <Row className='border-bottom pb-2 backBar'>
          <Col className='d-flex align-items-center'>
            <Link to="/agent/quotationlist">
              <FaArrowLeft fontSize={24} />
              <span className='ms-2'>{t('back') + " " + t('to') + " " + t('quotationLabel')}</span>
            </Link>
          </Col>
          <Col className='text-center'><h3><strong>{t('add') + " " + t('quotationLabel')}</strong></h3></Col>
          <Col className='text-end'></Col>

        </Row>
      </Container>

      <Container className='py-5 section-adminform'>


        <h3 className='form-title'>{t('status')}</h3>
        <Form>
          <Form.Check
            type="switch"
            id="custom-switch"
          />
        </Form>

        {/* <h3 className='form-title mt-5'>{t('images')}</h3>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>{t('chooseMainImage')}</Form.Label>
                    <Form.Control type="file" />
                    <div className='sec-row'>
                    <img className='mt-3' src='/images/agent/agent.png' height={80} />
                    <span><MdCancel fontSize={24} /></span>
                    </div>                
                </Form.Group> */}

        <h3 className='form-title mt-5'>{t('documents')}</h3>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>{t('id')}</Form.Label>
          <Form.Control type="file" />
          <h5 className='mt-3'>Datasheet.pdf (178Kb)</h5>
          <h5 className='mt-3'>Manufacturer certification.pdf (313Kb)</h5>
        </Form.Group>

        <h3 className='form-title mt-5'>{t('info')}</h3>
        <Form.Group controlId="formFile" className="mb-3">
          <FloatingLabel
            controlId="floatingInput"
            label={t('number')}
            className="mb-3 mt-3"
          >
            <Form.Control type="text" placeholder={t('number')} value="abc123" disabled
              onChange={(e) => setNumber(e.target.value)} />
          </FloatingLabel>

          <FloatingLabel
            label={t('date')}
            className="mb-3 mt-3"
          >
            <Form.Control type="date" placeholder={t('date')}
              onChange={(e) => setDob(e.target.value)} />
          </FloatingLabel>


          <Row className='d-flex align-items-center'>
            <Col sm={8}>
              {/* <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={top100Films}
                renderInput={(params) => <TextField {...params} label={t('client')} />}
              /> */}

              <Autocomplete
                id="combo-box-demo"
                value={name || null}
                onChange={(_, newValue) => {
                  // console.log(JSON.stringify(newValue, null, ' '));
                  setName(newValue);
                  setClientNameListError(false)
                }}

                inputValue={inputValue}
                onInputChange={(_, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                disableClearable
                options={clientNameList}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField {...params} name="clientNameList.name" label="" variant="outlined" size="small" />
                }
              />
              {clientNameListError ? <p className='productErrorMessage'>{defaultString.clientNameMsg}</p> : null}
            </Col>
            <Col sm={2} className="button-add-client">
              <div className='button-addClient'>
                <Link to="/agent/addprivateclient" className="d-grid gap-2">
                  <Button variant="success" size="lg">
                    {t('add') + " " + t('client')}
                  </Button>
                </Link>
              </div>
            </Col>
            <Col sm={2} className="button-add-client">
              <div className='button-addClient'>

                <Link to="/agent/addcompanyclient" className="d-grid gap-2">
                  <Button variant="success" size="lg">
                    {t('add') + " " + t('company')}
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>


        </Form.Group>

        <h3 className='form-title mt-5'>{t('shipping') + " " + t('address')}</h3>
        <Form.Group controlId="formFile" className="mb-3">

          <FloatingLabel
            controlId="floatingInput"
            label={t('address')}
            className="mb-3 mt-3"
          >
            <Form.Control type="text" placeholder={t('address')}
              onChange={(e) => {
                setAddress(e.target.value);
                setAddressError(false)
              }} />
          </FloatingLabel>
          {addressError ? <p className='productErrorMessage'>{defaultString.addressMsg}</p> : null}
          <FloatingLabel
            controlId="floatingInput"
            label={t('postCode')}
            className="mb-3 mt-3"
          >
            <Form.Control type="text" placeholder={t('posteCode')}
              onChange={(e) => setPostCode(e.target.value)} />
          </FloatingLabel>

          <FloatingLabel controlId="floatingSelect" label={t('country')}>
            <Form.Select aria-label="Floating label select example" value={country} onChange={(e) => setCountry(e.target.value)}>
              {
                options.map((value) =>
                  <option key={value.value}>{value.label}</option>
                )
              }
            </Form.Select>
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingInput"
            label={t('note')}
            className="mb-3 mt-3"
          >
            <Form.Control type="text" placeholder={t('note')}
              onChange={(e) => setNote(e.target.value)} />
          </FloatingLabel>

        </Form.Group>
      </Container>

      <Container fluid className='pb-5 section-adminform'>

        <h3 className='form-title mt-5 d-flex justify-content-between align-items-center'>
          <strong className=''>{t('items')}</strong>
          <strong className='text-end me-3'>
            <div className='add'>

              <Link to={`/agent/quotation/additem/${localStorage.getItem("solarpanel")}`}>
                <Button variant='contained' size='large' color='success'>
                  {t('ADD') + " " + t('item')}
                </Button>
              </Link>

            </div>
          </strong>
        </h3>
        <TableContainer component={Paper} className="mt-5">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('product')}</TableCell>
                <TableCell align="right">{t('manufacturer')}</TableCell>
                <TableCell align="right">{t('modelNo')}</TableCell>
                <TableCell align="right">{t('qty')}</TableCell>
                <TableCell align="right">{t('discount')}</TableCell>
                <TableCell align="right">{t('price')}</TableCell>
                <TableCell align="right">VAT(%)</TableCell>
                <TableCell align="right">{t('total') + " VAT"}</TableCell>

                <TableCell align="right"></TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {quoteListData && quoteListData.map((row, i) => {
                const vatAmount = calculateTotalVAT(row.price, row.quantity, row.vat, row.discount);
                return (

                  <TableRow
                    key={i}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {
                        row?.mainImage?.logo ?

                          <img className='product-img' src={apiURL.siteBaseUrl + row.mainImage.logo} alt="Product" />
                          :
                          <img className='product-img' src="/images/icons/no-image.png" alt="Product" />

                      }
                    </TableCell>
                    <TableCell align="right">{row?.manufacturerId?.name}</TableCell>
                    <TableCell align="right">{row.modelNo}</TableCell>
                    <TableCell align="right">
                      <FloatingLabel
                        controlId="floatingInput"
                        label={t('qty')}
                        className="mb-3 mt-3"
                      >
                        <Form.Control type="number" placeholder={t('qty')} value={row.quantity}
                          onChange={(event) => handleQuantityChange(i, event.target.value)} />
                      </FloatingLabel>
                    </TableCell>
                    <TableCell align="right">
                      <FloatingLabel
                        controlId="floatingInput"
                        label={t('discount')}
                        className="mb-3 mt-3"
                      >
                        <Form.Control type="number" placeholder={t('discount')} value={row.discount}
                          onChange={(event) => handleDiscountChange(i, event.target.value)} />
                      </FloatingLabel>
                    </TableCell>
                    <TableCell align="right">{row.price}</TableCell>

                    <TableCell align="right">  <FloatingLabel controlId="floatingSelect" label={t('vat')}>
                      <Form.Select aria-label="Floating label select example" value={row.vat} onChange={(event) => handleVatChange(i, event.target.value)}>
                        {
                          vats.map((value) =>
                            <option key={value.value}>{value.value}</option>
                          )
                        }
                      </Form.Select>
                    </FloatingLabel></TableCell>
                    <TableCell align="right">{vatAmount}</TableCell>
                    <TableCell align="right">
                      <div className="d-grid gap-2">
                        <Button variant="danger" size="lg" onClick={() => deleteQuoteList(i)} >
                          {t('delete')}
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Row className='my-5'>
          <Col sm={9}>
          </Col>
          <Col sm={3}>

            <div className='d-flex justify-content-end'>
              <h3>{t('total')}:</h3>
              <h3 className='ms-3'>{calculateTotal()}</h3>

            </div>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <div className="d-grid gap-2">
              <Button variant="success" size="lg">
                {t('generatePdf')}
              </Button>
            </div>
          </Col>
          <Col>
            <div className="d-grid gap-2">
              <Button variant="success" size="lg" onClick={fnAddQuotation}>
                {t('save')}
              </Button>
            </div>
          </Col>
          <Col>
            <div className="d-grid gap-2">
              <Button variant="danger" size="lg" >
                {t('delete')}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>

    </>
  );
};

export default AddQuotation;