import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "css/adminaside.css";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { NavLink } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import HomeIcon from "@mui/icons-material/Home";
import { GrUserWorker } from "react-icons/gr";
import {
  FaSolarPanel,
  FaCarSide,
  FaMountain,
  FaTools,
  FaPlug,
  FaArrowRight,
} from "react-icons/fa";
import { MdStorefront, MdSupportAgent, MdHome } from "react-icons/md";
import { GoPackage } from "react-icons/go";
import { RiLightbulbFlashFill, RiUserSmileFill } from "react-icons/ri";
import { FaLanguage } from "react-icons/fa";

import Manufacturer from "pages/AdminPanel/Manufacturer/List";
import AddManufacturer from "pages/AdminPanel/Manufacturer/Add/AddManufacturer";
import ProductList from "pages/AdminPanel/ProductList";
import EditSolarForm from "pages/AdminPanel/SolarPanel/EditSolarForm/EditSolarForm";
import StockList from "pages/AdminPanel/Stock/List";
import EditInverter from "pages/AdminPanel/Inverter/Edit/EditInverter";
import Dashboard from "pages/AdminPanel/Dashboard/Dashboard";
import AddAgent from "pages/AdminPanel/AgentAndInstaller/Agent/Add";
import AddCompanyAgent from "pages/AdminPanel/AgentAndInstaller/CompanyAgent/Add/AddCompanyAgent";
import AddInstaller from "pages/AdminPanel/AgentAndInstaller/Installer/Add/AddInstaller";
import AddCarPort from "pages/AdminPanel/CarPort/Add/AddCarPort";
import AddAccessory from "pages/AdminPanel/Storage/Accessories/Add/AddAccessory";
import AddBattery from "pages/AdminPanel/Storage/Batteries/Add/AddBattery";
import AddHybridInverter from "pages/AdminPanel/Storage/HybridInverter/Add/AddHybridInverter";
import UsersList from "pages/AdminPanel/AgentAndInstaller/List";
import EditStockForm from "pages/AdminPanel/Stock/Edit/EditStockForm";
import apiURL from "apiURL/apiURL";
import { AuthContext } from "context/AuthContext";
import { Icon, Menu } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { Logout, PersonAdd, Settings } from "@mui/icons-material";
import { resetToken } from "apiURL";

const drawerWidth = 280;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function AdminSidebar({ loca }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [path, setPath] = useState();
  const location = useLocation();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { i18n, t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isLogoutValueClear, setIsLogoutValueClear] = useState(false);
  const { dispatch } = useContext(AuthContext);

  const doLogout = async () => {
    const data = await resetToken();
    if (data === true) {
      dispatch({ type: "LOGOUT", payload: null });
    }
    setIsLogoutValueClear(true);
  };

  const data = [
    {
      icon: <MdHome fontSize="24" />,
      label: t("dashBoard"),
      link: "/admin",
      paths: [""],
    },
    {
      icon: <FaSolarPanel fontSize="24" />,
      label: t("solarPanel"),
      link: "/admin/product/" + localStorage.getItem("solarpanel"),
      paths: ["/admin/add-solar-panel"],
    },
    // { icon: <FaSolarPanel fontSize="24" />, label: t('solarPanel'), link: '/admin/solarpanel' },
    {
      icon: <RiUserSmileFill fontSize="24" />,
      label: t("manufacturer"),
      link: "/admin/manufacturer",
      paths: ["/admin/addmanufacturer"],
    },
    {
      icon: <RiLightbulbFlashFill fontSize="24" />,
      label: t("inverter"),
      link: "/admin/product/" + localStorage.getItem("inverter"),
      paths: ["/admin/add-inverter"],
    },
    {
      icon: <MdStorefront fontSize="24" />,
      label: t("storage"),
      link: "/admin/product/" + localStorage.getItem("storage"),
      paths: [
        "/admin/addbattery",
        "/admin/addhybridinverter",
        "/admin/addaccessory",
        "/admin/product/" + localStorage.getItem("hybrid_inverter"),
        "/admin/product/" + localStorage.getItem("accessory"),
      ],
    },
    {
      icon: <FaPlug fontSize="24" />,
      label: t("mounting"),
      link: "/admin/product/" + localStorage.getItem("mounting"),
      paths: [""],
    },
    {
      icon: <FaCarSide fontSize="24" />,
      label: t("carPort"),
      link: "/admin/product/" + localStorage.getItem("carport"),
      paths: ["/admin/addcarport"],
    },
    {
      icon: <FaMountain fontSize="24" />,
      label: t("assemblySystems"),
      link: "/admin/assemblySystems",
      paths: [""],
    },
    {
      icon: <FaTools fontSize="24" />,
      label: t("accessories"),
      link: "/admin/accessories",
      paths: [""],
    },
    {
      icon: <GoPackage fontSize="24" />,
      label: t("stock"),
      link: "/admin/stock",
      paths: [""],
    },
    {
      icon: <MdSupportAgent fontSize="24" />,
      label: t("agents&Installers"),
      link: "/admin/users-list/agent",
      paths: [
        "/admin/users-list/company-agent",
        "/admin/users-list/installer",
        "/admin/add-agent",
        "/admin/add-companyagent",
        "/admin/add-installer",
      ],
    },
  ];

  const changeLanguage = (e) => {
    const selectedLanguage = e.target.value;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("selectedLanguage", selectedLanguage);
  };

  const adminImage = JSON.parse(localStorage.getItem("user"))?.mainImage;
  const adminName =
    JSON.parse(localStorage.getItem("user"))?.firstName +
    " " +
    JSON.parse(localStorage.getItem("user"))?.lastName;

  const { pathname } = useLocation();

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    } else {
      i18n.changeLanguage("it");
    }
    //  console.log(You changed the page to: ${location.pathname})
    setPath(location.pathname);
  }, [location, path]);

  const [anchorElProfile, setAnchorElProfile] = React.useState(null);
  const openProfile = Boolean(anchorElProfile);
  const handleClickProfile = (event) => {
    setAnchorElProfile(event.currentTarget);
  };
  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };

  console.log(currentUser, "1233445");

  return (
    // <Router>
    <div className="admin-sidebar">
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          sx={{
            backgroundColor: "white",
            boxShadow: 1,
            // borderBottom: "2px solid var(--bs-border-color)",
          }}
        >
          <Toolbar className="section-toolbar">
            <div className="d-flex align-items-center">
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <MenuIcon sx={{ color: "#283739" }} />
              </IconButton>
              <Link to="/">
                <img
                  src="/LogoBlack.svg"
                  height="48"
                  className="d-inline-block align-top"
                  alt="React Bootstrap logo"
                />
              </Link>
              {/* <Typography color="black" variant="h6" noWrap component="div">
                {t("adminPanel")}
              </Typography> */}
            </div>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="d-flex align-items-center">
                <Link to="/">
                  <Box
                    sx={{
                      padding: "5px",
                      borderRadius: "50%",
                      marginRight: "16px",
                      backgroundColor: "#F2F2F2",
                      "&:hover": {
                        backgroundColor: "#e4e4e4",
                      },
                    }}
                  >
                    <HomeOutlinedIcon
                      sx={{ fontSize: "24px", color: "#283739" }}
                    />
                  </Box>
                </Link>

                <Avatar
                  onClick={handleClickProfile}
                  aria-controls={openProfile ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openProfile ? "true" : undefined}
                  alt="User"
                  // src={currentUser.mainImage != "" ? apiURL.siteBaseUrl + currentUser.mainImage : "/images/agent/agent.png"}
                  src={
                    adminImage != ""
                      ? apiURL.siteBaseUrl + adminImage
                      : "/images/agent/agent.png"
                  }
                  sx={{
                    width: "30px ",
                    height: "30px ",
                    textAlign: "center",
                    objectFit: "cover",
                    color: "transparent",
                    textIndent: "10000px",
                    "&:hover": {
                      boxShadow: 5,
                    },
                  }}
                />
              </div>

              <div className="ms-3 d-flex align-items-center language">
                <img src="../../images/Vector.png" height={20} />
                <select
                  as="select"
                  className="form-select"
                  value={i18n.language}
                  onChange={changeLanguage}
                  aria-label=".form-select-lg example"
                >
                  <option value="it">Italiano</option>
                  <option value="en">English</option>
                </select>
              </div>
            </Box>
          </Toolbar>
        </AppBar>

        <Menu
          anchorEl={anchorElProfile}
          id="account-menu"
          open={openProfile}
          onClose={handleCloseProfile}
          onClick={handleCloseProfile}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleCloseProfile}>
            <Avatar /> {currentUser.firstName}
          </MenuItem>

          <Divider />

          {currentUser && (
            <MenuItem onClick={doLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          )}
        </Menu>

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader sx={{ boxShadow: 1 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",

                width: "210px",
              }}
            >
              <Avatar sx={{ width: 32, height: 32 }} />
              <Typography ml={1}>{currentUser.firstName}</Typography>
            </Box>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>

          <List style={{ textAlign: "center" }}>
            <div className="mt-3 DrawerItems">
              {data.map((item, index) => (
                // <Link to={item.link}>

                <ListItem
                  key={item.label}
                  disablePadding
                  component={Link}
                  onClick={() => {
                    setSelectedIndex(index);
                  }}
                  to={item.link}
                  style={
                    path === item.link || item?.paths?.includes(path)
                      ? {
                          backgroundColor: "#e5f6f0",
                          color: "var(--primary-color)",
                        }
                      : {}
                  }
                >
                  <ListItemButton>
                    <ListItemIcon
                      to={item.link}
                      style={
                        path === item.link || item?.paths?.includes(path)
                          ? {
                              color: "var(--primary-color)",
                            }
                          : {}
                      }
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                </ListItem>
                //  </Link>
              ))}
            </div>
          </List>
        </Drawer>

        <Main
          open={open}
          sx={{
            paddingX: "16px",
            paddingY: "32px",
            backgroundColor: "#f2f2f2",
            minHeight: "100vh",
          }}
        >
          <DrawerHeader />
          <Routes>
            <Route path="/" name="Dashboard" element={<Dashboard />} />
            <Route path="/product/:id" element={<ProductList />} />
            <Route
              path="/add-solar-panel"
              name="AddSolarPanel"
              element={<EditSolarForm />}
            />
            <Route path="/manufacturer" element={<Manufacturer />} />
            <Route path="/addmanufacturer" element={<AddManufacturer />} />
            <Route path="/add-inverter" element={<EditInverter />} />
            <Route path="/stock" element={<StockList />} />
            <Route path="/add-agent" element={<AddAgent />} />
            <Route path="/add-companyagent" element={<AddCompanyAgent />} />
            <Route path="/add-installer" element={<AddInstaller />} />
            <Route path="/addcarport" element={<AddCarPort />} />
            <Route path="/addbattery" element={<AddBattery />} />
            <Route path="/addhybridinverter" element={<AddHybridInverter />} />
            <Route path="/addaccessory" element={<AddAccessory />} />
            <Route path="/users-list/:role" element={<UsersList />} />
            <Route path="/edit-stock" element={<EditStockForm />} />
          </Routes>
        </Main>
      </Box>
      {/* </Router> */}
    </div>
  );
}

export default AdminSidebar;
