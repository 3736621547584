import React from 'react';
import axios from "axios";
import apiURL from 'apiURL/apiURL';
function Dashboard(props) {
    return (
        <div className='justify-content-between'>
            HERE  WE WILL PUT SOME GRAPH AND STATISTICS LATER ON
        </div>
    );
}

export default Dashboard;