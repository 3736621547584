import { useState } from "react";

import {
    companyNameValidator,
    lastNameValidator,
    fiscalCodeValidator,
    addressValidator,
    deliveryAddressValidator,
    vatValidator,
    shippingAddressValidator,
} from "components/Validators/Validators.jsx";

const touchErrors = errors => {
    return Object.entries(errors).reduce((acc, [field, fieldError]) => {
        acc[field] = {
            ...fieldError,
            dirty: true,
        };
        return acc;
    }, {});
};

export const useAddCompanyClientValidator = form => {
    const [errors, setErrors] = useState({

        companyName: {
            dirty: false,
            error: false,
            message: "",
        },

        fiscalCode: {
            dirty: false,
            error: false,
            message: "",
        },
        vat: {
            dirty: false,
            error: false,
            message: "",
        },
        discountReward: {
            dirty: false,
            error: false,
            message: "",
        },

        address: {
            dirty: false,
            error: false,
            message: "",
        },
        shippingAddress: {
            dirty: false,
            error: false,
            message: "",
        },

    });

    const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
        let isValid = true;

        // Create a deep copy of the errors
        let nextErrors = JSON.parse(JSON.stringify(errors));

        // Force validate all the fields
        if (forceTouchErrors) {
            nextErrors = touchErrors(errors);
        }

        const { companyName = '', fiscalCode = '', vat = '', address = '', shippingAddress = '' } = form;




        if (nextErrors.companyName.dirty && (field ? field === "companyName" : true)) {
            const companyNameMessage = companyNameValidator(companyName, form);
            nextErrors.companyName.error = !!companyNameMessage;
            nextErrors.companyName.message = companyNameMessage;
            if (!!companyNameMessage) isValid = false;
        }



        if (nextErrors.fiscalCode.dirty && (field ? field === "fiscalCode" : true)) {
            const fiscalCodeMessage = fiscalCodeValidator(fiscalCode, form);
            nextErrors.fiscalCode.error = !!fiscalCodeMessage;
            nextErrors.fiscalCode.message = fiscalCodeMessage;
            if (!!fiscalCodeMessage) isValid = false;
        }

        if (nextErrors.vat.dirty && (field ? field === "vat" : true)) {
            const vatMessage = vatValidator(vat, form);
            nextErrors.vat.error = !!vatMessage;
            nextErrors.vat.message = vatMessage;
            if (!!vatMessage) isValid = false;
        }


        if (nextErrors.address.dirty && (field ? field === "address" : true)) {
            const addressMessage = addressValidator(address, form);
            nextErrors.address.error = !!addressMessage;
            nextErrors.address.message = addressMessage;
            if (!!addressMessage) isValid = false;
        }

        if (nextErrors.shippingAddress.dirty && (field ? field === "shippingAddress" : true)) {
            const shippingAddressMessage = shippingAddressValidator(shippingAddress, form);
            nextErrors.shippingAddress.error = !!shippingAddressMessage;
            nextErrors.shippingAddress.message = shippingAddressMessage;
            if (!!shippingAddressMessage) isValid = false;
        }

        console.log("isValid", isValid)
        setErrors(nextErrors);
        return {
            isValid,
            errors: nextErrors,
        };
    };

    const onBlurField = e => {
        const field = e.target.name;
        const fieldError = errors[field];
        if (fieldError.dirty) return;

        const updatedErrors = {
            ...errors,
            [field]: {
                ...errors[field],
                dirty: true,
            },
        };

        validateForm({ form, field, errors: updatedErrors });
    };

    return {
        validateForm,
        onBlurField,
        errors,
    };
};