import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router";

import Box from "@mui/material/Box";
import SubNav from "components/SubNav/SubNav";
import { useTranslation } from "react-i18next";
import "css/product.css";
import { Col, Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import axios from "axios";
import defaultString from "constants/defaultString.json";
import ProductStorageNav from "components/ProductStorageNav/ProductStorageNav";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {
  ButtonGroup,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Tooltip,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import SkeletonImg from "../../../src/SkeletonNew.png";
import MailIcon from "@mui/icons-material/Mail";
import Divider from "@mui/material/Divider";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { AuthContext } from "context/AuthContext";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Product() {
  const { cartList, doSetCartData } = useContext(AuthContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [qty, setQty] = useState(1);
  const { t } = useTranslation();
  // const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));

  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [searchContent, setSearchContent] = useState("");
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [manufacturerList, setManufacturerList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [checkSort, setCheckSort] = useState("");
  const [dimensionsList, setDimensionsList] = useState([]);
  const [pmaxList, setPmaxList] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [isActiveKeys, setIsActiveKeys] = useState([]);
  const [valueTabs, setValueTabs] = React.useState(0);
  const [value, setValue] = React.useState(0);
  const [rowData, setRowData] = React.useState([]);
  const [state, setState] = React.useState({
    left: false,
    LeftMain: false,
    right: false,
  });
  const [subTotal, setSubTotal] = useState("0");
  // const [totalPrice, setTotalPrice] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let limit = 10;
  const params = useParams();
  let tblCells = [
    t("product"),
    t("manufacturer"),
    t("modelNo"),
    t("pmax"),
    t("dimentions"),
    t("price"),
  ];
  let navigate = useNavigate();

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setSearchContent("");
  };

  if (params.id === localStorage.getItem("solarpanel")) {
    tblCells = [
      t("product"),
      t("manufacturer"),
      t("modelNo"),
      t("pmax"),
      t("dimentions"),
      t("price"),
    ];
  } else if (params.id === localStorage.getItem("mounting")) {
    tblCells = [
      t("product"),
      t("manufacturer"),
      t("modelNo"),
      t("pmax"),
      t("price"),
    ];
  } else if (params.id === localStorage.getItem("inverter")) {
    tblCells = [
      t("product"),
      t("manufacturer"),
      t("modelNo"),
      t("power"),
      t("phase"),
      t("mppt"),
      t("price"),
    ];
  } else if (params.id === localStorage.getItem("storage")) {
    tblCells = [
      t("product"),
      t("manufacturer"),
      t("modelNo"),
      t("power"),
      t("weight"),
      t("dimentions"),
      t("maxBattery"),
      t("ip"),
      t("price"),
    ];
  } else if (params.id === localStorage.getItem("hybrid_inverter")) {
    // t('mppt'),
    tblCells = [
      t("product"),
      t("manufacturer"),
      t("modelNo"),
      t("power"),
      t("voltageBattery"),
      t("dimentions"),
      t("price"),
    ];
  } else if (params.id === localStorage.getItem("accessory")) {
    tblCells = [
      t("product"),
      t("manufacturer"),
      t("modelNo"),
      t("description"),
      t("price"),
    ];
  } else if (params.id === localStorage.getItem("carport")) {
    tblCells = [
      t("product"),
      t("manufacturer"),
      t("modelNo"),
      t("parkingNo"),
      t("dimentions"),
      t("power"),
      t("price"),
    ];
  }

  const fnProductList = useCallback(
    async (paramsId, page, searchText = "") => {
      let searchString = "";
      if (searchText !== "") {
        searchString = "&search_text=" + searchText;
      }

      // console.log("current page in list funcion ", page);
      // console.log('searchString ',searchString)
      await axios
        .get(
          apiURL.service_list_product +
            `/${paramsId}?isActive=true&page=${page}+&limit=${limit}${searchString}`,
          {
            headers: {
              "Content-Type": "application/json",
              // 'Authorization': token,
              // "Access-Control-Allow-Origin": "*"
            },
          }
        )
        .then((res) => {
          // console.log("list res ::::::", res);
          setIsLoading(false);
          if (res.status === apiStatusCode.SUCCESS) {
            // console.log(res.data.data.product);
            setRows(res.data.data.product);
            setTotalRecords(res.data.data.totalRecords);
          } else {
            console.log("In else portion ", res.data);
            setOpen(true);
            setErrorMsg(res.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          // console.log("in err", error);
          setOpen(true);
          if (error.code === defaultString.network_error)
            setErrorMsg(error.message);
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === 404) {
              setOpen(false);
            }
          }
          setErrorMsg(error.response.data.message);
          setRows([]);
          setTotalRecords(0);
        });
    },
    [limit]
  );

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await fnProductList(params.id, newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const tableCellClicked = (row, selectedRow) => {
    // console.log("row", row);
    navigate("/product-details", {
      state: { row },
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fnProductList(params.id, currentPage);
    getModelData(params.id);
    getPmaxData(params.id);
    getManufacturerData(params.id);
    getDimnesionsData(params.id);
    // console.log("hello");
  }, [fnProductList, params.id, currentPage]);

  const getPmaxData = async (id) => {
    try {
      const response = await axios.get(apiURL.service_product_pmax + "/" + id);
      if (response.status === apiStatusCode.SUCCESS) {
        // console.log("response ::", response);
        setPmaxList(response.data.data.pmaxs); // Adjust this line based on your response structure
      } else {
        console.log("In else portion ", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getModelData = async (id) => {
    try {
      const response = await axios.get(apiURL.service_product_model + "/" + id);
      if (response.status === apiStatusCode.SUCCESS) {
        // console.log("response ::", response);
        setModelList(response.data.data.models); // Adjust this line based on your response structure
      } else {
        console.log("In else portion ", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getManufacturerData = async (id) => {
    try {
      const response = await axios.get(
        apiURL.service_list_all_manufacturer + "/" + id
      );
      if (response.status === apiStatusCode.SUCCESS) {
        // console.log("response 11 11 11  ::", response);
        setManufacturerList(response.data.data.manufacturer); // Adjust this line based on your response structure
      } else {
        console.log("In else portion ", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDimnesionsData = async (id) => {
    try {
      const response = await axios.get(
        apiURL.service_product_dimensions + "/" + id
      );
      if (response.status === apiStatusCode.SUCCESS) {
        // console.log("response :: 1 :: 2", response.data.data.dimensions);
        setDimensionsList(response.data.data.dimensions); // Adjust this line based on your response structure
      } else {
        console.log("In else portion ", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const toggleDrawer = (anchor, open, row) => {
    // console.log(JSON.stringify(event));
    setState({ ...state, [anchor]: open });
  };

  const toggleDrawerClose = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  const handleCheckboxChange = (optionId) => {
    if (checkedItems.includes(optionId)) {
      setCheckedItems(checkedItems.filter((id) => id !== optionId));
    } else {
      setCheckedItems([...checkedItems, optionId]);
    }
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 300, padding: "8px" }}
      role="presentation"
      onClick={() => {
        toggleDrawer(anchor, true);
      }}
      onKeyDown={() => {
        toggleDrawer(anchor, true);
      }}
    >
      <Accordion defaultActiveKey={isActiveKeys} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t("sortBy")}</Accordion.Header>
          <Accordion.Body>
            <div>
              <div className="form-check">
                <input
                  type="radio"
                  value="option1"
                  checked={selectedOption === "1"}
                  onChange={() => handleOptionChange("1")}
                  class="form-check-input"
                />
                <label class="form-check-label">Price Low-High</label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  value="option2"
                  checked={selectedOption === "0"}
                  onChange={() => handleOptionChange("0")}
                  class="form-check-input"
                />
                <label class="form-check-label">Price High-Low</label>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{t("manufacturer")}</Accordion.Header>
          <Accordion.Body>
            {manufacturerList &&
              manufacturerList.length > 1 &&
              manufacturerList.map((item, option) => (
                <div
                  className="form-check"
                  key={item._id}
                  controlId={`formBasicCheckbox-${item._id}`}
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    checked={checkedItems.includes(item._id)}
                    onChange={() => handleCheckboxChange(item._id)}
                    id={`flexCheckDefault_${item._id}`}
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    {item?.name}
                  </label>
                </div>
              ))}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>{t("modelNo")}</Accordion.Header>
          <Accordion.Body>
            {modelList &&
              modelList.length > 1 &&
              modelList.map((item) => (
                <div
                  className="form-check"
                  key={item?.modelNo}
                  controlId={`formBasicCheckbox-${item.modelNo}`}
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    checked={checkedItems.includes(item?.modelNo)}
                    onChange={() => handleCheckboxChange(item?.modelNo)}
                    id={`flexCheckDefault_${item?.modelNo}`}
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    {item?.modelNo}
                  </label>
                </div>
              ))}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>{t("dimentions")}</Accordion.Header>
          <Accordion.Body>
            {dimensionsList &&
              dimensionsList.length > 1 &&
              dimensionsList.map((item) => (
                <div
                  className="form-check"
                  key={item?.dimensions}
                  controlId={`formBasicCheckbox-${item?.dimensions}`}
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    checked={checkedItems.includes(item?.dimensions)}
                    onChange={() => handleCheckboxChange(item?.dimensions)}
                    id={`flexCheckDefault_${item?.dimensions}`}
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    {item?.dimensions}
                  </label>
                </div>
              ))}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>{t("pmax")}</Accordion.Header>
          <Accordion.Body>
            {pmaxList &&
              pmaxList.length > 1 &&
              pmaxList.map((item) => (
                <div
                  className="form-check"
                  key={item?.p_max}
                  controlId={`formBasicCheckbox-${item?.p_max}`}
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    checked={checkedItems.includes(item?.p_max)}
                    onChange={() => handleCheckboxChange(item?.p_max)}
                    id={`flexCheckDefault_${item?.p_max}`}
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    {item?.p_max}
                  </label>
                </div>
              ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Box>
  );

  const handleIncrement = (selectedItemMain) => {
    const newList = [...cartList];
    newList.forEach((item) => {
      if (item._id === selectedItemMain._id) {
        item.productCount = item.productCount + 1;
        item.totalPrice = item.productCount * item.price;
      }
    });

    let total = 0;
    for (let i = 0; i < newList.length; i++) {
      total += newList[i].totalPrice;
    }

    setSubTotal(total);
    doSetCartData(newList);
  };

  const handleDecrement = (selectedItemMain) => {
    const newList = [...cartList];
    newList.forEach((item) => {
      if (item._id === selectedItemMain._id && item.productCount > 0) {
        item.productCount = item.productCount - 1;
        item.totalPrice = item.productCount * item.price;
      }
    });

    let total = 0;
    for (let i = 0; i < newList.length; i++) {
      total += newList[i].totalPrice;
    }

    setSubTotal(total);
    doSetCartData(newList);
  };

  const deleteCart = (item) => {
    console.log("item:::---", item);
    let oldList = [...cartList];
    let newList = [];

    const selectedItemDataOrList = cartList.some(
      (mainItem) => item._id === mainItem._id
    );

    console.log("oldList", oldList);
    if (selectedItemDataOrList) {
      console.log("hello");
      oldList.map((item) => {});
    }
  };

  const doAddSelectedItem = (mainItem) => {
    const oldData = [...cartList];
    const newList = [];

    const selectedItemDataOrList = cartList.some(
      (item) => mainItem._id === item._id
    );

    if (oldData.length > 0 && selectedItemDataOrList) {
      oldData.forEach((item) => {
        if (item._id === mainItem._id) {
          newList.push({
            ...item,
            productCount: item.productCount + 1,
            totalPrice: (item.productCount + 1) * item.price,
          });
        } else {
          newList.push(item);
        }
      });
    } else {
      oldData.forEach((item) => {
        newList.push(item);
      });
      newList.push({
        ...mainItem,
        productCount: 1,
        totalPrice: mainItem.price,
      });
    }

    let total = 0;

    for (let i = 0; i < newList.length; i++) {
      total += newList[i].totalPrice;
    }

    console.log("total", total);
    console.log("newList", newList);

    setSubTotal(total);
    doSetCartData(newList);
    toggleDrawer("right", true, mainItem);
  };

  const listcart = (anchor, listCartMain) => {
    // console.log("item ::: new", item);
    return (
      <Box
        sx={{ width: 500 }}
        role="presentation"
        onClick={() => {
          toggleDrawer(anchor, true);
        }}
        onKeyDown={() => {
          toggleDrawer(anchor, true);
        }}
      >
        {listCartMain.map((item, index) => {
          return (
            <Box sx={{ padding: "12px 32px" }}>
              <Box sx={{ display: "flex", gap: 5, my: 2 }}>
                <Box>
                  <img
                    style={{
                      width: "80px",
                      height: "80px",
                      objectFit: "cover",
                    }}
                    variant="top"
                    src={apiURL.siteBaseUrl + item.mainImage.logo}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "var(--secondary-color)",
                        }}
                      >
                        {item.modelNo}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "var(--secondary-color)",
                        }}
                      >
                        {item.manufacturerId.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "var(--secondary-color)",
                          lineHeight: "24px",
                          fontWeight: 700,
                        }}
                      >
                        {`€${item.totalPrice}`}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ButtonGroup
                      size="small"
                      variant="contained"
                      aria-label="outlined button group"
                    >
                      <Button
                        onClick={() => {
                          handleDecrement(item);
                        }}
                        style={{
                          borderRadius: "0",
                          backgroundColor: "transparent",
                          border: "none",
                          color: "black",
                        }}
                      >
                        -
                      </Button>
                      <Button
                        style={{
                          borderRadius: "0",
                          backgroundColor: "transparent",
                          border: "none",
                          color: "black",
                        }}
                      >
                        {item.productCount}
                      </Button>
                      <Button
                        style={{
                          borderRadius: "0",
                          backgroundColor: "transparent",
                          border: "none",
                          color: "black",
                        }}
                        onClick={() => {
                          handleIncrement(item);
                        }}
                      >
                        +
                      </Button>
                    </ButtonGroup>
                    <Box
                      sx={{
                        marginLeft: 2,
                      }}
                    >
                      <Tooltip title={t("delete")}>
                        <DeleteRoundedIcon
                          onClick={() => {
                            deleteCart(item);
                          }}
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}

        <Box
          sx={{
            position: "fixed",
            zIndex: 999,
            bottom: 0,
            width: 500,
            backgroundClolor: "white",
          }}
        >
          <Box sx={{ borderTop: "1px solid #00000030" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "6px 32px",
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "var(--secondary-color)",
                }}
              >
                SubTotal
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                €{subTotal}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "6px 32px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "var(--secondary-color)",
                }}
              >
                Shipping
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                --
              </Typography>
            </Box>
          </Box>
          <Box sx={{ borderTop: "1px solid #00000030" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "6px 32px",
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "var(--secondary-color)",
                }}
              >
                Total(€)
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                €{subTotal}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "6px 32px",
                mb: 2,
                mt: 1,
              }}
            >
              <Button
                size="medium"
                color="primary"
                style={{
                  width: "100%",
                  backgroundColor: "var(--primary-color)",
                  border: "none",
                  "&:hover": {
                    backgroundColor: "var(--primary-color-hover)",
                  },
                }}
              >
                Confirm Order
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };

  const fnFilterData = async () => {
    setIsLoading(true);
    let parameters = {};

    let productId = params.id;

    const selectedManufacturer = manufacturerList
      .filter((item) => checkedItems.includes(item._id))
      .map((item) => item._id);

    const selectedModelNos = modelList
      .filter((item) => checkedItems.includes(item?.modelNo))
      .map((item) => item?.modelNo);

    const selectedPmaxes = pmaxList
      .filter((item) => checkedItems.includes(item?.p_max))
      .map((item) => item?.p_max);

    const selectedDimensions = dimensionsList
      .filter((item) => checkedItems.includes(item?.dimensions))
      .map((item) => item?.dimensions);

    const selectedPrice = selectedOption;
    parameters.productId = productId;

    if (selectedPrice.length > 0) {
      parameters.sortByPrice = selectedPrice;
      setIsActiveKeys((prevKeys) => [...prevKeys, "0"]);
    }

    if (selectedManufacturer.length > 0) {
      parameters.manufacturerIds = selectedManufacturer;
      setIsActiveKeys((prevKeys) => [...prevKeys, "1"]);
    }
    if (selectedModelNos.length > 0) {
      parameters.modelNos = selectedModelNos;
      setIsActiveKeys((prevKeys) => [...prevKeys, "2"]);
    }
    if (selectedDimensions.length > 0) {
      parameters.dimensions = selectedDimensions;
      setIsActiveKeys((prevKeys) => [...prevKeys, "3"]);
    }
    if (selectedPmaxes.length > 0) {
      parameters.pmaxes = selectedPmaxes;
      setIsActiveKeys((prevKeys) => [...prevKeys, "4"]);
    }

    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));

    await axios
      .post(apiURL.service_product_filter_data, parameters, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.status === apiStatusCode.SUCCESS) {
          setIsLoading(false);
          setRows(res.data.data.product);
          setTotalRecords(res.data.data.totalRecords);
        } else {
          // setOpen(true);
          // setErrorMsg(res.data.data.message);
          // console.log("rows",rows);
          // setRows("")
        }
      })
      .catch((error) => {
        if (error.response.data.status === apiStatusCode.NOTFOUND) {
          setIsLoading(false);
          setRows([]);
        }
        if (error.code === defaultString.network_error) {
          setIsLoading(false);
          setErrorMsg(error.message);
        } else setErrorMsg(error.response.data.message);
        // if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
        //   doLogout(error)
        // }
      });
  };

  const fnClearData = async () => {
    setCheckedItems([]);
    setSelectedOption("");
    setIsActiveKeys([]);
    window.location.reload();

    await axios
      .get(apiURL.service_list_product + `/${params.id}`, {
        headers: {
          "Content-Type": "application/json",
          // 'Authorization': token,
          // "Access-Control-Allow-Origin": "*"
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (res.status === apiStatusCode.SUCCESS) {
          setRows(res.data.data.product);
          setTotalRecords(res.data.data.totalRecords);
        } else {
          setOpen(true);
          setErrorMsg(res.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setOpen(true);
        if (error.code === defaultString.network_error)
          setErrorMsg(error.message);
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === 404) {
            setOpen(false);
          }
        }
        setErrorMsg(error.response.data.message);
        setRows([]);
        setTotalRecords(0);
      });
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div fluid className="product-nav-item">
          <div className="mb-3 table-navs border-bottom">
            <SubNav />
          </div>

          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              p={2}
              className="border-right-3"
              sx={{
                display: { xs: "none", sm: "none", md: "block", lg: "block" },
              }}
            >
              <Row className="border-bottom pb-3">
                <div className="d-flex align-items-center text-bold">
                  <img src="../images/sort.png" height={32} className="me-3" />
                  <div>
                    <h3 className="mb-0">{t("filter")}</h3>
                  </div>
                </div>
              </Row>

              <Row>
                {list("LeftMain")}
                <div style={{ display: "flex", marginTop: "16px" }}>
                  <Button
                    variant="outlined"
                    onClick={(id) => {
                      fnFilterData(id);
                    }}
                    style={{
                      paddingRight: "4px",
                      backgroundColor: "#212529",
                      color: "#fff",
                      width: "50%",
                      marginRight: "4px",
                    }}
                    disabled={checkedItems.length == 0 && selectedOption == ""}
                  >
                    {t("apply")}
                  </Button>

                  <Button
                    variant="outlined"
                    className="bg-dark text-white"
                    onClick={() => {
                      fnClearData();
                    }}
                    disabled={checkedItems.length == 0 && selectedOption == ""}
                    style={{
                      paddingRight: "4px",
                      backgroundColor: "#212529",
                      color: "#fff",
                      width: "50%",
                      marginLeft: "4px",
                    }}
                  >
                    {t("clear")}
                  </Button>
                </div>
              </Row>
            </Grid>

            {params.id === localStorage.getItem("solarpanel") && (
              <Grid item xs={12} sm={12} md={10} lg={10} p={2}>
                <Row className="mt-0">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item ">
                        <a href="#">{t("home")}</a>
                      </li>
                      <li class="breadcrumb-item active " aria-current="page">
                        {t("product")}
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#">{t("solarPanel")}</a>
                      </li>
                    </ol>
                  </nav>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid item>
                      <div className="d-flex align-items-end mb-3">
                        <div>
                          <h2 className="text-capitalize mb-0">
                            {t("solarPanel")}
                          </h2>
                        </div>
                        <div className="">
                          <h6 className="text-capitalize mb-1 ms-3 color">
                            {rows.length} {t("items")}
                          </h6>
                        </div>
                      </div>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        display: {
                          xs: "block",
                          sm: "block",
                          md: "none",
                          lg: "none",
                        },
                      }}
                    >
                      <div style={{}}>
                        <React.Fragment>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              toggleDrawer("left", true);
                            }}
                          >
                            <div className="d-flex align-items-center  text-bold">
                              <img
                                src="../images/sort.png"
                                height={24}
                                className="me-1"
                              />
                              <Typography>{t("filter")}</Typography>
                            </div>
                          </Button>
                          <Drawer
                            anchor={"left"}
                            open={state["left"]}
                            onClose={() => {
                              toggleDrawer("left", false);
                            }}
                            style={{ padding: "24px" }}
                          >
                            {list("left")}
                            <div
                              style={{
                                display: "flex",
                                marginTop: "16px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={(id) => {
                                  fnFilterData(id);
                                }}
                                style={{
                                  paddingRight: "4px",
                                  backgroundColor: "#212529",
                                  color: "#fff",
                                  width: "50%",
                                  marginRight: "4px",
                                }}
                                disabled={
                                  checkedItems.length == 0 &&
                                  selectedOption == ""
                                }
                              >
                                {t("apply")}
                              </Button>

                              <Button
                                variant="outlined"
                                className="bg-dark text-white"
                                onClick={() => {
                                  fnClearData();
                                }}
                                disabled={
                                  checkedItems.length == 0 &&
                                  selectedOption == ""
                                }
                                style={{
                                  paddingRight: "4px",
                                  backgroundColor: "#212529",
                                  color: "#fff",
                                  width: "50%",
                                  marginLeft: "4px",
                                }}
                              >
                                {t("clear")}
                              </Button>
                            </div>
                          </Drawer>
                        </React.Fragment>
                      </div>
                    </Grid>
                  </Grid>
                </Row>

                <Row>
                  {rows.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <Col
                        tabIndex={-1}
                        key={index}
                        // onClick={() => {
                        //   tableCellClicked(row);
                        // }}
                        className="mb-4"
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={3}
                      >
                        <Card>
                          {/* <Link
                            to="/product_details"
                            
                            style={{ textDecoration: "none", color: "#111" }}
                          > */}
                          <Card.Img
                            onClick={() => {
                              tableCellClicked(row, cartList);
                            }}
                            style={{
                              width: "100%",
                              height: "320px",
                              objectFit: "cover",
                            }}
                            variant="top"
                            // src={apiURL.siteBaseUrl + row.mainImage.logo}
                            // src={apiURL.siteBaseUrl +  row.mainImage.logo
                            //   ?   row.mainImage.logo
                            //     : apiURL.siteBaseUrl + "../images/sort.png"}
                            src={
                              row.mainImage.logo
                                ? apiURL.siteBaseUrl + row.mainImage.logo
                                : SkeletonImg
                            }
                          ></Card.Img>
                          {/* </Link> */}
                          <Card.Body>
                            <Typography className="txt-highlight">
                              {row.manufacturerId.name}
                            </Typography>
                            <Card.Title className="title-text mt-2">
                              {row.modelNo}
                            </Card.Title>

                            <div className="d-flex align-items-center">
                              <Card.Title className="txt-price mt-1">
                                {row.price} €
                              </Card.Title>

                              {/* <Card.Text className="ms-2 txt-cutprice">
                                  {row.price + 50} €
                                </Card.Text> */}
                            </div>
                            {/* <React.Fragment>
                              <Button
                                onClick={toggleDrawer("right", true)}
                                className="mt-2"
                                size="medium"
                                color="primary"
                                style={{
                                  backgroundColor: "var(--primary-color)",
                                  border: "none",
                                  "&:hover": {
                                    backgroundColor:
                                      "var(--primary-color-hover)",
                                  },
                                }}
                              >
                                {t("addToCart")}
                              </Button>
                              <Drawer
                                anchor="right"
                                open={state["right"]}
                                onClose={toggleDrawer("right", false)}
                              >
                                {list("right")}
                              </Drawer>
                            </React.Fragment> */}
                            <div style={{}}>
                              <React.Fragment>
                                <Button
                                  onClick={() => {
                                    doAddSelectedItem(row);
                                  }}
                                  className="mt-2"
                                  size="medium"
                                  color="primary"
                                  style={{
                                    backgroundColor: "var(--primary-color)",
                                    border: "none",
                                    "&:hover": {
                                      backgroundColor:
                                        "var(--primary-color-hover)",
                                    },
                                  }}
                                >
                                  {t("addToCart")}
                                </Button>
                                <Drawer
                                  anchor={"right"}
                                  open={state["right"]}
                                  onClose={() => {
                                    toggleDrawerClose("right", false);
                                  }}
                                  style={{ padding: "24px" }}
                                >
                                  {listcart("right", cartList)}
                                </Drawer>
                              </React.Fragment>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Grid>
            )}

            {params.id === localStorage.getItem("storage") && (
              <Grid item xs={12} sm={12} md={10} lg={10} p={2}>
                <Row className="mt-0">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item ">
                        <a href="#">Home</a>
                      </li>
                      <li class="breadcrumb-item active " aria-current="page">
                        Prodotti
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#">Storage</a>
                      </li>
                    </ol>
                  </nav>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid item>
                      <div className="d-flex align-items-end mb-3">
                        <div>
                          <h2 className="text-capitalize mb-0">Storage</h2>
                        </div>
                        <div className="">
                          <h6 className="text-capitalize mb-1 ms-3 color">
                            {rows.length} items
                          </h6>
                        </div>
                      </div>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        display: {
                          xs: "block",
                          sm: "block",
                          md: "none",
                          lg: "none",
                        },
                      }}
                    >
                      <div style={{}}>
                        <React.Fragment>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              toggleDrawer("left", true);
                            }}
                          >
                            <div className="d-flex align-items-center  text-bold">
                              <img
                                src="../images/sort.png"
                                height={24}
                                className="me-1"
                              />
                              <Typography>Filter</Typography>
                            </div>
                          </Button>
                          <Drawer
                            anchor={"left"}
                            open={state["left"]}
                            onClose={() => {
                              toggleDrawer("left", false);
                            }}
                          >
                            {list("left")}
                            <div
                              style={{
                                display: "flex",
                                marginTop: "16px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={(id) => {
                                  fnFilterData(id);
                                }}
                                style={{
                                  paddingRight: "4px",
                                  backgroundColor: "#212529",
                                  color: "#fff",
                                  width: "50%",
                                  marginRight: "4px",
                                }}
                                disabled={
                                  checkedItems.length == 0 &&
                                  selectedOption == ""
                                }
                              >
                                {t("apply")}
                              </Button>

                              <Button
                                variant="outlined"
                                className="bg-dark text-white"
                                onClick={() => {
                                  fnClearData();
                                }}
                                disabled={
                                  checkedItems.length == 0 &&
                                  selectedOption == ""
                                }
                                style={{
                                  paddingRight: "4px",
                                  backgroundColor: "#212529",
                                  color: "#fff",
                                  width: "50%",
                                  marginLeft: "4px",
                                }}
                              >
                                {t("clear")}
                              </Button>
                            </div>
                          </Drawer>
                        </React.Fragment>
                      </div>
                    </Grid>
                  </Grid>
                </Row>

                <Row>
                  {(params.id === localStorage.getItem("storage") ||
                    params.id === localStorage.getItem("hybrid_inverter") ||
                    params.id === localStorage.getItem("accessory")) && (
                    <ProductStorageNav />
                  )}
                </Row>

                <Row>
                  {rows.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // console.log(row.power, "rowrowrow");
                    return (
                      <Col
                        tabIndex={-1}
                        key={index}
                        className="mb-4"
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={3}
                      >
                        <Card>
                          {/* <Link
                            to="/product_details"
                            style={{
                              textDecoration: "none",
                              color: "#111",
                            }}
                          > */}
                          <Card.Img
                            onClick={() => {
                              tableCellClicked(row);
                            }}
                            style={{
                              width: "100%",
                              height: "320px",
                              objectFit: "cover",
                            }}
                            variant="top"
                            src={
                              row.mainImage.logo
                                ? apiURL.siteBaseUrl + row.mainImage.logo
                                : SkeletonImg
                            }
                          ></Card.Img>
                          {/* </Link> */}
                          <Card.Body>
                            <Typography className="txt-highlight">
                              {row.manufacturerId.name}
                            </Typography>
                            <Card.Title className="title-text mt-2">
                              {row.modelNo}
                            </Card.Title>
                            <div className="d-flex align-items-center">
                              <Card.Title className="txt-price mt-1">
                                {row.price} €
                              </Card.Title>

                              {/* <Card.Text className="ms-2 txt-cutprice">
                                  {row.price + 50} €
                                </Card.Text> */}
                            </div>
                            <Button
                              onClick={() => {
                                doAddSelectedItem(row);
                              }}
                              className="mt-2"
                              size="medium"
                              color="primary"
                              style={{
                                backgroundColor: "var(--primary-color)",
                                border: "none",
                                "&:hover": {
                                  backgroundColor: "var(--primary-color-hover)",
                                },
                              }}
                            >
                              {t("addToCart")}
                            </Button>
                            <Drawer
                              anchor={"right"}
                              open={state["right"]}
                              onClose={() => {
                                toggleDrawerClose("right", false);
                              }}
                              style={{ padding: "24px" }}
                            >
                              {listcart("right", cartList)}
                            </Drawer>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Grid>
            )}

            {params.id === localStorage.getItem("hybrid_inverter") && (
              <Grid item xs={12} sm={12} md={10} lg={10} p={2}>
                <Row className="mt-0">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item ">
                        <a href="#">Home</a>
                      </li>
                      <li class="breadcrumb-item active " aria-current="page">
                        Prodotti
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#">Storage</a>
                      </li>
                    </ol>
                  </nav>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid item>
                      <div className="d-flex align-items-end mb-3">
                        <div>
                          <h2 className="text-capitalize mb-0">Storage</h2>
                        </div>
                        <div className="">
                          <h6 className="text-capitalize mb-1 ms-3 color">
                            {rows.length} items
                          </h6>
                        </div>
                      </div>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        display: {
                          xs: "block",
                          sm: "block",
                          md: "none",
                          lg: "none",
                        },
                      }}
                    >
                      <div style={{}}>
                        <React.Fragment>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              toggleDrawer("left", true);
                            }}
                          >
                            <div className="d-flex align-items-center  text-bold">
                              <img
                                src="../images/sort.png"
                                height={24}
                                className="me-1"
                              />
                              <Typography>Filter</Typography>
                            </div>
                          </Button>
                          <Drawer
                            anchor={"left"}
                            open={state["left"]}
                            onClose={() => {
                              toggleDrawer("left", false);
                            }}
                          >
                            {list("left")}
                            <div
                              style={{
                                display: "flex",
                                marginTop: "16px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={(id) => {
                                  fnFilterData(id);
                                }}
                                style={{
                                  paddingRight: "4px",
                                  backgroundColor: "#212529",
                                  color: "#fff",
                                  width: "50%",
                                  marginRight: "4px",
                                }}
                                disabled={
                                  checkedItems.length == 0 &&
                                  selectedOption == ""
                                }
                              >
                                {t("apply")}
                              </Button>

                              <Button
                                variant="outlined"
                                className="bg-dark text-white"
                                onClick={() => {
                                  fnClearData();
                                }}
                                disabled={
                                  checkedItems.length == 0 &&
                                  selectedOption == ""
                                }
                                style={{
                                  paddingRight: "4px",
                                  backgroundColor: "#212529",
                                  color: "#fff",
                                  width: "50%",
                                  marginLeft: "4px",
                                }}
                              >
                                {t("clear")}
                              </Button>
                            </div>
                          </Drawer>
                        </React.Fragment>
                      </div>
                    </Grid>
                  </Grid>
                </Row>

                <Row>
                  {(params.id === localStorage.getItem("storage") ||
                    params.id === localStorage.getItem("hybrid_inverter") ||
                    params.id === localStorage.getItem("accessory")) && (
                    <ProductStorageNav />
                  )}
                </Row>

                <Row>
                  {rows.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // console.log(row.power, "rowrowrow");
                    return (
                      <Col
                        tabIndex={-1}
                        key={index}
                        className="mb-4"
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={3}
                      >
                        <Card>
                          {/* <Link
                            to="/product_details"
                            style={{
                              textDecoration: "none",
                              color: "#111",
                            }}
                          > */}
                          <Card.Img
                            onClick={() => {
                              tableCellClicked(row);
                            }}
                            style={{
                              width: "100%",
                              height: "320px",
                              objectFit: "cover",
                            }}
                            variant="top"
                            src={
                              row.mainImage.logo
                                ? apiURL.siteBaseUrl + row.mainImage.logo
                                : SkeletonImg
                            }
                          ></Card.Img>
                          {/* </Link> */}
                          <Card.Body>
                            <Typography className="txt-highlight">
                              {row.manufacturerId.name}
                            </Typography>
                            <Card.Title className="title-text mt-2">
                              {row.modelNo}
                            </Card.Title>
                            <div className="d-flex align-items-center">
                              <Card.Title className="txt-price mt-1">
                                {row.price} €
                              </Card.Title>

                              {/* <Card.Text className="ms-2 txt-cutprice">
                                  {row.price + 50} €
                                </Card.Text> */}
                            </div>
                            <Button
                              onClick={() => {
                                doAddSelectedItem(row);
                              }}
                              className="mt-2"
                              size="medium"
                              color="primary"
                              style={{
                                backgroundColor: "var(--primary-color)",
                                border: "none",
                                "&:hover": {
                                  backgroundColor: "var(--primary-color-hover)",
                                },
                              }}
                            >
                              {t("addToCart")}
                            </Button>
                            <Drawer
                              anchor={"right"}
                              open={state["right"]}
                              onClose={() => {
                                toggleDrawerClose("right", false);
                              }}
                              style={{ padding: "24px" }}
                            >
                              {listcart("right", cartList)}
                            </Drawer>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Grid>
            )}
            {params.id === localStorage.getItem("accessory") && (
              <Grid item xs={12} sm={12} md={10} lg={10} p={2}>
                <Row className="mt-0">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item ">
                        <a href="#">Home</a>
                      </li>
                      <li class="breadcrumb-item active " aria-current="page">
                        Prodotti
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#">Storage</a>
                      </li>
                    </ol>
                  </nav>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid item>
                      <div className="d-flex align-items-end mb-3">
                        <div>
                          <h2 className="text-capitalize mb-0">Storage</h2>
                        </div>
                        <div className="">
                          <h6 className="text-capitalize mb-1 ms-3 color">
                            {rows.length} items
                          </h6>
                        </div>
                      </div>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        display: {
                          xs: "block",
                          sm: "block",
                          md: "none",
                          lg: "none",
                        },
                      }}
                    >
                      <div style={{}}>
                        <React.Fragment>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              toggleDrawer("left", true);
                            }}
                          >
                            <div className="d-flex align-items-center  text-bold">
                              <img
                                src="../images/sort.png"
                                height={24}
                                className="me-1"
                              />
                              <Typography>Filter</Typography>
                            </div>
                          </Button>
                          <Drawer
                            anchor={"left"}
                            open={state["left"]}
                            onClose={() => {
                              toggleDrawer("left", false);
                            }}
                          >
                            {list("left")}
                            <div
                              style={{
                                display: "flex",
                                marginTop: "16px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={(id) => {
                                  fnFilterData(id);
                                }}
                                style={{
                                  paddingRight: "4px",
                                  backgroundColor: "#212529",
                                  color: "#fff",
                                  width: "50%",
                                  marginRight: "4px",
                                }}
                                disabled={
                                  checkedItems.length == 0 &&
                                  selectedOption == ""
                                }
                              >
                                {t("apply")}
                              </Button>

                              <Button
                                variant="outlined"
                                className="bg-dark text-white"
                                onClick={() => {
                                  fnClearData();
                                }}
                                disabled={
                                  checkedItems.length == 0 &&
                                  selectedOption == ""
                                }
                                style={{
                                  paddingRight: "4px",
                                  backgroundColor: "#212529",
                                  color: "#fff",
                                  width: "50%",
                                  marginLeft: "4px",
                                }}
                              >
                                {t("clear")}
                              </Button>
                            </div>
                          </Drawer>
                        </React.Fragment>
                      </div>
                    </Grid>
                  </Grid>
                </Row>

                <Row>
                  {(params.id === localStorage.getItem("storage") ||
                    params.id === localStorage.getItem("hybrid_inverter") ||
                    params.id === localStorage.getItem("accessory")) && (
                    <ProductStorageNav />
                  )}
                </Row>

                <Row>
                  {rows.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // console.log(row.power, "rowrowrow");
                    return (
                      <Col
                        tabIndex={-1}
                        key={index}
                        className="mb-4"
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={3}
                      >
                        <Card>
                          {/* <Link
                            to="/product_details"
                            style={{
                              textDecoration: "none",
                              color: "#111",
                            }}
                          > */}
                          <Card.Img
                            onClick={() => {
                              tableCellClicked(row);
                            }}
                            style={{
                              width: "100%",
                              height: "320px",
                              objectFit: "cover",
                            }}
                            variant="top"
                            src={
                              row.mainImage.logo
                                ? apiURL.siteBaseUrl + row.mainImage.logo
                                : SkeletonImg
                            }
                          ></Card.Img>
                          {/* </Link> */}
                          <Card.Body>
                            <Typography className="txt-highlight">
                              {row.manufacturerId.name}
                            </Typography>
                            <Card.Title className="title-text mt-2">
                              {row.modelNo}
                            </Card.Title>
                            <div className="d-flex align-items-center">
                              <Card.Title className="txt-price mt-1">
                                {row.price} €
                              </Card.Title>

                              {/* <Card.Text className="ms-2 txt-cutprice">
                                {row.price + 50} €
                              </Card.Text> */}
                            </div>
                            <Button
                              onClick={() => {
                                doAddSelectedItem(row);
                              }}
                              className="mt-2"
                              size="medium"
                              color="primary"
                              style={{
                                backgroundColor: "var(--primary-color)",
                                border: "none",
                                "&:hover": {
                                  backgroundColor: "var(--primary-color-hover)",
                                },
                              }}
                            >
                              {t("addToCart")}
                            </Button>
                            <Drawer
                              anchor={"right"}
                              open={state["right"]}
                              onClose={() => {
                                toggleDrawerClose("right", false);
                              }}
                              style={{ padding: "24px" }}
                            >
                              {listcart("right", cartList)}
                            </Drawer>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Grid>
            )}

            {params.id === localStorage.getItem("mouting") && (
              <Grid item xs={12} sm={12} md={10} lg={10} p={2}>
                <Row className="mt-0">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item ">
                        <a href="#">Home</a>
                      </li>
                      <li class="breadcrumb-item active " aria-current="page">
                        Prodotti
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#">Mounting</a>
                      </li>
                    </ol>
                  </nav>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid item>
                      <div className="d-flex align-items-end mb-3">
                        <div>
                          <h2 className="text-capitalize mb-0">Car Port</h2>
                        </div>
                        <div className="">
                          <h6 className="text-capitalize mb-1 ms-3 color">
                            {rows.length} items
                          </h6>
                        </div>
                      </div>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        display: {
                          xs: "block",
                          sm: "block",
                          md: "none",
                          lg: "none",
                        },
                      }}
                    >
                      <div style={{}}>
                        <React.Fragment>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              toggleDrawer("left", true);
                            }}
                          >
                            <div className="d-flex align-items-center  text-bold">
                              <img
                                src="../images/sort.png"
                                height={24}
                                className="me-1"
                              />
                              <Typography>Filter</Typography>
                            </div>
                          </Button>
                          <Drawer
                            anchor={"left"}
                            open={state["left"]}
                            onClose={() => {
                              toggleDrawer("left", false);
                            }}
                          >
                            {list("left")}
                            <div
                              style={{
                                display: "flex",
                                marginTop: "16px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={(id) => {
                                  fnFilterData(id);
                                }}
                                style={{
                                  paddingRight: "4px",
                                  backgroundColor: "#212529",
                                  color: "#fff",
                                  width: "50%",
                                  marginRight: "4px",
                                }}
                                disabled={
                                  checkedItems.length == 0 &&
                                  selectedOption == ""
                                }
                              >
                                {t("apply")}
                              </Button>

                              <Button
                                variant="outlined"
                                className="bg-dark text-white"
                                onClick={() => {
                                  fnClearData();
                                }}
                                disabled={
                                  checkedItems.length == 0 &&
                                  selectedOption == ""
                                }
                                style={{
                                  paddingRight: "4px",
                                  backgroundColor: "#212529",
                                  color: "#fff",
                                  width: "50%",
                                  marginLeft: "4px",
                                }}
                              >
                                {t("clear")}
                              </Button>
                            </div>
                          </Drawer>
                        </React.Fragment>
                      </div>
                    </Grid>
                  </Grid>
                </Row>

                <Row>
                  {rows.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // console.log(row.power, "rowrowrow");
                    return (
                      <Col
                        tabIndex={-1}
                        key={index}
                        className="mb-4"
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={3}
                      >
                        <Card>
                          {/* <Link
                            to="/product_details"
                            style={{ textDecoration: "none", color: "#111" }}
                          > */}
                          <Card.Img
                            onClick={() => {
                              tableCellClicked(row);
                            }}
                            style={{
                              width: "100%",
                              height: "320px",
                              objectFit: "cover",
                            }}
                            variant="top"
                            src={
                              row.mainImage.logo
                                ? apiURL.siteBaseUrl + row.mainImage.logo
                                : SkeletonImg
                            }
                          ></Card.Img>
                          {/* </Link> */}
                          <Card.Body>
                            <Typography className="txt-highlight">
                              {row.manufacturerId.name}
                            </Typography>
                            <Card.Title className="title-text mt-2">
                              {row.modelNo}
                            </Card.Title>
                            <div className="d-flex align-items-center">
                              <Card.Title className="txt-price mt-1">
                                {row.price} €
                              </Card.Title>

                              {/* <Card.Text className="ms-2 txt-cutprice">
                                 {row.price + 50} €
                               </Card.Text> */}
                            </div>
                            <Button
                              onClick={() => {
                                doAddSelectedItem(row);
                              }}
                              className="mt-2"
                              size="medium"
                              color="primary"
                              style={{
                                backgroundColor: "var(--primary-color)",
                                border: "none",
                                "&:hover": {
                                  backgroundColor: "var(--primary-color-hover)",
                                },
                              }}
                            >
                              {t("addToCart")}
                            </Button>
                            <Drawer
                              anchor={"right"}
                              open={state["right"]}
                              onClose={() => {
                                toggleDrawerClose("right", false);
                              }}
                              style={{ padding: "24px" }}
                            >
                              {listcart("right", cartList)}
                            </Drawer>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Grid>
            )}

            {params.id === localStorage.getItem("inverter") && (
              <Grid item xs={12} sm={12} md={10} lg={10} p={2}>
                <Row className="mt-0">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item ">
                        <a href="#">{t("home")}</a>
                      </li>
                      <li class="breadcrumb-item active " aria-current="page">
                        {t("product")}
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#">{t("inveter")}</a>
                      </li>
                    </ol>
                  </nav>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid item>
                      <div className="d-flex align-items-end mb-3">
                        <div>
                          <h2 className="text-capitalize mb-0">
                            {t("inveter")}
                          </h2>
                        </div>
                        <div className="">
                          <h6 className="text-capitalize mb-1 ms-3 color">
                            {rows.length} {t("items")}
                          </h6>
                        </div>
                      </div>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        display: {
                          xs: "block",
                          sm: "block",
                          md: "none",
                          lg: "none",
                        },
                      }}
                    >
                      <div style={{}}>
                        <React.Fragment>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              toggleDrawer("left", true);
                            }}
                          >
                            <div className="d-flex align-items-center  text-bold">
                              <img
                                src="../images/sort.png"
                                height={24}
                                className="me-1"
                              />
                              <Typography>{t("filter")}</Typography>
                            </div>
                          </Button>
                          <Drawer
                            anchor={"left"}
                            open={state["left"]}
                            onClose={() => {
                              toggleDrawer("left", false);
                            }}
                          >
                            {list("left")}
                            <div
                              style={{
                                display: "flex",
                                marginTop: "16px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={(id) => {
                                  fnFilterData(id);
                                }}
                                style={{
                                  paddingRight: "4px",
                                  backgroundColor: "#212529",
                                  color: "#fff",
                                  width: "50%",
                                  marginRight: "4px",
                                }}
                                disabled={
                                  checkedItems.length == 0 &&
                                  selectedOption == ""
                                }
                              >
                                {t("apply")}
                              </Button>

                              <Button
                                variant="outlined"
                                className="bg-dark text-white"
                                onClick={() => {
                                  fnClearData();
                                }}
                                disabled={
                                  checkedItems.length == 0 &&
                                  selectedOption == ""
                                }
                                style={{
                                  paddingRight: "4px",
                                  backgroundColor: "#212529",
                                  color: "#fff",
                                  width: "50%",
                                  marginLeft: "4px",
                                }}
                              >
                                {t("clear")}
                              </Button>
                            </div>
                          </Drawer>
                        </React.Fragment>
                      </div>
                    </Grid>
                  </Grid>
                </Row>

                <Row>
                  {rows.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // console.log(row.power, "rowrowrow");
                    const src = row.mainImage
                      ? apiURL.siteBaseUrl + row.mainImage.logo
                      : "/skeleton.png";
                    return (
                      <Col
                        tabIndex={-1}
                        key={index}
                        className="mb-4"
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={3}
                      >
                        <Card>
                          {/* <Link
                            to="/product_details"
                            style={{
                              textDecoration: "none",
                              color: "#111",
                            }}
                          > */}
                          <Card.Img
                            onClick={() => {
                              tableCellClicked(row);
                            }}
                            style={{
                              width: "100%",
                              height: "320px",
                              objectFit: "cover",
                            }}
                            variant="top"
                            src={
                              row.mainImage.logo
                                ? apiURL.siteBaseUrl + row.mainImage.logo
                                : SkeletonImg
                            }
                          ></Card.Img>
                          {/* </Link> */}
                          <Card.Body>
                            <Typography className="txt-highlight">
                              {row.manufacturerId.name}
                            </Typography>
                            <Card.Title className="title-text mt-2">
                              {row.modelNo}
                            </Card.Title>
                            <div className="d-flex align-items-center">
                              <Card.Title className="txt-price mt-1">
                                {row.price} €
                              </Card.Title>

                              {/* <Card.Text className="ms-2 txt-cutprice">
                                  {row.price + 50} €
                                </Card.Text> */}
                            </div>
                            <Button
                              onClick={() => {
                                doAddSelectedItem(row);
                              }}
                              className="mt-2"
                              size="medium"
                              color="primary"
                              style={{
                                backgroundColor: "var(--primary-color)",
                                border: "none",
                                "&:hover": {
                                  backgroundColor: "var(--primary-color-hover)",
                                },
                              }}
                            >
                              {t("addToCart")}
                            </Button>
                            <Drawer
                              anchor={"right"}
                              open={state["right"]}
                              onClose={() => {
                                toggleDrawerClose("right", false);
                              }}
                              style={{ padding: "24px" }}
                            >
                              {listcart("right", cartList)}
                            </Drawer>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Grid>
            )}

            {params.id === localStorage.getItem("carport") && (
              <Grid item xs={12} sm={12} md={10} lg={10} p={2}>
                <Row className="mt-0">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item ">
                        <a href="#">{t("homer")}</a>
                      </li>
                      <li class="breadcrumb-item active " aria-current="page">
                        {t("product")}
                      </li>
                      <li class="breadcrumb-item">
                        <a href="#">{t("carPort")}</a>
                      </li>
                    </ol>
                  </nav>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid item>
                      <div className="d-flex align-items-end mb-3">
                        <div>
                          <h2 className="text-capitalize mb-0">
                            {t("carPort")}
                          </h2>
                        </div>
                        <div className="">
                          <h6 className="text-capitalize mb-1 ms-3 color">
                            {rows.length} {t("items")}
                          </h6>
                        </div>
                      </div>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        display: {
                          xs: "block",
                          sm: "block",
                          md: "none",
                          lg: "none",
                        },
                      }}
                    >
                      <div style={{}}>
                        <React.Fragment>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              toggleDrawer("left", true);
                            }}
                          >
                            <div className="d-flex align-items-center  text-bold">
                              <img
                                src="../images/sort.png"
                                height={24}
                                className="me-1"
                              />
                              <Typography>{t("filter")}</Typography>
                            </div>
                          </Button>
                          <Drawer
                            anchor={"left"}
                            open={state["left"]}
                            onClose={() => {
                              toggleDrawer("left", false);
                            }}
                          >
                            {list("left")}
                            <div
                              style={{
                                display: "flex",
                                marginTop: "16px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={(id) => {
                                  fnFilterData(id);
                                }}
                                style={{
                                  paddingRight: "4px",
                                  backgroundColor: "#212529",
                                  color: "#fff",
                                  width: "50%",
                                  marginRight: "4px",
                                }}
                                disabled={
                                  checkedItems.length == 0 &&
                                  selectedOption == ""
                                }
                              >
                                {t("apply")}
                              </Button>

                              <Button
                                variant="outlined"
                                className="bg-dark text-white"
                                onClick={() => {
                                  fnClearData();
                                }}
                                disabled={
                                  checkedItems.length == 0 &&
                                  selectedOption == ""
                                }
                                style={{
                                  paddingRight: "4px",
                                  backgroundColor: "#212529",
                                  color: "#fff",
                                  width: "50%",
                                  marginLeft: "4px",
                                }}
                              >
                                {t("clear")}
                              </Button>
                            </div>
                          </Drawer>
                        </React.Fragment>
                      </div>
                    </Grid>
                  </Grid>
                </Row>

                <Row>
                  {rows.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // console.log(row.power, "rowrowrow");
                    return (
                      <Col
                        tabIndex={-1}
                        key={index}
                        className="mb-4"
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={3}
                      >
                        <Card>
                          {/* <Link
                            to="/product_details"
                            style={{ textDecoration: "none", color: "#111" }}
                          > */}
                          <Card.Img
                            onClick={() => {
                              tableCellClicked(row);
                            }}
                            style={{
                              width: "100%",
                              height: "320px",
                              objectFit: "cover",
                            }}
                            variant="top"
                            // src={apiURL.siteBaseUrl + row.mainImage.logo}
                            // src={row.mainImage.logo ? apiURL.siteBaseUrl + row.mainImage.logo : apiURL.siteBaseUrl +"../images/sort.png"}
                            src={
                              row.mainImage.logo
                                ? apiURL.siteBaseUrl + row.mainImage.logo
                                : SkeletonImg
                            }
                          ></Card.Img>
                          {/* </Link> */}
                          <Card.Body>
                            <Typography className="txt-highlight">
                              {row.manufacturerId.name}
                            </Typography>
                            <Card.Title className="title-text mt-2">
                              {row.modelNo}
                            </Card.Title>
                            <div className="d-flex align-items-center">
                              <Card.Title className="txt-price mt-1">
                                {row.price} €
                              </Card.Title>

                              {/* <Card.Text className="ms-2 txt-cutprice">
                                  {row.price + 50} €
                                </Card.Text> */}
                            </div>
                            <Button
                              onClick={() => {
                                doAddSelectedItem(row);
                              }}
                              className="mt-2"
                              size="medium"
                              color="primary"
                              style={{
                                backgroundColor: "var(--primary-color)",
                                border: "none",
                                "&:hover": {
                                  backgroundColor: "var(--primary-color-hover)",
                                },
                              }}
                            >
                              {t("addToCart")}
                            </Button>
                            <Drawer
                              anchor={"right"}
                              open={state["right"]}
                              onClose={() => {
                                toggleDrawerClose("right", false);
                              }}
                              style={{ padding: "24px" }}
                            >
                              {listcart("right", cartList)}
                            </Drawer>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Grid>
            )}
          </Grid>

          <Box sx={{ width: "100%" }} className="main-area">
            {/* <div className='filter'>
                <h2>{t('filter')}</h2>
                <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{t('manufacturer')}</Accordion.Header>
                    <Accordion.Body>
                    {t('manufacturerOption')}                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>{t('nominalPower')}</Accordion.Header>
                    <Accordion.Body>
                    {t('nominalPowerOption')}
                    </Accordion.Body>
                </Accordion.Item>
                </Accordion>
            </div> */}
            {/* width: '80%' */}
          </Box>
        </div>
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Product;
