import React from 'react';
import AgentSidebar from 'components/AgentSidebar/AgentSidebar';
function AgentHomeScreen(props) {
    return (
        <div>
            <AgentSidebar />
        </div>
    );
}

export default AgentHomeScreen;