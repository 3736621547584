import React, { useState, useMemo, useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import "css/Register.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Container } from "react-bootstrap";
import countryList from "react-select-country-list";
import InputGroup from "react-bootstrap/InputGroup";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import axios from "axios";
import defaultString from "constants/defaultString.json";
import { useUserValidator } from "hooks/useUserValidator";
import clsx from "clsx";
import { Typography } from "@mui/material";
// import { AuthContext } from 'context/AuthContext';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Register = (props) => {
  // const {dispatch} = useContext(AuthContext);
  const { t } = useTranslation();
  let navigate = useNavigate();

  const [userType, setUserType] = useState("Private Person");

  const [form, setForm] = useState({
    companyEmail: "",
    email: "",
    password: "",
  });

  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [address, setAddress] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyCountry, setCompanyCountry] = useState("Italy");
  const [vatNumber, setVatNumber] = useState("");
  // const [companyAddress, setCompanyAddress] = useState('')
  const [companyNumber, setCompanyNumber] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [PEC, setPEC] = useState("");
  const [SDI, setSDI] = useState("");
  const [companyProfile, setCompanyProfile] = useState("");
  const [isCompany, setIsCompany] = useState(false);
  const options = useMemo(() => countryList().getData(), []);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [country, setCountry] = useState("Italy");

  const { errors, validateForm, onBlurField } = useUserValidator(form);

  const onUpdateField = (e) => {
    const field = e.target.name;
    const nextFormState = {
      ...form,
      [field]: e.target.value,
    };
    setForm(nextFormState);

    if (errors[field].dirty)
      validateForm({
        form: nextFormState,
        errors,
        field,
      });
  };

  const handleAlertClose = (event, reason) => {
    setIsLoading(false);
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    validateForm({
      form: form,
      errors: {
        companyEmail: "",
        email: "",
        password: "",
      },
    });
  };

  const fnSignUp = async (e) => {
    e.preventDefault();
    const { isValid } = validateForm({ form, errors, forceTouchErrors: true });

    if (!isValid) {
      setOpen(true);
      setErrorMsg(defaultString.validData);
      return;
    }
    if (userType === "Private Person") {
      if (!form.password && !form.email) {
        setOpen(true);
        setErrorMsg(defaultString.validData);
        return;
      }
    }

    if (userType === "Installer") {
      if (
        !form.password &&
        !companyName &&
        !form.companyEmail &&
        !country & !address
      ) {
        console.log("here");
        setOpen(true);
        setErrorMsg(defaultString.validData);
        return;
      }
      if (!PEC && !SDI) {
        setOpen(true);
        setErrorMsg("PEC or SDI required");
        return;
      }
    }

    if (form.password) {
      setIsLoading(true);
      const formData = new FormData();

      if (companyProfile !== null) {
        formData.append("document", companyProfile);
      }
      //   formData.append('createdBy',JSON.parse(localStorage.getItem('user'))._id)
      formData.append("role", userType);
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("status", "Active");
      formData.append("isSignin", 1);

      let email;
      if (userType === "Installer") {
        email = form.companyEmail;
      } else {
        email = form.email;
      }

      // formData.append('email', form.email)
      formData.append("email", email);
      formData.append("password", form.password);
      formData.append("contactNumber", contactNumber);
      formData.append("address", address);
      formData.append("shippingContactNumber", companyNumber);
      formData.append("country", country);
      formData.append("isCompany", isCompany);
      formData.append("companyName", companyName);
      formData.append("companyEmail", form.companyEmail);
      formData.append("vat", vatNumber);
      formData.append("pec", PEC);
      formData.append("sdi", SDI);
      //   formData.append('code', code)
      //   formData.append('passportId', passportId)
      //   formData.append('fiscalCode', fiscalCode)
      //   formData.append('discountReward', discountReward)
      //   formData.append('dob', dob)

      axios
        .post(apiURL.service_create_user, formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setIsLoading(false);
          console.log(res.data);
          if (res.status === apiStatusCode.CREATED) {
            setOpen(true);
            setErrorMsg(res.data.message);
            // dispatch({type: "LOGIN", payload: res.data.data.user})
            // localStorage.setItem("userToken", JSON.stringify(res.data.data.token))
            navigate("/");
          } else {
            console.log("In else portion ", res.data);
            setOpen(true);
            setErrorMsg(res.data.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          setOpen(true);
          if (error.code === defaultString.network_error)
            setErrorMsg(error.message);
          else if (error.code === defaultString.bad_request)
            setErrorMsg(
              error.response.data.message +
                ": " +
                error.response.data.errors[0].msg
            );
          else
            setErrorMsg(
              error.response.data.message +
                ": " +
                error.response.data.errors[0].msg
            );
        });
      handleReset();
    } else {
      setOpen(true);
      setErrorMsg(defaultString.validData);
      return;
    }
  };

  // console.log(userType);
  // console.log('iscom ',isCompany)
  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Container className="py-5 section-register">
          <Row className="text-center" style={{ marginTop: "120px" }}>
            <h1>{t("welcome")}</h1>
            <h1>{t("signupToContiunue")}</h1>
          </Row>
          <Form>
            <Row className="d-flex justify-content-space-around my-5">
              <Col>
                <Form.Check
                  size="lg"
                  type="radio"
                  id="idPrivatePerson"
                  label={t("privatePerson")}
                  name="userType"
                  value="Private Person"
                  checked={userType === "Private Person"}
                  onChange={(e) => {
                    setUserType(e.target.value);
                    setIsCompany(false);
                    handleReset();
                  }}
                />
              </Col>

              <Col>
                <Form.Check
                  type="radio"
                  id="idAgent"
                  label={t("agent")}
                  value="Agent"
                  checked={userType === "Agent"}
                  name="userType"
                  onChange={(e) => {
                    setUserType(e.target.value);
                    setIsCompany(false);
                    handleReset();
                  }}
                />
              </Col>

              <Col>
                <Form.Check
                  type="radio"
                  id="idInstaller"
                  label={t("installer")}
                  value="Installer"
                  name="userType"
                  checked={userType === "Installer"}
                  onChange={(e) => {
                    setUserType(e.target.value);
                    setIsCompany(false);
                    handleReset();
                  }}
                />
              </Col>
            </Row>

            {(userType === "Private Person" || userType === "Agent") && (
              <>
                <Row className="mb-4">
                  <Col xs={12} sm={12} md={6}>
                    <FloatingLabel
                      controlId="floatingFirstName"
                      label={t("firstName")}
                    >
                      <Form.Control
                        type="text"
                        placeholder={t("firstName")}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <FloatingLabel
                      controlId="floatingLastName"
                      label={t("lastName")}
                    >
                      <Form.Control
                        type="text"
                        placeholder={t("lastName")}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </FloatingLabel>
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Col xs={12} sm={12} md={6}>
                    <FloatingLabel controlId="floatingEmail" label={t("email")}>
                      <Form.Control
                        type="email"
                        placeholder={t("email")}
                        // onChange={(e)=>setEmail(e.target.value)}
                        name="email"
                        value={form.email}
                        onChange={onUpdateField}
                        onBlur={onBlurField}
                        required
                      />
                    </FloatingLabel>
                    {errors.email.dirty && errors.email.error ? (
                      <p className="formFieldErrorMessage">
                        {errors.email.message}
                      </p>
                    ) : null}
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <InputGroup>
                      <FloatingLabel
                        controlId="floatingPassword"
                        label={t("password")}
                      >
                        <Form.Control
                          type={isRevealPwd ? "text" : "password"}
                          placeholder={t("password")}
                          aria-label={t("password")}
                          aria-describedby="basic-addon2"
                          name="password"
                          className={clsx(
                            "form-control TextBox",
                            errors.password.dirty &&
                              errors.password.error &&
                              "formFieldError"
                          )}
                          onChange={onUpdateField}
                          onBlur={onBlurField}
                          value={form.password}
                          // onChange={(e)=>setPassword(e.target.value)}
                        />
                      </FloatingLabel>
                      <Button
                        variant="outline-secondary"
                        id="button-addon2"
                        onClick={() =>
                          setIsRevealPwd((prevState) => !prevState)
                        }
                      >
                        {isRevealPwd ? <FaEye /> : <FaEyeSlash />}
                      </Button>
                    </InputGroup>
                    {errors.password.dirty && errors.password.error ? (
                      <p className="formFieldErrorMessage">
                        {errors.password.message}
                      </p>
                    ) : null}
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Col xs={12} sm={12} md={6}>
                    <FloatingLabel
                      controlId="floatingPhoneNumber"
                      label={t("phoneNumber")}
                    >
                      <Form.Control
                        type="text"
                        placeholder={t("phoneNumber")}
                        onChange={(e) => setContactNumber(e.target.value)}
                        required
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              </>
            )}

            {userType === "Private Person" && (
              <>
                <Form.Label className="mb-4">
                  <strong>{t("shippingInfo")}</strong>
                </Form.Label>

                <Row className="mb-4">
                  <Col xs={12} sm={12} md={12}>
                    <FloatingLabel
                      controlId="floatingAddress"
                      label={t("address")}
                    >
                      <Form.Control
                        type="text"
                        as="textarea"
                        placeholder={t("address")}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Col xs={12} sm={12} md={6}>
                    <FloatingLabel
                      controlId="floatingCountry"
                      label={t("country")}
                    >
                      <Form.Select
                        aria-label="Floating label select example"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        {options.map((value) => (
                          <option key={value.value}>{value.label}</option>
                        ))}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>

                  <Col xs={12} sm={12} md={6}>
                    <FloatingLabel
                      controlId="floatingNumber"
                      label={t("phoneNumber")}
                    >
                      <Form.Control
                        type="text"
                        placeholder={t("phoneNumber")}
                        onChange={(e) => setCompanyNumber(e.target.value)}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              </>
            )}

            {userType === "Agent" && (
              <Form.Check
                className="mb-4"
                type="checkbox"
                id="idAreYouCompany"
                label={t("isCompany")}
                name="isCompany"
                checked={isCompany}
                onChange={() => setIsCompany(!isCompany)}
              />
            )}

            {(isCompany || userType === "Installer") && (
              <>
                <Row className="mb-4">
                  <Col xs={12} sm={12} md={6}>
                    <FloatingLabel
                      controlId="floatingCompanyName"
                      label={t("companyName")}
                    >
                      <Form.Control
                        type="text"
                        placeholder={t("companyName")}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <FloatingLabel
                      controlId="floatingVATNumber"
                      label={t("vatNumber")}
                    >
                      <Form.Control
                        type="text"
                        placeholder={t("vatNumber")}
                        onChange={(e) => setVatNumber(e.target.value)}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Col xs={12} sm={12} md={6}>
                    <FloatingLabel controlId="floatingPEC" label={t("PEC")}>
                      <Form.Control
                        type="text"
                        placeholder={t("PEC")}
                        onChange={(e) => setPEC(e.target.value)}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <FloatingLabel controlId="floatingSDI" label={t("SDI")}>
                      <Form.Control
                        type="text"
                        placeholder={t("SDI")}
                        onChange={(e) => setSDI(e.target.value)}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Col xs={12} sm={12} md={6}>
                    <FloatingLabel
                      controlId="floatingCompanyeEmail"
                      label={t("companyEmail")}
                    >
                      <Form.Control
                        type="text"
                        placeholder={t("companyEmail")}
                        name="companyEmail"
                        value={form.companyEmail}
                        onChange={onUpdateField}
                        onBlur={onBlurField}
                        required
                      />
                    </FloatingLabel>
                    {errors.companyEmail.dirty && errors.companyEmail.error ? (
                      <p className="formFieldErrorMessage">
                        {errors.companyEmail.message}
                      </p>
                    ) : null}
                  </Col>
                  {userType === "Installer" && (
                    <Col xs={12} sm={12} md={6}>
                      <InputGroup>
                        <FloatingLabel
                          controlId="floatingPassword"
                          label={t("password")}
                        >
                          <Form.Control
                            type={isRevealPwd ? "text" : "password"}
                            placeholder={t("password")}
                            aria-label={t("password")}
                            aria-describedby="basic-addon2"
                            className={clsx(
                              "form-control TextBox",
                              errors.password.dirty &&
                                errors.password.error &&
                                "formFieldError"
                            )}
                            name="password"
                            onChange={onUpdateField}
                            onBlur={onBlurField}
                            value={form.password}
                            // onChange={(e)=>setPassword(e.target.value)}
                          />
                        </FloatingLabel>
                        <Button
                          variant="outline-secondary"
                          id="button-addon2"
                          onClick={() =>
                            setIsRevealPwd((prevState) => !prevState)
                          }
                        >
                          {isRevealPwd ? <FaEye /> : <FaEyeSlash />}
                        </Button>
                      </InputGroup>
                      {errors.password.dirty && errors.password.error ? (
                        <p className="formFieldErrorMessage">
                          {errors.password.message}
                        </p>
                      ) : null}
                    </Col>
                  )}
                </Row>

                <Row className="">
                  <Col xs={12} sm={12} md={6}>
                    <FloatingLabel
                      className="mb-4"
                      controlId="floatingCompanyCountry"
                      label={t("companyCountry")}
                    >
                      <Form.Select
                        aria-label="Floating label select example"
                        value={companyCountry}
                        onChange={(e) => setCompanyCountry(e.target.value)}
                      >
                        {options.map((value) => (
                          <option key={value.value}>{value.label}</option>
                        ))}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <FloatingLabel
                      controlId="floatingCompanyPhoneNumber"
                      label={t("companyNumber")}
                    >
                      <Form.Control
                        type="text"
                        placeholder={t("companyNumber")}
                        onChange={(e) => setCompanyNumber(e.target.value)}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <FloatingLabel
                      className="mb-4"
                      controlId="floatingCompanyAddress"
                      label={t("companyAddress")}
                    >
                      <Form.Control
                        type="text"
                        as="textarea"
                        placeholder={t("companyAddress")}
                        onChange={(e) => setAddress(e.target.value)}
                        rows="5"
                      />
                    </FloatingLabel>
                  </Col>
                </Row>

                <Row>
                  {userType === "Installer" && (
                    <Col xs={12} sm={12} md={6}>
                      <Form.Label> {t("uploadCompanyProfile")} </Form.Label>
                      <Form.Control
                        type="file"
                        name="companyProfile"
                        onChange={(e) => setCompanyProfile(e.target.files[0])}
                        accept="application/pdf,.doc,.docx"
                      />
                    </Col>
                  )}
                </Row>
              </>
            )}

            <div className="pt-4 text-center sec-signin">
              <Button
                variant="success mb-3"
                className="btn-lg success"
                type="submit"
                onClick={fnSignUp}
              >
                {t("signup")}
              </Button>
              <p className="mb-1">
                {t("alreadyHave")}? <a href="#">{t("signin")}</a>
              </p>
              <p>
                {t("agree")} <a href="#">{t("tnc")}</a> & <a>{t("policy")}</a>.
              </p>
            </div>
          </Form>
        </Container>
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Register;
