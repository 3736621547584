const siteBaseUrl = window.location.href.includes("localhost") ? "http://localhost:3001" : "https://euroboxpv.it"
// const apiBaseURL = {
//     "staging": siteBaseUrl + "/api/",
//     "production": siteBaseUrl + "/api/",
//     "add_slash": siteBaseUrl + "/",
// } 

// const baseUrl = apiBaseURL.staging;
const baseUrl = siteBaseUrl + "/api/";

const apiURL = {
    // "siteBaseUrl": apiBaseURL.add_slash,
    "siteBaseUrl": siteBaseUrl + "/",

    "service_login": baseUrl + "auth/login",
    "service_create_user": baseUrl + "auth/signup",
    "service_delete_user": baseUrl + "auth/delete",
    "service_update_user": baseUrl + "auth/update",
    "service_users_list": baseUrl + "auth/getUsers",
    "service_delete_user_attachment": baseUrl + "auth/deleteAttachment",
    "service_delete_users": baseUrl + "auth/deleteMany",
    "service_logout": baseUrl + "auth/logout",

    "service_forgot_password": baseUrl + "auth/forgotPassword",
    "service_reset_password": baseUrl + "auth/resetPassword",

    "service_get_products": baseUrl + "product/list",
    "service_list_product": baseUrl + "product/productDetails",
    "service_add_product": baseUrl + "product/addDetails",
    "service_edit_product": baseUrl + "product/updateDetails",
    "service_delete_attachment": baseUrl + "product/deleteAttachment",
    "service_delete_products": baseUrl + "product/deleteMany",

    "service_add_manufacturer": baseUrl + "manufacturer/add",
    "service_edit_manufacturer": baseUrl + "manufacturer/update",
    "service_delete_manufacturer": baseUrl + "manufacturer/deleteMany",
    "service_list_manufacturer": baseUrl + "manufacturer/list",
    "service_list_all_manufacturer": baseUrl + "manufacturer/listAll",
    "service_delete_manufacturer_attachment": baseUrl + "manufacturer/deleteAttachment",

    "service_add_stock": baseUrl + "stock/add",
    "service_edit_stock": baseUrl + "stock/update",
    "service_delete_stock": baseUrl + "stock/deleteMany",
    "service_list_stock": baseUrl + "stock/list",

    "service_add_client": baseUrl + "client/create",
    "service_edit_client": baseUrl + "client/update",
    "service_delete_client": baseUrl + "client/deleteMany",
    "service_list_client": baseUrl + "client/list",
    "service_delete_client_attachment": baseUrl + "client/deleteAttachment",
    "service_token_expire": baseUrl + "auth/isTokenExpire",
    "service_stock_filter_data": baseUrl + "stock/inputFilterData",

    "service_product_model": baseUrl + "product/productModel",
    "service_product_dimensions": baseUrl + "product/dimensions",
    "service_product_pmax": baseUrl + "product/pmax",

    "service_product_filter_data": baseUrl + "product/filter"
}

export default apiURL;

