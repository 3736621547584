import { useState } from "react";

import {
    emailValidator,
    newPasswordValidator,
    verifyForgotCodeValidator,
} from "components/Validators/Validators.jsx";

const touchErrors = errors => {
    return Object.entries(errors).reduce((acc, [field, fieldError]) => {
        acc[field] = {
            ...fieldError,
            dirty: true,
        };
        return acc;
    }, {});
};

export const useConfirmPasswordValidator = form => {
    const [errors, setErrors] = useState({
        verifyForgotCode: {
            dirty: false,
            error: false,
            message: "",
        },
        email: {
            dirty: false,
            error: false,
            message: "",
        },
        newPassword: {
            dirty: false,
            error: false,
            message: "",
        },
    });

    const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
        let isValid = true;

        // Create a deep copy of the errors
        let nextErrors = JSON.parse(JSON.stringify(errors));

        // Force validate all the fields
        if (forceTouchErrors) {
            nextErrors = touchErrors(errors);
        }

        const { verifyForgotCode = '', email = '', newPassword = '' } = form;

        if (nextErrors.email.dirty && (field ? field === "email" : true)) {
            const emailMessage = emailValidator(email, form);
            nextErrors.email.error = !!emailMessage;
            nextErrors.email.message = emailMessage;
            if (!!emailMessage) isValid = false;
        }
        if (nextErrors.verifyForgotCode.dirty && (field ? field === "verifyForgotCode" : true)) {
            const verifyForgotCodeMessage = verifyForgotCodeValidator(verifyForgotCode, form);
            nextErrors.verifyForgotCode.error = !!verifyForgotCodeMessage;
            nextErrors.verifyForgotCode.message = verifyForgotCodeMessage;
            if (!!verifyForgotCodeMessage) isValid = false;
        }


        if (nextErrors.newPassword.dirty && (field ? field === "newPassword" : true)) {
            const newPasswordMessage = newPasswordValidator(newPassword, form);
            nextErrors.newPassword.error = !!newPasswordMessage;
            nextErrors.newPassword.message = newPasswordMessage;
            if (!!newPasswordMessage) isValid = false;
        }



        console.log("isValid", isValid)
        setErrors(nextErrors);
        return {
            isValid,
            errors: nextErrors,
        };
    };

    const onBlurField = e => {
        const field = e.target.name;
        const fieldError = errors[field];
        if (fieldError.dirty) return;

        const updatedErrors = {
            ...errors,
            [field]: {
                ...errors[field],
                dirty: true,
            },
        };

        validateForm({ form, field, errors: updatedErrors });
    };

    return {
        validateForm,
        onBlurField,
        errors,
    };
};