import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import 'css/product.css';
import { alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import apiURL from 'apiURL/apiURL';
import apiStatusCode from 'constants/apiStatusCode';
import axios from "axios";
import defaultString from 'constants/defaultString.json';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import AlertDialog from 'components/AlertDialog/AlertDialog';
import AgentNav from 'components/AgentNav/AgentNav';
import ClientNav from 'components/ClientNav/ClientNav';
import { resetToken } from 'apiURL';
import { AuthContext } from 'context/AuthContext';

export default function ClientList() {

    const { t } = useTranslation();
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchContent, setSearchContent] = useState('');
    const [open, setOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [userType, setUserRole] = useState();
    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
    const [limit, setLimit] = useState(10);
    const { dispatch } = useContext(AuthContext);

    let navigate = useNavigate();
    const params = useParams();

    function EnhancedTableHead(props) {
        const { t } = useTranslation();
        const { onSelectAllClick, numSelected, rowCount } = props;

        let headCells = [];

        if (params.type === 'private') {
            headCells = [
                {
                    id: 'name',
                    numeric: true,
                    disablePadding: false,
                    label: t('name'),
                },
                {
                    id: 'lastname',
                    numeric: true,
                    disablePadding: false,
                    label: t('lastName'),
                },
                {
                    id: 'fiscalcode',
                    numeric: true,
                    disablePadding: false,
                    label: t('fiscalCode'),
                },
                {
                    id: 'address',
                    numeric: true,
                    disablePadding: false,
                    label: t('address'),
                },
                {
                    id: 'shippingAddress',
                    numeric: true,
                    disablePadding: false,
                    label: t('shippingAddress'),
                },
                {
                    id: 'status',
                    numeric: true,
                    disablePadding: false,
                    label: t('status'),
                },
                {
                    id: 'download',
                    numeric: true,
                    disablePadding: false,
                    label: '',
                },
            ];
        }
        else if (params.type === "company") {
            headCells = [

                {
                    id: 'name',
                    numeric: true,
                    disablePadding: false,
                    label: t('companyName'),
                },
                {
                    id: 'vat',
                    numeric: true,
                    disablePadding: false,
                    label: t('vatNumber'),
                },
                {
                    id: 'fiscalcode',
                    numeric: true,
                    disablePadding: false,
                    label: t('fiscalCode'),
                },
                {
                    id: 'address',
                    numeric: true,
                    disablePadding: false,
                    label: t('address'),
                },

                {
                    id: 'shippingAddress',
                    numeric: true,
                    disablePadding: false,
                    label: t('shippingAddress'),
                },

                {
                    id: 'status',
                    numeric: true,
                    disablePadding: false,
                    label: t('status'),
                },

                {
                    id: 'statistic',
                    numeric: true,
                    disablePadding: false,
                    label: '',
                },

            ];
        }

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                        >
                            {headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    function EnhancedTableToolbar(props) {
        const { numSelected } = props;
        const { t } = useTranslation();
        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >

                    </Typography>
                )}

                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton onClick={deleteClient}>
                            <DeleteIcon color="Red" />
                        </IconButton>
                    </Tooltip>
                ) : (

                    <Container fluid className='d-flex justify-content-end add'>
                    </Container>

                )}
            </Toolbar>
        );
    }

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setSearchContent('');
    };

    const fnClientList = useCallback(async (type, page, searchText = '') => {
        let searchString = '';
        if (searchText !== '') {
            searchString = '&search_text=' + searchText
        }

        console.log('in fn limit', limit)

        let userType = 'Private'
        if (type === 'private') userType = 'Private'
        else if (type === 'company') userType = 'Company'
        const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken))
        await axios.get(apiURL.service_list_client + `?page=${page}&limit=${limit}&type=${userType}&search_text${searchString}`
            ,
            {
                'headers': {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                }
            }
        )
            .then((res) => {
                // console.log(res)
                setIsLoading(false)
                if (res.status === apiStatusCode.SUCCESS) {
                    console.log(res.data.data)
                    setRows(res.data.data.clients)
                    setTotalRecords(res.data.data.totalRecords)
                }
                else {
                    console.log('In else portion ', res.data);
                    setOpen(true);
                    setErrorMsg(res.data.message)
                }
            }).catch((error) => {
                setIsLoading(false)
                console.log(error)
                setOpen(true);
                if (error.code === defaultString.network_error) setErrorMsg(error.message)
                else if (error.code === defaultString.bad_request) {
                    if (error.response.data.status === 404) {
                        setOpen(false);
                      }
                      else if (error.response.data.status === 401) {
                        setErrorMsg(error.response.data.message);
                    }
                }
                if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
                    doLogout(error)
                }
                setRows([])
                setTotalRecords(0)
            });
    }, [limit]
    )

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n._id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        // console.log(selectedIndex)
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = async (event, newPage) => {
        setPage(newPage);
        await fnClientList(userType, newPage + 1);
        setSelected([]);
    };

    const handleChangeRowsPerPage = async (event) => {
        // setRowsPerPage(parseInt(event.target.value, 10));
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
        // console.log(event.target.value)
        // console.log(rowsPerPage)
        // console.log(parseInt(event.target.value, 10))
        setLimit(parseInt(event.target.value, 10))
        await fnClientList(userType, 1);
        setSelected([]);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


    const navigateToUpdate = (data, type) => {
        if (type === "private") {
            navigate('/agent/addprivateclient', { state: { data } })
        }
        else if (type === "company") {
            navigate('/agent/addcompanyclient', { state: { data } })
        }

    }

    const confirmDeleteProduct = async () => {
        console.log('selected:: ', selected)
        await fnDeleteClient();
        setOpenDeleteAlert(false)
    }

    const handleDeleteAlertClose = () => {
        setOpenDeleteAlert(false);
        setSelected([])
    };

    const deleteClient = () => {
        setOpenDeleteAlert(true)
    };

    const fnDeleteClient = async () => {

        const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken))

        const objClient = JSON.stringify({
            'clientIds': selected
        });

        console.log(objClient)

        await axios.post(apiURL.service_delete_client, objClient, {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        })
            .then(async (res) => {
                console.log(res.data);
                if (res.data.status === apiStatusCode.DELETED) {
                    setOpen(true);
                    setErrorMsg(res.data.message)



                    const filteredArr = rows.filter(item => !selected.includes(item._id));
                    setRows(filteredArr);
                    setSelected([])
                    await fnClientList(userType, page + 1);
                }
                else {
                    setOpen(true);
                    setErrorMsg(res.data.data.message)
                }

            }).catch((error) => {
                console.log(error.response)
                setOpen(true);
                if (error.code === defaultString.network_error) setErrorMsg(error.message)
                else setErrorMsg(error.response.data.message)
                if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
                    doLogout(error)
                }
            });
    }

    useEffect(() => {
        console.log(params.type)
        setUserRole(params.type)
        setIsLoading(true)
        fnClientList(params.type, page + 1);
    }, [fnClientList, params.type, page])

    const doLogout = async (resErr) => {
        const data = await resetToken()
        if (data === true) {
            dispatch({ type: "LOGOUT", payload: null })
            navigate('/', { state: { isModalPopup: true, errorMsg: resErr?.response?.data?.message } })
        }
    }

    return (
        <>
            <Container fluid className='stock'>
                <h2 className='text-center mb-3 border-bottom'>
                    <strong>{t('private') + " & " + t('company')}</strong>
                </h2>

                <Container fluid className='mb-3 table-navs'>
                    <ClientNav />
                </Container>

                {
                    isLoading ? <LoadingSpinner /> : (
                        <Box sx={{ width: '100%' }} className="main-area">
                            {/* <div className='filter mr-3'>
                <h2>{t('filter')}</h2>
                <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>{t('name')}</Accordion.Header>
                <Accordion.Body>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Option 1" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Option 2" />
                  </Form.Group>

                  <Form.Group className="" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Option 3" />
                  </Form.Group>

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>{t('lastName')}</Accordion.Header>
                <Accordion.Body>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Option 4" />
                  </Form.Group>

                  <Form.Group className="" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Option 5" />
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>{t('fiscalCode')}</Accordion.Header>
                <Accordion.Body>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Option 6" />
                  </Form.Group>

                  <Form.Group className="" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Option 7" />
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>
              
              <Accordion.Item eventKey="3">
                <Accordion.Header>{t('reward')}</Accordion.Header>
                <Accordion.Body>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Option 8" />
                  </Form.Group>

                  <Form.Group className="" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Option 9" />
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>{t('quotation')}</Accordion.Header>
                <Accordion.Body>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Option 8" />
                  </Form.Group>

                  <Form.Group className="" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Option 9" />
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>

    
              
              <div className="d-grid gap-2 mt-3">
              <Button variant="secondary" className='py-3'>{t('applyFilter')}</Button>
              </div>
            </Accordion>
              </div> */}

                            <Paper sx={{ mb: 2, width: '100% !important', overflow: 'hidden' }}>
                                <EnhancedTableToolbar numSelected={selected.length} />
                                <TableContainer sx={{ maxHeight: 440 }}>
                                    <Table stickyHeader aria-label="sticky table"
                                        sx={{ minWidth: 750 }}
                                        // aria-labelledby="tableTitle"
                                        size='medium'
                                    >
                                        <EnhancedTableHead
                                            numSelected={selected.length}
                                            onSelectAllClick={handleSelectAllClick}
                                            rowCount={rows.length}
                                        />
                                        {
                                            (params.type === "private") &&
                                            <TableBody>
                                                {rows && rows
                                                    .map((row, index) => {

                                                        const isItemSelected = isSelected(row._id);
                                                        const labelId = `enhanced-table-checkbox-${index}`;

                                                        return (
                                                            <TableRow
                                                                hover

                                                                type="checkbox"
                                                                aria-checked={isItemSelected}
                                                                tabIndex={-1}
                                                                key={index}
                                                                selected={isItemSelected}
                                                                sx={{ textDecoration: "none" }}>

                                                                <TableCell padding="checkbox">
                                                                    <Checkbox
                                                                        onClick={(event) => handleClick(event, row._id)}
                                                                        color="primary"
                                                                        checked={isItemSelected}
                                                                        inputProps={{
                                                                            'aria-labelledby': labelId,
                                                                        }}
                                                                    />
                                                                </TableCell>



                                                                <TableCell align="right">
                                                                    <p>{row.firstName}</p>
                                                                </TableCell>

                                                                <TableCell align="right">
                                                                    <p>{row.lastName}</p>
                                                                </TableCell>

                                                                <TableCell align="right"><p>{row.fiscalCode ? 'x'.repeat(row.fiscalCode.length) : ''}</p></TableCell>

                                                                <TableCell align="right"><p>{row.address}</p></TableCell>

                                                                <TableCell align="right"><p>{row.shippingAddress}</p></TableCell>



                                                                <TableCell align="right" className='ms-auto'>
                                                                    <p>{row.status}</p>
                                                                </TableCell>

                                                                <TableCell align="right" className='stock'>
                                                                    <div className='d-flex no-wrap justify-content-end'>
                                                                        <Button variant='contained' size='medium' color='success'
                                                                            sx={{
                                                                                color: "#FD841F",
                                                                                backgroundColor: "#FEF1E6",
                                                                                border: 'none',
                                                                                padding: "12px 18px",
                                                                                fontWeight: 700,
                                                                                fontSize: '16px',
                                                                                boxShadow: "none",
                                                                                "&:hover": {
                                                                                    color: 'white',
                                                                                    backgroundColor: '#FD841F',
                                                                                    cursor: 'pointer',
                                                                                },
                                                                            }}>
                                                                            {t('statistics')}
                                                                        </Button>

                                                                        <Button variant='contained' size='medium' color='success' className='ms-3' onClick={() => { navigateToUpdate(row, 'private') }}
                                                                            sx={{
                                                                                color: "#FD841F",
                                                                                backgroundColor: "#FEF1E6",
                                                                                border: 'none',
                                                                                padding: "12px 18px",
                                                                                fontWeight: 700,
                                                                                fontSize: '16px',
                                                                                boxShadow: "none",
                                                                                "&:hover": {
                                                                                    color: 'white',
                                                                                    backgroundColor: '#FD841F',
                                                                                    cursor: 'pointer',
                                                                                },
                                                                            }}>
                                                                            {t('edit')}
                                                                        </Button>
                                                                    </div>
                                                                </TableCell>

                                                            </TableRow>
                                                        );
                                                    })}

                                            </TableBody>
                                        }
                                        {
                                            (params.type === "company") &&

                                            <TableBody>
                                                {rows
                                                    .map((row, index) => {

                                                        const isItemSelected = isSelected(row._id);
                                                        const labelId = `enhanced-table-checkbox-${index}`;

                                                        return (
                                                            <TableRow
                                                                hover

                                                                type="checkbox"
                                                                aria-checked={isItemSelected}
                                                                tabIndex={-1}
                                                                key={index}
                                                                selected={isItemSelected}
                                                                sx={{ textDecoration: "none" }}>

                                                                <TableCell padding="checkbox">
                                                                    <Checkbox
                                                                        onClick={(event) => handleClick(event, row._id)}
                                                                        color="primary"
                                                                        checked={isItemSelected}
                                                                        inputProps={{
                                                                            'aria-labelledby': labelId,
                                                                        }}
                                                                    />
                                                                </TableCell>



                                                                <TableCell align="right">
                                                                    <p>{row.companyName}</p>
                                                                </TableCell>

                                                                <TableCell align="right">
                                                                    <p>{row.vat ? 'x'.repeat(row.vat.length) : ''}</p>
                                                                </TableCell>

                                                                <TableCell align="right"><p>{row.fiscalCode ? 'x'.repeat(row.fiscalCode.length) : ''}</p></TableCell>

                                                                <TableCell align="right"><p>{row.address}</p></TableCell>

                                                                <TableCell align="right">
                                                                    <p>{row.shippingAddress}</p>
                                                                </TableCell>

                                                                <TableCell align="right" className='ms-auto'>
                                                                    <p>{row.status}</p>
                                                                </TableCell>

                                                                <TableCell align="right" className='stock'>
                                                                    <div className='d-flex no-wrap justify-content-end'>
                                                                        <Button variant='contained' size='medium' color='success'
                                                                            sx={{
                                                                                color: "#FD841F",
                                                                                backgroundColor: "#FEF1E6",
                                                                                border: 'none',
                                                                                padding: "12px 18px",
                                                                                fontWeight: 700,
                                                                                fontSize: '16px',
                                                                                boxShadow: "none",
                                                                                "&:hover": {
                                                                                    color: 'white',
                                                                                    backgroundColor: '#FD841F',
                                                                                    cursor: 'pointer',
                                                                                },
                                                                            }}>
                                                                            {t('statistics')}
                                                                        </Button>

                                                                        <Button variant='contained' size='medium' color='success' className='ms-3' onClick={() => { navigateToUpdate(row, 'company') }}
                                                                            sx={{
                                                                                color: "#FD841F",
                                                                                backgroundColor: "#FEF1E6",
                                                                                border: 'none',
                                                                                padding: "12px 18px",
                                                                                fontWeight: 700,
                                                                                fontSize: '16px',
                                                                                boxShadow: "none",
                                                                                "&:hover": {
                                                                                    color: 'white',
                                                                                    backgroundColor: '#FD841F',
                                                                                    cursor: 'pointer',
                                                                                },
                                                                            }}>
                                                                            {t('edit')}
                                                                        </Button>
                                                                    </div>
                                                                </TableCell>

                                                            </TableRow>
                                                        );
                                                    })}

                                            </TableBody>
                                        }


                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    // rowsPerPageOptions={[]}
                                    component="div"
                                    count={totalRecords}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>


                        </Box>
                    )
                }

            </Container>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleAlertClose} sx={{ width: '100%' }}>
                <Alert onClose={handleAlertClose} severity="error" >
                    {errorMsg}
                </Alert>
            </Snackbar>

            <AlertDialog open={openDeleteAlert} title={selected.length === 1 ? "Delete Client" : "Delete Clients"} message={selected.length === 1 ? "Are you sure you want to delete the client?" : "Are you sure you want to delete the clients?"} handleAction={confirmDeleteProduct} handleClose={handleDeleteAlertClose}></AlertDialog>
        </>
    );
}