import React, { useState, useMemo, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';
import Container from "react-bootstrap/Container";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa";
import countryList from 'react-select-country-list'
import Snackbar from '@mui/material/Snackbar';
import apiURL from 'apiURL/apiURL';
import MuiAlert from '@mui/material/Alert';
import AlertDialog from 'components/AlertDialog/AlertDialog';

import defaultString from 'constants/defaultString.json';
import { MdCancel } from 'react-icons/md';
import { useAddCompanyClientValidator } from 'hooks/useAddCompanyClientValidator';
import apiStatusCode from 'constants/apiStatusCode';
import axios from "axios";
import { AuthContext } from 'context/AuthContext';
import { resetToken } from 'apiURL';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddCompanyClient = (props) => {

    const { t } = useTranslation();
    const [isEdit, setIsEdit] = useState(false);
    const [sdi, setSdi] = useState('');
    const [pec, setPec] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [open, setOpen] = useState(false);
    const [country, setCountry] = useState('Italy');
    const [shippingCountry, setShippingCountry] = useState('Italy');
    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
    const [attachmentId, setAttachmentId] = useState('');
    const [documents, setDocuments] = useState([]);
    const [attachType, setAttachType] = useState("");
    const [shippingPostCode, setShippingPostCode] = useState('');
    const [postCode, setPostCode] = useState('');
    const [note, setNote] = useState('');
    const [status, setStatus] = useState(false);
    const [indexToBeDelete, setIndexToBeDelete] = useState('');
    const [isDisabledSaveBtn, setIsDisabledSaveBtn] = useState(false)
    const [isDisabledDeleteBtn, setIsDisabledDeleteBtn] = useState(false)
    const { dispatch } = useContext(AuthContext);
    const options = useMemo(() => countryList().getData(), [])
    const navigate = useNavigate();
    const { state } = useLocation();

    const [form, setForm] = useState({
        companyName: '',
        fiscalCode: '',
        vat: '',
        address: '',
        shippingAddress: ''
    });
    const { errors, validateForm, onBlurField } = useAddCompanyClientValidator(form);
    const onUpdateField = e => {
        const field = e.target.name;
        const nextFormState = {
            ...form,
            [field]: e.target.value,
        };
        setForm(nextFormState);

        if (errors[field].dirty)
            validateForm({
                form: nextFormState,
                errors,
                field,
            });
    };

    useEffect(() => {

        if (state != null) {
            console.log('state: ', state)
            if (state.data != null) {

                setIsEdit(true)

                setForm({
                    companyName: state.data.companyName,
                    vat: state.data.vat,
                    fiscalCode: state.data.fiscalCode,
                    address: state.data.address,
                    shippingAddress: state.data.shippingAddress
                })
                let prodStatus = (state.data.status === 'Active') ? true : false
                setStatus(prodStatus);
                setShippingCountry(state.data.shippingCountry)
                setCountry(state.data.country)
                setPostCode(state.data.postalCode)
                setShippingPostCode(state.data.shippingPostalCode)
                setSdi(state.data.sdi);
                setPec(state.data.pec);
                setNote(state.data.note);


                let uploadedDocArray = []
                if (state.data.documents.length > 0) {
                    for (let i = 0; i < state.data.documents.length; i++) {
                        uploadedDocArray.push(state.data.documents[i])
                    }
                    setDocuments(uploadedDocArray)
                }

            }
        }
    }, [state])





    const handleDocuments = (event) => {

        let attachmentObj = [];
        attachmentObj.push(event.target.files);

        for (let i = 0; i < attachmentObj[0].length; i++) {
            let docuObj = {};
            docuObj.id = 0;
            docuObj.document = attachmentObj[0][i];

            setDocuments(x => [...x, docuObj]);
        }
    };


    const handleDeleteAlertClose = () => {
        setOpenDeleteAlert(false);
        setAttachmentId('')
        setAttachType('')
        setIndexToBeDelete('')
    };
    const confirmDeleteItem = async () => {
        console.log('attachmentId:: ', attachmentId)
        setOpenDeleteAlert(false)
        if (attachType === "document") {
            console.log('indexToBeDelete: ', indexToBeDelete)
            if (attachmentId !== 0) {
                await fnDeleteAttachment(attachmentId);
            }
            else {
                const filteredArr = documents.filter((item, index) => index !== indexToBeDelete);
                setDocuments(filteredArr);
            }

        }
        else {

            await fnDeleteClient(attachmentId);

        }
    }


    const deleteItem = (itemId, index, attachmentType) => {
        // const s = attachments.filter((item, index) => index !== e);
        // setAttachments(s);
        // console.log(attachments)
        // console.log(s);
        setOpenDeleteAlert(true)
        setAttachmentId(itemId)
        setIndexToBeDelete(index)
        setAttachType(attachmentType)
    };


    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    const fnAddUser = async (e) => {

        e.preventDefault();
        const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken))
        const { isValid } = validateForm({ form, errors, forceTouchErrors: true });
        if (!isValid) {
            setOpen(true);
            setErrorMsg(defaultString.validData)
            return
        };

        if (documents.length > 5) {
            setOpen(true);
            setErrorMsg(defaultString.maxDocumentLimitMsg)
            return
        }


        if (form.companyName && form.fiscalCode && form.vat && form.address && form.shippingAddress) {

            const formData = new FormData();
            const user = JSON.parse(localStorage.getItem('user'));



            //     console.log(documents)
            if (documents.length > 0) {
                documents.map((image, i) => {
                    if (!!image.document) formData.append('document', image.document);
                })
            }
            setIsDisabledSaveBtn(true)
            console.log(status)
            let prodStatus = (status === true) ? 'Active' : 'InActive'
            console.log(prodStatus)

            formData.append('status', prodStatus)
            formData.append('companyName', form.companyName)
            formData.append('type', 'Company');
            formData.append('fiscalCode', form.fiscalCode)
            formData.append('address', form.address)
            formData.append('shippingAddress', form.shippingAddress)
            formData.append('country', country)
            formData.append('shippingCountry', shippingCountry)
            formData.append('postalCode', postCode)
            formData.append('vat', form.vat)
            formData.append('shippingPostalCode', shippingPostCode)
            formData.append('note', note)
            formData.append('sdi', sdi)
            formData.append('pec', pec)
            formData.append('createdby', user?._id)

            await axios.post(apiURL.service_add_client, formData, {
                'headers': {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': token
                }
            })
                .then((res) => {
                    console.log(res.data);
                    if (res?.status === apiStatusCode.CREATED) {
                        setOpen(true);
                        navigate(`/agent/client-list/company`);
                    }
                    else {
                        console.log('In else portion ', res.data);
                        setOpen(true);
                        setIsDisabledSaveBtn(false)
                        setErrorMsg(res?.data?.data?.message)
                    }
                }).catch((error) => {
                    console.log(error?.response)
                    setOpen(true);
                    setIsDisabledSaveBtn(false)
                    if (error?.code === defaultString.network_error) setErrorMsg(error?.message)
                    else setErrorMsg(error?.response?.data?.message)
                    if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
                        doLogout(error)
                    }
                });
        }
        else {
            setOpen(true);
            setErrorMsg(defaultString.validData)
        }

    }


    const fnEditUser = async (userId) => {

        // e.preventDefault();
        const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken))


        // if (!isValid) {
        //     setOpen(true);
        //     setErrorMsg(defaultString.validData)
        //     console.log(isValid);
        //     return
        // };

        if (form.companyName && form.fiscalCode && form.vat && form.address && form.shippingAddress) {
            if (documents.length > 5) {
                setOpen(true);
                setErrorMsg(defaultString.maxDocumentLimitMsg)
                return
            }

            const formData = new FormData();



            console.log(documents)
            if (documents.length > 0) {
                documents.map((image, i) => {
                    if (!!image.document && image.id === 0) formData.append('document', image.document);
                })
            }

            let prodStatus = (status === true) ? 'Active' : 'InActive'
            console.log(prodStatus)
            setIsDisabledSaveBtn(true)
            formData.append('status', prodStatus)
            formData.append('companyName', form.companyName)
            formData.append('fiscalCode', form.fiscalCode)
            formData.append('address', form.address)
            formData.append('shippingAddress', form.shippingAddress)
            formData.append('country', country)
            formData.append('shippingCountry', shippingCountry)
            formData.append('postalCode', postCode)
            formData.append('vat', form.vat)
            formData.append('shippingPostalCode', shippingPostCode)
            formData.append('note', note)
            formData.append('sdi', sdi)
            formData.append('pec', pec)

            await axios.put(`${apiURL.service_edit_client}/${userId}`, formData, {
                'headers': {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': token
                }
            })
                .then((res) => {
                    console.log(res.data);
                    if (res?.status === apiStatusCode.SUCCESS) {
                        setOpen(true);
                        setIsEdit(false)
                        navigate(`/agent/client-list/company`);
                    }
                    else {
                        console.log('In else portion ', res.data);
                        setOpen(true);
                        setIsDisabledSaveBtn(false)
                        setErrorMsg(res?.data?.data?.message)
                    }
                }).catch((error) => {
                    console.log(error.response)
                    setOpen(true);
                    setIsDisabledSaveBtn(false)
                    if (error.code === defaultString.network_error) setErrorMsg(error?.message)
                    else setErrorMsg(error?.response?.data?.message)
                    if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
                        doLogout(error)
                    }
                });
        }
        else {
            setOpen(true);
            setErrorMsg(defaultString.validData)
        }

    }


    const fnDeleteAttachment = async (attachmentId) => {

        const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken))

        await axios.delete(apiURL.service_delete_client_attachment + `/${attachmentId}`, {
            'headers': {
                'Authorization': token
            }
        })
            .then((res) => {
                console.log(res.data);
                if (res?.data?.status === apiStatusCode.DELETED) {
                    setOpen(true);
                    setErrorMsg(res?.data?.message)


                    if (attachType == 'document') {
                        const filteredArr = documents.filter((item) => item.id !== attachmentId);
                        setDocuments(filteredArr);
                    }

                    setAttachmentId('')
                }
                else {
                    setOpenDeleteAlert(false)
                    console.log('In else portion ', res.data);
                    setOpen(true);
                    setErrorMsg(res?.data?.data?.message)
                }
            }).catch((error) => {
                console.log(error.response)
                setOpen(true);
                if (error?.code === defaultString.network_error) setErrorMsg(error?.message)
                else setErrorMsg(error?.response?.data?.message)
                if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
                    doLogout(error)
                }
            });

    }


    const fnDeleteClient = async (attachmentId) => {

        const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken))
        console.log('token ', token)
        const objClient = JSON.stringify({
            'clientIds': [attachmentId]
        });

        console.log(objClient)
        setIsDisabledDeleteBtn(true)
        await axios.post(apiURL.service_delete_client, objClient, {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        })
            .then((res) => {
                console.log(res.data);
                if (res?.data?.status === apiStatusCode.DELETED) {
                    setOpen(true);
                    setErrorMsg(res?.data?.message)
                    setAttachmentId('')
                    navigate(`/agent/client-list/company`);
                }
                else {
                    console.log('In else portion ', res.data);
                    setOpen(true);
                    setIsDisabledDeleteBtn(false)
                    setErrorMsg(res?.data?.data?.message)
                }

            }).catch((error) => {
                console.log(error.response)
                setOpen(true);
                setIsDisabledDeleteBtn(false)
                if (error?.code === defaultString.network_error) setErrorMsg(error?.message)
                else setErrorMsg(error?.response?.data?.message)
                if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
                    doLogout(error)
                }
            });

    }




    const onStatusChange = () => {
        setStatus(!status);
    };

    const doLogout = async (resErr) => {
        const data = await resetToken()
        if (data === true) {
            dispatch({ type: "LOGOUT", payload: null })
            navigate('/', { state: { isModalPopup: true, errorMsg: resErr?.response?.data?.message } })
        }
    }

    return (
        <>
            {/* <div className="costome-carousel"> */}
            <Container fluid className='mt-5 fixed-top main-section'>
                <Row className='border-bottom pb-2 backBar'>
                    <Col className='d-flex align-items-center'>
                        <Link to="/agent/client-list/company">
                            <FaArrowLeft fontSize={24} />
                            <span className='ms-2'>{t('back') + " " + t('to') + " " + t('companyAgent')}</span>
                        </Link>
                    </Col>
                    <Col className='text-center'><h3><strong>
                        {(isEdit ? t('edit') : t('add')) + " " + t('CompanyAgent')}</strong></h3></Col>
                    <Col className='text-end'></Col>

                </Row>
            </Container>
            <Container className='py-5 mt-5 section-adminform'>



                <h3 className='form-title'>{t('status')}</h3>
                <Form>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        name='status'
                        checked={status}
                        value={status}
                        onChange={onStatusChange}
                    />
                </Form>

                <h3 className='form-title mt-5'>{t('documents')}</h3>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>{t('uploadCompanyProfile')}</Form.Label>
                    <Form.Control type="file" onChange={handleDocuments} multiple accept='application/pdf,.doc,.docx' />
                    {documents.length > 0 &&
                        documents.map((item, index) => {
                            return (
                                !!item.document &&
                                <div key={index} className='img-list'>
                                    {
                                        item.id === 0
                                            ?
                                            <h6 className='mt-1 ms-2'>{item.document.name}</h6>
                                            :
                                            <h6 className='mt-1 ms-2'>{item.document.split("/companyProfile/")[1]}</h6>
                                    }
                                    <MdCancel fontSize={20} className='doc-icon' onClick={() => deleteItem(item.id, index, "document")} />
                                </div>
                            );
                        })}


                </Form.Group>

                <h3 className='form-title mt-5'>{t('info')}</h3>
                <Form.Group controlId="formFile" className="mb-3">
                    <FloatingLabel
                        controlId="floatingInput"
                        label={t('companyName')}
                        className="mb-3 mt-3"
                    >
                        <Form.Control type="text" placeholder={t('companyName')}
                            value={form.companyName}
                            name="companyName"
                            onChange={onUpdateField}
                            onBlur={onBlurField} required />
                    </FloatingLabel>

                    {errors.companyName.dirty && errors.companyName.error ? (
                        <p className='productErrorMessage'>{errors.companyName.message}</p>
                    ) : null}

                    <FloatingLabel
                        controlId="floatingInput"
                        label={t('vatNumber')}
                        className="mb-3 mt-3"
                    >
                        <Form.Control type="text" placeholder={t('vatNumber')}
                            value={form.vat}
                            name="vat"
                            onChange={onUpdateField}
                            onBlur={onBlurField} required />
                    </FloatingLabel>

                    {errors.vat.dirty && errors.vat.error ? (
                        <p className='productErrorMessage'>{errors.vat.message}</p>
                    ) : null}

                    <FloatingLabel
                        controlId="floatingInput"
                        label={t('fiscalCode')}
                        className="mb-3 mt-3"
                    >
                        <Form.Control type="text" placeholder={t('fiscalCode')}
                            value={form.fiscalCode}
                            name="fiscalCode"
                            onChange={onUpdateField}
                            onBlur={onBlurField} required />
                    </FloatingLabel>

                    {errors.fiscalCode.dirty && errors.fiscalCode.error ? (
                        <p className='productErrorMessage'>{errors.fiscalCode.message}</p>
                    ) : null}

                    <FloatingLabel
                        controlId="floatingInput"
                        label={t('SDI')}
                        className="mb-3 mt-3"
                    >
                        <Form.Control type="text" placeholder={t('SDI')}
                            onChange={(e) => setSdi(e.target.value)} value={sdi} />
                    </FloatingLabel>

                    <FloatingLabel
                        controlId="floatingInput"
                        label={t('PEC')}
                        className="mb-3 mt-3"
                    >
                        <Form.Control type="calendar" placeholder={t('PEC')}
                            onChange={(e) => setPec(e.target.value)} value={pec} />
                    </FloatingLabel>

                    <FloatingLabel
                        controlId="floatingInput"
                        label={t('address')}
                        className="mb-3 mt-3"
                    >
                        <Form.Control type="text" placeholder={t('address')}
                            value={form.address}
                            name="address"
                            onChange={onUpdateField}
                            onBlur={onBlurField} required />
                    </FloatingLabel>

                    {errors.address.dirty && errors.address.error ? (
                        <p className='productErrorMessage'>{errors.address.message}</p>
                    ) : null}

                    <FloatingLabel
                        controlId="floatingInput"
                        label={t('postCode')}
                        className="mb-3 mt-3"
                    >
                        <Form.Control placeholder={t('postCode')}
                            onChange={(e) => setPostCode(e.target.value)} value={postCode} />
                    </FloatingLabel>

                    <FloatingLabel controlId="floatingSelect" label={t('country')}>
                        <Form.Select aria-label="Floating label select example" value={country} onChange={(e) => setCountry(e.target.value)}>
                            {
                                options.map((value) =>
                                    <option key={value.value}>{value.label}</option>
                                )
                            }
                        </Form.Select>
                    </FloatingLabel>

                </Form.Group>

                <h3 className='form-title mt-5'>{t('shipping') + " " + t('address')}</h3>
                <Form.Group controlId="formFile" className="mb-3">

                    <FloatingLabel
                        controlId="floatingInput"
                        label={t('address')}
                        className="mb-3 mt-3"
                    >
                        <Form.Control type="text" placeholder={t('address')}
                            value={form.shippingAddress}
                            name="shippingAddress"
                            onChange={onUpdateField}
                            onBlur={onBlurField} required />
                    </FloatingLabel>

                    {errors.shippingAddress.dirty && errors.shippingAddress.error ? (
                        <p className='productErrorMessage'>{errors.shippingAddress.message}</p>
                    ) : null}

                    <FloatingLabel
                        controlId="floatingInput"
                        label={t('postCode')}
                        className="mb-3 mt-3"
                    >
                        <Form.Control type="text" placeholder={t('postCode')}
                            onChange={(e) => setShippingPostCode(e.target.value)} value={shippingPostCode} />
                    </FloatingLabel>

                    <FloatingLabel controlId="floatingSelect" label={t('country')}>
                        <Form.Select aria-label="Floating label select example" value={shippingCountry} onChange={(e) => setShippingCountry(e.target.value)}>
                            {
                                options.map((value) =>
                                    <option key={value.value}>{value.label}</option>
                                )
                            }
                        </Form.Select>
                    </FloatingLabel>

                    <FloatingLabel
                        controlId="floatingInput"
                        label={t('note')}
                        className="mb-3 mt-3"
                    >
                        <Form.Control type="text" placeholder={t('note')}
                            onChange={(e) => setNote(e.target.value)} value={note} />
                    </FloatingLabel>

                </Form.Group>

                <Row className='mt-5'>
                    <Col>
                        <div className="d-grid gap-2">
                            <Button variant="success" size="lg" onClick={isEdit ? () => fnEditUser(state.data._id) : fnAddUser} disabled={isDisabledSaveBtn}>
                                {t('save')}
                            </Button>
                        </div>
                    </Col>
                    {
                        isEdit &&
                        <Col>

                            <div className="d-grid gap-2">
                                <Button variant="danger" size="lg" disabled={isDisabledDeleteBtn} onClick={() => deleteItem(state.data._id, 0,)}>
                                    {t('delete')}
                                </Button>
                            </div>

                        </Col>
                    }

                </Row>
            </Container>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleAlertClose} sx={{ width: '100%' }}>
                <Alert onClose={handleAlertClose} severity="error" >
                    {errorMsg}
                </Alert>
            </Snackbar>

            <AlertDialog open={openDeleteAlert} title={attachType === 'document' ? "Delete Attachment" : "Delete Client"} message={attachType === 'document' ? "Are you sure you want to delete the attachment? " : "Are you sure you want to delete the client ?"} handleAction={confirmDeleteItem} handleClose={handleDeleteAlertClose}></AlertDialog>

        </>
    );
};

export default AddCompanyClient;