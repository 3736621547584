import React, { useContext, useState } from "react";
import { useLocation, Link } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Table from "react-bootstrap/Table";
import Nav from "react-bootstrap/Nav";
import SubNav from "components/SubNav/SubNav";
import { useTranslation } from "react-i18next";
import "css/ProductDetails.css";
import { Container } from "react-bootstrap";
import apiURL from "apiURL/apiURL.js";
import { FaCartArrowDown } from "react-icons/fa";
import Grid from "@mui/material/Grid";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { Style } from "@mui/icons-material";
import { saveAs } from "file-saver";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  ButtonGroup,
  Divider,
  Drawer,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { AuthContext } from "context/AuthContext";

const ProductDetails = () => {
  const { cartList, doSetCartData } = useContext(AuthContext);
  const location = useLocation();
  // console.log(location);
  const { state } = location;

  const [qty, setQty] = useState(1);
  const [stateDrawer, setStateDrawer] = React.useState({
    left: false,
    LeftMain: false,
    right: false,
  });
  const [subTotal, setSubTotal] = useState("0");
  const { t } = useTranslation();

  let logoImg;
  if (!!state.row.mainImage.logo) {
    logoImg = {
      original: apiURL.siteBaseUrl + state.row.mainImage.logo,
      thumbnail: apiURL.siteBaseUrl + state.row.mainImage.logo,
      id: state.row.mainImage.id,
    };
  } else {
    logoImg = {
      original: "/images/icons/no-image.png",
      thumbnail: "/images/icons/no-image.png",
      id: 0,
    };
  }

  let arrGallery = [];
  if (state.row.otherImages.length > 0) {
    let otherImgs = [];
    //  console.log(apiURL.siteBaseUrl);
    // state.row.otherImages.map((img) =>{
    //     let objImg =img;
    //     img.original = apiURL.siteBaseUrl + objImg.original;
    //     img.thumbnail = apiURL.siteBaseUrl + objImg.thumbnail;
    //     //objImg = img;
    //     //console.log(objImg);
    //     otherImgs.push(img);
    // });

    for (let i = 0; i < state.row.otherImages.length; i++) {
      let objImg = state.row.otherImages[i];
      let obj = {};
      obj.original = apiURL.siteBaseUrl + objImg.original;
      obj.thumbnail = apiURL.siteBaseUrl + objImg.thumbnail;
      // console.log(obj)
      otherImgs.push(obj);
    }
    // console.log(otherImgs);

    arrGallery = [logoImg, ...otherImgs];
  } else {
    arrGallery = [logoImg];
  }

  let allDocuments = [];

  if (state.row.documents.length > 0) {
    state.row.documents.map((x) => allDocuments.push(x));
  }
  if (state.row.manufacturerDocuments.length > 0) {
    state.row.manufacturerDocuments.map((x) => allDocuments.push(x));
  }

  // console.log(allDocuments);

  const onDownloadClick = (documents) => {
    if (documents.length > 0) {
      var JSzip = require("jszip");
      var zip = new JSzip();
      const otherFiles = zip.folder("files");
      const list = documents.map(async (doc, index) => {
        const fileUrl = apiURL.siteBaseUrl + doc.document;

        const response = await fetch(fileUrl);
        const data = await response.blob();
        console.log(data);

        otherFiles.file(`document_${index + 1}.pdf`, data);
        // if (doc.document.includes('productDocument')) {
        //     otherFiles.file(`${doc.document.split("\\productDocument\\")[1]}`, data);
        // }
        // else if (doc.document.includes('manufacturerDocument')){
        //     otherFiles.file(`${doc.document.split("\\manufacturerDocument\\")[1]}`, data);
        // }

        return data;
      });

      // If all prmises are fullfilled it will call the zip
      // function and download it using the FileSaver library
      Promise.all(list).then(function () {
        zip.generateAsync({ type: "blob" }).then(function (content) {
          saveAs(content, "files");
        });
      });
    } else {
      //   setOpen(true)
      //   setErrorMsg('No file to be download')
    }
  };

  const toggleDrawer = (anchor, open, row) => {
    // console.log(JSON.stringify(event));
    setStateDrawer({ ...stateDrawer, [anchor]: open });
  };

  const toggleDrawerClose = (anchor, open) => {
    setStateDrawer({ ...stateDrawer, [anchor]: open });
  };

  const handleIncrement = (selectedItemMain) => {
    const newList = [...cartList];
    newList.forEach((item) => {
      if (item._id === selectedItemMain._id) {
        item.productCount = item.productCount + 1;
        item.totalPrice = item.productCount * item.price;
      }
    });

    let total = 0;
    for (let i = 0; i < newList.length; i++) {
      total += newList[i].totalPrice;
    }

    setSubTotal(total);
    doSetCartData(newList);
  };

  const handleDecrement = (selectedItemMain) => {
    const newList = [...cartList];
    newList.forEach((item) => {
      if (item._id === selectedItemMain._id && item.productCount > 0) {
        item.productCount = item.productCount - 1;
        item.totalPrice = item.productCount * item.price;
      }
    });

    let total = 0;
    for (let i = 0; i < newList.length; i++) {
      total += newList[i].totalPrice;
    }

    setSubTotal(total);
    doSetCartData(newList);
  };

  const doAddSelectedItem = (mainItem) => {
    console.log(" mainItem ::: ", JSON.stringify(mainItem));
    const oldData = [...cartList];
    const newList = [];

    const selectedItemDataOrList = cartList.some(
      (item) => mainItem._id === item._id
    );

    if (oldData.length > 0 && selectedItemDataOrList) {
      oldData.forEach((item) => {
        if (item._id === mainItem._id) {
          newList.push({
            ...item,
            productCount: item.productCount + 1,
            totalPrice: (item.productCount + 1) * item.price,
          });
        } else {
          newList.push(item);
        }
      });
    } else {
      oldData.forEach((item) => {
        newList.push(item);
      });
      newList.push({
        ...mainItem,
        productCount: 1,
        totalPrice: mainItem.price,
      });
    }

    let total = 0;

    for (let i = 0; i < newList.length; i++) {
      total += newList[i].totalPrice;
    }

    setSubTotal(total);
    doSetCartData(newList);
    toggleDrawer("right", true, mainItem);
  };

  const listcart = (anchor, item) => {
    return (
      <Box
        sx={{ width: 500 }}
        role="presentation"
        onClick={() => {
          toggleDrawer(anchor, true);
        }}
        onKeyDown={() => {
          toggleDrawer(anchor, true);
        }}
      >
        {cartList.map((item, index) => {
          return (
            <Box sx={{ padding: "12px 32px" }}>
              <Box sx={{ display: "flex", gap: 5, my: 2 }}>
                <Box>
                  <img
                    style={{
                      width: "80px",
                      height: "80px",
                      objectFit: "cover",
                    }}
                    variant="top"
                    src={apiURL.siteBaseUrl + item.mainImage.logo}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "var(--secondary-color)",
                        }}
                      >
                        {item.modelNo}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "var(--secondary-color)",
                        }}
                      >
                        {item.manufacturerId.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "var(--secondary-color)",
                          lineHeight: "24px",
                          fontWeight: 700,
                        }}
                      >
                        {`€${item.totalPrice}`}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ButtonGroup
                      size="small"
                      variant="contained"
                      aria-label="outlined button group"
                    >
                      <Button
                        onClick={() => {
                          handleDecrement(item);
                        }}
                        style={{
                          borderRadius: "0",
                          backgroundColor: "transparent",
                          border: "none",
                          color: "black",
                        }}
                      >
                        -
                      </Button>
                      <Button
                        style={{
                          borderRadius: "0",
                          backgroundColor: "transparent",
                          border: "none",
                          color: "black",
                        }}
                      >
                        {item.productCount}
                      </Button>
                      <Button
                        style={{
                          borderRadius: "0",
                          backgroundColor: "transparent",
                          border: "none",
                          color: "black",
                        }}
                        onClick={() => {
                          handleIncrement(item);
                        }}
                      >
                        +
                      </Button>
                    </ButtonGroup>
                    <Box
                      sx={{
                        marginLeft: 2,
                      }}
                    >
                      <Tooltip title={t("delete")}>
                        <DeleteRoundedIcon />
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}

        <Box
          sx={{
            position: "fixed",
            zIndex: 999,
            bottom: 0,
            width: 500,
            backgroundClolor: "white",
          }}
        >
          <Box sx={{ borderTop: "1px solid #00000030" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "6px 32px",
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "var(--secondary-color)",
                }}
              >
                SubTotal
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                €{subTotal}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "6px 32px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "var(--secondary-color)",
                }}
              >
                Shipping
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                --
              </Typography>
            </Box>
          </Box>
          <Box sx={{ borderTop: "1px solid #00000030" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "6px 32px",
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "var(--secondary-color)",
                }}
              >
                Total(€)
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                €{subTotal}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "6px 32px",
                mb: 2,
                mt: 1,
              }}
            >
              <Button
                size="medium"
                color="primary"
                style={{
                  width: "100%",
                  backgroundColor: "var(--primary-color)",
                  border: "none",
                  "&:hover": {
                    backgroundColor: "var(--primary-color-hover)",
                  },
                }}
              >
                Confirm Order
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <div>
      <Container fluid className="py-5 mt-5 section-productdetails">
        {state.row.productId._id === localStorage.getItem("solarpanel") && (
          <Container style={{ position: "relative", marginTop: "24px" }}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={5} className="gallery-container">
                <ImageGallery
                  items={arrGallery}
                  useBrowserFullscreen={true}
                  showPlayButton={false}
                />
              </Col>

              <Col xs={12} sm={12} md={6} lg={7}>
                <Breadcrumb style={{ marginTop: "48px" }}>
                  <Breadcrumb.Item href="/">{t("home")}</Breadcrumb.Item>
                  <Breadcrumb.Item
                    href={`/product/${localStorage.getItem("solarpanel")}`}
                  >
                    {t("solarPanel")}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {state.row.manufacturerId.name} {state.row.modelNo}
                  </Breadcrumb.Item>
                </Breadcrumb>
                <div className="div-title">
                  <h2>{state.row.modelNo}</h2>
                  <Box>
                    <img src="images/icons/share.png" />
                  </Box>
                </div>

                <Typography mt={0}>{state.row.manufacturerId.name}</Typography>

                <Typography mt={5} variant="h5" sx={{ fontWeight: "700" }}>
                  {state.row.price} €
                  <Typography component="span">(Inc. of all Taxes)</Typography>
                </Typography>

                {/* <div className="div-price">
                  <Typography sx={{ textDecoration: "line-through" }}>
                    €510
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "var(--primary-color)",
                      marginLeft: "8px",
                    }}
                  >
                    20%Off
                  </Typography>
                </div> */}

                {/* <Box className="div-discount" mt={3}>
                  <Typography variant="h5" sx={{ fontWeight: "700" }}>
                    Offers & Coupons
                  </Typography>

                  <Box className="coupon" mt={2}>
                    <div className="coupon-name">
                      <img src="images/icons/coupon.svg" />

                      <div style={{ marginLeft: "16px" }}>
                        <Typography variant="h6" sx={{ fontWeight: "500" }}>
                          Offers & Coupons
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: "500" }}>
                          Min cart value €50
                        </Typography>
                      </div>
                    </div>

                    <div>
                      <Link>View</Link>
                    </div>
                  </Box>

                  <Divider sx={{ my: "16px", borderColor: "#36AE7C" }} />

                  <Box className="coupon" mt={2}>
                    <div className="coupon-name">
                      <img src="images/icons/coupon.svg" />

                      <div style={{ marginLeft: "16px" }}>
                        <Typography variant="h6" sx={{ fontWeight: "500" }}>
                          Offers & Coupons
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: "500" }}>
                          Min cart value €50
                        </Typography>
                      </div>
                    </div>

                    <div>
                      <Link>View</Link>
                    </div>
                  </Box>
                </Box> */}

                <Box mt={0}>
                  <Button
                    onClick={() => onDownloadClick(allDocuments)}
                    variant="primary"
                    className="main-button"
                    style={{
                      padding: "16px 56px",
                      backgroundColor: "var(--primary-color)",
                      textTransform: "uppercase",
                      fontWeight: "500",
                      border: "none",
                      "&.hover": {
                        backgroundColor: "#1e7551",
                        boxShadow: "none",
                        margin: "16px",
                      },
                    }}
                  >
                    {t("download")} ({allDocuments.length})
                  </Button>

                  <Button
                    variant="primary"
                    onClick={() => {
                      doAddSelectedItem(state.row);
                    }}
                    className="secondary-button"
                    style={{
                      padding: "16px 56px",
                      backgroundColor: "#283739",
                      border: "none",
                      textTransform: "uppercase",
                      fontWeight: "500",
                      margin: "16px",
                    }}
                  >
                    {t("addToCart")}
                  </Button>

                  <Drawer
                    anchor={"right"}
                    open={stateDrawer["right"]}
                    onClose={() => {
                      toggleDrawerClose("right", false);
                    }}
                    style={{ padding: "24px" }}
                  >
                    {listcart("right")}
                  </Drawer>
                </Box>

                <Box mt={5}>
                  <Typography mb={2} variant="h4" sx={{ fontWeight: "600" }}>
                    {t("electricalCharacteristics")} (STC)
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("maximumPower")} (Pmax)
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.p_max}W
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("maxModuleEfficiency")}(%)
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.maxModuleEfficiency}%
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box mt={2}>
                  <Typography mb={2} variant="h4" sx={{ fontWeight: "600" }}>
                    {t("temperatureCharacteristics")}
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("temperatureCoefficientofPmax")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.temprature_pmax} %/°C
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box mt={2}>
                  <Typography mb={2} variant="h4" sx={{ fontWeight: "600" }}>
                    {t("shippingConfiguration")}
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("dimentions")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.dimensions}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("containerLength")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.containerLength}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("palletsPerContainer")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.palletsPerContainer}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("modulesPerPallet")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.modulePerPallet}pcs
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("modulesPerContainer")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.modulePerPallet}pcs
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box mt={2}>
                  <Typography mb={2} variant="h4" sx={{ fontWeight: "600" }}>
                    {t("description")}
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("description")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.description}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Col>
            </Row>
          </Container>
        )}
        {state.row.productId._id === localStorage.getItem("inverter") && (
          <Container style={{ position: "relative", marginTop: "24px" }}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={5} className="gallery-container">
                <ImageGallery
                  items={arrGallery}
                  useBrowserFullscreen={true}
                  showPlayButton={false}
                />
              </Col>

              <Col xs={12} sm={12} md={6} lg={7}>
                <Breadcrumb style={{ marginTop: "24px" }}>
                  <Breadcrumb.Item href="/">{t("home")}</Breadcrumb.Item>
                  <Breadcrumb.Item
                    href={`/product/${localStorage.getItem("inverter")}`}
                  >
                    {t("inverter")}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {state.row.manufacturerId.name} {state.row.modelNo}
                  </Breadcrumb.Item>
                </Breadcrumb>
                <div className="div-title">
                  <h2>{state.row.modelNo}</h2>
                  <Box>
                    <img src="images/icons/share.png" />
                  </Box>
                </div>

                <Typography mt={1}>{state.row.manufacturerId.name}</Typography>
                <Typography mt={3} variant="h5" sx={{ fontWeight: "700" }}>
                  {state.row.price} €
                  <Typography component="span">(Inc. of all Taxes)</Typography>
                </Typography>

                {/* <div className="div-price">
                <Typography sx={{ textDecoration: "line-through" }}>
                  €510
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "700",
                    color: "var(--primary-color)",
                    marginLeft: "8px",
                  }}
                >
                  20%Off
                </Typography>
              </div> */}

                {/* <Box className="div-discount" mt={3}>
                <Typography variant="h5" sx={{ fontWeight: "700" }}>
                  Offers & Coupons
                </Typography>

                <Box className="coupon" mt={2}>
                  <div className="coupon-name">
                    <img src="images/icons/coupon.svg" />

                    <div style={{ marginLeft: "16px" }}>
                      <Typography variant="h6" sx={{ fontWeight: "500" }}>
                        Offers & Coupons
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: "500" }}>
                        Min cart value €50
                      </Typography>
                    </div>
                  </div>

                  <div>
                    <Link>View</Link>
                  </div>
                </Box>

                <Divider sx={{ my: "16px", borderColor: "#36AE7C" }} />

                <Box className="coupon" mt={2}>
                  <div className="coupon-name">
                    <img src="images/icons/coupon.svg" />

                    <div style={{ marginLeft: "16px" }}>
                      <Typography variant="h6" sx={{ fontWeight: "500" }}>
                        Offers & Coupons
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: "500" }}>
                        Min cart value €50
                      </Typography>
                    </div>
                  </div>

                  <div>
                    <Link>View</Link>
                  </div>
                </Box>
              </Box> */}

                <Box mt={2}>
                  <Button
                    onClick={() => onDownloadClick(allDocuments)}
                    variant="primary"
                    className="main-button"
                    style={{
                      padding: "16px 56px",
                      backgroundColor: "var(--primary-color)",
                      border: "none",
                      "&.hover": {
                        backgroundColor: "#1e7551",
                        boxShadow: "none",
                        textTransform: "uppercase",
                        margin: "16px",
                      },
                    }}
                  >
                    {t("download")} ({allDocuments.length})
                  </Button>

                  <Button
                    variant="primary"
                    onClick={() => {
                      doAddSelectedItem(state.row);
                    }}
                    className="secondary-button"
                    style={{
                      padding: "16px 56px",
                      backgroundColor: "#283739",
                      border: "none",
                      textTransform: "uppercase",
                      fontWeight: "500",
                      margin: "16px",
                    }}
                  >
                    {t("addToCart")}
                  </Button>

                  <Drawer
                    anchor={"right"}
                    open={stateDrawer["right"]}
                    onClose={() => {
                      toggleDrawerClose("right", false);
                    }}
                    style={{ padding: "24px" }}
                  >
                    {listcart("right")}
                  </Drawer>
                </Box>

                <Box mt={5}>
                  <Typography mb={2} variant="h4" sx={{ fontWeight: "600" }}>
                    {t("inputCd")} (STC)
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("photoPower")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.photovoltaicPower}W
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("maxVoltage")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.maxVoltage}V
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("mppt")}(%)
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.mppt}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box mt={2}>
                  <Typography mb={2} variant="h4" sx={{ fontWeight: "600" }}>
                    {t("outputAc")}
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("nominalOutputPower")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.nominalOutputPower}kW
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("gridVoltage")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.gridVoltage}V
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("efficiency")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.efficiency}%
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("phase")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.phases}{" "}
                            {state.row.phases === 1 ? "phase" : "phases"}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Col>
            </Row>
          </Container>
        )}
        {state.row.productId._id === localStorage.getItem("storage") && (
          <Container style={{ position: "relative", marginTop: "24px" }}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={5} className="gallery-container">
                <ImageGallery
                  items={arrGallery}
                  useBrowserFullscreen={true}
                  showPlayButton={false}
                />
              </Col>

              <Col xs={12} sm={12} md={6} lg={7}>
                <Breadcrumb style={{ marginTop: "24px" }}>
                  <Breadcrumb.Item href="/">{t("home")}</Breadcrumb.Item>
                  <Breadcrumb.Item
                    href={`/product/${localStorage.getItem("storage")}`}
                  >
                    {t("battery")}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {state.row.manufacturerId.name} {state.row.modelNo}
                  </Breadcrumb.Item>
                </Breadcrumb>
                <div className="div-title">
                  <h2>{state.row.modelNo}</h2>
                  <Box>
                    <img src="images/icons/share.png" />
                  </Box>
                </div>

                <Typography mt={1}>{state.row.manufacturerId.name}</Typography>
                <Typography mt={3} variant="h5" sx={{ fontWeight: "700" }}>
                  {state.row.price} €
                  <Typography component="span">(Inc. of all Taxes)</Typography>
                </Typography>

                {/* <div className="div-price">
          <Typography sx={{ textDecoration: "line-through" }}>
            €510
          </Typography>
          <Typography
            sx={{
              fontWeight: "700",
              color: "var(--primary-color)",
              marginLeft: "8px",
            }}
          >
            20%Off
          </Typography>
        </div> */}

                {/* <Box className="div-discount" mt={3}>
          <Typography variant="h5" sx={{ fontWeight: "700" }}>
            Offers & Coupons
          </Typography>

          <Box className="coupon" mt={2}>
            <div className="coupon-name">
              <img src="images/icons/coupon.svg" />

              <div style={{ marginLeft: "16px" }}>
                <Typography variant="h6" sx={{ fontWeight: "500" }}>
                  Offers & Coupons
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: "500" }}>
                  Min cart value €50
                </Typography>
              </div>
            </div>

            <div>
              <Link>View</Link>
            </div>
          </Box>

          <Divider sx={{ my: "16px", borderColor: "#36AE7C" }} />

          <Box className="coupon" mt={2}>
            <div className="coupon-name">
              <img src="images/icons/coupon.svg" />

              <div style={{ marginLeft: "16px" }}>
                <Typography variant="h6" sx={{ fontWeight: "500" }}>
                  Offers & Coupons
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: "500" }}>
                  Min cart value €50
                </Typography>
              </div>
            </div>

            <div>
              <Link>View</Link>
            </div>
          </Box>
        </Box> */}

                <Box mt={2}>
                  <Button
                    onClick={() => onDownloadClick(allDocuments)}
                    variant="primary"
                    className="main-button"
                    style={{
                      padding: "16px 56px",
                      backgroundColor: "var(--primary-color)",
                      border: "none",
                      "&.hover": {
                        backgroundColor: "#1e7551",
                        boxShadow: "none",
                        textTransform: "uppercase",
                        margin: "16px",
                      },
                    }}
                  >
                    {t("download")} ({allDocuments.length})
                  </Button>

                  <Button
                    variant="primary"
                    onClick={() => {
                      doAddSelectedItem(state.row);
                    }}
                    className="secondary-button"
                    style={{
                      padding: "16px 56px",
                      backgroundColor: "#283739",
                      border: "none",
                      textTransform: "uppercase",
                      fontWeight: "500",
                      margin: "16px",
                    }}
                  >
                    {t("addToCart")}
                  </Button>

                  <Drawer
                    anchor={"right"}
                    open={stateDrawer["right"]}
                    onClose={() => {
                      toggleDrawerClose("right", false);
                    }}
                    style={{ padding: "24px" }}
                  >
                    {listcart("right")}
                  </Drawer>
                </Box>

                <Box mt={5}>
                  <Typography mb={2} variant="h4" sx={{ fontWeight: "600" }}>
                    {t("mechanicalData")}
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("weight")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.weight}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("dimentions")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.dimensions} mm
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("parkingNo")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.parkingNo}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box mt={2}>
                  <Typography mb={2} variant="h4" sx={{ fontWeight: "600" }}>
                    {t("electricalData")}
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("power")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.power}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("maxBattery")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.maxNoBatteryConnection}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("ip")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.ip}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box mt={2}>
                  <Typography mb={2} variant="h4" sx={{ fontWeight: "600" }}>
                    {t("description")}
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("description")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.description}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Col>
            </Row>
          </Container>
        )}
        {state.row.productId._id ===
          localStorage.getItem("hybrid_inverter") && (
          <Container style={{ position: "relative", marginTop: "24px" }}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={5} className="gallery-container">
                <ImageGallery
                  items={arrGallery}
                  useBrowserFullscreen={true}
                  showPlayButton={false}
                />
              </Col>

              <Col xs={12} sm={12} md={6} lg={7}>
                <Breadcrumb style={{ marginTop: "24px" }}>
                  <Breadcrumb.Item href="/">{t("home")}</Breadcrumb.Item>
                  <Breadcrumb.Item
                    href={`/product/${localStorage.getItem("hybrid_inverter")}`}
                  >
                    {t("hybrid") + " " + t("inverter")}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {state.row.manufacturerId.name} {state.row.modelNo}
                  </Breadcrumb.Item>
                </Breadcrumb>
                <div className="div-title">
                  <h2>{state.row.modelNo}</h2>
                  <Box>
                    <img src="images/icons/share.png" />
                  </Box>
                </div>

                <Typography mt={1}>{state.row.manufacturerId.name}</Typography>
                <Typography mt={3} variant="h5" sx={{ fontWeight: "700" }}>
                  {state.row.price} €
                  <Typography component="span">(Inc. of all Taxes)</Typography>
                </Typography>

                {/* <div className="div-price">
        <Typography sx={{ textDecoration: "line-through" }}>
          €510
        </Typography>
        <Typography
          sx={{
            fontWeight: "700",
            color: "var(--primary-color)",
            marginLeft: "8px",
          }}
        >
          20%Off
        </Typography>
      </div> */}

                {/* <Box className="div-discount" mt={3}>
        <Typography variant="h5" sx={{ fontWeight: "700" }}>
          Offers & Coupons
        </Typography>

        <Box className="coupon" mt={2}>
          <div className="coupon-name">
            <img src="images/icons/coupon.svg" />

            <div style={{ marginLeft: "16px" }}>
              <Typography variant="h6" sx={{ fontWeight: "500" }}>
                Offers & Coupons
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: "500" }}>
                Min cart value €50
              </Typography>
            </div>
          </div>

          <div>
            <Link>View</Link>
          </div>
        </Box>

        <Divider sx={{ my: "16px", borderColor: "#36AE7C" }} />

        <Box className="coupon" mt={2}>
          <div className="coupon-name">
            <img src="images/icons/coupon.svg" />

            <div style={{ marginLeft: "16px" }}>
              <Typography variant="h6" sx={{ fontWeight: "500" }}>
                Offers & Coupons
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: "500" }}>
                Min cart value €50
              </Typography>
            </div>
          </div>

          <div>
            <Link>View</Link>
          </div>
        </Box>
      </Box> */}

                <Box mt={2}>
                  <Button
                    onClick={() => onDownloadClick(allDocuments)}
                    variant="primary"
                    className="main-button"
                    style={{
                      padding: "16px 56px",
                      backgroundColor: "var(--primary-color)",
                      border: "none",
                      "&.hover": {
                        backgroundColor: "#1e7551",
                        boxShadow: "none",
                        textTransform: "uppercase",
                        margin: "16px",
                      },
                    }}
                  >
                    {t("download")} ({allDocuments.length})
                  </Button>

                  <Button
                    variant="primary"
                    onClick={() => {
                      doAddSelectedItem(state.row);
                    }}
                    className="secondary-button"
                    style={{
                      padding: "16px 56px",
                      backgroundColor: "#283739",
                      border: "none",
                      textTransform: "uppercase",
                      fontWeight: "500",
                      margin: "16px",
                    }}
                  >
                    {t("addToCart")}
                  </Button>

                  <Drawer
                    anchor={"right"}
                    open={stateDrawer["right"]}
                    onClose={() => {
                      toggleDrawerClose("right", false);
                    }}
                    style={{ padding: "24px" }}
                  >
                    {listcart("right")}
                  </Drawer>
                </Box>

                <Box mt={5}>
                  <Typography mb={2} variant="h4" sx={{ fontWeight: "600" }}>
                    {t("mechanicalData")}
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("dimentions")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.dimensions} mm
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box mt={2}>
                  <Typography mb={2} variant="h4" sx={{ fontWeight: "600" }}>
                    {t("electricalData")}
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("power")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.power}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("maxInverter")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.maxNoInverterConnection}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("batteryVoltageRange")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.batteryVoltageRange}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("maxChangeCurrent")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.maxChargingCurrent}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box mt={2}>
                  <Typography mb={2} variant="h4" sx={{ fontWeight: "600" }}>
                    {t("description")}
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("description")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.description}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Col>
            </Row>
          </Container>
        )}
        {state.row.productId._id === localStorage.getItem("accessory") && (
          <Container style={{ position: "relative", marginTop: "24px" }}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={5} className="gallery-container">
                <ImageGallery
                  items={arrGallery}
                  useBrowserFullscreen={true}
                  showPlayButton={false}
                />
              </Col>

              <Col xs={12} sm={12} md={6} lg={7}>
                <Breadcrumb style={{ marginTop: "24px" }}>
                  <Breadcrumb.Item href="/">{t("home")}</Breadcrumb.Item>
                  <Breadcrumb.Item
                    href={`/product/${localStorage.getItem("accessory")}`}
                  >
                    {t("accessory")}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {state.row.manufacturerId.name} {state.row.modelNo}
                  </Breadcrumb.Item>
                </Breadcrumb>
                <div className="div-title">
                  <h2>{state.row.modelNo}</h2>
                  <Box>
                    <img src="images/icons/share.png" />
                  </Box>
                </div>

                <Typography mt={1}>{state.row.manufacturerId.name}</Typography>
                <Typography mt={3} variant="h5" sx={{ fontWeight: "700" }}>
                  {state.row.price} €
                  <Typography component="span">(Inc. of all Taxes)</Typography>
                </Typography>

                {/* <div className="div-price">
      <Typography sx={{ textDecoration: "line-through" }}>
        €510
      </Typography>
      <Typography
        sx={{
          fontWeight: "700",
          color: "var(--primary-color)",
          marginLeft: "8px",
        }}
      >
        20%Off
      </Typography>
    </div> */}

                {/* <Box className="div-discount" mt={3}>
      <Typography variant="h5" sx={{ fontWeight: "700" }}>
        Offers & Coupons
      </Typography>

      <Box className="coupon" mt={2}>
        <div className="coupon-name">
          <img src="images/icons/coupon.svg" />

          <div style={{ marginLeft: "16px" }}>
            <Typography variant="h6" sx={{ fontWeight: "500" }}>
              Offers & Coupons
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: "500" }}>
              Min cart value €50
            </Typography>
          </div>
        </div>

        <div>
          <Link>View</Link>
        </div>
      </Box>

      <Divider sx={{ my: "16px", borderColor: "#36AE7C" }} />

      <Box className="coupon" mt={2}>
        <div className="coupon-name">
          <img src="images/icons/coupon.svg" />

          <div style={{ marginLeft: "16px" }}>
            <Typography variant="h6" sx={{ fontWeight: "500" }}>
              Offers & Coupons
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: "500" }}>
              Min cart value €50
            </Typography>
          </div>
        </div>

        <div>
          <Link>View</Link>
        </div>
      </Box>
    </Box> */}

                <Box mt={2}>
                  <Button
                    onClick={() => onDownloadClick(allDocuments)}
                    variant="primary"
                    className="main-button"
                    style={{
                      padding: "16px 56px",
                      backgroundColor: "var(--primary-color)",
                      border: "none",
                      "&.hover": {
                        backgroundColor: "#1e7551",
                        boxShadow: "none",
                        textTransform: "uppercase",
                        margin: "16px",
                      },
                    }}
                  >
                    {t("download")} ({allDocuments.length})
                  </Button>

                  <Button
                    variant="primary"
                    onClick={() => {
                      doAddSelectedItem(state.row);
                    }}
                    className="secondary-button"
                    style={{
                      padding: "16px 56px",
                      backgroundColor: "#283739",
                      border: "none",
                      textTransform: "uppercase",
                      fontWeight: "500",
                      margin: "16px",
                    }}
                  >
                    {t("addToCart")}
                  </Button>

                  <Drawer
                    anchor={"right"}
                    open={stateDrawer["right"]}
                    onClose={() => {
                      toggleDrawerClose("right", false);
                    }}
                    style={{ padding: "24px" }}
                  >
                    {listcart("right")}
                  </Drawer>
                </Box>

                <Box mt={2}>
                  <Typography mb={2} variant="h4" sx={{ fontWeight: "600" }}>
                    {t("description")}
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("description")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.description}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Col>
            </Row>
          </Container>
        )}
        {state.row.productId._id === localStorage.getItem("carport") && (
          <Container style={{ position: "relative", marginTop: "24px" }}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={5} className="gallery-container">
                <ImageGallery
                  items={arrGallery}
                  useBrowserFullscreen={true}
                  showPlayButton={false}
                />
              </Col>

              <Col xs={12} sm={12} md={6} lg={7}>
                <Breadcrumb style={{ marginTop: "24px" }}>
                  <Breadcrumb.Item href="/">{t("home")}</Breadcrumb.Item>
                  <Breadcrumb.Item
                    href={`/product/${localStorage.getItem("carport")}`}
                  >
                    {t("carPort")}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {state.row.manufacturerId.name} {state.row.modelNo}
                  </Breadcrumb.Item>
                </Breadcrumb>
                <div className="div-title">
                  <h2>{state.row.modelNo}</h2>
                  <Box>
                    <img src="images/icons/share.png" />
                  </Box>
                </div>

                <Typography mt={1}>{state.row.manufacturerId.name}</Typography>
                <Typography mt={3} variant="h5" sx={{ fontWeight: "700" }}>
                  {state.row.price} €
                  <Typography component="span">(Inc. of all Taxes)</Typography>
                </Typography>

                {/* <div className="div-price">
            <Typography sx={{ textDecoration: "line-through" }}>
              €510
            </Typography>
            <Typography
              sx={{
                fontWeight: "700",
                color: "var(--primary-color)",
                marginLeft: "8px",
              }}
            >
              20%Off
            </Typography>
          </div> */}

                {/* <Box className="div-discount" mt={3}>
            <Typography variant="h5" sx={{ fontWeight: "700" }}>
              Offers & Coupons
            </Typography>

            <Box className="coupon" mt={2}>
              <div className="coupon-name">
                <img src="images/icons/coupon.svg" />

                <div style={{ marginLeft: "16px" }}>
                  <Typography variant="h6" sx={{ fontWeight: "500" }}>
                    Offers & Coupons
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: "500" }}>
                    Min cart value €50
                  </Typography>
                </div>
              </div>

              <div>
                <Link>View</Link>
              </div>
            </Box>

            <Divider sx={{ my: "16px", borderColor: "#36AE7C" }} />

            <Box className="coupon" mt={2}>
              <div className="coupon-name">
                <img src="images/icons/coupon.svg" />

                <div style={{ marginLeft: "16px" }}>
                  <Typography variant="h6" sx={{ fontWeight: "500" }}>
                    Offers & Coupons
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: "500" }}>
                    Min cart value €50
                  </Typography>
                </div>
              </div>

              <div>
                <Link>View</Link>
              </div>
            </Box>
          </Box> */}

                <Box mt={2}>
                  <Button
                    onClick={() => onDownloadClick(allDocuments)}
                    variant="primary"
                    className="main-button"
                    style={{
                      padding: "16px 56px",
                      backgroundColor: "var(--primary-color)",
                      border: "none",
                      "&.hover": {
                        backgroundColor: "#1e7551",
                        boxShadow: "none",
                        textTransform: "uppercase",
                        margin: "16px",
                      },
                    }}
                  >
                    {t("download")} ({allDocuments.length})
                  </Button>

                  <Button
                    variant="primary"
                    onClick={() => {
                      doAddSelectedItem(state.row);
                    }}
                    className="secondary-button"
                    style={{
                      padding: "16px 56px",
                      backgroundColor: "#283739",
                      border: "none",
                      textTransform: "uppercase",
                      fontWeight: "500",
                      margin: "16px",
                    }}
                  >
                    {t("addToCart")}
                  </Button>

                  <Drawer
                    anchor={"right"}
                    open={stateDrawer["right"]}
                    onClose={() => {
                      toggleDrawerClose("right", false);
                    }}
                    style={{ padding: "24px" }}
                  >
                    {listcart("right")}
                  </Drawer>
                </Box>

                <Box mt={5}>
                  <Typography mb={2} variant="h4" sx={{ fontWeight: "600" }}>
                    {t("mechanicalData")}
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("parkingNo")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.parkingNo}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("dimentions")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.dimensions} mm
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("parkingNo")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.parkingNo}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box mt={2}>
                  <Typography mb={2} variant="h4" sx={{ fontWeight: "600" }}>
                    {t("electricalData")}
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("power")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.power}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("solarPanel")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.solarPanel}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("inverter")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.inverter}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box mt={2}>
                  <Typography mb={2} variant="h4" sx={{ fontWeight: "600" }}>
                    {t("description")}
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("description")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.description}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Col>
            </Row>
          </Container>
        )}
        {state.row.productId._id === localStorage.getItem("mounting") && (
          <Container style={{ position: "relative", marginTop: "24px" }}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={5} className="gallery-container">
                <ImageGallery
                  items={arrGallery}
                  useBrowserFullscreen={true}
                  showPlayButton={false}
                />
              </Col>

              <Col xs={12} sm={12} md={6} lg={7}>
                <Breadcrumb style={{ marginTop: "24px" }}>
                  <Breadcrumb.Item href="/">{t("home")}</Breadcrumb.Item>
                  <Breadcrumb.Item
                    href={`/product/${localStorage.getItem("mounting")}`}
                  >
                    {t("mounting")}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {state.row.manufacturerId.name} {state.row.modelNo}
                  </Breadcrumb.Item>
                </Breadcrumb>
                <div className="div-title">
                  <h2>{state.row.modelNo}</h2>
                  <Box>
                    <img src="images/icons/share.png" />
                  </Box>
                </div>

                <Typography mt={1}>{state.row.manufacturerId.name}</Typography>
                <Typography mt={3} variant="h5" sx={{ fontWeight: "700" }}>
                  {state.row.price} €
                  <Typography component="span">(Inc. of all Taxes)</Typography>
                </Typography>

                {/* <div className="div-price">
       <Typography sx={{ textDecoration: "line-through" }}>
         €510
       </Typography>
       <Typography
         sx={{
           fontWeight: "700",
           color: "var(--primary-color)",
           marginLeft: "8px",
         }}
       >
         20%Off
       </Typography>
     </div> */}

                {/* <Box className="div-discount" mt={3}>
       <Typography variant="h5" sx={{ fontWeight: "700" }}>
         Offers & Coupons
       </Typography>

       <Box className="coupon" mt={2}>
         <div className="coupon-name">
           <img src="images/icons/coupon.svg" />

           <div style={{ marginLeft: "16px" }}>
             <Typography variant="h6" sx={{ fontWeight: "500" }}>
               Offers & Coupons
             </Typography>
             <Typography variant="body2" sx={{ fontWeight: "500" }}>
               Min cart value €50
             </Typography>
           </div>
         </div>

         <div>
           <Link>View</Link>
         </div>
       </Box>

       <Divider sx={{ my: "16px", borderColor: "#36AE7C" }} />

       <Box className="coupon" mt={2}>
         <div className="coupon-name">
           <img src="images/icons/coupon.svg" />

           <div style={{ marginLeft: "16px" }}>
             <Typography variant="h6" sx={{ fontWeight: "500" }}>
               Offers & Coupons
             </Typography>
             <Typography variant="body2" sx={{ fontWeight: "500" }}>
               Min cart value €50
             </Typography>
           </div>
         </div>

         <div>
           <Link>View</Link>
         </div>
       </Box>
     </Box> */}

                <Box mt={2}>
                  <Button
                    onClick={() => onDownloadClick(allDocuments)}
                    variant="primary"
                    className="main-button"
                    style={{
                      padding: "16px 56px",
                      backgroundColor: "var(--primary-color)",
                      border: "none",
                      "&.hover": {
                        backgroundColor: "#1e7551",
                        boxShadow: "none",
                        textTransform: "uppercase",
                        margin: "16px",
                      },
                    }}
                  >
                    {t("download")} ({allDocuments.length})
                  </Button>

                  <Button
                    variant="primary"
                    onClick={() => {
                      doAddSelectedItem(state.row);
                    }}
                    className="secondary-button"
                    style={{
                      padding: "16px 56px",
                      backgroundColor: "#283739",
                      border: "none",
                      textTransform: "uppercase",
                      fontWeight: "500",
                      margin: "16px",
                    }}
                  >
                    {t("addToCart")}
                  </Button>

                  <Drawer
                    anchor={"right"}
                    open={stateDrawer["right"]}
                    onClose={() => {
                      toggleDrawerClose("right", false);
                    }}
                    style={{ padding: "24px" }}
                  >
                    {listcart("right")}
                  </Drawer>
                </Box>

                <Box mt={5}>
                  <Typography mb={2} variant="h4" sx={{ fontWeight: "600" }}>
                    {t("inputCd")} (STC)
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("photoPower")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.photovoltaicPower}W
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("maxVoltage")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.maxVoltage}V
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("mppt")}(%)
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.mppt}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box mt={2}>
                  <Typography mb={2} variant="h4" sx={{ fontWeight: "600" }}>
                    {t("outputAc")}
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("nominalOutputPower")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.nominalOutputPower} kW
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("gridVoltage")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.gridVoltage} V
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("efficiency")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.efficiency} %
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box mt={2}>
                  <Typography mb={2} variant="h4" sx={{ fontWeight: "600" }}>
                    {t("description")}
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" width="50%">
                            {t("description")}
                          </TableCell>
                          <TableCell align="left" width="50%">
                            {state.row.description}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Col>
            </Row>
          </Container>
        )}
      </Container>
    </div>
  );
};

export default ProductDetails;
