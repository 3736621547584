import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "css/AgentNav.css";
import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { t } from "i18next";
import { Add } from "@mui/icons-material";
import { Col, Container, Row } from "react-bootstrap";
import { Grid } from "@mui/material";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Form from "react-bootstrap/Form";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  maxHeight: "85%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px",
  overflowY: "scroll",
};

function AgentNav() {
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const [status, setStatus] = useState(false);
  const location = useLocation();
  const [path, setPath] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    //  console.log(`You changed the page to: ${location.pathname}`)
    setPath(location.pathname);
  }, [location, path]);

  const onStatusChange = () => {
    setStatus(!status);
  };

  const isAgentRoute = path === "/admin/users-list/agent";
  const isCompanyAgentRoute = path === "/admin/users-list/company-agent";
  const isInstallerRoute = path === "/admin/users-list/installer";

  return (
    <div>
      <Row>
        <Col xs={12} md={6}>
          <Nav className="justify-content-start">
            <Nav.Item className="me-2 storageLink">
              <Nav.Link
                as={Link}
                style={{ color: "#7A7A7A" }}
                to="/admin/users-list/agent"
                active={path === "/admin/users-list/agent" ? true : false}
              >
                <b>{t("agent")}</b>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="me-2 storageLink">
              <Nav.Link
                as={Link}
                style={{ color: "#7A7A7A" }}
                to="/admin/users-list/company-agent"
                active={
                  path === "/admin/users-list/company-agent" ? true : false
                }
              >
                <b>{t("companyAgent")}</b>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="me-2 storageLink">
              <Nav.Link
                as={Link}
                style={{ color: "#7A7A7A" }}
                to="/admin/users-list/installer"
                active={path === "/admin/users-list/installer" ? true : false}
              >
                <b>{t("installer")}</b>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col xs={12} md={6} className="d-flex justify-content-end agentNav add">

        </Col>
      </Row>
    </div>
  );
}

export default AgentNav;
