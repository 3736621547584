import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import { Col, Container, Row } from "react-bootstrap";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Form from "react-bootstrap/Form";
import "css/AgentNav.css";
import { Add } from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import Switch from "@mui/material/Switch";
import { Grid } from "@mui/material";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  maxHeight: "85%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px",
  overflowY: "scroll",
};

function StorageNav() {
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const [status, setStatus] = useState(false);

  const location = useLocation();
  const [path, setPath] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    //  console.log(`You changed the page to: ${location.pathname}`)
    setPath(location.pathname);
  }, [location, path]);

  const isBatteryActive =
    path === `/admin/product/${localStorage.getItem("storage")}`;
  const isHybridInverterActive =
    path === `/admin/product/${localStorage.getItem("hybrid_inverter")}`;
  const isAccessoryActive =
    path === `/admin/product/${localStorage.getItem("accessory")}`;

  const onStatusChange = () => {
    setStatus(!status);
  };

  return (
    <div>
      <Row>
        <Col xs={12} md={6}>
          <Nav className="justify-content-start">
            <Nav.Item className="me-2 storageLink">
              <Nav.Link
                as={Link}
                to={`/admin/product/${localStorage.getItem("storage")}`}
                active={
                  path === `/admin/product/${localStorage.getItem("storage")}`
                    ? true
                    : false
                }
              >
                <b>{t("battery")}</b>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="me-2 storageLink">
              <Nav.Link
                as={Link}
                to={`/admin/product/${localStorage.getItem("hybrid_inverter")}`}
                active={
                  path ===
                  `/admin/product/${localStorage.getItem("hybrid_inverter")}`
                    ? true
                    : false
                }
              >
                <b>{t("hybrid") + " " + t("inverter")}</b>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="me-2 storageLink">
              <Nav.Link
                as={Link}
                to={`/admin/product/${localStorage.getItem("accessory")}`}
                active={
                  path === `/admin/product/${localStorage.getItem("accessory")}`
                    ? true
                    : false
                }
              >
                <b>{t("accessory")}</b>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>

        
      </Row>
    </div>
  );
}

export default StorageNav;
