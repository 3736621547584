import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
import Container from "react-bootstrap/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import "css/EditSolarForm.css";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import axios from "axios";
import defaultString from "constants/defaultString.json";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AlertDialog from "components/AlertDialog/AlertDialog";
import { useCartPortValidator } from "hooks/useCartPortValidator";
import { AuthContext } from "context/AuthContext";
import { resetToken } from "apiURL";
import Box from "@mui/material/Box";
import { Button, Divider, Grid, IconButton, Input } from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  maxHeight: "85%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px",
  overflowY: "scroll",
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddCarPort = (props) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [status, setStatus] = useState(false);
  const [list, setList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [name, setName] = useState(list[0]);
  const [modelNo, setModelNo] = useState("");
  const [efficiency, setModelEfficiency] = useState("");
  const [solarPanel, setSolarPanel] = useState("");
  const [inverter, setInverter] = useState("");
  const [dimention, setDimention] = useState("");
  const [tempPmax, setTempPmax] = useState("");
  const [length, setLength] = useState("");
  const [palletPerContainer, setPalletPerContainer] = useState("");
  const [modulePerPallet, setModulePerPallet] = useState("");
  const [modulePerContainer, setModulePerContainer] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [mainImage, setMainImage] = useState({ id: 0, logo: null });
  const [otherImages, setOtherImages] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [attachmentId, setAttachmentId] = useState("");
  const [isChangeMainImage, setIsChangeMainImage] = useState(false);
  const [indexToBeDelete, setIndexToBeDelete] = useState("");
  const [attachType, setAttachType] = useState("");
  const [logoImageError, setLogoImageError] = useState(false);
  const [manufacturerError, setManufacturerError] = useState(false);
  const [isDisabledSaveBtn, setIsDisabledSaveBtn] = useState(false);
  const [isDisabledDeleteBtn, setIsDisabledDeleteBtn] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const [form, setForm] = useState({
    modelNo: "",
    power: "",
    price: "",
    dimention: "",
    parkingNo: "",
  });
  const [data, setData] = useState([]);
  const [ip, setIp] = useState("");
  const [parkingNo, setParkingNo] = useState("");
  const [power, setPower] = useState("");
  const [maxNoBatteryConnection, setMaxNoBatteryConnection] = useState("");
  const [weight, setWeight] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = (data) => {
    setOpenModal(!openModal);
    setData(data);
  };
  const [severity, setSeverity] = useState("error");

  const { errors, validateForm, onBlurField } = useCartPortValidator(form);
  const navigate = useNavigate();
  const { state } = useLocation();

  const onUpdateField = (e) => {
    const field = e.target.name;
    const nextFormState = {
      ...form,
      [field]: e.target.value,
    };
    setForm(nextFormState);

    if (errors[field].dirty)
      validateForm({
        form: nextFormState,
        errors,
        field,
      });
  };

  useEffect(() => {
    const getManufacturer = async () => {
      await axios
        .get(
          apiURL.service_list_all_manufacturer +
            "/" +
            localStorage.getItem("carport")
        )
        .then((res) => {
          if (res.status === apiStatusCode.SUCCESS) {
            setList(res.data.data.manufacturer);
          } else {
            console.log("In else portion ", res.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getManufacturer();
  }, []);

  useEffect(() => {
    console.log("state: ", state);
    if (props.stateObj) {
      setIsEdit(true);
      // setModelNo(state.data.modelNo)
      setForm({
        modelNo: props.stateObj.modelNo,
        power: props.stateObj.power,
        dimention: props.stateObj.dimensions,
        parkingNo: props.stateObj.parkingNo,
        price: props.stateObj.price,
      });
      let prodStatus = props.stateObj.status === "Active" ? true : false;
      setStatus(prodStatus);
      setName(props.stateObj.manufacturerId);
      // setModelNo(props.stateObj.modelNo);
      setDescription(props.stateObj.description);
      // setPrice(props.stateObj.price);
      // setParkingNo(props.stateObj.parkingNo)
      setSolarPanel(props.stateObj.solarPanel);
      // setDimention(props.stateObj.dimensions);
      setInverter(props.stateObj.inverter);
      // setPower(props.stateObj.power)

      if (props.stateObj.mainImage.logo !== null) {
        setMainImage(props.stateObj.mainImage);
      }

      let uploadedArray = [];
      if (props.stateObj.otherImages.length > 0) {
        for (let i = 0; i < props.stateObj.otherImages.length; i++) {
          uploadedArray.push(props.stateObj.otherImages[i]);
        }
        setOtherImages(uploadedArray);
      }

      let uploadedDocArray = [];
      if (props.stateObj.documents.length > 0) {
        for (let i = 0; i < props.stateObj.documents.length; i++) {
          uploadedDocArray.push(props.stateObj.documents[i]);
        }
        setDocuments(uploadedDocArray);
      }
    }
  }, [props]);

  useEffect(() => {
    if (!props.isEdit) {
      setForm({
        modelNo: "",
        power: "",
        price: "",
        dimention: "",
        parkingNo: "",
      });
      setName("");
      setStatus("");
      setDescription("");
      setSolarPanel("");
      setInverter("");
      setMainImage("");
      setOtherImages("");
      setDocuments("");
    }
    if (props.handleClose) {
      setManufacturerError(false);
      setLogoImageError(false);
      validateForm({
        form: form,
        errors: {
          modelNo: "",
          power: "",
          price: "",
          dimention: "",
          parkingNo: "",
        },
      });
    }
  }, [props.handleClose, props.isEdit]);

  const handleMainPicture = (event) => {
    const isLt2M = event.target.files[0].size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setSeverity("error");
      setOpen(true);
      setErrorMsg("Image must smaller than 2MB!");
      return;
    }
    setMainImage({ id: 0, logo: event.target.files[0] });
    setLogoImageError(false);
    setIsChangeMainImage(true);
  };

  const handleOtherImages = (event) => {
    let fileObj = [];
    fileObj.push(event.target.files);

    for (let i = 0; i < fileObj[0].length; i++) {
      let otherImageObj = {};
      otherImageObj.id = 0;
      otherImageObj.original = fileObj[0][i];

      const isLt2M = fileObj[0][i].size / 1024 / 1024 < 2;
      if (!isLt2M) {
        setSeverity("error");
        setOpen(true);
        setErrorMsg("Image must smaller than 2MB!");
        return;
      }
      setOtherImages((x) => [...x, otherImageObj]);
    }
  };

  const handleDocuments = (event) => {
    let attachmentObj = [];
    attachmentObj.push(event.target.files);

    for (let i = 0; i < attachmentObj[0].length; i++) {
      let docuObj = {};
      docuObj.id = 0;
      docuObj.document = attachmentObj[0][i];

      setDocuments((x) => [...x, docuObj]);
    }
  };

  const handleDeleteAlertClose = () => {
    setOpenDeleteAlert(false);
    setAttachmentId("");
    setAttachType("");
    setIndexToBeDelete("");
  };

  const confirmDeleteItem = async () => {
    console.log("attachmentId:: ", attachmentId);
    setOpenDeleteAlert(false);

    if (attachType == "mainImage") {
      if (attachmentId !== 0) {
        await fnDeleteAttachment(attachmentId);
      } else {
        setMainImage({ id: 0, logo: null });
        setLogoImageError(true);
      }
      let inputId = document.getElementById("formFile1");
      inputId.value = null;
    } else if (attachType === "otherImage") {
      console.log("indexToBeDelete: ", indexToBeDelete);
      if (attachmentId !== 0) {
        await fnDeleteAttachment(attachmentId);
      } else {
        const filteredArr = otherImages.filter(
          (item, index) => index !== indexToBeDelete
        );
        setOtherImages(filteredArr);
      }
      // let inputId = document.getElementById('otherImages');
      // inputId.value = null;
    } else if (attachType === "document") {
      console.log("indexToBeDelete: ", indexToBeDelete);
      if (attachmentId !== 0) {
        await fnDeleteAttachment(attachmentId);
      } else {
        const filteredArr = documents.filter(
          (item, index) => index !== indexToBeDelete
        );
        setDocuments(filteredArr);
      }
      // let inputId = document.getElementById('otherImages');
      // inputId.value = null;
    } else if (attachType === "product") {
      if (attachmentId !== 0) {
        await fnDeleteProduct(attachmentId);
      }
    }
  };

  const deleteItem = (itemId, index, attachmentType) => {
    // const s = attachments.filter((item, index) => index !== e);
    // setAttachments(s);
    // console.log(attachments)
    // console.log(s);
    setOpenDeleteAlert(true);
    setAttachmentId(itemId);
    setIndexToBeDelete(index);
    setAttachType(attachmentType);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const fnAddProduct = async (e) => {
    e.preventDefault();
    if (!name) {
      setManufacturerError(true);
    }

    if (!mainImage.logo) {
      setLogoImageError(true);
      setSeverity("error");
      setOpen(true);
      setErrorMsg(defaultString.validData);
    }
    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));
    const { isValid } = validateForm({ form, errors, forceTouchErrors: true });
    if (!isValid) {
      setSeverity("error");
      setOpen(true);
      setErrorMsg(defaultString.validData);
      return;
    }
    console.log(mainImage);
    // setOpen(true);
    // setErrorMsg(defaultString.validData)

    if (otherImages.length > 5) {
      setSeverity("error");
      setOpen(true);
      setErrorMsg(defaultString.maxOtherLimitMsg);
      return;
    }

    if (documents.length > 5) {
      setSeverity("error");
      setOpen(true);
      setErrorMsg(defaultString.maxDocumentLimitMsg);
      return;
    }

    if (
      form.modelNo &&
      form.power &&
      form.dimention &&
      form.parkingNo &&
      form.price
    ) {
      if (!mainImage.logo) {
        setSeverity("error");
        setLogoImageError(true);
        setOpen(true);
        setErrorMsg(defaultString.validData);
        return;
      }
      const formData = new FormData();

      if (mainImage.logo != null) {
        formData.append("mainImage", mainImage.logo);
      }

      console.log(otherImages);
      if (otherImages.length > 0) {
        otherImages.map((image, i) => {
          if (!!image.original) formData.append("otherImage", image.original);
        });
      }

      console.log(documents);
      if (documents.length > 0) {
        documents.map((image, i) => {
          if (!!image.document) formData.append("document", image.document);
        });
      }
      setIsDisabledSaveBtn(true);
      console.log(status);
      let prodStatus = status === true ? "Active" : "InActive";
      console.log(prodStatus);

      formData.append("status", prodStatus);
      formData.append("productId", localStorage.getItem("carport"));
      formData.append("manufacturerId", name._id);
      formData.append("modelNo", form.modelNo);
      formData.append("parkingNo", form.parkingNo);
      formData.append("price", form.price);
      formData.append("dimensions", form.dimention);
      formData.append("power", form.power);
      formData.append("description", description);
      formData.append("solarPanel", solarPanel);
      formData.append("inverter", inverter);

      await axios
        .post(apiURL.service_add_product, formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.status === apiStatusCode.CREATED) {
            setOpen(true);
            setSeverity("success");
            setErrorMsg(res.data.message);
            props.handleClose({
              reload: true,
            });
            navigate(`/admin/product/${localStorage.getItem("carport")}`);
          } else {
            console.log("In else portion ", res.data);
            setOpen(true);
            setIsDisabledSaveBtn(false);
            setSeverity("error");
            setErrorMsg(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error.response);
          setOpen(true);
          setIsDisabledSaveBtn(false);
          if (error.code === defaultString.network_error) {
            setSeverity("error");
            setErrorMsg(error.message);
          } else {
            setSeverity("error");
            setErrorMsg(error.response.data.message);
          }
          if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
            doLogout(error);
          }
        });
    } else {
      setOpen(true);
      setSeverity("error");
      setErrorMsg(defaultString.validData);
    }
  };

  const fnEditProduct = async (productDetailId) => {
    // e.preventDefault();
    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));

    if (!name) {
      setManufacturerError(true);
    }

    if (!mainImage.logo) {
      setLogoImageError(true);
      setSeverity("error");
      setOpen(true);
      setErrorMsg(defaultString.validData);
    }

    const { isValid } = validateForm({ form, errors, forceTouchErrors: true });

    if (!isValid) {
      setSeverity("error");
      setOpen(true);
      setErrorMsg(defaultString.validData);
      return;
    }
    if (
      form.modelNo &&
      form.power &&
      form.dimention &&
      form.parkingNo &&
      form.price
    ) {
      if (!mainImage.logo) {
        setLogoImageError(true);
        setSeverity("error");
        setOpen(true);
        setErrorMsg(defaultString.validData);
        return;
      }

      if (otherImages.length > 5) {
        setSeverity("error");
        setOpen(true);
        setErrorMsg(defaultString.maxOtherLimitMsg);
        return;
      }

      if (documents.length > 5) {
        setSeverity("error");
        setOpen(true);
        setErrorMsg(defaultString.maxDocumentLimitMsg);
        return;
      }
      setIsDisabledSaveBtn(true);
      const formData = new FormData();

      if (!!mainImage.logo && mainImage.id === 0) {
        if (isChangeMainImage === true) {
          formData.append("mainImage", mainImage.logo);
        }
      }

      console.log(otherImages);
      if (otherImages.length > 0) {
        otherImages.map((image, i) => {
          console.log(image);
          if (!!image.original && image.id === 0)
            formData.append("otherImage", image.original);
        });
      }

      console.log(documents);
      if (documents.length > 0) {
        documents.map((image, i) => {
          if (!!image.document && image.id === 0)
            formData.append("document", image.document);
        });
      }

      let prodStatus = status === true ? "Active" : "InActive";
      console.log(prodStatus);
      formData.append("productDetailId", productDetailId);
      // formData.append('productId',state.productId._id)
      formData.append("status", prodStatus);
      formData.append("manufacturerId", name._id);
      formData.append("modelNo", form.modelNo);
      formData.append("parkingNo", form.parkingNo);
      formData.append("price", form.price);
      formData.append("dimensions", form.dimention);
      formData.append("power", form.power);
      formData.append("description", description);
      formData.append("solarPanel", solarPanel);
      formData.append("inverter", inverter);

      await axios
        .post(apiURL.service_edit_product, formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.status === apiStatusCode.SUCCESS) {
            setOpen(true);
            setIsEdit(false);
            setSeverity("success");
            setErrorMsg(res.data.message);
            props.handleClose({
              reload: true,
            });
            navigate(`/admin/product/${localStorage.getItem("carport")}`);
          } else {
            console.log("In else portion ", res.data);
            setOpen(true);
            setIsDisabledSaveBtn(false);
            setSeverity("error");
            setErrorMsg(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error.response);
          setOpen(true);
          setIsDisabledSaveBtn(false);
          if (error.code === defaultString.network_error) {
            setSeverity("error");
            setErrorMsg(error.message);
          } else {
            setSeverity("error");
            setErrorMsg(error.response.data.message);
          }
          if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
            doLogout(error);
          }
        });
    } else {
      setOpen(true);
      setSeverity("error");
      setErrorMsg(defaultString.validData);
    }
  };

  const fnDeleteAttachment = async (attachmentId) => {
    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));

    await axios
      .delete(apiURL.service_delete_attachment + `/${attachmentId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === apiStatusCode.DELETED) {
          setOpen(true);
          setErrorMsg(res.data.message);
          setSeverity("success");
          if (attachType == "mainImage") {
            setMainImage({ id: 0, logo: null });
          } else if (attachType == "otherImage") {
            const filteredArr = otherImages.filter(
              (item) => item.id !== attachmentId
            );
            setOtherImages(filteredArr);
          } else if (attachType == "document") {
            const filteredArr = documents.filter(
              (item) => item.id !== attachmentId
            );
            setDocuments(filteredArr);
          }

          setAttachmentId("");
        } else {
          setOpenDeleteAlert(false);
          console.log("In else portion ", res.data);
          setSeverity("error");
          setOpen(true);
          setErrorMsg(res.data.data.message);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setSeverity("error");
        setOpen(true);
        if (error.code === defaultString.network_error)
          setErrorMsg(error.message);
        else setErrorMsg(error.response.data.message);
        if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
          doLogout(error);
        }
      });
  };

  const fnDeleteProduct = async (attachmentId) => {
    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));
    console.log("token ", token);
    const objProduct = JSON.stringify({
      productDetailIds: [attachmentId],
    });

    console.log(objProduct);
    setIsDisabledDeleteBtn(true);
    await axios
      .post(apiURL.service_delete_products, objProduct, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === apiStatusCode.DELETED) {
          setOpen(true);
          setSeverity("success");
          setErrorMsg(res.data.message);
          setAttachmentId("");
          props.handleClose({
            reload: true,
          });
          // navigate(`/admin/product/${localStorage.getItem("carport")}`);
        } else {
          console.log("In else portion ", res.data);
          setOpen(true);
          setIsDisabledDeleteBtn(false);
          setSeverity("error");
          setErrorMsg(res.data.data.message);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setOpen(true);
        setIsDisabledDeleteBtn(false);
        if (error.code === defaultString.network_error) {
          setSeverity("error");
          setErrorMsg(error.message);
        } else {
          setSeverity("error");
          setErrorMsg(error.response.data.message);
        }
        if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
          doLogout(error);
        }
      });
  };

  const onStatusChange = () => {
    setStatus(!status);
  };

  const doLogout = async (resErr) => {
    const data = await resetToken();
    if (data === true) {
      dispatch({ type: "LOGOUT", payload: null });
      navigate("/", {
        state: { isModalPopup: true, errorMsg: resErr.response.data.message },
      });
    }
  };

  return (
    <>
      {/* <div className="costome-carousel"> */}
      <Modal
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: "70%",
            backgroundColor: "white",
            borderRadius: 2,
            height: "80%",
          }}
        >
          <Box
            sx={{
              padding: "8px 16px",
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: "white",
              borderBottom: "1px solid #d4d4d4",
              display: "flex",
              justifyContent: "space-between",
              borderRadius: "16px 16px 0 0",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontWeight: 700,
                fontSize: "24px",
                marginRight: "24px",
                color: "var(--primary-color)",
              }}
            >
              Add Car Pot
            </Typography>

            <IconButton
              onClick={() => {
                props.handleClose();
              }}
            >
              <Close />
            </IconButton>
          </Box>

          <Container
            fluid="md"
            style={{
              height: "90%",
              overflowY: "scroll",
            }}
          >
            <Box>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 700 }}
                className="my-2"
              >
                {t("status")}
              </Typography>
              <Row>
                <Col>
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      name="status"
                      checked={status}
                      value={status}
                      className="custom-switch-lg"
                      onChange={onStatusChange}
                    />
                  </Form>
                </Col>
              </Row>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 700 }}
                className="my-2"
              >
                {t("images")}
              </Typography>
              <Row>
                <Col lg="6">
                  <Form.Group controlId="formFile1" className="mb-3">
                    <Form.Label>{t("chooseMainImage") + "*"}</Form.Label>
                    <Form.Control
                      type="file"
                      className="no-focus-shadow"
                      onChange={handleMainPicture}
                      name="mainImage"
                      accept="image/*"
                    />
                    <div className="sec-row">
                      {!!mainImage.logo && (
                        <>
                          {mainImage.id === 0 ? (
                            <img
                              key={mainImage.logo.name}
                              className="mt-3"
                              accept="image/*"
                              src={
                                mainImage.logo !== null ||
                                mainImage.logo !== undefined
                                  ? URL.createObjectURL(mainImage.logo)
                                  : null
                              }
                              alt={mainImage.logo.name}
                              height={80}
                              width={80}
                            />
                          ) : (
                            <img
                              key={mainImage.logo}
                              className="mt-3"
                              accept="image/*"
                              src={
                                mainImage.logo
                                  ? apiURL.siteBaseUrl + mainImage.logo
                                  : null
                              }
                              alt={mainImage.logo}
                              height={80}
                              width={80}
                            />
                          )}

                          <MdCancel
                            fontSize={20}
                            className="close-icon"
                            onClick={() =>
                              deleteItem(mainImage.id, 0, "mainImage")
                            }
                          />
                        </>
                      )}
                      {logoImageError ? (
                        <p className="productErrorMessage">{t("logoError")}</p>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Form.Group>
                    <Form.Label>{t("chooseOtherImage")}</Form.Label>
                    <Form.Control
                      type="file"
                      className="no-focus-shadow"
                      multiple
                      onChange={handleOtherImages}
                      id="otherImages"
                      accept="image/*"
                    />
                    <div className="sec-row me-3">
                      {otherImages.length > 0 &&
                        otherImages.map((item, index) => {
                          return (
                            !!item.original && (
                              <div key={index} className="img-list">
                                {item.id === 0 ? (
                                  <img
                                    className="mt-3"
                                    accept="image/*"
                                    src={
                                      !!item.original
                                        ? URL.createObjectURL(item.original)
                                        : null
                                    }
                                    alt={item.original.name}
                                    height={80}
                                    width={80}
                                  />
                                ) : (
                                  <img
                                    className="mt-3"
                                    accept="image/*"
                                    src={
                                      !!item.original
                                        ? apiURL.siteBaseUrl + item.original
                                        : null
                                    }
                                    alt={item.original}
                                    height={80}
                                    width={80}
                                  />
                                )}

                                <MdCancel
                                  fontSize={20}
                                  className="close-icon"
                                  onClick={() =>
                                    deleteItem(item.id, index, "otherImage")
                                  }
                                />
                              </div>
                            )
                          );
                        })}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Box
                sx={{
                  border: "1px solid #e2e2e2",
                  marginY: "16px",
                }}
              />
              <Row>
                <Typography sx={{ marginBottom: "8px", fontWeight: 700 }}>
                  {t("documents")}
                </Typography>
                <Col lg="12">
                  <Form.Group controlId="formFile2" className="mb-3">
                    <Form.Label>{t("uploadDocuments")}</Form.Label>
                    <Form.Control
                      type="file"
                      className="no-focus-shadow"
                      onChange={handleDocuments}
                      multiple
                      accept="application/pdf,.doc,.docx"
                    />
                    {documents.length > 0 &&
                      documents.map((item, index) => {
                        return (
                          !!item.document && (
                            <div key={index} className="img-list">
                              {item.id === 0 ? (
                                <h6 className="mt-1 ms-2">
                                  {item.document.name}
                                </h6>
                              ) : (
                                <h6 className="mt-1 ms-2">
                                  {item.document.split("/productDocument/")[1]}
                                </h6>
                              )}
                              <MdCancel
                                fontSize={20}
                                className="doc-icon"
                                onClick={() =>
                                  deleteItem(item.id, index, "document")
                                }
                              />
                            </div>
                          )
                        );
                      })}
                  </Form.Group>
                </Col>
              </Row>
              <Box
                sx={{
                  border: "1px solid #e2e2e2",
                  marginY: "16px",
                }}
              />
              <Row>
                <Typography sx={{ marginBottom: "8px", fontWeight: 700 }}>
                  {t("manufacturer")} {t("data")}
                </Typography>
                <Col lg="6">
                  <FloatingLabel
                    className="custom-select"
                    controlId="floatingSelectGrid"
                    label={t("manufacturer") + "*"}
                  >
                    <Form.Select
                      style={{
                        color: "var(title-color) !important",
                      }}
                      className="no-focus-shadow"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      aria-label="Floating label select example"
                    >
                      <option>Select an option</option>
                      {console.log("name", name)}
                      {list &&
                        list.map((item, index) => (
                          <option key={item._id} value={item._id}>
                            {item?.name}
                          </option>
                        ))}
                    </Form.Select>
                  </FloatingLabel>
                  {manufacturerError ? (
                    <p className="productErrorMessage mb-0">
                      {t("manufacturerNameError")}
                    </p>
                  ) : null}
                </Col>
                <Col lg="6">
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      className="no-focus-shadow"
                      placeholder={t("electricalCharacteristics")}
                      name="modelNo"
                      value={form.modelNo}
                      onChange={onUpdateField}
                      onBlur={onBlurField}
                      required
                    />
                    <label htmlFor="floatingInputCustom">
                      {t("electricalCharacteristics") + "*"}
                    </label>
                  </Form.Floating>
                  {errors.modelNo.dirty && errors.modelNo.error ? (
                    <p className="productErrorMessage">{t("modalError")}</p>
                  ) : null}
                </Col>
              </Row>
              <Box
                sx={{
                  border: "1px solid #e2e2e2",
                  marginY: "16px",
                }}
              />
              <Row>
                <Typography sx={{ marginBottom: "8px", fontWeight: 700 }}>
                  {t("mechanicalData")}
                </Typography>
                <Col lg="4">
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      className="no-focus-shadow"
                      placeholder={t("parkingNo")}
                      name="parkingNo"
                      value={form.parkingNo}
                      onChange={onUpdateField}
                      onBlur={onBlurField}
                      required
                    />
                    <label htmlFor="floatingInputCustom">
                      {t("parkingNo") + "*"}
                    </label>
                  </Form.Floating>
                  {errors.parkingNo.dirty && errors.parkingNo.error ? (
                    <p className="productErrorMessage">{t("parkingError")}</p>
                  ) : null}
                </Col>
                <Col lg="4">
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      className="no-focus-shadow"
                      placeholder={t("dimentions") + "(m)"}
                      name="dimention"
                      value={form.dimention}
                      onChange={onUpdateField}
                      onBlur={onBlurField}
                      required
                    />
                    <label htmlFor="floatingInputCustom">
                      {t("dimentions") + "(m)" + "*"}
                    </label>
                  </Form.Floating>
                  {errors.dimention.dirty && errors.dimention.error ? (
                    <p className="productErrorMessage">{t("dimentionError")}</p>
                  ) : null}
                </Col>
                <Col lg="4">
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      className="no-focus-shadow"
                      placeholder={t("parkingNo")}
                      name="parkingNo"
                      value={form.parkingNo}
                      onChange={onUpdateField}
                      onBlur={onBlurField}
                      required
                    />
                    <label htmlFor="floatingInputCustom">
                      {t("parkingNo") + "*"}
                    </label>
                  </Form.Floating>
                  {errors.parkingNo.dirty && errors.parkingNo.error ? (
                    <p className="productErrorMessage">{t("parkingError")}</p>
                  ) : null}
                </Col>
              </Row>
              <Box
                sx={{
                  border: "1px solid #e2e2e2",
                  marginY: "16px",
                }}
              />
              <Row>
                <Typography sx={{ marginBottom: "8px", fontWeight: 700 }}>
                  {t("electricalData")}
                </Typography>
                <Col lg="4">
                  <Form.Floating>
                    <Form.Control
                      type="number"
                      className="no-focus-shadow"
                      placeholder={t("power") + " " + "(kw)"}
                      name="power"
                      value={form.power}
                      onChange={onUpdateField}
                      onBlur={onBlurField}
                      required
                    />
                    <label htmlFor="floatingInputCustom">
                      {t("power") + " " + "(kw)" + "*"}
                    </label>
                  </Form.Floating>
                  {errors.power.dirty && errors.power.error ? (
                    <p className="productErrorMessage">{t("powerError")}</p>
                  ) : null}
                </Col>
                <Col lg="4">
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      className="no-focus-shadow"
                      placeholder={t("solarPanel")}
                      value={solarPanel}
                      onChange={(e) => setSolarPanel(e.target.value)}
                    />
                    <label htmlFor="floatingInputCustom">
                      {t("solarPanel")}
                    </label>
                  </Form.Floating>
                </Col>
                <Col lg="4">
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      className="no-focus-shadow"
                      placeholder={t("inverter")}
                      value={inverter}
                      onChange={(e) => setInverter(e.target.value)}
                    />
                    <label htmlFor="floatingInputCustom">{t("inverter")}</label>
                  </Form.Floating>
                </Col>
              </Row>
              <Box
                sx={{
                  border: "1px solid #e2e2e2",
                  marginY: "16px",
                }}
              />

              <Row style={{ marginY: "16px" }}>
                <Typography sx={{ marginBottom: "8px", fontWeight: 700 }}>
                  {t("description")}
                </Typography>
                <Col lg="12">
                  <Form.Floating>
                    <Form.Control
                      as="textarea"
                      className="no-focus-shadow"
                      placeholder={t("description")}
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      style={{ height: "100px" }}
                      onBlur={onBlurField}
                    />
                    <label htmlFor="floatingInputCustom">
                      {t("description")}
                    </label>
                  </Form.Floating>
                </Col>
              </Row>
              <Box
                sx={{
                  border: "1px solid #e2e2e2",
                  marginY: "16px",
                }}
              />
              <Row>
                <Typography sx={{ marginBottom: "8px", fontWeight: 700 }}>
                  {t("price") + " " + t("configurations")}
                </Typography>
                <Col lg="12">
                  <Form.Floating>
                    <Form.Control
                      type="number"
                      className="no-focus-shadow"
                      placeholder={t("price") + " " + "(€)"}
                      name="price"
                      value={form.price}
                      onChange={onUpdateField}
                      onBlur={onBlurField}
                      required
                    />
                    <label htmlFor="floatingInputCustom">
                      {t("price") + " " + "(€)" + "*"}
                    </label>
                  </Form.Floating>
                  {errors.price.dirty && errors.price.error ? (
                    <p className="productErrorMessage">{t("priceError")}</p>
                  ) : null}
                </Col>
              </Row>

              <Box
                sx={{
                  border: "1px solid #e2e2e2",
                  marginY: "16px",
                }}
              />

              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                  marginBottom: "4px",
                }}
              >
                {props.isEdit && (
                  <Grid item xs={1}>
                    <Button
                      sx={{
                        backgroundColor: "#ff6166",
                        "&:hover": {
                          backgroundColor: "#fa1919",
                        },
                      }}
                      fullWidth
                      variant="contained"
                      size="medium"
                      onClick={() =>
                        deleteItem(props.stateObj._id, 0, "product")
                      }
                    >
                      {t("delete")}
                    </Button>
                  </Grid>
                )}
                <Grid item xs={1} ml={1}>
                  <Button
                    fullWidth
                    onClick={
                      isEdit
                        ? () => fnEditProduct(props.stateObj._id)
                        : fnAddProduct
                    }
                    variant="contained"
                    sx={{
                      backgroundColor: "var(--primary-color)",
                      "&:hover": {
                        backgroundColor: "var(--primary-color-hover)",
                      },
                    }}
                    size="medium"
                  >
                    {t("save")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Modal>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
      >
        <Alert onClose={handleAlertClose} severity={severity}>
          {errorMsg}
        </Alert>
      </Snackbar>

      <AlertDialog
        open={openDeleteAlert}
        title={
          attachType === "product" ? "Delete Product" : "Delete Attachment"
        }
        message={
          attachType === "product"
            ? "Are you sure you want to delete the product?"
            : "Are you sure you want to delete the attachment?"
        }
        handleAction={confirmDeleteItem}
        handleClose={handleDeleteAlertClose}
      ></AlertDialog>
    </>
  );
};

export default AddCarPort;
