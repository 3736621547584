import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
import Container from "react-bootstrap/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import "css/EditSolarForm.css";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import axios from "axios";
import defaultString from "constants/defaultString.json";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AlertDialog from "components/AlertDialog/AlertDialog";
import { useAddBatteryValidator } from "hooks/useAddBatteryValidator";
import { AuthContext } from "context/AuthContext";
import { resetToken } from "apiURL";
import Box from "@mui/material/Box";
import { Divider, Grid, IconButton, Input } from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  maxHeight: "85%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px",
  overflowY: "scroll",
};

const AddBattery = (props) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [status, setStatus] = useState(false);
  const [list, setList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [name, setName] = useState(list[0]);
  const [parkingNo, setParkingNo] = useState("");
  const [description, setDescription] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [mainImage, setMainImage] = useState({ id: 0, logo: null });
  const [otherImages, setOtherImages] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [attachmentId, setAttachmentId] = useState("");
  const [isChangeMainImage, setIsChangeMainImage] = useState(false);
  const [indexToBeDelete, setIndexToBeDelete] = useState("");
  const [attachType, setAttachType] = useState("");
  const [logoImageError, setLogoImageError] = useState(false);
  const [manufacturerError, setManufacturerError] = useState(false);
  const [isDisabledSaveBtn, setIsDisabledSaveBtn] = useState(false);
  const [isDisabledDeleteBtn, setIsDisabledDeleteBtn] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const [form, setForm] = useState({
    modelNo: "",
    power: "",
    maxNoBatteryConnection: "",
    ip: "",
    price: "",
    dimentions: "",
    weight: "",
  });
  const [ip, setIp] = useState("");
  const [power, setPower] = useState("");
  const [maxNoBatteryConnection, setMaxNoBatteryConnection] = useState("");
  const [weight, setWeight] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [data, setData] = useState([]);
  const [severity, setSeverity] = useState("error");

  const { errors, validateForm, onBlurField } = useAddBatteryValidator(form);

  const navigate = useNavigate();
  const { state } = useLocation();

  const onUpdateField = (e) => {
    const field = e.target.name;
    const nextFormState = {
      ...form,
      [field]: e.target.value,
    };
    setForm(nextFormState);

    if (errors[field].dirty)
      validateForm({
        form: nextFormState,
        errors,
        field,
      });
  };

  console.log("123", props.stateObj);

  useEffect(() => {
    const getManufacturer = async () => {
      await axios
        .get(
          apiURL.service_list_all_manufacturer +
            "/" +
            localStorage.getItem("storage")
        )
        .then((res) => {
          if (res.status === apiStatusCode.SUCCESS) {
            setList(res.data.data.manufacturer);
          } else {
            console.log("In else portion ", res.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getManufacturer();
  }, []);

  useEffect(() => {
    if (props.stateObj) {
      setIsEdit(true);
      setForm({
        modelNo: props.stateObj.modelNo,
        power: props.stateObj.power,
        maxNoBatteryConnection: props.stateObj.maxNoBatteryConnection,
        ip: props.stateObj.ip,
        price: props.stateObj.price,
        dimentions: props.stateObj.dimensions,
        weight: props.stateObj.weight,
      });
      let prodStatus = props.stateObj.status === "Active" ? true : false;
      setStatus(prodStatus);
      setName(props.stateObj.manufacturerId);
      setDescription(props.stateObj.description);
      setParkingNo(props.stateObj.parkingNo);

      if (props.stateObj.mainImage.logo !== null) {
        setMainImage(props.stateObj.mainImage);
      }

      let uploadedArray = [];
      if (props.stateObj.otherImages.length > 0) {
        for (let i = 0; i < props.stateObj.otherImages.length; i++) {
          uploadedArray.push(props.stateObj.otherImages[i]);
        }
        setOtherImages(uploadedArray);
      }

      let uploadedDocArray = [];
      if (props.stateObj.documents.length > 0) {
        for (let i = 0; i < props.stateObj.documents.length; i++) {
          uploadedDocArray.push(props.stateObj.documents[i]);
        }
        setDocuments(uploadedDocArray);
      }
    }
  }, [props]);

  useEffect(() => {
    if (!props.isEdit) {
      setForm({
        modelNo: "",
        power: "",
        maxNoBatteryConnection: "",
        ip: "",
        price: "",
        dimentions: "",
        weight: "",
      });
      setName("");
      setStatus("");
      setDescription("");
      setParkingNo("");
      setMainImage("");
      setOtherImages("");
      setDocuments("");
    }
    if (props.handleClose) {
      setManufacturerError(false);
      setLogoImageError(false);
      validateForm({
        form: form,
        errors: {
          modelNo: "",
          power: "",
          maxNoBatteryConnection: "",
          ip: "",
          price: "",
          dimentions: "",
          weight: "",
        },
      });
    }
  }, [props.handleClose, props.isEdit]);

  const handleMainPicture = (event) => {
    const isLt2M = event.target.files[0].size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setSeverity("error");
      setOpen(true);
      setErrorMsg("Image must smaller than 2MB!");
      return;
    }
    setMainImage({ id: 0, logo: event.target.files[0] });
    setLogoImageError(false);
    setIsChangeMainImage(true);
  };

  const handleOtherImages = (event) => {
    let fileObj = [];
    fileObj.push(event.target.files);

    for (let i = 0; i < fileObj[0].length; i++) {
      let otherImageObj = {};
      otherImageObj.id = 0;
      otherImageObj.original = fileObj[0][i];

      const isLt2M = fileObj[0][i].size / 1024 / 1024 < 2;
      if (!isLt2M) {
        setSeverity("error");
        setOpen(true);
        setErrorMsg("Image must smaller than 2MB!");
        return;
      }
      setOtherImages((x) => [...x, otherImageObj]);
    }
  };

  const handleDocuments = (event) => {
    let attachmentObj = [];
    attachmentObj.push(event.target.files);

    for (let i = 0; i < attachmentObj[0].length; i++) {
      let docuObj = {};
      docuObj.id = 0;
      docuObj.document = attachmentObj[0][i];

      setDocuments((x) => [...x, docuObj]);
    }
  };

  const handleDeleteAlertClose = () => {
    setOpenDeleteAlert(false);
    setAttachmentId("");
    setAttachType("");
    setIndexToBeDelete("");
  };

  const confirmDeleteItem = async () => {
    console.log("attachmentId:: ", attachmentId);
    setOpenDeleteAlert(false);
    if (attachType == "mainImage") {
      if (attachmentId !== 0) {
        await fnDeleteAttachment(attachmentId);
      } else {
        setMainImage({ id: 0, logo: null });

        setLogoImageError(true);
      }
      let inputId = document.getElementById("formFile1");
      inputId.value = null;
    } else if (attachType === "otherImage") {
      console.log("indexToBeDelete: ", indexToBeDelete);
      if (attachmentId !== 0) {
        await fnDeleteAttachment(attachmentId);
      } else {
        const filteredArr = otherImages.filter(
          (item, index) => index !== indexToBeDelete
        );
        setOtherImages(filteredArr);
      }
      // let inputId = document.getElementById('otherImages');
      // inputId.value = null;
    } else if (attachType === "document") {
      console.log("indexToBeDelete: ", indexToBeDelete);
      if (attachmentId !== 0) {
        await fnDeleteAttachment(attachmentId);
      } else {
        const filteredArr = documents.filter(
          (item, index) => index !== indexToBeDelete
        );
        setDocuments(filteredArr);
      }
      // let inputId = document.getElementById('otherImages');
      // inputId.value = null;
    } else if (attachType === "product") {
      if (attachmentId !== 0) {
        await fnDeleteProduct(attachmentId);
      }
    }
  };

  const deleteItem = (itemId, index, attachmentType) => {
    // const s = attachments.filter((item, index) => index !== e);
    // setAttachments(s);
    // console.log(attachments)
    // console.log(s);
    setOpenDeleteAlert(true);
    setAttachmentId(itemId);
    setIndexToBeDelete(index);
    setAttachType(attachmentType);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const fnAddProduct = async (e) => {
    e.preventDefault();
    if (!name) {
      setManufacturerError(true);
    }

    if (!mainImage.logo) {
      setSeverity("error");
      setLogoImageError(true);
      setOpen(true);
      setErrorMsg(defaultString.validData);
    }
    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));
    const { isValid } = validateForm({ form, errors, forceTouchErrors: true });
    if (!isValid) {
      setSeverity("error");
      setOpen(true);
      setErrorMsg(defaultString.validData);
      return;
    }
    console.log(mainImage);

    if (otherImages.length > 5) {
      setSeverity("error");
      setOpen(true);
      setErrorMsg(defaultString.maxOtherLimitMsg);
      return;
    }

    if (documents.length > 5) {
      setSeverity("error");
      setOpen(true);
      setErrorMsg(defaultString.maxDocumentLimitMsg);
      return;
    }

    if (
      form.modelNo &&
      form.power &&
      form.maxNoBatteryConnection &&
      form.ip &&
      form.price &&
      form.dimentions &&
      form.weight
    ) {
      if (!mainImage.logo) {
        setLogoImageError(true);
        setSeverity("error");
        setOpen(true);
        setErrorMsg(defaultString.validData);
        return;
      }
      const formData = new FormData();

      if (mainImage.logo != null) {
        formData.append("mainImage", mainImage.logo);
      }

      console.log(otherImages);
      if (otherImages.length > 0) {
        otherImages.map((image, i) => {
          if (!!image.original) formData.append("otherImage", image.original);
        });
      }

      console.log(documents);
      if (documents.length > 0) {
        documents.map((image, i) => {
          if (!!image.document) formData.append("document", image.document);
        });
      }
      setIsDisabledSaveBtn(true);
      let prodStatus = status === true ? "Active" : "InActive";

      formData.append("status", prodStatus);
      formData.append("productId", localStorage.getItem("storage"));
      formData.append("manufacturerId", name);
      formData.append("modelNo", form.modelNo);
      formData.append("weight", form.weight);
      formData.append("dimensions", form.dimentions);
      formData.append("storageTypes", "Battery");
      formData.append("parkingNo", parkingNo);
      formData.append("power", form.power);
      formData.append("maxNoBatteryConnection", form.maxNoBatteryConnection);
      formData.append("ip", form.ip);
      formData.append("description", description);
      formData.append("price", form.price);

      await axios
        .post(apiURL.service_add_product, formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.status === apiStatusCode.CREATED) {
            setOpen(true);
            setSeverity("success");
            setErrorMsg(res.data.message);
            props.handleClose({
              reload: true,
            });
            navigate(`/admin/product/${localStorage.getItem("storage")}`);
          } else {
            console.log("In else portion ", res.data);
            setOpen(true);
            setIsDisabledSaveBtn(false);
            setSeverity("error");
            setErrorMsg(res.data.message);
            props.handleClose({
              reload: true,
            });
          }
        })
        .catch((error) => {
          console.log(error.response);
          setOpen(true);
          setSeverity("error");
          setIsDisabledSaveBtn(false);
          if (error.code === defaultString.network_error) {
            setSeverity("error");
            setErrorMsg(error.message);
          } else {
            setSeverity("error");
            setErrorMsg(error.response.data.message);
          }
          if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
            doLogout(error);
            setSeverity("error");
          }
        });
    } else {
      setOpen(true);
      setSeverity("error");
      setErrorMsg(defaultString.validData);
    }
  };

  const fnEditProduct = async (productDetailId) => {
    // e.preventDefault();
    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));
    // if (!name) {
    //   setManufacturerError(true);
    // }

    if (!mainImage.logo) {
      setLogoImageError(true);
      setSeverity("error");
      setOpen(true);
      setErrorMsg(defaultString.validData);
    }
    const { isValid } = validateForm({ form, errors, forceTouchErrors: true });
    if (!isValid) {
      setSeverity("error");
      setOpen(true);
      setErrorMsg(defaultString.validData);
      return;
    }
    if (
      form.modelNo &&
      form.power &&
      form.maxNoBatteryConnection &&
      form.ip &&
      form.price &&
      form.dimentions &&
      form.weight
    ) {
      if (!mainImage.logo) {
        setLogoImageError(true);
        setSeverity("error");
        setOpen(true);
        setErrorMsg(defaultString.validData);
        return;
      }
      if (otherImages.length > 5) {
        setSeverity("error");
        setOpen(true);
        setErrorMsg(defaultString.maxOtherLimitMsg);
        return;
      }

      if (documents.length > 5) {
        setSeverity("error");
        setOpen(true);
        setErrorMsg(defaultString.maxDocumentLimitMsg);
        return;
      }

      const formData = new FormData();

      if (!!mainImage.logo && mainImage.id === 0) {
        if (isChangeMainImage === true) {
          formData.append("mainImage", mainImage.logo);
        }
      }

      console.log(otherImages);
      if (otherImages.length > 0) {
        otherImages.map((image, i) => {
          console.log(image);
          if (!!image.original && image.id === 0)
            formData.append("otherImage", image.original);
        });
      }

      console.log(documents);
      if (documents.length > 0) {
        documents.map((image, i) => {
          if (!!image.document && image.id === 0)
            formData.append("document", image.document);
        });
      }

      setIsDisabledSaveBtn(true);
      let prodStatus = status === true ? "Active" : "InActive";
      console.log(prodStatus);
      formData.append("productDetailId", productDetailId);
      // formData.append('productId',state.productId._id)
      formData.append("status", prodStatus);
      formData.append("productId", localStorage.getItem("storage"));
      formData.append("manufacturerId", name._id);
      formData.append("modelNo", form.modelNo);
      formData.append("weight", form.weight);
      formData.append("dimensions", form.dimentions);
      formData.append("storageTypes", "Battery");
      formData.append("parkingNo", parkingNo);
      formData.append("power", form.power);
      formData.append("maxNoBatteryConnection", form.maxNoBatteryConnection);
      formData.append("ip", form.ip);
      formData.append("description", description);
      formData.append("price", form.price);

      await axios
        .post(apiURL.service_edit_product, formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.status === apiStatusCode.SUCCESS) {
            setOpen(true);
            setIsEdit(false);
            setSeverity("success");
            setErrorMsg(res.data.message);
            props.handleClose({
              reload: true,
            });
          } else {
            console.log("In else portion ", res.data);
            setOpen(true);
            setIsDisabledSaveBtn(false);
            setSeverity("error");
            setErrorMsg(res.data.message);
            props.handleClose({
              reload: true,
            });
          }
        })
        .catch((error) => {
          console.log(error.response);
          setOpen(true);
          setSeverity("error");
          setIsDisabledSaveBtn(false);
          if (error.code === defaultString.network_error)
            setErrorMsg(error.message);
          else setErrorMsg(error.response.data.message);
          if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
            doLogout(error);
            setSeverity("error");
          }
        });
    } else {
      setOpen(true);
      setSeverity("error");
      setErrorMsg(defaultString.validData);
    }
  };

  const fnDeleteAttachment = async (attachmentId) => {
    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));

    await axios
      .delete(apiURL.service_delete_attachment + `/${attachmentId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === apiStatusCode.DELETED) {
          setSeverity("success");
          setOpen(true);
          setErrorMsg(res.data.message);

          if (attachType == "mainImage") {
            setMainImage({ id: 0, logo: null });
          } else if (attachType == "otherImage") {
            const filteredArr = otherImages.filter(
              (item) => item.id !== attachmentId
            );
            setOtherImages(filteredArr);
          } else if (attachType == "document") {
            const filteredArr = documents.filter(
              (item) => item.id !== attachmentId
            );
            setDocuments(filteredArr);
          }

          setAttachmentId("");
        } else {
          setOpenDeleteAlert(false);
          console.log("In else portion ", res.data);
          setSeverity("error");
          setOpen(true);
          setErrorMsg(res.data.data.message);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setSeverity("error");
        setOpen(true);
        if (error.code === defaultString.network_error)
          setErrorMsg(error.message);
        else setErrorMsg(error.response.data.message);
        if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
          doLogout(error);
        }
      });
  };

  const fnDeleteProduct = async (attachmentId) => {
    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));
    console.log("token ", token);
    const objProduct = JSON.stringify({
      productDetailIds: [attachmentId],
    });

    console.log(objProduct);
    setIsDisabledDeleteBtn(true);
    await axios
      .post(apiURL.service_delete_products, objProduct, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === apiStatusCode.DELETED) {
          setOpen(true);
          setSeverity("success");
          setErrorMsg(res.data.message);
          setAttachmentId("");
          props.handleClose({
            reload: true,
          });
          // navigate(`/admin/product/${localStorage.getItem("storage")}`);
        } else {
          console.log("In else portion ", res.data);
          setOpen(true);
          setIsDisabledDeleteBtn(false);
          setSeverity("error");
          setErrorMsg(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setOpen(true);
        setIsDisabledDeleteBtn(false);
        if (error.code === defaultString.network_error) {
          setSeverity("error");
          setErrorMsg(error.message);
        } else {
          setSeverity("error");
          setErrorMsg(error.response.data.message);
        }
        if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
          doLogout(error);
        }
      });
  };

  const onStatusChange = () => {
    setStatus(!status);
  };

  const doLogout = async (resErr) => {
    const data = await resetToken();
    if (data === true) {
      dispatch({ type: "LOGOUT", payload: null });
      navigate("/", {
        state: { isModalPopup: true, errorMsg: resErr.response.data.message },
      });
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <>
      <>
        <Modal
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={props.open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              width: "70%",
              backgroundColor: "white",
              borderRadius: 2,
              height: "80%",
            }}
          >
            <Box
              sx={{
                padding: "8px 16px",
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "white",
                borderBottom: "1px solid #d4d4d4",
                display: "flex",
                justifyContent: "space-between",
                borderRadius: "16px 16px 0 0",
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  fontWeight: 700,
                  fontSize: "24px",
                  marginRight: "24px",
                  color: "var(--primary-color)",
                }}
              >
                {t("add") + " " + t("battery")}
              </Typography>

              <IconButton
                onClick={() => {
                  props.handleClose();
                }}
              >
                <Close />
              </IconButton>
            </Box>

            <Container
              fluid="md"
              style={{
                height: "90%",
                overflowY: "scroll",
              }}
            >
              <Box>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 700 }}
                  className="my-2"
                >
                  {t("status")}
                </Typography>
                <Row>
                  <Col>
                    <Form>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        name="status"
                        checked={status}
                        value={status}
                        className="custom-switch-lg"
                        onChange={onStatusChange}
                      />
                    </Form>
                  </Col>
                </Row>

                <Typography
                  sx={{ fontSize: "16px", fontWeight: 700 }}
                  className="my-2"
                >
                  {t("images")}
                </Typography>
                <Row>
                  <Col lg="6">
                    <Form.Group controlId="formFile1" className="mb-3">
                      <Form.Label>{t("chooseMainImage") + "*"}</Form.Label>
                      <Form.Control
                        type="file"
                        className="no-focus-shadow"
                        onChange={handleMainPicture}
                        name="mainImage"
                        accept="image/*"
                      />
                      <div className="sec-row">
                        {!!mainImage.logo && (
                          <>
                            {mainImage.id === 0 ? (
                              <img
                                key={mainImage.logo.name}
                                className="mt-3"
                                accept="image/*"
                                src={
                                  mainImage.logo !== null ||
                                  mainImage.logo !== undefined
                                    ? URL.createObjectURL(mainImage.logo)
                                    : null
                                }
                                alt={mainImage.logo.name}
                                height={80}
                                width={80}
                              />
                            ) : (
                              <img
                                key={mainImage.logo}
                                className="mt-3"
                                accept="image/*"
                                src={
                                  mainImage.logo
                                    ? apiURL.siteBaseUrl + mainImage.logo
                                    : null
                                }
                                alt={mainImage.logo}
                                height={80}
                                width={80}
                              />
                            )}

                            <MdCancel
                              fontSize={20}
                              className="close-icon"
                              onClick={() =>
                                deleteItem(mainImage.id, 0, "mainImage")
                              }
                            />
                          </>
                        )}
                        {logoImageError ? (
                          <p className="productErrorMessage">
                            {t("logoError")}
                          </p>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="6">
                    <Form.Group>
                      <Form.Label>{t("chooseOtherImage")}</Form.Label>
                      <Form.Control
                        type="file"
                        className="no-focus-shadow"
                        multiple
                        onChange={handleOtherImages}
                        id="otherImages"
                        accept="image/*"
                      />
                      <div className="sec-row me-3">
                        {otherImages.length > 0 &&
                          otherImages.map((item, index) => {
                            return (
                              !!item.original && (
                                <div key={index} className="img-list">
                                  {item.id === 0 ? (
                                    <img
                                      className="mt-3"
                                      accept="image/*"
                                      src={
                                        !!item.original
                                          ? URL.createObjectURL(item.original)
                                          : null
                                      }
                                      alt={item.original.name}
                                      height={80}
                                      width={80}
                                    />
                                  ) : (
                                    <img
                                      className="mt-3"
                                      accept="image/*"
                                      src={
                                        !!item.original
                                          ? apiURL.siteBaseUrl + item.original
                                          : null
                                      }
                                      alt={item.original}
                                      height={80}
                                      width={80}
                                    />
                                  )}

                                  <MdCancel
                                    fontSize={20}
                                    className="close-icon"
                                    onClick={() =>
                                      deleteItem(item.id, index, "otherImage")
                                    }
                                  />
                                </div>
                              )
                            );
                          })}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Box
                  sx={{
                    border: "1px solid #e2e2e2",
                    marginY: "16px",
                  }}
                />
                <Row>
                  <Typography sx={{ marginBottom: "8px", fontWeight: 700 }}>
                    {t("documents")}
                  </Typography>
                  <Col lg="12">
                    <Form.Group controlId="formFile2" className="mb-3">
                      <Form.Label>{t("uploadDocuments")}</Form.Label>
                      <Form.Control
                        type="file"
                        className="no-focus-shadow"
                        onChange={handleDocuments}
                        multiple
                        accept="application/pdf,.doc,.docx"
                      />
                      {documents.length > 0 &&
                        documents.map((item, index) => {
                          return (
                            !!item.document && (
                              <div key={index} className="img-list">
                                {item.id === 0 ? (
                                  <h6 className="mt-1 ms-2">
                                    {item.document.name}
                                  </h6>
                                ) : (
                                  <h6 className="mt-1 ms-2">
                                    {
                                      item.document.split(
                                        "/productDocument/"
                                      )[1]
                                    }
                                  </h6>
                                )}
                                <MdCancel
                                  fontSize={20}
                                  className="doc-icon"
                                  onClick={() =>
                                    deleteItem(item.id, index, "document")
                                  }
                                />
                              </div>
                            )
                          );
                        })}
                      {/* <h5 className='mt-3'>Datasheet.pdf (178Kb)</h5>
                    <h5 className='mt-3'>Manufacturer certification.pdf (313Kb)</h5> */}
                    </Form.Group>
                  </Col>
                </Row>
                <Box
                  sx={{
                    border: "1px solid #e2e2e2",
                    marginY: "16px",
                  }}
                />
                <Row>
                  <Typography sx={{ marginBottom: "8px", fontWeight: 700 }}>
                    {t("manufacturer")} {t("data")}
                  </Typography>
                  <Col lg="6">
                    <FloatingLabel
                      className="custom-select"
                      controlId="floatingSelectGrid"
                      label={t("manufacturer") + "*"}
                    >
                      <Form.Select
                        style={{
                          color: "var(title-color) !important",
                        }}
                        className="no-focus-shadow"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        aria-label="Floating label select example"
                      >
                        <option>Select an option</option>
                        {console.log("name", name)}
                        {list &&
                          list.map((item, index) => (
                            <option key={item._id} value={item._id}>
                              {item?.name}
                            </option>
                          ))}
                      </Form.Select>
                    </FloatingLabel>
                    {manufacturerError ? (
                      <p className="productErrorMessage mb-0">
                        {t("manufacturerNameError")}
                      </p>
                    ) : null}
                  </Col>
                  <Col lg="6">
                    <Form.Floating>
                      <Form.Control
                        type="text"
                        className="no-focus-shadow"
                        placeholder={t("electricalCharacteristics")}
                        name="modelNo"
                        value={form.modelNo}
                        onChange={onUpdateField}
                        onBlur={onBlurField}
                        required
                      />
                      <label htmlFor="floatingInputCustom">
                        {t("electricalCharacteristics") + "*"}
                      </label>
                    </Form.Floating>
                    {errors.modelNo.dirty && errors.modelNo.error ? (
                      <p className="productErrorMessage">{t("modalError")}</p>
                    ) : null}
                  </Col>
                </Row>
                <Box
                  sx={{
                    border: "1px solid #e2e2e2",
                    marginY: "16px",
                  }}
                />
                <Row>
                  <Typography sx={{ marginBottom: "8px", fontWeight: 700 }}>
                    {t("mechanicalData")}
                  </Typography>
                  <Col lg="4">
                    <Form.Floating>
                      <Form.Control
                        type="number"
                        className="no-focus-shadow"
                        placeholder={t("weight") + "(kg)"}
                        name="weight"
                        value={form.weight}
                        onChange={onUpdateField}
                        onBlur={onBlurField}
                        required
                      />
                      <label htmlFor="floatingInputCustom">
                        {t("weight") + "(kg)" + "*"}
                      </label>
                    </Form.Floating>
                    {errors.weight.dirty && errors.weight.error ? (
                      <p className="productErrorMessage">{t("weightError")}</p>
                    ) : null}
                  </Col>
                  <Col lg="4">
                    <Form.Floating>
                      <Form.Control
                        type="text"
                        className="no-focus-shadow"
                        placeholder={t("dimentions") + "(mm)"}
                        name="dimentions"
                        value={form.dimentions}
                        onChange={onUpdateField}
                        onBlur={onBlurField}
                        required
                      />
                      <label htmlFor="floatingInputCustom">
                        {t("dimentions") + "(mm)" + "*"}
                      </label>
                    </Form.Floating>
                    {errors.dimentions.dirty && errors.dimentions.error ? (
                      <p className="productErrorMessage">
                        {t("dimentionError")}
                      </p>
                    ) : null}
                  </Col>
                  <Col lg="4">
                    <Form.Floating>
                      <Form.Control
                        type="text"
                        className="no-focus-shadow"
                        placeholder={t("parkingNo")}
                        value={parkingNo}
                        onChange={(e) => setParkingNo(e.target.value)}
                      />
                      <label htmlFor="floatingInputCustom">
                        {t("parkingNo")}
                      </label>
                    </Form.Floating>
                  </Col>
                </Row>
                <Box
                  sx={{
                    border: "1px solid #e2e2e2",
                    marginY: "16px",
                  }}
                />
                <Row>
                  <Typography sx={{ marginBottom: "8px", fontWeight: 700 }}>
                    {t("electricalData")}
                  </Typography>
                  <Col lg="4">
                    <Form.Floating>
                      <Form.Control
                        type="number"
                        className="no-focus-shadow"
                        placeholder={t("power") + " " + "(kw)"}
                        name="power"
                        value={form.power}
                        onChange={onUpdateField}
                        onBlur={onBlurField}
                        required
                      />
                      <label htmlFor="floatingInputCustom">
                        {t("power") + " " + "(kw)" + "*"}
                      </label>
                    </Form.Floating>
                    {errors.power.dirty && errors.power.error ? (
                      <p className="productErrorMessage">{t("powerError")}</p>
                    ) : null}
                  </Col>
                  <Col lg="4">
                    <Form.Floating>
                      <Form.Control
                        type="number"
                        className="no-focus-shadow"
                        placeholder={t("maxBattery")}
                        name="maxNoBatteryConnection"
                        value={form.maxNoBatteryConnection}
                        onChange={onUpdateField}
                        onBlur={onBlurField}
                        required
                      />
                      <label htmlFor="floatingInputCustom">
                        {t("maxBattery") + "*"}
                      </label>
                    </Form.Floating>
                    {errors.maxNoBatteryConnection.dirty &&
                    errors.maxNoBatteryConnection.error ? (
                      <p className="productErrorMessage">
                        {t("maxNoBatteryConnectionError")}
                      </p>
                    ) : null}
                  </Col>
                  <Col lg="4">
                    <Form.Floating>
                      <Form.Control
                        type="text"
                        className="no-focus-shadow"
                        placeholder={t("ip")}
                        name="ip"
                        value={form.ip}
                        onChange={onUpdateField}
                        onBlur={onBlurField}
                        required
                      />
                      <label htmlFor="floatingInputCustom">
                        {t("ip") + "*"}
                      </label>
                    </Form.Floating>
                    {errors.ip.dirty && errors.ip.error ? (
                      <p className="productErrorMessage">{t("ipError")}</p>
                    ) : null}
                  </Col>
                </Row>
                <Box
                  sx={{
                    border: "1px solid #e2e2e2",
                    marginY: "16px",
                  }}
                />
                <Row>
                  <Typography sx={{ marginBottom: "8px", fontWeight: 700 }}>
                    {t("description")}
                  </Typography>
                  <Col lg="12">
                    <Form.Floating>
                      <Form.Control
                        as="textarea"
                        style={{ height: "100px" }}
                        className="no-focus-shadow"
                        placeholder={t("description")}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      <label htmlFor="floatingInputCustom">
                        {t("description")}
                      </label>
                    </Form.Floating>
                  </Col>
                </Row>
                <Box
                  sx={{
                    border: "1px solid #e2e2e2",
                    marginY: "16px",
                  }}
                />
                <Row>
                  <Typography sx={{ marginBottom: "8px", fontWeight: 700 }}>
                    {t("price") + t("configuration")}
                  </Typography>
                  <Col lg="12">
                    <Form.Floating>
                      <Form.Control
                        type="number"
                        className="no-focus-shadow"
                        placeholder={t("price")}
                        name="price"
                        value={form.price}
                        onChange={onUpdateField}
                        onBlur={onBlurField}
                        required
                      />
                      <label htmlFor="floatingInputCustom">
                        {t("price") + "*"}
                      </label>
                    </Form.Floating>
                    {errors.price.dirty && errors.price.error ? (
                      <p className="productErrorMessage">{t("priceError")}</p>
                    ) : null}
                  </Col>
                </Row>

                <Box
                  sx={{
                    border: "1px solid #e2e2e2",
                    marginY: "16px",
                  }}
                />
                <Grid
                  container
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    width: "100%",
                    marginBottom: "4px",
                  }}
                >
                  {props.isEdit && (
                    <Grid item xs={1}>
                      <Button
                        sx={{
                          backgroundColor: "#ff6166",
                          "&:hover": {
                            backgroundColor: "#fa1919",
                          },
                        }}
                        fullWidth
                        variant="contained"
                        size="medium"
                        onClick={() =>
                          deleteItem(props.stateObj._id, 0, "product")
                        }
                      >
                        {t("delete")}
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={1} ml={1}>
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={isDisabledSaveBtn}
                      onClick={
                        isEdit
                          ? () => fnEditProduct(props.stateObj._id)
                          : fnAddProduct
                      }
                      sx={{
                        backgroundColor: "var(--primary-color)",
                        "&:hover": {
                          backgroundColor: "var(--primary-color-hover)",
                        },
                      }}
                      size="medium"
                    >
                      {t("save")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Modal>
      </>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
      >
        <Alert onClose={handleAlertClose} severity={severity}>
          {errorMsg}
        </Alert>
      </Snackbar>

      <AlertDialog
        open={openDeleteAlert}
        title={
          attachType === "product" ? "Delete Product" : "Delete Attachment"
        }
        message={
          attachType === "product"
            ? "Are you sure you want to delete the product?"
            : "Are you sure you want to delete the attachment?"
        }
        handleAction={confirmDeleteItem}
        handleClose={handleDeleteAlertClose}
      ></AlertDialog>
    </>
  );
};

export default AddBattery;
