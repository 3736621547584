import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
import "css/EditSolarForm.css";
import Container from "react-bootstrap/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { FaArrowLeft, FaEyeSlash, FaEye } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import countryList from "react-select-country-list";
import InputGroup from "react-bootstrap/InputGroup";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import axios from "axios";
import defaultString from "constants/defaultString.json";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AlertDialog from "components/AlertDialog/AlertDialog";
import { useAddCompanyAgentValidator } from "hooks/useAddCompanyAgentValidator";
import { AuthContext } from "context/AuthContext";
import { resetToken } from "apiURL";
import { IconButton, Modal } from "@mui/material";
import { Box, Grid, Typography } from "@mui/material";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { Close } from "@mui/icons-material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const AddCompanyAgent = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation();
  const [sdi, setSdi] = useState("");
  const [pec, setPec] = useState("");
  const [address, setAddress] = useState("");
  const [pin, setPin] = useState("");
  const [country, setCountry] = useState("Italy");
  const [code, setCode] = useState("");
  const [mainImage, setMainImage] = useState({ id: 0, logo: null });
  const options = useMemo(() => countryList().getData(), []);
  const [attachmentId, setAttachmentId] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealCPwd, setIsRevealCPwd] = useState(false);
  const [status, setStatus] = useState(false);
  const [passport, setPassport] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [indexToBeDelete, setIndexToBeDelete] = useState("");
  const [attachType, setAttachType] = useState("");
  const [isChangeMainImage, setIsChangeMainImage] = useState(false);
  const [logoImageError, setLogoImageError] = useState(false);
  const [isDisabledSaveBtn, setIsDisabledSaveBtn] = useState(false);
  const [isDisabledDeleteBtn, setIsDisabledDeleteBtn] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [reload, setReload] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const [form, setForm] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    fiscalCode: "",
    vat: "",
    discountReward: "",
  });
  const { errors, validateForm, onBlurField } =
    useAddCompanyAgentValidator(form);

  const navigate = useNavigate();
  const { state } = useLocation();
  const [openModal, setOpenModal] = React.useState(false);
  const handleClose = () => setOpenModal(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "65%",
    maxHeight: "85%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "16px",
    overflowY: "scroll",
  };

  const onUpdateField = (e) => {
    const field = e.target.name;
    const nextFormState = {
      ...form,
      [field]: e.target.value,
    };
    setForm(nextFormState);

    if (errors[field].dirty)
      validateForm({
        form: nextFormState,
        errors,
        field,
      });
  };
  // const [error, setError] = useState({
  //     email: '',
  //     password: '',
  //     confirmPassword: '',
  //     companyName: ''

  // })

  // const onInputChange = e => {
  //     const { name, value } = e.target;
  //     setInput(prev => ({
  //         ...prev,
  //         [name]: value
  //     }));
  //     validateInput(e);
  // }

  // const validateInput = e => {
  //     let { name, value } = e.target;
  //     console.log('name:value', name, value)
  //     setError(prev => {
  //         const stateObj = { ...prev, [name]: "" };

  //         switch (name) {
  //             case "email":
  //                 if (!value) {
  //                     stateObj[name] = "Email is required!";
  //                 } else if (!new RegExp(/\S+@\S+\.\S+/).test(value)) {
  //                     stateObj[name] = "Incorrect email format";
  //                 }
  //                 break;
  //             case "password":
  //                 if (!value) {
  //                     stateObj[name] = "Please enter Password.";
  //                 } else if (input.confirmPassword && value !== input.confirmPassword) {
  //                     stateObj["confirmPassword"] = "Password and Confirm Password does not match.";
  //                 } else {
  //                     stateObj["confirmPassword"] = input.confirmPassword ? "" : error.confirmPassword;
  //                 }
  //                 break;

  //             case "confirmPassword":
  //                 if (!value) {
  //                     stateObj[name] = "Please enter Confirm Password.";
  //                 } else if (input.password && value !== input.password) {
  //                     stateObj[name] = "Password and Confirm Password does not match.";
  //                 }
  //                 break;
  //             case "companyName":
  //                 if (!value) {
  //                     stateObj[name] = "Please enter Company Name.";
  //                 }
  //                 break;

  //             default:
  //                 break;
  //         }

  //         return stateObj;
  //     });
  // }

  console.log("props.stateObj", props.stateObj);

  useEffect(() => {
    if (props.stateObj) {
      setIsEdit(true);

      setForm({
        email: props.stateObj.email,
        companyName: props.stateObj.companyName,
        fiscalCode: props.stateObj.fiscalCode,
        vat: props.stateObj.vat,
        discountReward: props.stateObj.discountReward,
      });
      let prodStatus = props.stateObj.status === "Active" ? true : false;
      setStatus(prodStatus);
      setPassport(props.stateObj.passportId);
      setSdi(props.stateObj.sdi);
      setAddress(props.stateObj.address);
      setPin(props.stateObj.pinCode);
      setCountry(props.stateObj.country);
      setPec(props.stateObj.pec);
      setCode(props.stateObj.code);

      if (props.stateObj.mainImage.logo !== null) {
        setMainImage(props.stateObj.mainImage);
      }

      let uploadedDocArray = [];
      if (props.stateObj.documents.length > 0) {
        for (let i = 0; i < props.stateObj.documents.length; i++) {
          uploadedDocArray.push(props.stateObj.documents[i]);
        }
        setDocuments(uploadedDocArray);
      }
    }
  }, [props]);

  useEffect(() => {
    if (!props.isEdit) {
      setForm({
        email: "",
        password: "",
        confirmPassword: "",
        companyName: "",
        fiscalCode: "",
        vat: "",
        discountReward: "",
      });
      setStatus("");
      setPassport("");
      setSdi("");
      setAddress("");
      setPin("");
      setCountry("");
      setPec("");
      setCode("");
      setMainImage("");
      setDocuments("");
    }
    if (props.handleClose) {
      setLogoImageError(false);
      validateForm({
        form: form,
        errors: {
          email: "",
          password: "",
          confirmPassword: "",
          companyName: "",
          fiscalCode: "",
          vat: "",
          discountReward: "",
        },
      });
    }
  }, [props.handleClose, props.isEdit]);

  const handleMainPicture = (event) => {
    const isLt2M = event.target.files[0].size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setSeverity("error")
      setOpen(true);
      setErrorMsg("Image must smaller than 2MB!");
      return;
    }
    setMainImage({ id: 0, logo: event.target.files[0] });
    setLogoImageError(false);
    setIsChangeMainImage(true);
  };

  const handleDocuments = (event) => {
    let attachmentObj = [];
    attachmentObj.push(event.target.files);

    for (let i = 0; i < attachmentObj[0].length; i++) {
      let docuObj = {};
      docuObj.id = 0;
      docuObj.document = attachmentObj[0][i];

      setDocuments((x) => [...x, docuObj]);
    }
  };
  const handleDeleteAlertClose = () => {
    setOpenDeleteAlert(false);
    setAttachmentId("");
    setAttachType("");
    setIndexToBeDelete("");
  };

  const confirmDeleteItem = async () => {
    console.log("attachmentId:: ", attachmentId);
    setOpenDeleteAlert(false);
    if (attachType == "mainImage") {
      if (attachmentId !== 0) {
        await fnDeleteAttachment(attachmentId);
      } else {
        setMainImage({ id: 0, logo: null });

        setLogoImageError(true);
      }
      let inputId = document.getElementById("formFile1");
      inputId.value = null;
    } else if (attachType === "document") {
      console.log("indexToBeDelete: ", indexToBeDelete);
      if (attachmentId !== 0) {
        await fnDeleteAttachment(attachmentId);
      } else {
        const filteredArr = documents.filter(
          (item, index) => index !== indexToBeDelete
        );
        setDocuments(filteredArr);
      }
      // let inputId = document.getElementById('otherImages');
      // inputId.value = null;
    } else if (attachType === "product") {
      if (attachmentId !== 0) {
        await fnDeleteProduct(attachmentId);
      }
    }
  };
  const deleteItem = (itemId, index, attachmentType) => {
    // const s = attachments.filter((item, index) => index !== e);
    // setAttachments(s);
    // console.log(attachments)
    // console.log(s);
    setOpenDeleteAlert(true);
    setAttachmentId(itemId);
    setIndexToBeDelete(index);
    setAttachType(attachmentType);
  };
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const fnAddUser = async (e) => {
    e.preventDefault();

    if (!mainImage.logo) {
      setLogoImageError(true);
      setSeverity("error")
      setOpen(true);
      setErrorMsg(defaultString.validData);
    }
    console.log("mainImage", mainImage);
    const { isValid } = validateForm({ form, errors, forceTouchErrors: true });
    if (!isValid) {
      setSeverity("error")
      setOpen(true);
      setErrorMsg(defaultString.validData);
      return;
    }

    if (documents.length > 5) {
      setSeverity("error")
      setOpen(true);
      setErrorMsg(defaultString.maxDocumentLimitMsg);
      return;
    }

    if (
      form.email &&
      form.password &&
      form.companyName &&
      form.fiscalCode &&
      form.vat &&
      form.discountReward
    ) {
      if (!mainImage.logo) {
        setLogoImageError(true);
        setSeverity("error")
        setOpen(true);
        setErrorMsg(defaultString.validData);
        return;
      }
      const formData = new FormData();
      const user = JSON.parse(localStorage.getItem("user"));

      if (mainImage.logo != null) {
        formData.append("mainImage", mainImage.logo);
      }

      console.log(documents);
      if (documents.length > 0) {
        documents.map((image, i) => {
          if (!!image.document) formData.append("document", image.document);
        });
      }
      setIsDisabledSaveBtn(true);
      console.log(status);
      let prodStatus = status === true ? "Active" : "InActive";
      console.log(prodStatus);

      formData.append("status", prodStatus);
      formData.append("isSignin", 0);
      formData.append("role", "CompanyAgent"); // It will be different as per user
      formData.append("email", form.email);
      formData.append("password", form.password);
      formData.append("companyName", form.companyName);
      formData.append("vat", form.vat);
      formData.append("fiscalCode", form.fiscalCode);
      formData.append("discountReward", form.discountReward);
      formData.append("sdi", sdi);
      formData.append("pec", pec);
      formData.append("address", address);
      formData.append("pinCode", pin);
      formData.append("country", country);
      formData.append("code", code);
      formData.append("passportId", passport);
      formData.append("createdby", user._id);

      await axios
        .post(apiURL.service_create_user, formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            // 'Authorization': token
          },
        })
        .then((res) => {
          if (res.status === apiStatusCode.CREATED) {
            setOpen(true);
            setSeverity("success");
            setErrorMsg(res.data.message);
            props.handleClose({
              reload: true,
            });
            // navigate(`/admin/users-list/company-agent`);
          } else {
            console.log("In else portion ", res.data);
            setSeverity("error")
            setOpen(true);
            setIsDisabledSaveBtn(false);
            setErrorMsg(res.data.data.message);
          }
        })
        .catch((error) => {
          console.log(error.response);
          setSeverity("error")
          setOpen(true);
          setIsDisabledSaveBtn(false);
          if (error.code === defaultString.network_error)
            setErrorMsg(error.message);
          else setErrorMsg(error.response.data.message);
          if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
            doLogout(error);
          }
        });
    } else {
      setSeverity("error")
      setOpen(true);
      setErrorMsg(defaultString.validData);
    }
  };

  const fnEditUser = async (userId) => {
    // e.preventDefault();

    if (!mainImage.logo) {
      setLogoImageError(true);
      setSeverity("error")
      setOpen(true);
      setErrorMsg(defaultString.validData);
    }
    console.log("mainImage", mainImage);

    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));

    const { isValid } = validateForm({ form, errors, forceTouchErrors: true });

    console.log("isValid", isValid);
    // if (!isValid) {
    //     setOpen(true);
    //     setErrorMsg(defaultString.validData)
    //     console.log(isValid);
    //     return
    // };

    if (
      form.email &&
      form.companyName &&
      form.fiscalCode &&
      form.vat &&
      form.discountReward
    ) {
      if (!mainImage.logo) {
        setSeverity("error")
        setLogoImageError(true);
        setOpen(true);
        setErrorMsg(defaultString.validData);
        return;
      }

      if (documents.length > 5) {
        setSeverity("error")
        setOpen(true);
        setErrorMsg(defaultString.maxDocumentLimitMsg);
        return;
      }

      const formData = new FormData();

      if (!!mainImage.logo && mainImage.id === 0) {
        if (isChangeMainImage === true) {
          formData.append("mainImage", mainImage.logo);
        }
      }

      console.log(documents);
      if (documents.length > 0) {
        documents.map((image, i) => {
          if (!!image.document && image.id === 0)
            formData.append("document", image.document);
        });
      }
      setIsDisabledSaveBtn(true);
      let prodStatus = status === true ? "Active" : "InActive";
      console.log(prodStatus);
      formData.append("status", prodStatus);
      formData.append("userId", userId);
      formData.append("role", "CompanyAgent"); // It will be different as per user
      formData.append("email", form.email);
      formData.append("password", form.password);
      formData.append("companyName", form.companyName);
      formData.append("vat", form.vat);
      formData.append("fiscalCode", form.fiscalCode);
      formData.append("discountReward", form.discountReward);
      formData.append("sdi", sdi);
      formData.append("pec", pec);
      formData.append("address", address);
      formData.append("pinCode", pin);
      formData.append("country", country);
      formData.append("code", code);
      formData.append("passportId", passport);

      await axios
        .post(apiURL.service_update_user, formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.status === apiStatusCode.SUCCESS) {
            setOpen(true);
            setSeverity("success");
            setIsEdit(false);
            setErrorMsg(res.data.message);
            props.handleClose({ reload: true });
          } else {
            console.log("In else portion ", res.data);
            setSeverity("error")
            setOpen(true);
            setIsDisabledSaveBtn(false);
            setErrorMsg(res.data.data.message);
          }
        })
        .catch((error) => {
          console.log(error.response);
          setSeverity("error")
          setOpen(true);
          setIsDisabledSaveBtn(false);
          if (error.code === defaultString.network_error)
            setErrorMsg(error.message);
          else setErrorMsg(error.response.data.message);
          if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
            doLogout(error);
          }
        });
    } else {
      setSeverity("error")
      setOpen(true);
      setErrorMsg(defaultString.validData);
    }
  };

  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };

  const fnDeleteAttachment = async (attachmentId) => {
    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));

    await axios
      .delete(apiURL.service_delete_user_attachment + `/${attachmentId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === apiStatusCode.DELETED) {
          setOpen(true);
          setErrorMsg(res.data.message);
          setSeverity("success");
          if (attachType == "mainImage") {
            setMainImage({ id: 0, logo: null });
          } else if (attachType == "document") {
            const filteredArr = documents.filter(
              (item) => item.id !== attachmentId
            );
            setDocuments(filteredArr);
          }

          setAttachmentId("");
        } else {
          setOpenDeleteAlert(false);
          console.log("In else portion ", res.data);
          setSeverity("error")
          setOpen(true);
          setErrorMsg(res.data.data.message);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setSeverity("error")
        setOpen(true);
        if (error.code === defaultString.network_error)
          setErrorMsg(error.message);
        else setErrorMsg(error.response.data.message);
        if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
          doLogout(error);
        }
      });
  };

  const fnDeleteProduct = async (attachmentId) => {
    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));
    console.log("token ", token);
    const objProduct = JSON.stringify({
      userIds: [attachmentId],
    });

    console.log(objProduct);
    setIsDisabledDeleteBtn(true);
    await axios
      .post(apiURL.service_delete_users, objProduct, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === apiStatusCode.DELETED) {
          setOpen(true);
          setAttachmentId("");
          setSeverity("success");
          setErrorMsg(res.data.message);
          props.handleClose({
            reload: true,
          });
        } else {
          console.log("In else portion ", res.data);
          setSeverity("error")
          setOpen(true);
          setIsDisabledDeleteBtn(false);
          setErrorMsg(res.data.data.message);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setSeverity("error")
        setOpen(true);
        setIsDisabledDeleteBtn(false);
        if (error.code === defaultString.network_error)
          setErrorMsg(error.message);
        else setErrorMsg(error.response.data.message);
        if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
          doLogout(error);
        }
      });
  };

  const onStatusChange = () => {
    setStatus(!status);
  };

  const doLogout = async (resErr) => {
    const data = await resetToken();
    if (data === true) {
      dispatch({ type: "LOGOUT", payload: null });
      navigate("/", {
        state: { isModalPopup: true, errorMsg: resErr.response.data.message },
      });
    }
  };

  return (
    <>
      {/* <div className="costome-carousel"> */}
      <Modal
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: "70%",
            backgroundColor: "white",
            borderRadius: 2,
            height: "80%",
          }}
        >
          <Box
            sx={{
              padding: "8px 16px",
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: "white",
              borderBottom: "1px solid #d4d4d4",
              display: "flex",
              justifyContent: "space-between",
              borderRadius: "16px 16px 0 0",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontWeight: 700,
                fontSize: "24px",
                marginRight: "24px",
              }}
            >
              {t("add") + " " + t("companyagent")}
            </Typography>

            <IconButton
              onClick={() => {
                props.handleClose();
              }}
            >
              <Close />
            </IconButton>
          </Box>

          <Container
            fluid="md"
            style={{
              height: "90%",
              overflowY: "scroll",
            }}
          >
            <Box>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 700 }}
                className="my-2"
              >
                {t("status")}
              </Typography>
              <Row>
                <Col>
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      name="status"
                      checked={status}
                      value={status}
                      className="custom-switch-lg"
                      onChange={onStatusChange}
                    />
                  </Form>
                </Col>
              </Row>

              <Typography
                sx={{ fontSize: "16px", fontWeight: 700 }}
                className="my-2"
              >
                {t("images")}
              </Typography>
              <Row>
                <Col lg="6">
                  <Form.Group controlId="formFile1" className="mb-3">
                    <Form.Label>{t("chooseMainImage") + "*"}</Form.Label>
                    <Form.Control
                      type="file"
                      className="no-focus-shadow"
                      onChange={handleMainPicture}
                      name="mainImage"
                      accept="image/*"
                    />
                    <div className="sec-row">
                      {!!mainImage.logo && (
                        <>
                          {mainImage.id === 0 ? (
                            <img
                              key={mainImage.logo.name}
                              className="mt-3"
                              accept="image/*"
                              src={
                                mainImage.logo !== null ||
                                mainImage.logo !== undefined
                                  ? URL.createObjectURL(mainImage.logo)
                                  : null
                              }
                              alt={mainImage.logo.name}
                              height={80}
                              width={80}
                            />
                          ) : (
                            <img
                              key={mainImage.logo}
                              className="mt-3"
                              accept="image/*"
                              src={
                                mainImage.logo
                                  ? apiURL.siteBaseUrl + mainImage.logo
                                  : null
                              }
                              alt={mainImage.logo}
                              height={80}
                              width={80}
                            />
                          )}

                          <MdCancel
                            fontSize={20}
                            className="close-icon"
                            onClick={() =>
                              deleteItem(mainImage.id, 0, "mainImage")
                            }
                          />
                        </>
                      )}
                      {logoImageError ? (
                        <p className="productErrorMessage">{t("logoError")}</p>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Box
                sx={{
                  border: "1px solid #e2e2e2",
                  marginY: "16px",
                }}
              />
              <Row>
                <Typography sx={{ marginBottom: "8px", fontWeight: 700 }}>
                  {t("documents")}
                </Typography>
                <Col lg="12">
                  <Form.Group controlId="formFile2" className="mb-3">
                    <Form.Label>{t("uploadDocuments")}</Form.Label>
                    <Form.Control
                      type="file"
                      className="no-focus-shadow"
                      onChange={handleDocuments}
                      multiple
                      accept="application/pdf,.doc,.docx"
                    />
                    {documents.length > 0 &&
                      documents.map((item, index) => {
                        return (
                          !!item.document && (
                            <div key={index} className="img-list">
                              {item.id === 0 ? (
                                <h6 className="mt-1 ms-2">
                                  {item.document.name}
                                </h6>
                              ) : (
                                <h6 className="mt-1 ms-2">
                                  {item.document.split("/companyProfile/")[1]}
                                </h6>
                              )}
                              <MdCancel
                                fontSize={20}
                                className="doc-icon"
                                onClick={() =>
                                  deleteItem(item.id, index, "document")
                                }
                              />
                            </div>
                          )
                        );
                      })}
                  </Form.Group>
                </Col>
              </Row>
              <Box
                sx={{
                  border: "1px solid #e2e2e2",
                  marginY: "16px",
                }}
              />
              <Row>
                <Typography sx={{ marginBottom: "8px", fontWeight: 700 }}>
                  {t("info")}
                </Typography>
                <Col lg="4">
                  <Form.Floating>
                    <Form.Control
                      type="email"
                      className="no-focus-shadow"
                      placeholder={t("email")}
                      name="email"
                      value={form.email}
                      onChange={onUpdateField}
                      onBlur={onBlurField}
                      required
                    />
                    <label htmlFor="floatingInputCustom">
                      {t("email") + "*"}
                    </label>
                  </Form.Floating>
                  {errors.email.dirty && errors.email.error ? (
                    <p className="productErrorMessage pb-0 mb-0">
                      {t("emailError")}
                    </p>
                  ) : null}
                </Col>
                {!props.isEdit && (
                  <Col lg="4">
                    <InputGroup>
                      <Form.Floating>
                        <Form.Control
                          type={isRevealPwd ? "text" : "password"}
                          className="no-focus-shadow"
                          placeholder={t("password")}
                          aria-label={t("password")}
                          aria-describedby="basic-addon2"
                          name="password"
                          onChange={onUpdateField}
                          onBlur={onBlurField}
                          value={form.password}
                          required
                        />
                        <label htmlFor="floatingInputCustom">
                          {t("password") + "*"}
                        </label>
                      </Form.Floating>

                      <Button
                        variant="secondary"
                        style={{
                          backgroundColor: "#ced4da",
                          border: "1px solid #ced4da",
                        }}
                        id="button-addon2s"
                        onClick={() =>
                          setIsRevealPwd((prevState) => !prevState)
                        }
                      >
                        {isRevealPwd ? <FaEye /> : <FaEyeSlash />}
                      </Button>
                    </InputGroup>
                    {errors.password.dirty && errors.password.error ? (
                      <p className="productErrorMessage mb-0 pb-0">
                        {t("passwordError")}
                      </p>
                    ) : null}
                  </Col>
                )}

                {!props.isEdit && (
                  <Col lg="4">
                    <InputGroup>
                      <Form.Floating>
                        <Form.Control
                          type={isRevealCPwd ? "text" : "password"}
                          className="no-focus-shadow"
                          placeholder={t("cnfmPassword")}
                          aria-label={t("cnfmPassword")}
                          aria-describedby="basic-addon2"
                          name="confirmPassword"
                          onChange={onUpdateField}
                          onBlur={onBlurField}
                          value={form.confirmPassword}
                          required
                        />
                        <label htmlFor="floatingInputCustom">
                          {t("cnfmPassword") + "*"}
                        </label>
                      </Form.Floating>
                      <Button
                        variant="secondary"
                        style={{
                          backgroundColor: "#ced4da",
                          border: "1px solid #ced4da",
                        }}
                        id="button-addon2"
                        onClick={() =>
                          setIsRevealCPwd((prevState) => !prevState)
                        }
                      >
                        {isRevealCPwd ? <FaEye /> : <FaEyeSlash />}
                      </Button>
                    </InputGroup>
                    {errors.confirmPassword.dirty &&
                    errors.confirmPassword.error ? (
                      <p className="productErrorMessage mb-0 pb-0">
                        {t("confirmPasswordError")}
                      </p>
                    ) : null}
                  </Col>
                )}
              </Row>
              <Row className="mt-3">
                <Col lg="4">
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      className="no-focus-shadow"
                      placeholder={t("companyName")}
                      name="companyName"
                      value={form.companyName}
                      onChange={onUpdateField}
                      onBlur={onBlurField}
                      required
                    />
                    <label htmlFor="floatingInputCustom">
                      {t("companyName") + "*"}
                    </label>
                  </Form.Floating>

                  {errors.companyName.dirty && errors.companyName.error ? (
                    <p className="productErrorMessage pb-0">
                      {t("companyNameError")}
                    </p>
                  ) : null}
                </Col>
                <Col lg="4">
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      className="no-focus-shadow"
                      placeholder={t("vatNumber")}
                      name="vat"
                      value={form.vat}
                      onChange={onUpdateField}
                      onBlur={onBlurField}
                      required
                    />
                    <label htmlFor="floatingInputCustom">
                      {t("vatNumber") + "*"}
                    </label>
                  </Form.Floating>

                  {errors.vat.dirty && errors.vat.error ? (
                    <p className="productErrorMessage pb-0">
                      {t("vatNumberError")}
                    </p>
                  ) : null}
                </Col>
                <Col lg="4">
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      className="no-focus-shadow"
                      placeholder={t("fiscalCode")}
                      name="fiscalCode"
                      value={form.fiscalCode}
                      onChange={onUpdateField}
                      onBlur={onBlurField}
                      required
                    />
                    <label htmlFor="floatingInputCustom">
                      {t("fiscalCode") + "*"}
                    </label>
                  </Form.Floating>

                  {errors.fiscalCode.dirty && errors.fiscalCode.error ? (
                    <p className="productErrorMessage pb-0">
                      {t("fiscalCodeError")}
                    </p>
                  ) : null}
                </Col>
              </Row>

              <Row className="mt-3">
                <Col lg="4">
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      className="no-focus-shadow"
                      placeholder={t("discountReward")}
                      name="discountReward"
                      value={form.discountReward}
                      onChange={onUpdateField}
                      onBlur={onBlurField}
                      required
                    />
                    <label htmlFor="floatingInputCustom">
                      {t("discountReward") + "*"}
                    </label>
                  </Form.Floating>

                  {errors.discountReward.dirty &&
                  errors.discountReward.error ? (
                    <p className="productErrorMessage pb-0">
                      {t("discountRewardError")}
                    </p>
                  ) : null}
                </Col>
                <Col lg="4">
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      className="no-focus-shadow"
                      placeholder={t("SDI")}
                      value={sdi}
                      onChange={(e) => setSdi(e.target.value)}
                    />
                    <label htmlFor="floatingInputCustom">{t("SDI")}</label>
                  </Form.Floating>
                </Col>
                <Col lg="4">
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      className="no-focus-shadow"
                      placeholder={t("PEC")}
                    />
                    <label htmlFor="floatingInputCustom">{t("PEC")}</label>
                  </Form.Floating>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col lg="12">
                  <Form.Floating>
                    <Form.Control
                      as="textarea"
                      className="no-focus-shadow"
                      placeholder={t("address")}
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      style={{ height: "100px" }}
                    />
                    <label htmlFor="floatingInputCustom">{t("address")}</label>
                  </Form.Floating>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col lg="4">
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      placeholder={t("pinCode")}
                      className="no-focus-shadow"
                      value={pin}
                      onChange={(e) => setPin(e.target.value)}
                    />
                    <label htmlFor="floatingInputCustom">{t("pinCode")}</label>
                  </Form.Floating>
                </Col>

                <Col lg="4">
                  <FloatingLabel
                    className="custom-select"
                    controlId="floatingSelectGrid"
                    label={t("country")}
                  >
                    <Form.Select
                      style={{
                        color: "var(title-color) !important",
                      }}
                      className="no-focus-shadow"
                      aria-label="Floating label select example"
                      value={country}
                      onChange={handleChangeCountry}
                    >
                      <option>Select an option</option>
                      {options.map((value) => (
                        <option key={value.value}>{value.label}</option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>

                <Col lg="4">
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      placeholder={t("code")}
                      className="no-focus-shadow"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                    <label htmlFor="floatingInputCustom">{t("code")}</label>
                  </Form.Floating>
                </Col>
              </Row>

              <Box
                sx={{
                  border: "1px solid #e2e2e2",
                  marginY: "16px",
                }}
              />
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                  marginBottom: "4px",
                }}
              >
                {props.isEdit && (
                  <Grid item xs={1} >
                    <Button
                      sx={{
                        backgroundColor: "#ff6166",
                        "&:hover": {
                          backgroundColor: "#fa1919",
                        },
                      }}
                      fullWidth
                      variant="contained"
                      size="medium"
                      onClick={() =>
                        deleteItem(props.stateObj._id, 0, "product")
                      }
                    >
                      {t("delete")}
                    </Button>
                  </Grid>
                )}
                <Grid item xs={1} ml={1}>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      backgroundColor: "var(--primary-color)",
                      "&:hover": {
                        backgroundColor: "var(--primary-color-hover)",
                      },
                    }}
                    size="medium"
                    onClick={
                      props.isEdit ? () => fnEditUser(props.stateObj._id) : fnAddUser
                    }
                  >
                    {t("save")}
                  </Button>
                </Grid>
                
              </Grid>
            </Box>
          </Container>
        </Box>
      </Modal>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
      >
        <Alert onClose={handleAlertClose} severity={severity}>
          {errorMsg}
        </Alert>
      </Snackbar>

      <AlertDialog
        open={openDeleteAlert}
        title={
          attachType === "product" ? "Delete Product" : "Delete Attachment"
        }
        message={
          attachType === "product"
            ? "Are you sure you want to delete the product?"
            : "Are you sure you want to delete the attachment?"
        }
        handleAction={confirmDeleteItem}
        handleClose={handleDeleteAlertClose}
      ></AlertDialog>
    </>
  );
};

export default AddCompanyAgent;
