import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'css/aboutus.css';

import { useTranslation  } from 'react-i18next';


function AboutUs(props) {

  const { t } = useTranslation();

    return (
        <div>
            <Row>
          
          <div className="carousel-slide-contact position-relative">
            {/* <video className="w-100" src="video/carousel/solar.mp4" autoPlay loop>
            </video> */}
            <img
              className="img-height-contact"
              src="images/carousel/slide1.jpeg"
              alt="Second slide"
            />
            <div className="carousel-caption position-absolute start-50 w-100 translate-middle">
              <h1 className="text-bold-contact mb-3">{t('aboutfv2')}</h1>
              <p>{t('aboutfv2SubText')}</p>
            </div>
          </div>
        
      </Row>
        <Container className='section-aboutus'>
            <Row>
                <Col>
                    <div >
                        <p className="headerText">
                        {t('aboutPara')}
                        </p>
                    </div>
                </Col>
                
            </Row>
            <Row className='my-5'>
                <Col sm={12} md={7} className='column'>
                    <div>
                        <h1 className='mb-4'>{t('companyCulture')}</h1>
                        <p>
                            <strong>{t('mission')}: </strong> 
                            {t('missionPara')}
                        </p>
                        <p className='mt-4'>
                        <strong>{t('vision')}: </strong> 
                            {t('visionPara')}
                        </p>
                    </div>
                </Col>
                <Col sm={12} md={5}>
                    <div>
                        <img  className='bannerImg' src="images/carousel/solar_panel.jpg" alt="Solar Panel" />
                    </div>
                </Col>
            </Row>
            <Row className='my-5'>
                
                    <h1 className='mb-4'>{t('why')}?</h1>
                    <p>
                    {t('whyPara1')}
                    </p>
                    <p className='mt-4'> 
                    {t('whyPara2')}
                    </p>
                
            </Row>
            <Row>

            </Row>
        </Container>
        </div>
        
    );
}

export default AboutUs;