import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from 'pages/Home/Home';
import Login from 'pages/Login';
import Register from 'pages/Register';
import AboutUs from 'pages/AboutUs';
import ContactUs from 'pages/ContactUs';
import Product from 'pages/Product';
import HomeScreen from 'pages/AdminPanel/HomeScreen';
import AdminLayout from 'layout/AdminLayout';
import DashboardLayout from 'layout/DashboardLayout';
import ProductDetails from 'pages/Product/ProductDetails/ProductDetails';
import AgentLayout from 'layout/AgentLayout';
import AgentHomeScreen from 'pages/AgentPanel/HomeScreen/HomeScreen';
import axios from "axios";
import apiURL from 'apiURL/apiURL';
import { AuthContext } from 'context/AuthContext';
import ResetPassword from 'pages/ForgotPassword/ResetPassword';
import ProfileList from 'pages/AgentPanel/Profile';
import defaultString from 'constants/defaultString.json';
import ProductDetailsNew from 'pages/Product/ProductDetailsNew';
import Trial from 'pages/Trial/Trial';

function App() {

  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/" />
  }


  useEffect(() => {
    async function isTokenExpired() {
      let token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken))
      await axios.get(apiURL.service_token_expire,
        {
          'headers': {
            'Authorization': token
          }
        }
      )
        .then((res) => {
        }).catch((error) => {
          if (error?.response?.data?.data?.isForceLogout)
            dispatch({ type: "LOGOUT", payload: null })
        });

    }
    async function getproducts() {
      await axios.get(apiURL.service_get_products,
        {
          'headers': {
            'Content-Type': 'application/json',
          }
        }
      )
        .then((res) => {

          let product = res.data.data.product

          product.forEach(element => {
            if (element.name === "Solar Panel") localStorage.setItem('solarpanel', element._id)
            if (element.name === "Inverters") localStorage.setItem('inverter', element._id)
            if (element.name === "Storage") localStorage.setItem('storage', element._id)
            if (element.name === "Mounting System") localStorage.setItem('mounting', element._id)
            if (element.name === "Car Ports") localStorage.setItem('carport', element._id)
            if (element.name === "Hybrid Inverter") localStorage.setItem('hybrid_inverter', element._id)
            if (element.name === "Accessory") localStorage.setItem('accessory', element._id)
            // console.log(element)
          });

          // 
        }).catch((error) => {

        });
    }
    getproducts();
    isTokenExpired();
  }, [])

  return (
    <>
      <Router>
        <Routes>
          <Route element={
            <RequireAuth>
              <AdminLayout />
            </RequireAuth>}
          >
            <Route path="/admin/*" element={<HomeScreen />} />
          </Route>

          <Route element={
            <RequireAuth>
              <AgentLayout />
            </RequireAuth>}
          >
            <Route path="/agent/*" element={<AgentHomeScreen />} />
          </Route>

          <Route element={<DashboardLayout />}>
            <Route path="/" name="Home" element={<Home />} />
            <Route path="/sign-in" element={<Login />} />
            <Route path="/sign-up" element={<Register />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/trial" element={<Trial />} />

            <Route path="/product/:id"
              element={
                // <RequireAuth>
                <Product />
                // </RequireAuth>
              }
            />
            <Route path="/product-details"
              element={
                <ProductDetails />
              }
            />
            <Route path="/contact-us" name="contact-us" element={<ContactUs />} />
            <Route path="/agent/profilelist" name="contact-us" element={<ProfileList />} />

          </Route>
        </Routes>
      </Router>
    </>
  );
}
export default App;
