import { useState } from "react";

import {
    emailValidator,
    passwordValidator,
    confirmPasswordValidator,
    discountRewardValidator,
    fiscalCodeValidator,
    vatValidator,
    companyNameValidator,
} from "components/Validators/Validators.jsx";

const touchErrors = errors => {
    return Object.entries(errors).reduce((acc, [field, fieldError]) => {
        acc[field] = {
            ...fieldError,
            dirty: true,
        };
        return acc;
    }, {});
};

export const useAddCompanyAgentValidator = form => {
    const [errors, setErrors] = useState({
        email: {
            dirty: false,
            error: false,
            message: "",
        },
        password: {
            dirty: false,
            error: false,
            message: "",
        },
        confirmPassword: {
            dirty: false,
            error: false,
            message: "",
        },
        discountReward: {
            dirty: false,
            error: false,
            message: "",
        },

        companyName: {
            dirty: false,
            error: false,
            message: "",
        },

        vat: {
            dirty: false,
            error: false,
            message: "",
        },
        fiscalCode: {
            dirty: false,
            error: false,
            message: "",
        },
    });

    const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
        let isValid = true;

        // Create a deep copy of the errors
        let nextErrors = JSON.parse(JSON.stringify(errors));

        // Force validate all the fields
        if (forceTouchErrors) {
            nextErrors = touchErrors(errors);
        }

        const { companyName = '', email = '', password = '', confirmPassword = '', discountReward = '', fiscalCode = '', vat = '' } = form;

        if (nextErrors.email.dirty && (field ? field === "email" : true)) {
            const emailMessage = emailValidator(email, form);
            nextErrors.email.error = !!emailMessage;
            nextErrors.email.message = emailMessage;
            if (!!emailMessage) isValid = false;
        }

        if (nextErrors.password.dirty && (field ? field === "password" : true)) {
            const passwordMessage = passwordValidator(password, form);
            nextErrors.password.error = !!passwordMessage;
            nextErrors.password.message = passwordMessage;
            if (!!passwordMessage) isValid = false;
        }

        if (nextErrors.confirmPassword.dirty && (field ? field === "confirmPassword" : true)) {
            const confirmPasswordMessage = confirmPasswordValidator(confirmPassword, form);

            nextErrors.confirmPassword.error = !!confirmPasswordMessage;
            nextErrors.confirmPassword.message = confirmPasswordMessage;
            if (!!confirmPasswordMessage) isValid = false;
        }

        if (nextErrors.companyName.dirty && (field ? field === "companyName" : true)) {
            const companyNameMessage = companyNameValidator(companyName, form);
            nextErrors.companyName.error = !!companyNameMessage;
            nextErrors.companyName.message = companyNameMessage;
            if (!!companyNameMessage) isValid = false;
        }

        if (nextErrors.vat.dirty && (field ? field === "vat" : true)) {
            const vatMessage = vatValidator(vat, form);
            nextErrors.vat.error = !!vatMessage;
            nextErrors.vat.message = vatMessage;
            if (!!vatMessage) isValid = false;
        }

        if (nextErrors.discountReward.dirty && (field ? field === "discountReward" : true)) {
            const discountRewardMessage = discountRewardValidator(discountReward, form);
            nextErrors.discountReward.error = !!discountRewardMessage;
            nextErrors.discountReward.message = discountRewardMessage;
            if (!!discountRewardMessage) isValid = false;
        }

        if (nextErrors.fiscalCode.dirty && (field ? field === "fiscalCode" : true)) {
            const fiscalCodeMessage = fiscalCodeValidator(fiscalCode, form);
            nextErrors.fiscalCode.error = !!fiscalCodeMessage;
            nextErrors.fiscalCode.message = fiscalCodeMessage;
            if (!!fiscalCodeMessage) isValid = false;
        }

        console.log("isValid", isValid)
        setErrors(nextErrors);
        return {
            isValid,
            errors: nextErrors,
        };
    };

    const onBlurField = e => {
        const field = e.target.name;
        const fieldError = errors[field];
        if (fieldError.dirty) return;

        const updatedErrors = {
            ...errors,
            [field]: {
                ...errors[field],
                dirty: true,
            },
        };

        validateForm({ form, field, errors: updatedErrors });
    };

    return {
        validateForm,
        onBlurField,
        errors,
    };
};