import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "components/Navbar/Navbar";
import Footer from "components/Footer";

import "css/home.css";

function DashboardLayout(props) {
  return (
    <div>
      <Navbar></Navbar>

      <div className="main-outlet">
        <Outlet />
      </div>

      <Footer></Footer>
    </div>
  );
}

export default DashboardLayout;
