import { useState } from "react";

import {
    firstNameValidator,
    lastNameValidator,
    fiscalCodeValidator,
    addressValidator,
    shippingAddressValidator,
} from "components/Validators/Validators.jsx";

const touchErrors = errors => {
    return Object.entries(errors).reduce((acc, [field, fieldError]) => {
        acc[field] = {
            ...fieldError,
            dirty: true,
        };
        return acc;
    }, {});
};

export const useAddPrivateClientValidator = form => {
    const [errors, setErrors] = useState({

        firstName: {
            dirty: false,
            error: false,
            message: "",
        },
        lastName: {
            dirty: false,
            error: false,
            message: "",
        },
        fiscalCode: {
            dirty: false,
            error: false,
            message: "",
        },
        address: {
            dirty: false,
            error: false,
            message: "",
        },
        shippingAddress: {
            dirty: false,
            error: false,
            message: "",
        },
    });

    const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
        let isValid = true;

        // Create a deep copy of the errors
        let nextErrors = JSON.parse(JSON.stringify(errors));

        // Force validate all the fields
        if (forceTouchErrors) {
            nextErrors = touchErrors(errors);
        }

        const { firstName = '', lastName = '', fiscalCode = '', address = '', shippingAddress = '' } = form;




        if (nextErrors.firstName.dirty && (field ? field === "firstName" : true)) {
            const firstNameMessage = firstNameValidator(firstName, form);
            nextErrors.firstName.error = !!firstNameMessage;
            nextErrors.firstName.message = firstNameMessage;
            if (!!firstNameMessage) isValid = false;
        }

        if (nextErrors.lastName.dirty && (field ? field === "lastName" : true)) {
            const lastNameMessage = lastNameValidator(lastName, form);
            nextErrors.lastName.error = !!lastNameMessage;
            nextErrors.lastName.message = lastNameMessage;
            if (!!lastNameMessage) isValid = false;
        }



        if (nextErrors.fiscalCode.dirty && (field ? field === "fiscalCode" : true)) {
            const fiscalCodeMessage = fiscalCodeValidator(fiscalCode, form);
            nextErrors.fiscalCode.error = !!fiscalCodeMessage;
            nextErrors.fiscalCode.message = fiscalCodeMessage;
            if (!!fiscalCodeMessage) isValid = false;
        }

        if (nextErrors.address.dirty && (field ? field === "address" : true)) {
            const addressMessage = addressValidator(address, form);
            nextErrors.address.error = !!addressMessage;
            nextErrors.address.message = addressMessage;
            if (!!addressMessage) isValid = false;
        }

        if (nextErrors.shippingAddress.dirty && (field ? field === "shippingAddress" : true)) {
            const shippingAddressMessage = shippingAddressValidator(shippingAddress, form);
            nextErrors.shippingAddress.error = !!shippingAddressMessage;
            nextErrors.shippingAddress.message = shippingAddressMessage;
            if (!!shippingAddressMessage) isValid = false;
        }
        console.log("isValid", isValid)
        setErrors(nextErrors);
        return {
            isValid,
            errors: nextErrors,
        };
    };

    const onBlurField = e => {
        const field = e.target.name;
        const fieldError = errors[field];
        if (fieldError.dirty) return;

        const updatedErrors = {
            ...errors,
            [field]: {
                ...errors[field],
                dirty: true,
            },
        };

        validateForm({ form, field, errors: updatedErrors });
    };

    return {
        validateForm,
        onBlurField,
        errors,
    };
};