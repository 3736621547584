import React, { useContext, useEffect, useMemo, useState } from 'react'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import countryList from 'react-select-country-list';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { AuthContext } from 'context/AuthContext';
const ProfileList = () => {
    const { t } = useTranslation();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [companyName, setCompanyName] = useState('')
    const [vatNumber, setVatNumber] = useState('')
    const [companyNumber, setCompanyNumber] = useState('')
    const [companyEmail, setCompanyEmail] = useState('')
    const [vat, setVat] = useState('')
    const [email, setEmail] = useState('');
    const [PEC, setPEC] = useState('')
    const [SDI, setSDI] = useState('')
    const [companyProfile, setCompanyProfile] = useState('')
    const [isCompany, setIsCompany] = useState(false);
    const [companyCountry, setCompanyCountry] = useState('Italy')
    const options = useMemo(() => countryList().getData(), [])
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [open, setOpen] = useState(false);
    const [country, setCountry] = useState('Italy')
    const {currentUser}=useContext(AuthContext)

    useEffect(() => {
        if (currentUser != null) {
          
console.log(currentUser.firstName);
                // setIsEdit(true)
                setFirstName(currentUser.firstName)

            
                setLastName(currentUser.lastName)
                setEmail(currentUser.email)
                setContactNumber(currentUser.shippingContactNumber)
                setCompanyName(currentUser.companyName)
                setVatNumber(currentUser.vat)
                setPEC(currentUser.pec)
                setSDI(currentUser.sdi)
                setCompanyEmail(currentUser.companyEmail)
                setCountry(currentUser.country)
                setAddress(currentUser.address)
                setCompanyNumber(currentUser.shippingContactNumber)

           
        }
    }, [currentUser])

  return (
    <div>
         <Container className='py-5 section-register'>
                        <Row className='text-center mb-5'>
                            <h1>{t('update')}</h1>
                            
                        </Row>
                        <Form>
           {
                                (currentUser?.role === "Agent") &&
                                <>
                                    <Row className="mb-4">
                                        <Col>
                                            <FloatingLabel controlId="floatingFirstName" label={t("firstName")}>
                                                <Form.Control type="text" placeholder={t("firstName")}  value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)} required 
                                                />
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel controlId="floatingLastName" label={t("lastName")}>
                                                <Form.Control type="text" placeholder={t("lastName")} value={lastName}
                                                onChange={(e) => setLastName(e.target.value)} required 
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>

                                    <Row className="mb-4">
                                        <Col>
                                            <FloatingLabel controlId="floatingEmail" label={t("email")}>
                                                <Form.Control
                                                    type="email"
                                                    placeholder={t('email')}
                                                    onChange={(e)=>setEmail(e.target.value)} 
                                                    name="email"
                                                    value={email}
                                                    // value={form.email}
                                                    // onChange={onUpdateField}
                                                    // onBlur={onBlurField} required 
                                                    />
                                            </FloatingLabel>
                                            {/* {errors.email.dirty && errors.email.error ? (
                                                <p className='formFieldErrorMessage'>{errors.email.message}</p>
                                            ) : null} */}
                                        </Col>
                                
                                    </Row>

                                    <Row className="mb-4">
                                        <Col md={6}>
                                            <FloatingLabel controlId="floatingPhoneNumber" label={t("phoneNumber")}>
                                                <Form.Control type="text" placeholder={t("phoneNumber")} value={contactNumber} 
                                                onChange={(e) => setContactNumber(e.target.value)} required
                                                 />
                                            </FloatingLabel>
                                        </Col>

                                    </Row>
                                </>
                             }
                             {
                                (currentUser?.role === "Installer") && 
                                <>
                                    <Row className="mb-4">
                                        <Col>
                                            <FloatingLabel controlId="floatingCompanyName" label={t('companyName')}>
                                                <Form.Control type="text" placeholder={t('companyName')} 
                                               value={companyName} onChange={(e) => setCompanyName(e.target.value)}
                                                 />
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel controlId="floatingVATNumber" label={t('vatNumber')}>
                                                <Form.Control type="text" placeholder={t('vatNumber')} value={vatNumber}
                                                onChange={(e) => setVatNumber(e.target.value)} 
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>

                                    <Row className="mb-4">
                                        <Col>
                                            <FloatingLabel controlId="floatingPEC" label={t("PEC")}>
                                                <Form.Control type="text" placeholder={t("PEC")} 
                                                value={PEC}
                                                onChange={(e) => 
                                                    setPEC(e.target.value)}
                                                     />
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel controlId="floatingSDI" label={t("SDI")}>
                                                <Form.Control type="text" placeholder={t("SDI")} value={SDI}
                                                onChange={(e) => setSDI(e.target.value)} 
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>


                                    <Row className="mb-4">
                                        <Col md={6} sm={12}>
                                            <FloatingLabel controlId="floatingCompanyeEmail" label={t('companyEmail')}>
                                                <Form.Control type="text" placeholder={t('companyEmail')} name="companyEmail"  value={companyEmail}
                                                 onChange={(e) => setCompanyEmail(e.target.value)}
                                                // value={form.companyEmail}
                                                //     onChange={onUpdateField}
                                                //     onBlur={onBlurField} required 
                                                />
                                            </FloatingLabel>
                                            {/* {errors.companyEmail.dirty && errors.companyEmail.error ? (
                                                <p className='formFieldErrorMessage'>{errors.companyEmail.message}</p>
                                            ) : null} */}
                                        </Col>
                                      

                                    </Row>

                                    <Row className="">
                                        <Col>
                                            <FloatingLabel className='mb-4' controlId="floatingCompanyCountry" label={t('companyCountry')}>
                                                <Form.Select aria-label="Floating label select example" value={companyCountry} onChange={(e) => setCompanyCountry(e.target.value)}>
                                                    {
                                                        options.map((value) =>
                                                            <option key={value.value}>{value.label}</option>
                                                        )
                                                    }
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel className='mb-4' controlId="floatingCompanyAddress" label={t('companyAddress')}>
                                                <Form.Control type="text" as="textarea" placeholder={t('companyAddress')} value={address}
                                                onChange={(e) => setAddress(e.target.value)} 
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>

                                    <Row >
                                        {
                                            currentUser.role === "Installer" &&
                                            <Col>
                                                <Form.Label> {t('uploadCompanyProfile')} </Form.Label>
                                                <Form.Control type="file" name="companyProfile" 
                                                onChange={(e) => setCompanyProfile(e.target.files[0])} 
                                                accept='application/pdf,.doc,.docx' />
                                            </Col>
                                         }
                                        <Col md={6} sm={12}>
                                            <FloatingLabel controlId="floatingCompanyPhoneNumber" label={t('companyNumber')}>
                                                <Form.Control type="text" placeholder={t('companyNumber')}  value={companyNumber}
                                                onChange={(e) => setCompanyNumber(e.target.value)} 
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                </>
                             } 
                            <div className='pt-4 text-center'>
                                <Button variant="success mb-5" className='btn-lg success' type="submit" >
                                    {t('update')}
                                </Button>
                              
                            </div>

                        </Form>
                    </Container>
                    {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleAlertClose} sx={{ width: '100%' }}>
                <Alert onClose={handleAlertClose} severity="error" >
                    {errorMsg}
                </Alert>
            </Snackbar> */}
    </div>
  )
}

export default ProfileList