import React, { useState, useContext, useEffect } from "react";
import defaultString from "constants/defaultString";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import "css/Login.css";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import axios from "axios";
import { useLoginFormValidator } from "hooks/useLoginFormValidator";
import clsx from "clsx";
import { AuthContext } from "context/AuthContext";

import InputGroup from "react-bootstrap/InputGroup";
import { FaArrowLeft, FaEyeSlash, FaEye } from "react-icons/fa";
import FloatingLabel from "react-bootstrap/FloatingLabel";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Login(props) {
  const { dispatch } = useContext(AuthContext);
  let navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const { errors, validateForm, onBlurField } = useLoginFormValidator(form);

  const onUpdateField = (e) => {
    const field = e.target.name;
    const nextFormState = {
      ...form,
      [field]: e.target.value,
    };
    setForm(nextFormState);

    if (errors[field].dirty)
      validateForm({
        form: nextFormState,
        errors,
        field,
      });
  };

  const handleAlertClose = (event, reason) => {
    setIsLoading(false);
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const fnLogin = (e) => {
    e.preventDefault();
    const { isValid } = validateForm({ form, errors, forceTouchErrors: true });
    if (!isValid) {
      setOpen(true);
      setErrorMsg(defaultString.validData);
      return;
    }

    if (form.email && form.password) {
      setIsLoading(true);

      console.log(form);
      axios
        .post(apiURL.service_login, form)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === apiStatusCode.SUCCESS) {
            setIsLoading(false);
            dispatch({ type: "LOGIN", payload: res.data.data.user });
            localStorage.setItem(
              "userToken",
              JSON.stringify(res.data.data.token)
            );
            navigate(`/product/${localStorage.getItem("solarpanel")}`);
            props.handleModalOpen();
          } else {
            setIsLoading(false);
            console.log(res.data);
            setOpen(true);
            setErrorMsg(res.data.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);

          setOpen(true);
          if (error.code === defaultString.network_error)
            setErrorMsg(error.message);
          else setErrorMsg(error.response.data.message);
        });
    } else {
      setOpen(true);
      setErrorMsg(defaultString.validData);
      return;
    }
  };

  useEffect(() => {
    if (props.isLogoutValueClear) {
      validateForm({
        form: form,
        errors: {
          email: "",
          password: "",
        },
      });
      setForm({
        email: "",
        password: "",
      });
      setOpen(false);
      setErrorMsg("");
    }
  }, [props.isLogoutValueClear]);
  const fnForgotPassword = async (e) => {
    if (forgotEmail) {
      e.preventDefault();

      const userObject = {
        email: forgotEmail,
      };
      console.log(userObject);
      axios
        .post(apiURL.service_forgot_password, userObject)
        .then((res) => {
          // setOpenDialog(false);
          setOpen(true);
          if (res.data.status === apiStatusCode.SUCCESS) {
            setErrorMsg(res.data.message);
            navigate("/reset-password");
          } else {
            console.log(res.data);
            setErrorMsg(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          setOpen(true);
          if (error.code === defaultString.network_error)
            setErrorMsg(error.message);
          else setErrorMsg(error.response.data.message);
        });
    } else {
      setOpen(true);
      setErrorMsg(defaultString.validData);
    }
  };

  const { t } = useTranslation();

  const handleHide = () => {
    validateForm({
      form: form,
      errors: {
        email: "",
        password: "",
      },
    });
    setForm({
      email: "",
      password: "",
    });
    setOpen(false);
    setErrorMsg("");
    props.handleModalOpen();
  };

  return (
    <Modal
      className="modal-open"
      show={props.modalOpen}
      onHide={() => {
        handleHide();
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Modal.Body className="text-center">
            <h1>{t("welcome")}</h1>
            <p>{t("signinToContinue")}</p>

            <Form className="p-3">
              <Form.Floating className="mb-3">
                <Form.Control
                  id="floatingInputCustom"
                  type="email"
                  placeholder={t("email")}
                  name="email"
                  value={form.email}
                  onChange={onUpdateField}
                  onBlur={onBlurField}
                  required
                />
                <label htmlFor="floatingInputCustom">{t("email")}</label>
                {errors.email.dirty && errors.email.error ? (
                  <p className="formFieldErrorMessage">
                    {errors.email.message}
                  </p>
                ) : null}
              </Form.Floating>

              <InputGroup className="mb-3 mt-3">
                <FloatingLabel
                  controlId="floatingPassword1"
                  label={t("password")}
                >
                  <Form.Control
                    type={isRevealPwd ? "text" : "password"}
                    placeholder={t("password")}
                    aria-label={t("password")}
                    aria-describedby="basic-addon2"
                    name="password"
                    onChange={onUpdateField}
                    onBlur={onBlurField}
                    value={form.password}
                    required
                  />
                </FloatingLabel>

                <Button
                  variant="outline-secondary"
                  id="button-addon2"
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                >
                  {isRevealPwd ? <FaEye /> : <FaEyeSlash />}
                </Button>
              </InputGroup>

              {/* <Form.Floating>
                <Form.Control
                  id="floatingPasswordCustom"
                  type="password"
                  placeholder={t('password')}
                  name="password"
                  className={clsx(
                    'form-control TextBox',
                    errors.password.dirty &&
                    errors.password.error &&
                    "formFieldError"
                  )}
                  onChange={onUpdateField}
                  onBlur={onBlurField}
                  value={form.password}
                />
                <label htmlFor="floatingPasswordCustom">{t('password')}</label>
              </Form.Floating> */}
              {errors.password.dirty && errors.password.error ? (
                <p className="formFieldErrorMessage">
                  {errors.password.message}
                </p>
              ) : null}

              <div className="mt-5">
                <Button variant="success" type="submit" onClick={fnLogin}>
                  {t("signin")}
                </Button>
              </div>
            </Form>
          </Modal.Body>
          {/* <Modal.Footer className="p-4">
            <Button variant="secondary" onClick={props.handleModalOpen}>
                Close
              </Button>
            <Button variant="success" type="submit" onClick={fnLogin}>
              {t("signin")}
            </Button>
          </Modal.Footer> */}

          <div className="text-center pb-5 px-5">
            <p>
              <Link onClick={props.handleModalOpenForgot}>
                {" "}
                {t("forgotPassword")}?
              </Link>
            </p>
            <p>
              {t("dontHave")}?{" "}
              <Link to="/sign-up" onClick={props.handleModalOpen}>
                {t("createAccount")}
              </Link>
            </p>
            <p>
              {t("agree")} <a>{t("tnc")}</a> & <a>{t("policy")}</a>.
            </p>
          </div>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleAlertClose}
            sx={{ width: "100%" }}
          >
            <Alert onClose={handleAlertClose} severity="error">
              {errorMsg}
            </Alert>
          </Snackbar>
        </>
      )}
    </Modal>
  );
}

export default Login;
