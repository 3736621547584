import React from 'react';

function Dashboard(props) {
    return (
        <div className='justify-content-between'>
            ON AGENT PANEL WE WILL PUT SOME GRAPH AND STATISTICS LATER ON
        </div>
    );
}

export default Dashboard;