export const companyEmailValidator = companyEmail => {
  if (!companyEmail) {
    return "Email is required";
  } else if (!new RegExp(/\S+@\S+\.\S+/).test(companyEmail)) {
    return "Incorrect email format";
  }
  return "";
};

export const emailValidator = email => {
  if (!email) {
    return "Email is required";
  } else if (!new RegExp(/\S+@\S+\.\S+/).test(email)) {
    return "Incorrect email format";
  }
  return "";
};

export const passwordValidator = password => {
  if (!password) {
    return "Password is required";
  } else if (password.length < 8) {
    return "Password must have a minimum 8 characters";
  }
  return "";
};

export const confirmPasswordValidator = (confirmPassword, form) => {
  if (!confirmPassword) {
    return "Confirm password is required";
  } else if (confirmPassword.length < 8) {
    return "Confirm password must have a minimum 8 characters";
  } else if (confirmPassword !== form.password) {
    return "Passwords do not match";
  }
  return "";
};

export const modelNoValidator = modelNo => {
  if (!modelNo) {
    return "Model no is required";
  }
  return "";
};
export const pmaxValidator = p_max => {
  if (!p_max) {
    return "Pmax is required";
  }
  return "";
};
export const priceValidator = price => {
  if (!price) {
    return "Price is required";
  }
  else if (price <= 0) {
    return "Price must be greater than zero";
  }
  return "";
};
export const nominalPowerValidator = nominalPower => {
  if (!nominalPower) {
    return "Nominal Power is required";
  }
  return "";
};

export const mpptValidator = mppt => {
  if (!mppt) {
    return "Dimention is required";
  }
  return "";
};
export const powerValidator = power => {
  if (!power) {
    return "Power is required";
  }
  return "";
};
export const maxNoBatteryConnectionPowerValidator = maxNoBatteryConnection => {
  if (!maxNoBatteryConnection) {
    return "MaxNoBatteryConnection is required";
  }
  return "";
};
export const weightValidator = weight => {
  if (!weight) {
    return "Weight is required";
  }
  return "";
};
export const ipValidator = ip => {
  if (!ip) {
    return "IP is required";
  }
  return "";
};
export const dimentionValidator = dimention => {
  if (!dimention) {
    return "Dimention is required";
  }
  return "";
};
export const batteryVoltageRangeValidator = batteryVoltageRange => {
  if (!batteryVoltageRange) {
    return "Battery voltage Range is required";
  }
  return "";
};

export const descriptionValidator = description => {
  if (!description) {
    return "Description is required";
  }
  return "";
};
export const parkingNoValidator = parkingNo => {
  if (!parkingNo) {
    return "Parking no is required";
  }
  return "";
};
export const firstNameValidator = firstName => {
  if (!firstName) {
    return "Firstname is required";
  }
  return "";
};
export const lastNameValidator = lastName => {
  if (!lastName) {
    return "Lastname is required";
  }
  return "";
};
export const discountRewardValidator = discountReward => {
  if (!discountReward) {
    return "Discount reward is required";
  }
  return "";
};

export const fiscalCodeValidator = fiscalCode => {
  if (!fiscalCode) {
    return "Fiscal code is required";
  }
  return "";
};

export const companyNameValidator = companyName => {
  if (!companyName) {
    return "Company name is required";
  }
  return "";
};
export const vatValidator = vat => {
  if (!vat) {
    return "Vat number is required";
  }
  return "";
};
export const verifyForgotCodeValidator = verifyForgotCode => {
  if (!verifyForgotCode) {
    return "Verification code is required";
  }
  return "";
};

export const addressValidator = address => {
  if (!address) {
    return "Address is required";
  }
  return "";
};

export const shippingAddressValidator = shippingAddress => {
  if (!shippingAddress) {
    return "Shipping address is required";
  }
  return "";
};

export const newPasswordValidator = newPassword => {
  if (!newPassword) {
    return "Password is required";
  }
  else if (newPassword.length < 8) {
    return "Password must have a minimum 8 characters";
  }
  return ""
};
