import React, { useState, useEffect } from "react";
import Nav from 'react-bootstrap/Nav';
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

function AgentSubNav(props) {
    const location = useLocation();
    const [path, setPath] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        //  console.log(`You changed the page to: ${location.pathname}`)
        setPath(location.pathname);
    }, [location, path]);

    return (
        <div>
            <Nav className="justify-content-center">
                <Nav.Item className="me-2">
                    <Nav.Link href={`/agent/quotation/additem/${localStorage.getItem('solarpanel')}`} active={path === `/agent/quotation/additem/${localStorage.getItem('solarpanel')}` ? true : false} >{t("solarPanel")}
                    </Nav.Link>
                </Nav.Item >
                <Nav.Item className="me-2">
                    <Nav.Link href={`/agent/quotation/additem/${localStorage.getItem('inverter')}`} active={path === `/agent/quotation/additem/${localStorage.getItem('inverter')}` ? true : false}>{t("inverter")}</Nav.Link>
                </Nav.Item>
                <Nav.Item className="me-2">
                    <Nav.Link href={`/agent/quotation/additem/${localStorage.getItem('storage')}`} active={path === `/agent/quotation/additem/${localStorage.getItem('storage')}` ? true : false}>{t("storage")} </Nav.Link>
                </Nav.Item>
                <Nav.Item className="me-2">
                    <Nav.Link href={`/agent/quotation/additem/${localStorage.getItem('mounting')}`} active={path === `/agent/quotation/additem/${localStorage.getItem('mounting')}` ? true : false}>{t("mounting")}</Nav.Link>
                </Nav.Item>
                <Nav.Item className="me-2">
                    <Nav.Link href={`/agent/quotation/additem/${localStorage.getItem('carport')}`} active={path === `/agent/quotation/additem/${localStorage.getItem('carport')}` ? true : false}>{t("carPort")}</Nav.Link>
                </Nav.Item>
            </Nav>
        </div>
    );
}

export default AgentSubNav;

