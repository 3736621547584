import { useState } from "react";

import {
    emailValidator,
    passwordValidator,
    confirmPasswordValidator,
    companyEmailValidator,
} from "components/Validators/Validators.jsx";

const touchErrors = errors => {
    return Object.entries(errors).reduce((acc, [field, fieldError]) => {
        acc[field] = {
            ...fieldError,
            dirty: true,
        };
        return acc;
    }, {});
};

export const useUserValidator = form => {
    const [errors, setErrors] = useState({
        companyEmail: {
            dirty: false,
            error: false,
            message: "",
        },
        email: {
            dirty: false,
            error: false,
            message: "",
        },
        password: {
            dirty: false,
            error: false,
            message: "",
        },
        confirmPassword: {
            dirty: false,
            error: false,
            message: "",
        },
    });

    const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
        let isValid = true;

        // Create a deep copy of the errors
        let nextErrors = JSON.parse(JSON.stringify(errors));

        // Force validate all the fields
        if (forceTouchErrors) {
            nextErrors = touchErrors(errors);
        }

        const { companyEmail = '', lastName = '', email = '', password = '', confirmPassword = '' } = form;



        if (nextErrors.email.dirty && (field ? field === "email" : true)) {
            const emailMessage = emailValidator(email, form);
            nextErrors.email.error = !!emailMessage;
            nextErrors.email.message = emailMessage;
            if (!!emailMessage) isValid = false;
        }
        if (email === '') isValid = true;


        if (nextErrors.password.dirty && (field ? field === "password" : true)) {
            const passwordMessage = passwordValidator(password, form);
            nextErrors.password.error = !!passwordMessage;
            nextErrors.password.message = passwordMessage;
            if (!!passwordMessage) isValid = false;
        }
        if (password === '') isValid = true;

        // if (confirmPassword !== '') {
        //     if (
        //         nextErrors.confirmPassword.dirty &&
        //         (field ? field === "confirmPassword" : true)
        //     ) {
        //         const confirmPasswordMessage = confirmPasswordValidator(
        //             confirmPassword,
        //             form
        //         );
        //         nextErrors.confirmPassword.error = !!confirmPasswordMessage;
        //         nextErrors.confirmPassword.message = confirmPasswordMessage;
        //         if (!!confirmPasswordMessage) isValid = false;
        //     }
        // }
        // if (confirmPassword === '') isValid = true;

        if (nextErrors.companyEmail.dirty && (field ? field === "companyEmail" : true)) {
            const companyEmailMessage = companyEmailValidator(companyEmail, form);
            nextErrors.companyEmail.error = !!companyEmailMessage;
            nextErrors.companyEmail.message = companyEmailMessage;
            if (!!companyEmailMessage) isValid = false;
        }

        if (companyEmail === '') isValid = true;

        console.log("isValid", isValid)
        setErrors(nextErrors);
        return {
            isValid,
            errors: nextErrors,
        };
    };

    const onBlurField = e => {
        const field = e.target.name;
        const fieldError = errors[field];
        if (fieldError.dirty) return;

        const updatedErrors = {
            ...errors,
            [field]: {
                ...errors[field],
                dirty: true,
            },
        };

        validateForm({ form, field, errors: updatedErrors });
    };

    return {
        validateForm,
        onBlurField,
        errors,
    };
};