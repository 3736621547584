import React, { useEffect, useRef, useState } from 'react';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Link } from 'react-router-dom';
import { useUserValidator } from 'hooks/useUserValidator';
import ReactDOM from "react-dom";
import { useNavigate } from 'react-router';
import 'css/ForgotPassword.css'
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import defaultString from 'constants/defaultString.json';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useConfirmPasswordValidator } from 'hooks/useConfirmPasswordValidator';
import apiURL from 'apiURL/apiURL';
import axios from 'axios';
import apiStatusCode from 'constants/apiStatusCode';
import { FaArrowLeft, FaEyeSlash, FaEye } from "react-icons/fa";
import InputGroup from 'react-bootstrap/InputGroup';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const ResetPassword = (props) => {

    const { t } = useTranslation();

    const [form, setForm] = useState({
        verifyForgotCode: "",
        email: "",
        newPassword: ""

    });
    const [errorMsg, setErrorMsg] = useState('');
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const inputRef = useRef(null);
    let navigate = useNavigate();
    const { errors, validateForm, onBlurField } = useConfirmPasswordValidator(form);
    const onUpdateField = e => {
        console.log("xvdsvsv", e);
        const field = e.target.name;
        const nextFormState = {
            ...form,
            [field]: e.target.value,
        };
        setForm(nextFormState);



        if (errors[field].dirty)
            validateForm({
                form: nextFormState,
                errors,
                field,
            });
    };


    useEffect(() => {
        function handleClickBtn(event) {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                validateForm({
                    form: form,
                    errors: {
                        verifyForgotCode: "",
                        email: "",
                        newPassword: ""
                    },
                });
                setForm({
                    verifyForgotCode: "",
                    email: "",
                    newPassword: ""
                })
                setOpen(false);
                setErrorMsg('');
            }
        }


        const buttons = document.getElementsByClassName('btn-navbar');
        for (let i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener('click', handleClickBtn);
        }
        return () => {
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].removeEventListener('click', handleClickBtn);
            }
        };
    }, []);



    const fnResetPassord = (e) => {
        e.preventDefault();
        const { isValid } = validateForm({ form, errors, forceTouchErrors: true });
        if (!isValid) {

            setOpen(true);
            setErrorMsg(defaultString.validData)
            return

        }


        if (form.verifyForgotCode || form.email || form.newPassword) {
            setIsLoading(true);

            console.log(form);
            axios.post(apiURL.service_reset_password, form, {
                'headers': {
                    'Content-Type': 'application/json',

                }
            })
                .then((res) => {
                    console.log(res);

                    if (res.data.status === apiStatusCode.SUCCESS) {
                        setOpen(true);
                        setErrorMsg(res.data.message)
                        navigate('/');
                    }
                    else {

                        console.log(res.data);
                        setErrorMsg(res.data.message)
                    }

                }).catch((error) => {
                    setIsLoading(false)
                    console.log(error)
                    setOpen(true);
                    if (error.email === defaultString.network_error) setErrorMsg(error.message)
                    else setErrorMsg(error.response.data.message)
                });
        }
        else {
            setOpen(true)
            setErrorMsg(defaultString.validData)
            return;
        }

    };
    const handleAlertClose = (event, reason) => {


        setOpen(false);
    };
    return (
        <div>
            <Container className='py-5 section-forgot-password'>
                <Row className='text-center mb-5'>
                    <h1>{t('resetPassword')}</h1>
                </Row>
                <Form ref={inputRef}>

                    <Row className="mb-4">
                        <Col>
                            <FloatingLabel controlId="floaingForgotEmail" label={t('code')}>
                                <Form.Control type="text" placeholder={t('code')} name="verifyForgotCode" onBlur={onBlurField} value={form.verifyForgotCode}
                                    onChange={onUpdateField} required />
                            </FloatingLabel>
                            {errors.verifyForgotCode.dirty && errors.verifyForgotCode.error ? (
                                <p className='formFieldErrorMessage'>{errors.verifyForgotCode.message}</p>
                            ) : null}
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col>
                            <FloatingLabel controlId="floaingNewPassword" label={t('email')}>
                                <Form.Control type="email" placeholder={t('email')} name="email" onBlur={onBlurField} value={form.email}
                                    onChange={onUpdateField} required />
                            </FloatingLabel>
                            {errors.email.dirty && errors.email.error ? (
                                <p className='formFieldErrorMessage'>{errors.email.message}</p>
                            ) : null}
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col>
                            <InputGroup className="mb-3 mt-3">
                                <FloatingLabel controlId="floatingPassword1" label={t("newPassword")}>
                                    <Form.Control
                                        type={isRevealPwd ? "text" : "password"}
                                        placeholder={t("newPassword")}
                                        aria-label={t("newPassword")}
                                        aria-describedby="basic-addon2"
                                        name="newPassword"
                                        onChange={onUpdateField}
                                        onBlur={onBlurField}
                                        value={form.newPassword}
                                        required
                                    />
                                </FloatingLabel>

                                <Button variant="outline-secondary" id="button-addon2" onClick={() => setIsRevealPwd(prevState => !prevState)}>
                                    {isRevealPwd ? <FaEye /> : <FaEyeSlash />}
                                </Button>

                            </InputGroup>
                            {errors.newPassword.dirty && errors.newPassword.error ? (
                                <p className='formFieldErrorMessage'>{errors.newPassword.message}</p>
                            ) : null}

                        </Col>
                    </Row>





                    <div className='pt-4 text-center'>
                        <Button variant="success mb-5" className='btn-lg success' type="submit" onClick={fnResetPassord}>
                            {t('save')}
                        </Button>
                    </div>

                </Form>
            </Container>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleAlertClose} sx={{ width: '100%' }}>
                <Alert onClose={handleAlertClose} severity="error" >
                    {errorMsg}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ResetPassword;