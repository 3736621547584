import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';
import Container from "react-bootstrap/Container";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa";
import "css/EditSolarForm.css";
import apiURL from 'apiURL/apiURL';
import apiStatusCode from 'constants/apiStatusCode';
import axios from "axios";
import defaultString from 'constants/defaultString.json';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AlertDialog from 'components/AlertDialog/AlertDialog';
import { resetToken } from 'apiURL';
import { AuthContext } from 'context/AuthContext';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditStockForm = () => {

    const { t } = useTranslation();
    const [isEdit, setIsEdit] = useState(false);
    const [status, setStatus] = useState(false);
    const [modelNo, setModelNo] = useState('');
    const [containerNo, setContainerNo] = useState('');
    const [palletNo, setPalletNo] = useState('');
    const [serialNo, setSerialNo] = useState('');
    const [p_max, setP_max] = useState('');
    const [isc, setIsc] = useState('');
    const [vmp, setVmp] = useState('');
    const [imp, setImp] = useState('');
    const [voc, setVoc] = useState('');
    const [ff, setFf] = useState('');
    const [current, setCurrent] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [open, setOpen] = useState(false);
    const [stockId, setStockId] = useState('');
    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
    const { dispatch } = useContext(AuthContext);

    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        if (state != null) {
            console.log('state: ', state)
            if (state.data != null) {

                setIsEdit(true)
                setModelNo(state.data.modelNo)

                let prodStatus = (state.data.status === 'Active') ? true : false
                setStatus(prodStatus);
                setContainerNo(state.data.containerNo)
                setPalletNo(state.data.palletNo)
                setSerialNo(state.data.serialNo)
                setP_max(state.data.p_max)
                setVmp(state.data.vmp)
                setImp(state.data.imp)
                setVoc(state.data.voc)
                setIsc(state.data.isc)
                setIsc(state.data.isc)
                setFf(state.data.ff)
                setCurrent(state.data.current)

            }
        }
    }, [state])

    const confirmDeleteStock = async () => {

        await fnDeleteStock(stockId);
        setOpenDeleteAlert(false)
    }
    const handleDeleteAlertClose = () => {
        setOpenDeleteAlert(false);
        setStockId("")
    };


    const deleteStock = (stockId) => {
        setOpenDeleteAlert(true)
        setStockId(stockId)

    };
    const fnDeleteStock = async (stockId) => {

        const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken))
        const objStock = JSON.stringify({
            'stockIds': [stockId]
        });

        await axios.post(apiURL.service_delete_stock, objStock, {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        })
            .then((res) => {
                console.log(res.data);
                if (res.data.status === apiStatusCode.DELETED) {
                    setOpen(true);
                    setErrorMsg(res.data.message)
                    setStockId('')
                    navigate(`/admin/stock`);
                }
                else {
                    console.log('In else portion ', res.data);
                    setOpen(true);
                    setErrorMsg(res.data.data.message)
                }

            }).catch((error) => {
                console.log(error.response)
                setOpen(true);
                if (error.code === defaultString.network_error) setErrorMsg(error.message)
                else setErrorMsg(error.response.data.message)
                if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
                    doLogout(error)
                }
            });

    }
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    const fnEditStock = async (stockId) => {
        console.log(stockId);
        const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken))

        let prodStatus = (status === true) ? 'Active' : 'InActive'

        let objStock = {}

        if (modelNo && containerNo && palletNo && serialNo) {

            objStock.status = prodStatus
            objStock.modelNo = modelNo
            objStock.containerNo = containerNo
            objStock.palletNo = palletNo
            objStock.serialNo = serialNo
            objStock.p_max = p_max.tofixed(2)
            objStock.vmp = vmp.tofixed(2)
            objStock.imp = imp.tofixed(2)
            objStock.voc = voc.tofixed(2)
            objStock.isc = isc.tofixed(2)
            objStock.ff = ff.tofixed(2)
            objStock.current = current



        }

        await axios.put(`${apiURL.service_edit_stock}/${stockId}`, objStock, {
            'headers': {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            }
        })
            .then((res) => {
                console.log(res.data);
                if (res.status === apiStatusCode.SUCCESS) {
                    setOpen(true);
                    setIsEdit(false)
                    navigate(`/admin/stock`);
                }
                else {
                    console.log('In else portion ', res.data);
                    setOpen(true);
                    setErrorMsg(res.data.data.message)
                }
            }).catch((error) => {
                console.log(error.response)
                setOpen(true);
                if (error.code === defaultString.network_error) setErrorMsg(error.message)
                else setErrorMsg(error.response.data.message)
                if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
                    doLogout(error)
                }
            });
    }

    const onStatusChange = () => {
        setStatus(!status);
    };

    const doLogout = async (resErr) => {
        const data = await resetToken()
        if (data === true) {
            dispatch({ type: "LOGOUT", payload: null })
            navigate('/', { state: { isModalPopup: true, errorMsg: resErr.response.data.message } })
        }
    }

    return (
        <>
            {/* <div className="costome-carousel"> */}
            <Container fluid className='mt-5'>
                <Row className='border-bottom pb-2 backBar'>
                    <Col className='d-flex align-items-center'>
                        <Link to="/admin/stock">
                            <FaArrowLeft fontSize={24} />
                            <span className='ms-2'>{t('back') + " " + t('to') + " " + t('stock')}</span>
                        </Link>
                    </Col>
                    <Col className='text-center'><h3><strong>{(isEdit ? t('edit') : t('add')) + " " + t('stock')}</strong></h3></Col>
                    <Col className='text-end'></Col>

                </Row>
            </Container>

            <Container className='py-5 mt-5 section-adminform'>

                <h3 className='form-title'>{t('status')}</h3>
                <Form>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        name='status'
                        checked={status}
                        value={status}
                        onChange={onStatusChange}
                    />
                </Form>

                <Form.Group controlId="formFile5" className="mb-3">
                    <FloatingLabel
                        controlId="floatingInput"
                        label={t('mondelNo')}
                        className="mb-3 mt-3"
                    >
                        <Form.Control type="text" placeholder={t('modelNo')}
                            onChange={(e) => setModelNo(e.target.value)} value={modelNo} />
                    </FloatingLabel>

                    <FloatingLabel
                        controlId="floatingInput"
                        label={t('containerNo')}
                        className="mb-3 mt-3"
                    >
                        <Form.Control type="text" placeholder={t('containerNo')}
                            onChange={(e) => setContainerNo(e.target.value)} value={containerNo} />
                    </FloatingLabel>

                    <FloatingLabel
                        controlId="floatingInput"
                        label={t('palletNo')}
                        className="mb-3 mt-3"
                    >
                        <Form.Control type="text" placeholder={t('palletNo')}
                            onChange={(e) => setPalletNo(e.target.value)} value={palletNo} />
                    </FloatingLabel>

                    <FloatingLabel
                        controlId="floatingInput"
                        label={t('serialNo')}
                        className="mb-3 mt-3"
                    >
                        <Form.Control type="text" placeholder={t('serialNo')}
                            onChange={(e) => serialNo(e.target.value)} value={serialNo} />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label={t('p_max')}
                        className="mb-3 mt-3"
                    >
                        <Form.Control type="text" placeholder={t('p_max')}
                            onChange={(e) => setP_max(e.target.value)} value={p_max} />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label={t('vmp')}
                        className="mb-3 mt-3"
                    >
                        <Form.Control type="text" placeholder={t('vmp')}
                            onChange={(e) => setVmp(e.target.value)} value={vmp} />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label={t('imp')}
                        className="mb-3 mt-3"
                    >
                        <Form.Control type="text" placeholder={t('imp')}
                            onChange={(e) => setImp(e.target.value)} value={imp} />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label={t('voc')}
                        className="mb-3 mt-3"
                    >
                        <Form.Control type="text" placeholder={t('voc')}
                            onChange={(e) => setVoc(e.target.value)} value={voc} />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label={t('isc')}
                        className="mb-3 mt-3"
                    >
                        <Form.Control type="text" placeholder={t('isc')}
                            onChange={(e) => setIsc(e.target.value)} value={isc} />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label={t('ff')}
                        className="mb-3 mt-3"
                    >
                        <Form.Control type="text" placeholder={t('ff')}
                            onChange={(e) => setFf(e.target.value)} value={ff} />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label={t('current')}
                        className="mb-3 mt-3"
                    >
                        <Form.Control type="text" placeholder={t('current')}
                            onChange={(e) => setCurrent(e.target.value)} value={current} />
                    </FloatingLabel>
                </Form.Group>



                <Row className='mt-5'>
                    <Col>
                        <div className="d-grid gap-2">
                            <Button variant="success" size="lg" onClick={() => fnEditStock(state.data._id)}>
                                {t('save')}
                            </Button>
                        </div>
                    </Col>
                    <Col>
                        <div className="d-grid gap-2">
                            <Button variant="danger" size="lg" onClick={() => deleteStock(state.data._id)}>
                                {t('delete')}
                            </Button>
                        </div>
                    </Col>

                </Row>
            </Container>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleAlertClose} sx={{ width: '100%' }}>
                <Alert onClose={handleAlertClose} severity="error" >
                    {errorMsg}
                </Alert>
            </Snackbar>

            <AlertDialog open={openDeleteAlert} handleAction={confirmDeleteStock} title={stockId ? "Delete Stock" : ""} message={stockId ? "Are you sure you want to delete the stock?" : ""} handleClose={handleDeleteAlertClose}></AlertDialog>

        </>
    );
};

export default EditStockForm;