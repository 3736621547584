import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import ClientNav from 'components/ClientNav/ClientNav';
import 'css/product.css';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';

function createData(product, name, vat, fiscalcode, reward, quotation, status, statistics) {
  return {
    product,
    name,
    vat,
    fiscalcode,
    reward,
    quotation,
    status,
  };
}

const rows = [
  createData('1', "CentricWave", "XXXXXX", "XXXXXX", "5%", "12", "Active"),
  createData('2', "CentricWave", "XXXXXX", "XXXXXX", "5%", "12", "Active"),
  createData('3', "CentricWave", "XXXXXX", "XXXXXX", "5%", "12", "Active"),
  createData('4', "CentricWave", "XXXXXX", "XXXXXX", "5%", "12", "Active"),
  createData('5', "CentricWave", "XXXXXX", "XXXXXX", "5%", "12", "Active"),
];

function EnhancedTableHead(props) {
  const { t } = useTranslation();
  const { onSelectAllClick, numSelected, rowCount } = props;

  const headCells = [
    {
      id: 'img',
      numeric: false,
      disablePadding: true,
      label: t('Logo'),
    },
    {
      id: 'name',
      numeric: true,
      disablePadding: false,
      label: t('name'),
    },
    {
      id: 'vat',
      numeric: true,
      disablePadding: false,
      label: t('vatNumber'),
    },
    {
      id: 'fiscalcode',
      numeric: true,
      disablePadding: false,
      label: t('fiscalCode'),
    },
    {
      id: 'reward',
      numeric: true,
      disablePadding: false,
      label: t('reward'),
    },

    {
      id: 'quotation',
      numeric: true,
      disablePadding: false,
      label: t('quotation'),
    },

    {
      id: 'status',
      numeric: true,
      disablePadding: false,
      label: t('status'),
    },

    {
      id: 'statistic',
      numeric: true,
      disablePadding: false,
      label: '',
    },
  ];
  return (

    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  // const {t} = useTranslation();
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >

        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Container fluid className='d-flex justify-content-end add'>
        </Container>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function CompanyAgentList() {

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { t } = useTranslation();
  let navigate = useNavigate();

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.product);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const navigateToUpdate = (data) => {
    navigate('/admin/addcompanyagent', { state: { data } })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (

    <Container fluid className='py-5'>

      <h2 className='text-center mb-5 border-bottom'>
        <strong>{t('client')}</strong>
      </h2>

      <Container fluid className='mb-3 table-navs'>
        <ClientNav />
      </Container>

      <Box sx={{ width: '100%' }} className="main-area">

        <div className='filter mr-3'>
          <h2>{t('filter')}</h2>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>{t('name')}</Accordion.Header>
              <Accordion.Body>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Option 1" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Option 2" />
                </Form.Group>

                <Form.Group className="" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Option 3" />
                </Form.Group>

              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>{t('vatNumber')}</Accordion.Header>
              <Accordion.Body>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Option 4" />
                </Form.Group>

                <Form.Group className="" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Option 5" />
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>{t('fiscalCode')}</Accordion.Header>
              <Accordion.Body>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Option 6" />
                </Form.Group>

                <Form.Group className="" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Option 7" />
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>{t('reward')}</Accordion.Header>
              <Accordion.Body>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Option 8" />
                </Form.Group>

                <Form.Group className="" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Option 9" />
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>{t('quotation')}</Accordion.Header>
              <Accordion.Body>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Option 8" />
                </Form.Group>

                <Form.Group className="" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Option 9" />
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>

            <div className="d-grid gap-2 mt-3">
              <Button variant="secondary" className='py-3'>{t('applyFilter')}</Button>
            </div>
          </Accordion>
        </div>

        <Paper sx={{ width: '85%', mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size='medium'
            >
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rows.length}
              />
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {

                    const isItemSelected = isSelected(row.product);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.product)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                        sx={{ textDecoration: "none" }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>

                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className='img-box'
                        >
                          <img className='product-img' src='/images/agent/agent.png' alt="Product" />

                        </TableCell>
                        <TableCell align="right">{row.name}</TableCell>
                        <TableCell align="right">{row.vat}</TableCell>
                        <TableCell align="right">{row.fiscalcode}</TableCell>
                        <TableCell align="right">{row.reward}</TableCell>
                        <TableCell align="right">{row.quotation}
                        </TableCell>
                        <TableCell align="right" className='ms-auto'>

                          {row.status}
                        </TableCell>
                        <TableCell align="right">{row.price}</TableCell>
                        <TableCell align="right">
                          <div className='d-flex no-wrap justify-content-end'>
                            <Button variant='contained' size='medium' color='success'
                              sx={{
                                color: "#FD841F",
                                backgroundColor: "#FEF1E6",
                                border: 'none',
                                padding: "12px 18px",
                                fontWeight: 700,
                                fontSize: '16px',
                                boxShadow: "none",
                                "&:hover": {
                                  color: 'white',
                                  backgroundColor: '#FD841F',
                                  cursor: 'pointer',
                                },
                              }}>
                              {t('statistics')}
                            </Button>

                            <Button variant='contained' size='medium' color='success' className='ms-3' onClick={() => { navigateToUpdate(row) }}
                              sx={{
                                color: "#FD841F",
                                backgroundColor: "#FEF1E6",
                                border: 'none',
                                padding: "12px 18px",
                                fontWeight: 700,
                                fontSize: '16px',
                                boxShadow: "none",
                                "&:hover": {
                                  color: 'white',
                                  backgroundColor: '#FD841F',
                                  cursor: 'pointer',
                                },
                              }}>
                              {t('edit')}
                            </Button>
                          </div>
                        </TableCell>

                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

    </Container>
  );
}