import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";

import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import "css/AgentNav.css";
import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { t } from "i18next";

function ClientNav() {
  const location = useLocation();
  const [path, setPath] = useState();
  const { t } = useTranslation();


  useEffect(() => {
    //  console.log(`You changed the page to: ${location.pathname}`)
    setPath(location.pathname);
  }, [location, path]);

  return (
    <div>
      <Row>
        <Col xs={12} md={6}>
          <Nav className="justify-content-start">
            <Nav.Item className="me-2">
              <Nav.Link
                as={Link}
                to="/agent/client-list/private"
                active={path === "/agent/client-list/private" ? true : false}
              >
                <b>{t('private')}</b>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="me-2">
              <Nav.Link
                as={Link}
                to="/agent/client-list/company"
                active={path === "/agent/client-list/company" ? true : false}
              >
                <b>{t('company')}</b>
              </Nav.Link>
            </Nav.Item>
            {/* <Nav.Item className="me-2">
              <Nav.Link
                href="/agent/accessorylist"
                active={path === "/agent/accessorylist" ? true : false}
              >
                <b>{t('accessory')}</b>{" "}
              </Nav.Link>
            </Nav.Item> */}
          </Nav>
        </Col>

        <Col xs={12} md={6} className="d-flex justify-content-end agentNav add">

          <Link to="/agent/addprivateclient">
            <Button variant="contained" size="large" color="success"
              sx={{
                color: "white",
                backgroundColor: "#FD841F",
                border: 'none',
                padding: "12px 20px",
                fontWeight: "700",
                borderRadius: "8px",
                "&:hover": {
                  color: 'white',
                  backgroundColor: '#E14D2A',
                  cursor: 'pointer',
                },
              }}
            >
              {t("add") + " " + t("private")}
            </Button>
          </Link>

          <Link to="/agent/addcompanyclient">
            <Button
              className="ms-3"
              variant="contained"
              size="large"
              color="success"
              sx={{
                color: "white",
                backgroundColor: "#FD841F",
                border: 'none',
                padding: "12px 20px",
                fontWeight: "700",
                borderRadius: "8px",
                "&:hover": {
                  color: 'white',
                  backgroundColor: '#E14D2A',
                  cursor: 'pointer',
                },
              }}
            >
              {t("add") + " " + t("company")}
            </Button>
          </Link>

          {/* <Button
            className="ms-3"
            variant="contained"
            size="large"
            color="success"
          >
           
            <Link to="/agent/addaccessory">
            {t("add") + " " + t("accessory")}
            </Link>
          </Button> */}
        </Col>
      </Row>
    </div>
  );
}

export default ClientNav;
