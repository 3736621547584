import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "css/productStorageNav.css";

function ProductStorageNav() {
  const location = useLocation();
  const [path, setPath] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    //  console.log(`You changed the page to: ${location.pathname}`)
    setPath(location.pathname);
  }, [location, path]);

  return (
    <div className="product-storage-nav">
      <Row>
        <Col xs={12} md={12}>
          <Nav className="justify-content-start mb-3">
            <Nav.Item className="me-2 ">
              <Nav.Link
                className="on-hover-text"
                href={`/product/${localStorage.getItem("storage")}`}
                active={
                  path === `/product/${localStorage.getItem("storage")}`
                    ? true
                    : false
                }
              >
                <b className="">{t("battery")}</b>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="me-2">
              <Nav.Link
                className="on-hover-text"
                href={`/product/${localStorage.getItem("hybrid_inverter")}`}
                active={
                  path === `/product/${localStorage.getItem("hybrid_inverter")}`
                    ? true
                    : false
                }
              >
                <b>{t("hybrid") + " " + t("inverter")}</b>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="me-2">
              <Nav.Link
                className="on-hover-text"
                href={`/product/${localStorage.getItem("accessory")}`}
                active={
                  path === `/product/${localStorage.getItem("accessory")}`
                    ? true
                    : false
                }
              >
                <b>{t("accessory")}</b>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
    </div>
  );
}

export default ProductStorageNav;
