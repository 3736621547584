import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import "css/product.css";
import { alpha } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { Container, FloatingLabel, InputGroup } from "react-bootstrap";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import axios from "axios";
import defaultString from "constants/defaultString.json";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import AlertDialog from "components/AlertDialog/AlertDialog";
import AgentNav from "components/AgentNav/AgentNav";
import { resetToken } from "apiURL";
import { AuthContext } from "context/AuthContext";
import { Breadcrumbs } from "@mui/material";
import { Add, Edit, Equalizer } from "@mui/icons-material";
import { Col, Row } from "react-bootstrap";
import { Grid } from "@mui/material";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { useAddAgentValidator } from "hooks/useAddAgentValidator";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import countryList from "react-select-country-list";
import AddAgent from "../Agent/Add";
import AddCompanyAgent from "../CompanyAgent/Add";
import { GiBrainFreeze, GiBubbleField, GiHoodedFigure } from "react-icons/gi";
import { RiFolderSharedFill } from "react-icons/ri";
import AddInstaller from "../Installer/Add";
import ProductDetails from "pages/Product/ProductDetails";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  maxHeight: "85%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px",
  overflowY: "scroll",
};

export default function UsersList(props) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchContent, setSearchContent] = useState("");
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [userRole, setUserRole] = useState();
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [limit, setLimit] = useState(10);
  const [originalData, setOriginalData] = useState([]);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [accessoryObj, setAccessoryObj] = useState(null);

  const { dispatch } = useContext(AuthContext);
  let navigate = useNavigate();
  const params = useParams();

  function EnhancedTableHead(props) {
    const { t } = useTranslation();
    const { onSelectAllClick, numSelected, rowCount } = props;

    let headCells = [];

    if (params.role === "agent") {
      headCells = [
        {
          id: "product",
          numeric: false,
          disablePadding: true,
          label: t("Logo"),
        },
        {
          id: "name",
          numeric: true,
          disablePadding: false,
          label: t("name"),
        },
        {
          id: "lastname",
          numeric: true,
          disablePadding: false,
          label: t("lastName"),
        },
        {
          id: "fiscalcode",
          numeric: true,
          disablePadding: false,
          label: t("fiscalCode"),
        },
        {
          id: "reward",
          numeric: true,
          disablePadding: false,
          label: t("reward"),
        },
        {
          id: "quotation",
          numeric: true,
          disablePadding: false,
          label: t("quotation"),
        },
        {
          id: "status",
          numeric: true,
          disablePadding: false,
          label: t("status"),
        },
        {
          id: "download",
          numeric: true,
          disablePadding: false,
          label: "",
        },
      ];
    } else if (params.role === "company-agent") {
      headCells = [
        {
          id: "product",
          numeric: false,
          disablePadding: true,
          label: t("Logo"),
        },
        {
          id: "name",
          numeric: true,
          disablePadding: false,
          label: t("companyName"),
        },
        {
          id: "vat",
          numeric: true,
          disablePadding: false,
          label: t("vatNumber"),
        },
        {
          id: "fiscalcode",
          numeric: true,
          disablePadding: false,
          label: t("fiscalCode"),
        },
        {
          id: "reward",
          numeric: true,
          disablePadding: false,
          label: t("reward"),
        },

        {
          id: "quotation",
          numeric: true,
          disablePadding: false,
          label: t("quotation"),
        },

        {
          id: "status",
          numeric: true,
          disablePadding: false,
          label: t("status"),
        },

        {
          id: "statistic",
          numeric: true,
          disablePadding: false,
          label: "",
        },
      ];
    } else if (params.role === "installer") {
      headCells = [
        {
          id: "product",
          numeric: false,
          disablePadding: true,
          label: t("Logo"),
        },
        {
          id: "name",
          numeric: true,
          disablePadding: false,
          label: t("companyName"),
        },
        {
          id: "vat",
          numeric: true,
          disablePadding: false,
          label: t("vatNumber"),
        },
        {
          id: "fiscalcode",
          numeric: true,
          disablePadding: false,
          label: t("fiscalCode"),
        },
        {
          id: "discount",
          numeric: true,
          disablePadding: false,
          label: t("discount"),
        },

        {
          id: "quotation",
          numeric: true,
          disablePadding: false,
          label: t("quotation"),
        },

        {
          id: "status",
          numeric: true,
          disablePadding: false,
          label: t("status"),
        },

        {
          id: "statistic",
          numeric: true,
          disablePadding: false,
          label: "",
        },
      ];
    }

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  function EnhancedTableToolbar(props) {
    const { numSelected, originalData, setRows } = props;
    const { t } = useTranslation();
    return (
      //
      <div>
        <Button
          variant="contained"
          size="small"
          color="success"
          onClick={() => {
            props.onAddOpen();
            // handleRemoveSearch();
            setRows(originalData);
          }}
          sx={{
            color: "white",
            backgroundColor: "var(--primary-color)",
            border: "none",
            padding: "6px 16px",
            fontWeight: "700",
            borderRadius: "4px",
            "&:hover": {
              color: "white",
              backgroundColor: "var(--primary-color-hover)",
              cursor: "pointer",
            },
          }}
          startIcon={<Add />}
        >
          {t("ADD")}
        </Button>
      </div>
    );
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setSearchContent("");
  };

  const fnUserList = useCallback(
    async (role, page, searchText = "") => {
      let searchString = "";
      if (searchText !== "") {
        searchString = "&search_text=" + searchText;
      }

      console.log("in fn limit", limit);

      let userRole = "Agent";
      if (role === "agent") userRole = "Agent";
      else if (role === "company-agent") userRole = "CompanyAgent";
      else if (role === "installer") userRole = "Installer";

      const token = JSON.parse(
        localStorage.getItem(defaultString.jsonWebToken)
      );

      await axios
        .get(
          apiURL.service_users_list +
            `?page=${page}&limit=${limit}&role=${userRole}&search_text${searchString}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
        .then((res) => {
          // console.log(res)
          setIsLoading(false);
          if (res.status === apiStatusCode.SUCCESS) {
            console.log(res.data.data);
            setRows(res.data.data.users);
            setTotalRecords(res.data.data.totalRecords);
          } else {
            console.log("In else portion ", res.data);
            setOpen(true);
            setErrorMsg(res.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          setOpen(true);
          if (error.code === defaultString.network_error)
            setErrorMsg(error.message);
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === 404) {
              setOpen(false);
            } else if (error.response.data.status === 401) {
              setErrorMsg(error.response.data.message);
            }
          }
          if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
            doLogout(error);
          }
          console.log(error?.response?.status);
          setRows([]);
          setTotalRecords(0);
        });
    },
    [limit]
  );

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    // console.log(selectedIndex)
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await fnUserList(userRole, newPage + 1);
    setSelected([]);
  };

  const handleChangeRowsPerPage = async (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    // console.log(event.target.value)
    // console.log(rowsPerPage)
    // console.log(parseInt(event.target.value, 10))
    setLimit(parseInt(event.target.value, 10));
    await fnUserList(userRole, 1);
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const navigateToUpdate = (data, role) => {
    if (role === "agent") {
      navigate("/admin/add-agent", { state: { data } });
    } else if (role === "company-agent") {
      navigate("/admin/add-companyagent", { state: { data } });
    } else if (role === "installer") {
      navigate("/admin/add-installer", { state: { data } });
    }
  };

  const confirmDeleteProduct = async () => {
    console.log("selected:: ", selected);
    await fnDeleteProduct();
    setOpenDeleteAlert(false);
  };

  const handleDeleteAlertClose = () => {
    setOpenDeleteAlert(false);
    setSelected([]);
  };

  const deleteProduct = () => {
    setOpenDeleteAlert(true);
  };

  const fnDeleteProduct = async () => {
    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));

    const objProduct = JSON.stringify({
      userIds: selected,
    });

    console.log(objProduct);

    await axios
      .post(apiURL.service_delete_users, objProduct, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.status === apiStatusCode.DELETED) {
          setOpen(true);
          setErrorMsg(res.data.message);

          const filteredArr = rows.filter(
            (item) => !selected.includes(item._id)
          );
          setRows(filteredArr);
          setSelected([]);
          await fnUserList(userRole, page + 1);
        } else {
          setOpen(true);
          setErrorMsg(res.data.data.message);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setOpen(true);
        if (error.code === defaultString.network_error)
          setErrorMsg(error.message);
        else setErrorMsg(error.response.data.message);
        if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
          doLogout(error);
        }
      });
  };

  useEffect(() => {
    console.log(params.role);
    setUserRole(params.role);
    setIsLoading(true);
    fnUserList(params.role, page + 1);
  }, [fnUserList, params.role, page]);

  const doLogout = async (resErr) => {
    const data = await resetToken();
    if (data === true) {
      dispatch({ type: "LOGOUT", payload: null });
      navigate("/", {
        state: { isModalPopup: true, errorMsg: resErr.response.data.message },
      });
    }
  };

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const [status, setStatus] = useState(false);
  const location = useLocation();
  const [path, setPath] = useState();

  useEffect(() => {
    //  console.log(`You changed the page to: ${location.pathname}`)
    setPath(location.pathname);
  }, [location, path]);

  const onStatusChange = () => {
    setStatus(!status);
  };

  const isAgentRoute = path === "/admin/users-list/agent";
  const isCompanyAgentRoute = path === "/admin/users-list/company-agent";
  const isInstallerRoute = path === "/admin/users-list/installer";

  const { state } = useLocation();
  const [passport, setPassport] = useState("");
  const [documents, setDocuments] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [code, setCode] = useState("");
  const [dob, setDob] = useState("");
  const [mainImage, setMainImage] = useState({ id: 0, logo: null });
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealCPwd, setIsRevealCPwd] = useState(false);
  const [country, setCountry] = useState("Italy");
  const options = useMemo(() => countryList().getData(), []);

  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };

  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    email: "",
    fiscalCode: "",
    discountReward: "",
    companyName: "",
  });

  useEffect(() => {
    if (state != null) {
      console.log("state: ", state);
      if (state.data != null) {
        setIsEdit(true);

        setForm({
          firstName: state.data.firstName,
          email: state.data.email,
          lastName: state.data.lastName,
          fiscalCode: state.data.fiscalCode,
          discountReward: state.data.discountReward,
        });
        let prodStatus = state.data.status === "Active" ? true : false;
        setStatus(prodStatus);
        setPassport(state.data.passportId);
        setDob(state.data.dob);
        setCode(state.data.code);
        // setCountry(state.data.country);

        if (state.data.mainImage.logo !== null) {
          setMainImage(state.data.mainImage);
        }

        let uploadedDocArray = [];
        if (state.data.documents.length > 0) {
          for (let i = 0; i < state.data.documents.length; i++) {
            uploadedDocArray.push(state.data.documents[i]);
          }
          setDocuments(uploadedDocArray);
        }
      }
    }
  }, [state]);

  const { errors, validateForm, onBlurField } = useAddAgentValidator(form);

  const onUpdateField = (e) => {
    const field = e.target.name;
    const nextFormState = {
      ...form,
      [field]: e.target.value,
    };
    setForm(nextFormState);

    if (errors[field].dirty)
      validateForm({
        form: nextFormState,
        errors,
        field,
      });
  };

  const editDataAgent = (data) => {
    setOpenAddUser(!openAddUser);
    setAccessoryObj(data);
    setIsEdit(true);
  };

  const editDataCompany = (data) => {
    setOpenAddUser(!openAddUser);
    setAccessoryObj(data);
    setIsEdit(true);
  };

  const editDataInstaller = (data) => {
    setOpenAddUser(!openAddUser);
    setAccessoryObj(data);
    setIsEdit(true);
  };

  return (
    <>
      <Container fluid className="stock">
        <Box className="d-flex justify-content-between align-items-center">
          <Box>
            <h2>
              <strong>
                {params.id === localStorage.getItem("solarpanel") && t("agent")}
              </strong>
            </h2>
          </Box>

          <Box>
            <EnhancedTableToolbar
              numSelected={selected.length}
              onAddOpen={() => {
                setOpenAddUser(!openAddUser);
                // setDefaultRows(true);
              }}
            />
          </Box>
        </Box>

        <Container fluid className="mb-0 table-navs">
          <AgentNav />
        </Container>

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Box sx={{ width: "100%" }} className="main-area">
            {/* <div className='filter mr-3'>
                <h2>{t('filter')}</h2>
                <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>{t('name')}</Accordion.Header>
                <Accordion.Body>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Option 1" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Option 2" />
                  </Form.Group>

                  <Form.Group className="" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Option 3" />
                  </Form.Group>

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>{t('lastName')}</Accordion.Header>
                <Accordion.Body>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Option 4" />
                  </Form.Group>

                  <Form.Group className="" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Option 5" />
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>{t('fiscalCode')}</Accordion.Header>
                <Accordion.Body>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Option 6" />
                  </Form.Group>

                  <Form.Group className="" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Option 7" />
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>
              
              <Accordion.Item eventKey="3">
                <Accordion.Header>{t('reward')}</Accordion.Header>
                <Accordion.Body>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Option 8" />
                  </Form.Group>

                  <Form.Group className="" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Option 9" />
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>{t('quotation')}</Accordion.Header>
                <Accordion.Body>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Option 8" />
                  </Form.Group>

                  <Form.Group className="" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Option 9" />
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>

    
              
              <div className="d-grid gap-2 mt-3">
              <Button variant="secondary" className='py-3'>{t('applyFilter')}</Button>
              </div>
            </Accordion>
              </div> */}

            <Paper
              sx={{
                width: "100%",
                mb: 2,
                p: 1.5,
                overflow: "hidden",
                borderRadius: "16px",
              }}
            >
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{ minWidth: 750 }}
                  // aria-labelledby="tableTitle"
                  size="medium"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={rows && rows}
                  />
                  {params.role === "agent" && (
                    <TableBody>
                      {rows &&
                        rows.map((row, index) => {
                          const isItemSelected = isSelected(row._id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={index}
                              selected={isItemSelected}
                              sx={{ textDecoration: "none" }}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  onClick={(event) =>
                                    handleClick(event, row._id)
                                  }
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell>

                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                className="img-box"
                              >
                                {row.mainImage.logo != null ? (
                                  <img
                                    className="product-img"
                                    src={
                                      apiURL.siteBaseUrl + row.mainImage.logo
                                    }
                                    alt="User"
                                  />
                                ) : (
                                  <img
                                    className="product-img"
                                    src="/images/icons/no-image.png"
                                    alt="User"
                                  />
                                )}
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.firstName}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.lastName}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>
                                  {row.fiscalCode
                                    ? "x".repeat(row.fiscalCode.length)
                                    : ""}
                                </p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.discountReward}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.quotation}</p>
                              </TableCell>

                              <TableCell align="right" className="ms-auto">
                                <p>{row.status}</p>
                              </TableCell>

                              <TableCell align="right" className="stock">
                                <div className="d-flex no-wrap justify-content-end">
                                  <Button
                                    variant="contained"
                                    size="medium"
                                    color="success"
                                    sx={{
                                      backgroundColor: "transparent",
                                      border:
                                        "1px solid var(--secondary-color)",
                                      fontWeight: 700,
                                      color: "var(--secondary-color)",
                                      fontSize: "14px",
                                      boxShadow: "none",
                                      "&:hover": {
                                        color: "white",
                                        backgroundColor: "var(--primary-color)",
                                        border:
                                          "1px solid var(--primary-color)",
                                        cursor: "pointer",
                                        boxShadow: "none",
                                      },
                                    }}
                                  >
                                    {/* {t('statistics')} */}
                                    <Equalizer />
                                  </Button>

                                  <Button
                                    variant="contained"
                                    size="medium"
                                    color="success"
                                    className="ms-3"
                                    onClick={() => {
                                      editDataAgent(row);
                                      // setRows(originalData);
                                    }}
                                    sx={{
                                      backgroundColor: "transparent",
                                      border:
                                        "1px solid var(--secondary-color)",
                                      fontWeight: 700,
                                      color: "var(--secondary-color)",
                                      fontSize: "14px",
                                      boxShadow: "none",
                                      "&:hover": {
                                        color: "white",
                                        backgroundColor: "var(--primary-color)",
                                        border:
                                          "1px solid var(--primary-color)",
                                        cursor: "pointer",
                                        boxShadow: "none",
                                      },
                                    }}
                                  >
                                    <Edit />
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  )}
                  {params.role === "company-agent" && (
                    <TableBody>
                      {rows &&
                        rows.map((row, index) => {
                          const isItemSelected = isSelected(row._id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={index}
                              selected={isItemSelected}
                              sx={{ textDecoration: "none" }}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  onClick={(event) =>
                                    handleClick(event, row._id)
                                  }
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell>

                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                className="img-box"
                              >
                                {row.mainImage.logo != null ? (
                                  <img
                                    className="product-img"
                                    src={
                                      apiURL.siteBaseUrl + row.mainImage.logo
                                    }
                                    alt="User"
                                  />
                                ) : (
                                  <img
                                    className="product-img"
                                    src="/images/icons/no-image.png"
                                    alt="User"
                                  />
                                )}
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.companyName}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>
                                  {row.vat ? "x".repeat(row.vat.length) : ""}
                                </p>
                              </TableCell>

                              <TableCell align="right">
                                <p>
                                  {row.fiscalCode
                                    ? "x".repeat(row.fiscalCode.length)
                                    : ""}
                                </p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.discountReward}</p>
                              </TableCell>

                              <TableCell align="right">
                                <p>{row.quotation}</p>
                              </TableCell>

                              <TableCell align="right" className="ms-auto">
                                <p>{row.status}</p>
                              </TableCell>

                              <TableCell align="right" className="stock">
                                <div className="d-flex no-wrap justify-content-end">
                                  <Button
                                    variant="contained"
                                    size="medium"
                                    color="success"
                                    sx={{
                                      backgroundColor: "transparent",
                                      border:
                                        "1px solid var(--secondary-color)",
                                      fontWeight: 700,
                                      color: "var(--secondary-color)",
                                      fontSize: "14px",
                                      boxShadow: "none",
                                      "&:hover": {
                                        color: "white",
                                        backgroundColor: "var(--primary-color)",
                                        border:
                                          "1px solid var(--primary-color)",
                                        cursor: "pointer",
                                        boxShadow: "none",
                                      },
                                    }}
                                  >
                                    {/* {t('statistics')} */}
                                    <Equalizer />
                                  </Button>

                                  <Button
                                    variant="contained"
                                    size="medium"
                                    color="success"
                                    className="ms-3"
                                    onClick={() => {
                                      editDataCompany(row);
                                      // setRows(originalData);
                                    }}
                                    sx={{
                                      backgroundColor: "transparent",
                                      border:
                                        "1px solid var(--secondary-color)",
                                      fontWeight: 700,
                                      color: "var(--secondary-color)",
                                      fontSize: "14px",
                                      boxShadow: "none",
                                      "&:hover": {
                                        color: "white",
                                        backgroundColor: "var(--primary-color)",
                                        border:
                                          "1px solid var(--primary-color)",
                                        cursor: "pointer",
                                        boxShadow: "none",
                                      },
                                    }}
                                  >
                                    <Edit />
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  )}
                  {params.role === "installer" && (
                    <TableBody>
                      {rows &&
                        rows
                          // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const isItemSelected = isSelected(row._id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={index}
                                selected={isItemSelected}
                                sx={{ textDecoration: "none" }}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    onClick={(event) =>
                                      handleClick(event, row._id)
                                    }
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                      "aria-labelledby": labelId,
                                    }}
                                  />
                                </TableCell>

                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                  className="img-box"
                                >
                                  {row.mainImage.logo != null ? (
                                    <img
                                      className="product-img"
                                      src={
                                        apiURL.siteBaseUrl + row.mainImage.logo
                                      }
                                      alt="User"
                                    />
                                  ) : (
                                    <img
                                      className="product-img"
                                      src="/images/icons/no-image.png"
                                      alt="User"
                                    />
                                  )}
                                </TableCell>

                                <TableCell align="right">
                                  <p>{row.companyName}</p>
                                </TableCell>

                                <TableCell align="right">
                                  <p>
                                    {row.vat ? "x".repeat(row.vat.length) : ""}
                                  </p>
                                </TableCell>

                                <TableCell align="right">
                                  <p>
                                    {row.fiscalCode
                                      ? "x".repeat(row.fiscalCode.length)
                                      : ""}
                                  </p>
                                </TableCell>

                                <TableCell align="right">
                                  <p>{row.discountReward}</p>
                                </TableCell>

                                <TableCell align="right">
                                  <p>{row.quotation}</p>
                                </TableCell>

                                <TableCell align="right" className="ms-auto">
                                  <p>{row.status}</p>
                                </TableCell>

                                <TableCell align="right" className="stock">
                                  <div className="d-flex no-wrap justify-content-end">
                                    <Button
                                      variant="contained"
                                      size="medium"
                                      color="success"
                                      sx={{
                                        backgroundColor: "transparent",
                                        border:
                                          "1px solid var(--secondary-color)",
                                        fontWeight: 700,
                                        color: "var(--secondary-color)",
                                        fontSize: "14px",
                                        boxShadow: "none",
                                        "&:hover": {
                                          color: "white",
                                          backgroundColor:
                                            "var(--primary-color)",
                                          border:
                                            "1px solid var(--primary-color)",
                                          cursor: "pointer",
                                          boxShadow: "none",
                                        },
                                      }}
                                    >
                                      {/* {t('statistics')} */}
                                      <Equalizer />
                                    </Button>

                                    <Button
                                      variant="contained"
                                      size="medium"
                                      color="success"
                                      className="ms-3"
                                      onClick={() => {
                                        editDataInstaller(row);
                                        // setRows(originalData);
                                      }}
                                      sx={{
                                        backgroundColor: "transparent",
                                        border:
                                          "1px solid var(--secondary-color)",
                                        fontWeight: 700,
                                        color: "var(--secondary-color)",
                                        fontSize: "14px",
                                        boxShadow: "none",
                                        "&:hover": {
                                          color: "white",
                                          backgroundColor:
                                            "var(--primary-color)",
                                          border:
                                            "1px solid var(--primary-color)",
                                          cursor: "pointer",
                                          boxShadow: "none",
                                        },
                                      }}
                                    >
                                      <Edit />
                                    </Button>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      {/* {emptyRows > 0 && (
                              <TableRow
                                style={{
                                  height: (53) * emptyRows,
                                }}
                              >
                                <TableCell colSpan={6} />
                              </TableRow>
                            )} */}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                // rowsPerPageOptions={[]}
                component="div"
                count={totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            {params.role === "agent" && (
              <AddAgent
                open={openAddUser}
                // handleClose={props.handleClose}
                isEdit={isEdit}
                stateObj={accessoryObj}
                handleClose={(data) => {
                  if (data?.reload) {
                    fnUserList(userRole, page + 1);
                  }
                  setIsEdit(false);
                  setAccessoryObj(null);
                  setOpenAddUser(!openAddUser);
                }}
              />
            )}

            {params.role === "company-agent" && (
              <AddCompanyAgent
                open={openAddUser}
                // handleClose={props.handleClose}
                isEdit={isEdit}
                stateObj={accessoryObj}
                handleClose={(data) => {
                  if (data?.reload) {
                    fnUserList(userRole, page + 1);
                  }
                  setIsEdit(false);
                  setAccessoryObj(null);
                  setOpenAddUser(!openAddUser);
                }}
              />
            )}

            {params.role === "installer" && (
              <AddInstaller
                open={openAddUser}
                // handleClose={props.handleClose}
                isEdit={isEdit}
                stateObj={accessoryObj}
                handleClose={(data) => {
                  if (data?.reload) {
                    fnUserList(userRole, page + 1);
                  }
                  setIsEdit(false);
                  setAccessoryObj(null);
                  setOpenAddUser(!openAddUser);
                }}
              />
            )}
          </Box>
        )}
      </Container>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>

      <AlertDialog
        open={openDeleteAlert}
        title={selected.length === 1 ? "Delete User" : "Delete Users"}
        message={
          selected.length === 1
            ? "Are you sure you want to delete the user?"
            : "Are you sure you want to delete the users?"
        }
        handleAction={confirmDeleteProduct}
        handleClose={handleDeleteAlertClose}
      ></AlertDialog>
    </>
  );
}
