import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'css/footer.css'
import { Box } from '@mui/material';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Container fluid className='section-footer p-5'>

        <Row className='pb-5 border-bottom footer-row'>
          <Col lg="6">

            <Box >
            <img alt="logo" src="/LogoBlack.svg" height={80} className="m-0"
            style={{padding:"12px", backgroundColor:"var(--white-color)"}}
            />
            </Box>
            <p className='text-white mt-3 w-50'>{t('footer_title')}</p>

          </Col>

          <Col>
            <Link to="/" style={{
              TextDecoration: "none",
              displa:"flex",
            }}>
              <h4>{t("home")}</h4>
            </Link>

          </Col>
          <Col>
            <Link to="/about-us"><h4>{t("aboutus")}</h4></Link>

          </Col>
          <Col className='links'>
            <h4>{t("product")}</h4>
            <ul className='mt-4 footer-link'>
              <Link to={`/product/${localStorage.getItem('solarpanel')}`}><li>{t("solarPanel")}</li></Link>
              <Link to={`/product/${localStorage.getItem('inverter')}`}><li>{t("inverter")}</li></Link>
              <Link to={`/product/${localStorage.getItem('storage')}`}><li>{t("storage")}</li></Link>
              <Link to={`/product/${localStorage.getItem('mounting')}`}><li>{t("mounting")}</li></Link>
              <Link to={`/product/${localStorage.getItem('carport')}`}><li>{t("carPort")}</li></Link>
            </ul>
          </Col>

        </Row>

        <Row className='border-bottom'>
          <Col className='text-center important-links'>
            <h6>{t("generalGuidence")}</h6>
            <h6>{t("privacy")}</h6>
            <h6>{t("cookies")}</h6>
            <h6>{t("internalPolicies")}</h6>
          </Col>
        </Row>

        {/* <Row className='border-bottom'>
    <Col className='text-center socialmedia'>
      
      <img src="images/icons/fb48.png" alt="fb" height={48} className="me-1" />
      <img src="images/icons/youtube48.png" alt="youtube" height={48} className="me-1" />
      <img src="images/icons/wtsp48.png" alt="whatsapp" height={48} className="me-1" />
    
    </Col>
</Row> */}

        <Row className='pt-5'>
          <Col className='copy-text'>
            <p>copyright @2023 | Eurobox Impianti s.r.l. {t("allRights")}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
