import React, { useState, useContext } from 'react';
import defaultString from 'constants/defaultString';
import { useNavigate } from 'react-router';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import "css/Login.css";
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import apiURL from 'apiURL/apiURL';
import apiStatusCode from 'constants/apiStatusCode';

import axios from "axios";

import { AuthContext } from 'context/AuthContext';

import { useForgotPasswordValidator } from 'hooks/useAddForgotPasswordValidator';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ForgotPassword(props) {

  const { dispatch } = useContext(AuthContext);
  let navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [open, setOpen] = useState(false);
  const [isDisabledForget, setIsDisabledForget] = useState(false);

  const { errors, validateForm, onBlurField } = useForgotPasswordValidator(form);

  const onUpdateField = e => {
    const field = e.target.name;
    const nextFormState = {
      ...form,
      [field]: e.target.value,
    };
    setForm(nextFormState);

    if (errors[field].dirty)
      validateForm({
        form: nextFormState,
        errors,
        field,
      });
  };

  const handleAlertClose = (event, reason) => {
    setIsLoading(false)
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const fnForgot = (e) => {

    e.preventDefault();
    const { isValid } = validateForm({ form, errors, forceTouchErrors: true });

    if (!isValid) {
      setOpen(true);
      setErrorMsg(defaultString.validData)
      return
    };

    if (form.email) {

      setIsLoading(true);
      setIsDisabledForget(true)
      console.log(form);
      axios.post(apiURL.service_forgot_password, form, {
        'headers': {
          'Content-Type': 'application/json',

        }
      })
        .then((res) => {
          console.log(res);
          setIsLoading(false)
          if (res.data.status === apiStatusCode.SUCCESS) {
            setOpen(true);
            setErrorMsg(res.data.message)
            setIsDisabledForget(true)
            setTimeout(() => {
              handleHide()
              // Handle the API response and update the resetStatus accordingly
              // For this example, we assume the API response is successful
              navigate('/reset-password');

              setIsDisabledForget(false)

            }, 3000);
            console.log("msg", res.data.message);

          }
          else {

            console.log(res.data);
            setErrorMsg(res.data.message)
          }

        }).catch((error) => {
          setIsLoading(false)
          setOpen(true);
          if (error.email === defaultString.network_error) setErrorMsg(error.message)
          else setErrorMsg(error.response.data.message)
        });

    }
    else {
      setOpen(true)
      setErrorMsg(defaultString.validData)
      return;
    }

  };

  // const fnForgotPassword = async (e) => {

  //   if (forgotEmail) {

  //     e.preventDefault();

  //     const userObject = {
  //       email: forgotEmail,
  //     };
  //     console.log(userObject);
  //     axios.post(apiURL.service_forgot_password, userObject)
  //       .then((res) => {

  //         // setOpenDialog(false);
  //         setOpen(true);
  //         if (res.data.status === apiStatusemail.SUCCESS) {
  //           setErrorMsg(res.data.message)
  //           navigate('/reset-password');
  //         }
  //         else {

  //           console.log(res.data);
  //           setErrorMsg(res.data.message)
  //         }
  //       }).catch((error) => {

  //         console.log(error)
  //         setOpen(true);
  //         if (error.email === defaultString.network_error) setErrorMsg(error.message)
  //         else setErrorMsg(error.response.data.message)
  //       });
  //   }
  //   else {
  //     setOpen(true)
  //     setErrorMsg(defaultString.validData)
  //   }

  // }
  const handleHide = () => {
    validateForm({
      form: form,
      errors: {
        email: "",
      },
    });
    setForm({
      email: "",
    })
    setOpen(false);
    setErrorMsg('');
    props.handleModalOpenForgot();
  }

  const { t } = useTranslation();

  return (

    <Modal show={props.modalOpenForgot} onHide={() => { handleHide() }} centered >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>

      {isLoading ? <LoadingSpinner /> : (
        <>
          <Modal.Body className='text-center'>
            <h1>{t('forgotPassword')}</h1>
            <p>{t('useCredentials')}</p>

            <Form className='p-3'>
              <Form.Floating className="mb-3">
                <Form.Control
                  id="floatingInputCustom"
                  type="email"
                  placeholder={t('email')}
                  name="email"
                  value={form.email}
                  onChange={onUpdateField}
                  onBlur={onBlurField} required
                />
                {errors.email.dirty && errors.email.error ? (
                  <p className='formFieldErrorMessage'>{errors.email.message}</p>
                ) : null}
              </Form.Floating>

            </Form>
          </Modal.Body>
          <Modal.Footer className='p-4'>
            <Button variant="success" type="submit" disabled={isDisabledForget} onClick={fnForgot}>
              {t('Send')}
            </Button>
          </Modal.Footer>



          <Snackbar open={open} autoHideDuration={6000} onClose={handleAlertClose} sx={{ width: '100%' }}>
            <Alert onClose={handleAlertClose} severity="error" >
              {errorMsg}
            </Alert>
          </Snackbar>
        </>

      )}

    </Modal>

  )
}





export default ForgotPassword;