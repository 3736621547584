import React, { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import "css/ManufacturerList.css";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import { Container } from "react-bootstrap";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import axios from "axios";
import defaultString from "constants/defaultString.json";
import "css/product.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import AlertDialog from "components/AlertDialog/AlertDialog";
import { saveAs } from "file-saver";
import { AuthContext } from "context/AuthContext";
import { resetToken } from "apiURL";
import { Breadcrumbs } from "@mui/material";
import { Add, Edit, FileDownload } from "@mui/icons-material";
import { Col, Row } from "react-bootstrap";
import { Grid } from "@mui/material";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import Modal from "@mui/material/Modal";
import AddManufacturer from "../Add";
import SkeletonImg from "../../../../../src/SkeletonNew.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  maxHeight: "85%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px",
  overflowY: "scroll",
};

export default function ManufacturerList(props) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  // const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchContent, setSearchContent] = useState("");
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [limit, setLimit] = useState(10);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const [status, setStatus] = useState(false);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [solarPanelObj, setSolarPanelObj] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const { dispatch } = useContext(AuthContext);
  const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));

  let navigate = useNavigate();

  const [originalData, setOriginalData] = useState([]);

  function EnhancedTableHead(props) {
    const { t } = useTranslation();
    const { onSelectAllClick, numSelected, rowCount } = props;

    let headCells = [];

    headCells = [
      {
        id: "logo",
        numeric: false,
        disablePadding: true,
        label: t("Logo"),
      },
      {
        id: "name",
        numeric: true,
        disablePadding: false,
        label: t("name"),
      },
      {
        id: "type",
        numeric: true,
        disablePadding: false,
        label: t("type"),
      },
      {
        id: "download",
        numeric: true,
        disablePadding: false,
        label: "",
      },
    ];

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  function EnhancedTableToolbar(props) {
    const { numSelected, setRows, originalData } = props;
    const { t } = useTranslation();
    return (
      //
      <div>
        <Button
          variant="contained"
          size="small"
          color="success"
          onClick={() => {
            props.onAddOpen();
            // handleRemoveSearch();
            setRows(originalData);
          }}
          sx={{
            color: "white",
            backgroundColor: "var(--primary-color)",
            border: "none",
            padding: "6px 16px",
            fontWeight: "700",
            borderRadius: "4px",
            "&:hover": {
              color: "white",
              backgroundColor: "var(--primary-color-hover)",
              cursor: "pointer",
            },
          }}
          startIcon={<Add />}
        >
          {t("ADD")}
        </Button>
      </div>
    );
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setSearchContent("");
  };

  const fnList = useCallback(
    async (page, searchText = "") => {
      let searchString = "";
      if (searchText !== "") {
        searchString = "&search_text=" + searchText;
      }

      // console.log('searchString ',searchString)
      await axios
        .get(
          apiURL.service_list_manufacturer +
            `?page=${page}&limit=${limit}${searchString}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
        .then((res) => {
          // console.log(res)
          setIsLoading(false);
          if (res.status === apiStatusCode.SUCCESS) {
            console.log(res.data.data);
            setRows(res.data.data.manufacturer);
            // setTotalPages(res.data.data.totalPages)
            setTotalRecords(res.data.data.totalRecords);
          } else {
            console.log("In else portion ", res.data);
            setOpen(true);
            setErrorMsg(res.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          setOpen(true);
          if (error.code === defaultString.network_error)
            setErrorMsg(error.message);
          else if (error.code === defaultString.bad_request)
            setErrorMsg(error.response.data.message);
          if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
            doLogout(error);
          }
          setRows([]);
          setTotalRecords(0);
        });
    },
    [limit, token]
  );

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    // console.log(selectedIndex)
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await fnList(newPage + 1);
    setSelected([]);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
    await fnList(1);
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const navigateToUpdate = (data) => {
    navigate("/admin/addmanufacturer", { state: { data } });
  };

  const confirmDeleteProduct = async () => {
    console.log("selected:: ", selected);
    await fnDeleteManufacturer();
    setOpenDeleteAlert(false);
  };

  const handleDeleteAlertClose = () => {
    setOpenDeleteAlert(false);
    setSelected([]);
  };

  const deleteManufacturer = () => {
    setOpenDeleteAlert(true);
  };

  const fnDeleteManufacturer = async () => {
    console.log("token ", token);
    console.log("ids ", selected);

    const obj = JSON.stringify({
      ids: selected,
    });

    console.log(obj);

    await axios
      .post(apiURL.service_delete_manufacturer, obj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.status === apiStatusCode.DELETED) {
          setOpen(true);
          setErrorMsg(res.data.message);

          const filteredArr = rows.filter(
            (item) => !selected.includes(item._id)
          );
          setRows(filteredArr);
          setSelected([]);
          await fnList(page + 1);
        } else {
          setOpen(true);
          setErrorMsg(res.data.data.message);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setOpen(true);
        if (error.code === defaultString.network_error)
          setErrorMsg(error.message);
        else setErrorMsg(error.response.data.message);
        if (error?.response?.status === apiStatusCode.UNAUTHORIZED) {
          doLogout(error);
        }
      });
  };

  const onDownloadClick = (documents) => {
    if (documents.length > 0) {
      var JSzip = require("jszip");
      var zip = new JSzip();
      const otherFiles = zip.folder("files");
      const list = documents.map(async (doc, index) => {
        const fileUrl = apiURL.siteBaseUrl + doc.document;

        const response = await fetch(fileUrl);
        const data = await response.blob();
        // console.log(data);
        otherFiles.file(`document_${index + 1}.pdf`, data);
        return data;
      });

      // If all prmises are fullfilled it will call the zip
      // function and download it using the FileSaver library
      Promise.all(list).then(function () {
        zip.generateAsync({ type: "blob" }).then(function (content) {
          saveAs(content, "files");
        });
      });
    } else {
      setOpen(true);
      setErrorMsg("No file to be download");
    }
    setSelected([]);
  };

  useEffect(() => {
    setIsLoading(true);
    fnList(page + 1);
  }, [fnList, page]);

  const doLogout = async (resErr) => {
    const data = await resetToken();
    if (data === true) {
      dispatch({ type: "LOGOUT", payload: null });
      navigate("/", {
        state: { isModalPopup: true, errorMsg: resErr.response.data.message },
      });
    }
  };

  const onStatusChange = () => {
    setStatus(!status);
  };

  const editDataModal = (data) => {
    setOpenAddUser(!openAddUser);
    setSolarPanelObj(data);
    setIsEdit(true);
  };

  return (
    <>
      <Container fluid className="stock">
        <Box className="d-flex justify-content-between align-items-center">
          <Box>
            <h2>
              <strong>{t("manufacturer")}</strong>
            </h2>
          </Box>

          <Box>
            <Tooltip title="Add">
              <EnhancedTableToolbar
                onAddOpen={() => {
                  setOpenAddUser(!openAddUser);
                  // setDefaultRows(true);
                }}
                numSelected={selected.length}
              />
            </Tooltip>
          </Box>
        </Box>

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Box sx={{ width: "100%" }} className="main-area mt-3">
            <Paper
              sx={{
                width: "100%",
                mb: 2,
                p: 1.5,
                overflow: "hidden",
                borderRadius: "16px",
              }}
            >
              <TableContainer sx={{ maxHeight: 700 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{ minWidth: 750 }}
                  // aria-labelledby="tableTitle"
                  size="medium"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {rows
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const isItemSelected = isSelected(row._id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                            sx={{ textDecoration: "none" }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                onClick={(event) => handleClick(event, row._id)}
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>

                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              className="image-box"
                            >
                              <img
                                className="product-img"
                                src={
                                  row.mainImage.logo
                                    ? apiURL.siteBaseUrl + row.mainImage.logo
                                    : SkeletonImg
                                }
                                alt="Product"
                              />
                            </TableCell>

                            <TableCell align="right">
                              <p>{row.name}</p>
                            </TableCell>

                            <TableCell align="right">
                              <p>
                                {row.type.map((type, index) =>
                                  row.type.length === 1
                                    ? type.name
                                    : index + 1 === row.type.length
                                    ? type.name
                                    : type.name + ", "
                                )}
                              </p>
                            </TableCell>

                            <TableCell align="right" className="stock">
                              <div className="d-flex no-wrap justify-content-end">
                                <Button
                                  variant="contained"
                                  size="medium"
                                  onClick={() => onDownloadClick(row.documents)}
                                  startIcon={<FileDownload />}
                                  sx={{
                                    backgroundColor: "transparent",
                                    border: "1px solid var(--secondary-color)",
                                    fontWeight: 700,
                                    color: "var(--secondary-color)",
                                    fontSize: "14px",
                                    boxShadow: "none",
                                    "&:hover": {
                                      color: "white",
                                      backgroundColor: "var(--primary-color)",
                                      border: "1px solid var(--primary-color)",
                                      cursor: "pointer",
                                      boxShadow: "none",
                                    },
                                  }}
                                >
                                  {/* {t('download')} */}({row.documents.length}
                                  )
                                </Button>

                                <Button
                                  variant="contained"
                                  size="small"
                                  className="ms-3"
                                  onClick={() => {
                                    editDataModal(row);
                                    // setRows(originalData);
                                  }}
                                  // startIcon={<Edit/>}
                                  sx={{
                                    backgroundColor: "transparent",
                                    border: "1px solid var(--secondary-color)",
                                    fontWeight: 700,
                                    color: "var(--secondary-color)",
                                    fontSize: "14px",
                                    boxShadow: "none",
                                    "&:hover": {
                                      color: "white",
                                      backgroundColor: "var(--primary-color)",
                                      border: "1px solid var(--primary-color)",
                                      cursor: "pointer",
                                      boxShadow: "none",
                                    },
                                  }}
                                >
                                  {/* {t('edit')} */}
                                  <Edit />
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {/* {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: (53) * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )} */}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                className="custom-pagination"
                rowsPerPageOptions={[5, 10, 25]}
                // rowsPerPageOptions={[]}
                component="div"
                count={totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <AddManufacturer
              open={openAddUser}
              // handleClose={props.handleClose}
              isEdit={isEdit}
              stateObj={solarPanelObj}
              handleClose={(data) => {
                if (data?.reload) {
                  fnList(page + 1);
                }
                // setEdit(false);
                setSolarPanelObj(null);
                setOpenAddUser(!openAddUser);
                setIsEdit(false);
              }}
            />
          </Box>
        )}
      </Container>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>

      <AlertDialog
        open={openDeleteAlert}
        title={
          selected.length === 1 ? "Delete Manufacturer" : "Delete Manufacturers"
        }
        message={
          selected.length === 1
            ? "Are you sure you want to delete the manufacturer?"
            : "Are you sure you want to delete the manufacturers?"
        }
        handleAction={confirmDeleteProduct}
        handleClose={handleDeleteAlertClose}
      ></AlertDialog>
    </>
  );
}
