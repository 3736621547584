import React, { useState, useContext, useEffect } from "react";
import "css/navbar.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FaLanguage,
  FaEnvelope,
  FaSearch,
  FaUserCircle,
  FaGlobeAsia,
} from "react-icons/fa";
import Badge from "@mui/material/Badge";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import CartDrawer from "components/CartDrawer/CartDrawer";
import Login from "pages/Login";
import defaultString from "constants/defaultString.json";
import { AuthContext } from "context/AuthContext";
import axios from "axios";
import apiURL from "apiURL/apiURL";
import apiStatusCode from "constants/apiStatusCode";
import { resetToken } from "apiURL";
import ForgotPassword from "pages/ForgotPassword/ForgotPassword";
import { Button } from "react-bootstrap";
import SubNav from "components/SubNav/SubNav";
import {
  Avatar,
  Box,
  ButtonGroup,
  Divider,
  Drawer,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { Logout, PersonAdd, Settings } from "@mui/icons-material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

function Navigationbar() {
  const { i18n, t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [modalOpenForgot, setModalOpenForgot] = useState(false);
  const [isLogoutValueClear, setIsLogoutValueClear] = useState(false);
  const { dispatch, currentUser, cartList, doSetCartData } =
    useContext(AuthContext);
  const [subTotal, setSubTotal] = useState("0");
  const [state, setState] = React.useState({
    left: false,
    LeftMain: false,
    right: false,
  });

  const doLogout = async () => {
    const data = await resetToken();
    if (data === true) {
      dispatch({ type: "LOGOUT", payload: null });
    }
    setIsLogoutValueClear(true);
  };

  const handleDrawerOpen = (event) => {
    setShowDrawer((current) => !current);
  };
  const handleModalOpen = (event) => {
    // 👇️ toggle shown state
    setModalOpen((current) => !current);
    // 👇️ or simply set it to true
    // setIsShown(true);
  };

  const changeLanguage = (e) => {
    const selectedLanguage = e.target.value;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("selectedLanguage", selectedLanguage);
  };

  useEffect(() => {
    if (
      localStorage.getItem("solarpanel") === null ||
      localStorage.getItem("inverter") == null ||
      localStorage.getItem("storage") == null ||
      localStorage.getItem("mounting") == null ||
      localStorage.getItem("carport") == null ||
      localStorage.getItem("hybrid_inverter") == null ||
      localStorage.getItem("accessory") == null
    ) {
      getproducts();
    }
  }, []);
  const getproducts = async () => {
    await axios
      .get(apiURL.service_get_products, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        let product = res.data.data.product;

        product.forEach((element) => {
          console.log("element", element);
          if (element.name === "Solar Panel")
            localStorage.setItem("solarpanel", element._id);
          if (element.name === "Inverters")
            localStorage.setItem("inverter", element._id);
          if (element.name === "Storage")
            localStorage.setItem("storage", element._id);
          if (element.name === "Mounting System")
            localStorage.setItem("mounting", element._id);
          if (element.name === "Car Ports")
            localStorage.setItem("carport", element._id);
          if (element.name === "Hybrid Inverter")
            localStorage.setItem("hybrid_inverter", element._id);
          if (element.name === "Accessory")
            localStorage.setItem("accessory", element._id);
          // console.log(element)
        });

        //
      })
      .catch((error) => {});
  };

  const handleModalOpenForgot = (event) => {
    setModalOpenForgot((current) => !current);
    setModalOpen(false);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    } else {
      i18n.changeLanguage("it");
    }
  }, []);

  const toggleDrawer = (anchor, open, row) => {
    // console.log(JSON.stringify(event));
    setState({ ...state, [anchor]: open });
  };

  const toggleDrawerClose = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  const doAddSelectedItem = (item) => {
    toggleDrawer("right", true);
    let newList = [...cartList];
    let total = 0;

    for (let i = 0; i < newList.length; i++) {
      total += newList[i].totalPrice;
    }

    console.log("newList", newList);

    setSubTotal(total);
  };

  const handleIncrement = (selectedItemMain) => {
    const newList = [...cartList];
    newList.forEach((item) => {
      if (item._id === selectedItemMain._id) {
        item.productCount = item.productCount + 1;
        item.totalPrice = item.productCount * item.price;
      }
    });

    let total = 0;
    for (let i = 0; i < newList.length; i++) {
      total += newList[i].totalPrice;
    }

    setSubTotal(total);
    doSetCartData(newList);
  };

  const handleDecrement = (selectedItemMain) => {
    const newList = [...cartList];
    newList.forEach((item) => {
      if (item._id === selectedItemMain._id && item.productCount > 0) {
        item.productCount = item.productCount - 1;
        item.totalPrice = item.productCount * item.price;
      }
    });

    let total = 0;
    for (let i = 0; i < newList.length; i++) {
      total += newList[i].totalPrice;
    }

    setSubTotal(total);
    doSetCartData(newList);
  };

  const listcart = (anchor, listCartMain) => {
    // console.log("item ::: new", item);

    return (
      <Box
        sx={{ width: 500 }}
        role="presentation"
        onClick={() => {
          toggleDrawer(anchor, true);
        }}
        onKeyDown={() => {
          toggleDrawer(anchor, true);
        }}
      >
        {listCartMain.map((item, index) => {
          return (
            <Box sx={{ padding: "12px 32px" }}>
              <Box sx={{ display: "flex", gap: 5, my: 2 }}>
                <Box>
                  <img
                    style={{
                      width: "80px",
                      height: "80px",
                      objectFit: "cover",
                    }}
                    variant="top"
                    src={apiURL.siteBaseUrl + item.mainImage.logo}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "var(--secondary-color)",
                        }}
                      >
                        {item.modelNo}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "var(--secondary-color)",
                        }}
                      >
                        {item.manufacturerId.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "var(--secondary-color)",
                          lineHeight: "24px",
                          fontWeight: 700,
                        }}
                      >
                        {`€${item.totalPrice}`}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ButtonGroup
                      size="small"
                      variant="contained"
                      aria-label="outlined button group"
                    >
                      <Button
                        onClick={() => {
                          handleDecrement(item);
                        }}
                        style={{
                          borderRadius: "0",
                          backgroundColor: "transparent",
                          border: "none",
                          color: "black",
                        }}
                      >
                        -
                      </Button>
                      <Button
                        style={{
                          borderRadius: "0",
                          backgroundColor: "transparent",
                          border: "none",
                          color: "black",
                        }}
                      >
                        {item.productCount}
                      </Button>
                      <Button
                        style={{
                          borderRadius: "0",
                          backgroundColor: "transparent",
                          border: "none",
                          color: "black",
                        }}
                        onClick={() => {
                          handleIncrement(item);
                        }}
                      >
                        +
                      </Button>
                    </ButtonGroup>
                    <Box
                      sx={{
                        marginLeft: 2,
                      }}
                    >
                      <Tooltip title={t("delete")}>
                        <DeleteRoundedIcon />
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}

        <Box
          sx={{
            position: "fixed",
            zIndex: 999,
            bottom: 0,
            width: 500,
            backgroundClolor: "white",
          }}
        >
          <Box sx={{ borderTop: "1px solid #00000030" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "6px 32px",
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "var(--secondary-color)",
                }}
              >
                SubTotal
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                €{subTotal}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "6px 32px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "var(--secondary-color)",
                }}
              >
                Shipping
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                --
              </Typography>
            </Box>
          </Box>
          <Box sx={{ borderTop: "1px solid #00000030" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "6px 32px",
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "var(--secondary-color)",
                }}
              >
                Total(€)
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                €{subTotal}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "6px 32px",
                mb: 2,
                mt: 1,
              }}
            >
              <Button
                size="medium"
                color="primary"
                style={{
                  width: "100%",
                  backgroundColor: "var(--primary-color)",
                  border: "none",
                  "&:hover": {
                    backgroundColor: "var(--primary-color-hover)",
                  },
                }}
              >
                Confirm Order
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <div>
      <div className="navigation-bar fixed-top">
        <div className="top-navigation">
          <Grid
            container
            p={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "var(--primary-color)",
              color: "var(--white-color)",
            }}
          >
            <Grid item paddingLeft={1}>
              {t("sitoInCostruzione")}
            </Grid>
            <Grid
              item
              paddingRight={1}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "var(--primary-color)",
              }}
            >
              {/* <Tooltip title="Email">
                <img src="../images/sms.png" height={20} className="me-3" />
              </Tooltip>
              <Tooltip title="Search">
                <img
                  src="../images/search-normal.png"
                  height={20}
                  className="me-3"
                />
              </Tooltip> */}
              <div>
                <div className="user-area">
                  {currentUser && currentUser.role === "Admin" && (
                    <Tooltip title="Admin Panel">
                      <Link
                        to="/admin"
                        style={{
                          color: "#fff",
                          textDecoration: "none",
                        }}
                      >
                        <img
                          src="../images/admin.png"
                          height={20}
                          className="me-3"
                        />
                      </Link>
                    </Tooltip>
                  )}
                  {currentUser && currentUser.role === "Agent" && (
                    <Link
                      to="/agent"
                      style={{
                        color: "#fff",
                        textDecoration: "none",
                      }}
                    >
                      <button className="btn-navbar">{t("agentPanel")}</button>
                    </Link>
                  )}
                  {currentUser && currentUser.role === "Installer" && (
                    <Link
                      to="/agent"
                      style={{
                        color: "#fff",
                        textDecoration: "none",
                      }}
                    >
                      <button className="btn-navbar">{t("agentPanel")}</button>
                    </Link>
                  )}
                  {currentUser ? (
                    <>
                      <Tooltip title="logout">
                        <Link
                          to="/"
                          style={{
                            color: "#fff",
                            textDecoration: "none",
                          }}
                        >
                          <img
                            src="../images/logout.png"
                            height={20}
                            onClick={doLogout}
                          />
                        </Link>
                      </Tooltip>
                    </>
                  ) : (
                    // <button
                    //   variant="success"
                    //   className="btn-navbar"
                    //   onClick={handleModalOpen}
                    // >
                    //   {" "}
                    //   {t("signup")}
                    // </button>
                    <Box
                      onClick={handleModalOpen}
                      sx={{
                        "&:hover": {
                          cursor: "pointer",
                        },
                      }}
                    >
                      <img
                        src="../images/user-square.png"
                        height={20}
                        // className="me-3"
                      />
                    </Box>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="main-navigation">
          <Navbar
            className="top-navigation"
            collapseOnSelect
            expand="lg"
            variant="light"
          >
            <Container
              fluid
              className="custom-navbar flex-between"
              style={{
                borderBottom: "1px solid #d7d7d7",
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid item xs={6} sm={3} md={3} lg={3} id="first-grid">
                  <div>
                    <Navbar.Brand href="/">
                      <img
                        src="/LogoBlack.svg"
                        height="48"
                        className="d-inline-block align-top"
                        alt="FV02 logo"
                      />
                    </Navbar.Brand>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} id="second-grid">
                  <div>
                    <Navbar id="responsive-navbar-nav">
                      <Nav className="m-auto">
                        <Nav.Link className="text-color-nav" href="/">
                          {t("home")}
                        </Nav.Link>
                        <NavDropdown
                          title={t("product")}
                          id="collasible-nav-dropdown"
                          style={{
                            zIndex: 99,
                          }}
                        >
                          <NavDropdown.Item
                            href={`/product/${localStorage.getItem(
                              "solarpanel"
                            )}`}
                          >
                            {t("solarPanel")}
                          </NavDropdown.Item>
                          <NavDropdown.Divider />

                          <NavDropdown.Item
                            href={`/product/${localStorage.getItem(
                              "inverter"
                            )}`}
                          >
                            {t("inverter")}
                          </NavDropdown.Item>
                          <NavDropdown.Divider />

                          <NavDropdown.Item
                            href={`/product/${localStorage.getItem("storage")}`}
                          >
                            {t("storage")}
                          </NavDropdown.Item>
                          <NavDropdown.Divider />

                          <NavDropdown.Item
                            href={`/product/${localStorage.getItem(
                              "mounting"
                            )}`}
                          >
                            {t("mounting")}
                          </NavDropdown.Item>
                          <NavDropdown.Divider />

                          <NavDropdown.Item
                            href={`/product/${localStorage.getItem("carport")}`}
                          >
                            {t("carPort")}
                          </NavDropdown.Item>
                        </NavDropdown>

                        <Nav.Link href="/contact-us">{t("contactus")}</Nav.Link>

                        {/* <Nav.Link href="about-us">{t("aboutus")}</Nav.Link> */}
                      </Nav>

                      <Nav className="pe-3">
                        {/* <Badge badgeContent={4} color="info" className="">
                    <FaShoppingCart color="#253745" size="1.8em"  onClick={handleDrawerOpen} />  
                  </Badge> */}
                      </Nav>
                    </Navbar>
                  </div>
                </Grid>

                <Grid item xs={6} sm={3} md={3} lg={3} id="third-grid">
                  <div className="last-col align-items-center">
                    {/* <div className="user-area">
                  {currentUser && currentUser.role === "Admin" && (
                    <Link to="/admin">
                      <button className="btn-navbar">{t("adminPanel")}</button>
                    </Link>
                  )}
                  {currentUser && currentUser.role === "Agent" && (
                    <Link to="/agent">
                      <button className="btn-navbar">
                        {" "}
                        {t("agentPanel")}{" "}
                      </button>
                    </Link>
                  )}
                  {currentUser && currentUser.role === "Installer" && (
                    <Link to="/agent">
                      <button className="btn-navbar">
                        {" "}
                        {t("agentPanel")}{" "}
                      </button>
                    </Link>
                  )}
                  {currentUser ? (
                    <>
                      <Link to="/">
                        <button className="btn-navbar" onClick={doLogout}>
                          {" "}
                          {t("logout")}{" "}
                        </button>
                      </Link>
                    </>
                  ) : (
                    <button
                      variant="success"
                      className="btn-navbar"
                      onClick={handleModalOpen}
                    >
                      {" "}
                      {t("signup")}
                    </button>
                  )}
                </div> */}

                    <div className="ms-3 d-flex align-items-center">
                      <div>
                        <Tooltip title={t("addToCart")}>
                          <AddShoppingCartIcon
                            onClick={() => {
                              doAddSelectedItem();
                            }}
                            sx={{ color: "#949eb2", fontSize: "24px" }}
                          />
                        </Tooltip>
                        <Drawer
                          anchor={"right"}
                          open={state["right"]}
                          onClose={() => {
                            toggleDrawerClose("right", false);
                          }}
                          style={{ padding: "24px" }}
                        >
                          {listcart("right", cartList)}
                        </Drawer>
                      </div>
                    </div>

                    <div className="ms-3 d-flex align-items-center language">
                      <img src="../images/Vector.png" height={20} />

                      <select
                        as="select"
                        className="form-select"
                        value={i18n.language}
                        onChange={changeLanguage}
                        aria-label=".form-select-lg example"
                      >
                        <option value="it">Italiano</option>
                        <option value="en">English</option>
                      </select>
                    </div>
                  </div>
                </Grid>
              </Grid>

              {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
            </Container>
          </Navbar>
        </div>

        <Login
          modalOpen={modalOpen}
          handleModalOpen={handleModalOpen}
          handleModalOpenForgot={handleModalOpenForgot}
          isLogoutValueClear={isLogoutValueClear}
        />

        <ForgotPassword
          modalOpenForgot={modalOpenForgot}
          handleModalOpenForgot={handleModalOpenForgot}
        />
        <CartDrawer
          showDrawer={showDrawer}
          handleDrawerOpen={handleDrawerOpen}
        />
      </div>
      {/* <Login show={true} /> */}
    </div>
  );
}

export default Navigationbar;
